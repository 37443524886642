import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useDebounce } from "usehooks-ts";
import orderApi from "../../../../api/order";
import { FiltersSearchParams, Paged } from "../../../../models/common";
import { Order } from "../../../../models/order";
import { Button } from "../../../../styles/button";
import { FadeInBottom } from "../../../../styles/fadeIn";
import { PageSection } from "../../../../styles/layout";
import { TableHeaderActions } from "../../../../styles/table";
import { H2 } from "../../../../styles/title";
import {
  DEBOUNCE_TIMEOUT_MS,
  DEFAULT_PAGE_SIZE,
} from "../../../../utils/constants";
import { getErrorMessage } from "../../../../utils/errors";
import { useAdminOrdersFilterStore } from "../../../../utils/store";
import {
  OnChangeOrder,
  OrderType,
  WithRequired,
} from "../../../../utils/types";
import FilterTextInput from "../../../common/form/Filters/FilterTextInput";
import { FilterContainer } from "../../../common/form/Filters/styles";
import Pagination from "../../../common/Pagination/Pagination";
import AdminOrdersTable from "../AdminOrdersTable/AdminOrdersTable";

export interface AdminOrdersFilter
  extends WithRequired<FiltersSearchParams, "page"> {
  name?: string;
  cardNumber?: string;
}

export const DEFAULT_ADMIN_ORDERS_FILTER: AdminOrdersFilter = { page: 1 };

const AdminOrdersContent: FC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const [ordersData, setOrdersData] = useState<Paged<Order>>({
    count: 0,
    data: [],
  });

  const { filters, setFilters } = useAdminOrdersFilterStore();
  const debouncedFilters = useDebounce(filters, DEBOUNCE_TIMEOUT_MS);

  const order: OrderType = useMemo(
    () => ({ orderBy: filters.orderBy, orderDesc: filters.orderDesc }),
    [filters.orderBy, filters.orderDesc]
  );
  const handleChangeOrder = useCallback<OnChangeOrder>(
    (orderBy, orderDesc) => {
      setFilters({ ...filters, orderBy, orderDesc });
    },
    [filters, setFilters]
  );

  const handleResetFilter = useCallback(() => {
    setFilters(DEFAULT_ADMIN_ORDERS_FILTER);
  }, [setFilters]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const response = await orderApi.getAllOrders({
          ...(debouncedFilters.name && { name: debouncedFilters.name }),
          ...(debouncedFilters.cardNumber && {
            cardNumber: debouncedFilters.cardNumber,
          }),
          ...(debouncedFilters.orderBy && {
            orderBy: debouncedFilters.orderBy,
          }),
          ...(debouncedFilters.orderDesc && {
            orderDesc: debouncedFilters.orderDesc,
          }),
          pageSize: DEFAULT_PAGE_SIZE,
          page: debouncedFilters.page,
        });
        setOrdersData(response.data);
      } catch (err) {
        toast.error(getErrorMessage(err, t("errors.loadError")));
      }
      setLoading(false);
    })();
  }, [t, debouncedFilters]);

  return (
    <PageSection>
      <FadeInBottom>
        <TableHeaderActions>
          <div>
            <H2>{t("adminOrders.title")}</H2>
          </div>
        </TableHeaderActions>
        <TableHeaderActions>
          <FilterContainer>
            <FilterTextInput
              value={filters.name}
              label={t("adminClients.filter.name")}
              onInputChange={(value) => {
                setFilters({
                  ...filters,
                  name: value,
                  page: 1,
                });
              }}
            />
            <FilterTextInput
              value={filters.cardNumber}
              label={t("adminClients.filter.cardNumber")}
              onInputChange={(value) => {
                setFilters({
                  ...filters,
                  cardNumber: value,
                  page: 1,
                });
              }}
            />
            <Button
              onClick={handleResetFilter}
              style={{ alignSelf: "flex-end" }}
            >
              {t("common.resetFilter")}
            </Button>
          </FilterContainer>
        </TableHeaderActions>
        <TableHeaderActions>
          <b>
            {(!loading || ordersData.count > 0) &&
              `${ordersData.count} ${t("adminOrders.countTextPlural")}`}
          </b>
        </TableHeaderActions>
        <FadeInBottom>
          <AdminOrdersTable
            data={ordersData.data}
            order={order}
            onChangeOrder={handleChangeOrder}
            isLoading={loading}
          />
          <Pagination
            initialPage={filters.page}
            totalCount={ordersData.count}
            onPageChange={(n: number) => setFilters({ ...filters, page: n })}
          />
        </FadeInBottom>
      </FadeInBottom>
    </PageSection>
  );
};

export default AdminOrdersContent;
