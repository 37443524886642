export const IconNames = [
  { prefix: "fas", iconName: "ad" },
  { prefix: "fas", iconName: "address-book" },
  { prefix: "far", iconName: "address-book" },
  { prefix: "fas", iconName: "address-card" },
  { prefix: "far", iconName: "address-card" },
  { prefix: "fas", iconName: "adjust" },
  { prefix: "fas", iconName: "air-freshener" },
  { prefix: "fas", iconName: "align-center" },
  { prefix: "fas", iconName: "align-justify" },
  { prefix: "fas", iconName: "align-left" },
  { prefix: "fas", iconName: "align-right" },
  { prefix: "fas", iconName: "allergies" },
  { prefix: "fas", iconName: "ambulance" },
  { prefix: "fas", iconName: "american-sign-language-interpreting" },
  { prefix: "fas", iconName: "angle-double-down" },
  { prefix: "fas", iconName: "angle-double-left" },
  { prefix: "fas", iconName: "angle-double-right" },
  { prefix: "fas", iconName: "angle-double-up" },
  { prefix: "fas", iconName: "angle-down" },
  { prefix: "fas", iconName: "angle-left" },
  { prefix: "fas", iconName: "angle-right" },
  { prefix: "fas", iconName: "angle-up" },
  { prefix: "fas", iconName: "angry" },
  { prefix: "far", iconName: "angry" },
  { prefix: "fas", iconName: "anchor" },
  { prefix: "fas", iconName: "ankh" },
  { prefix: "fas", iconName: "apple-alt" },
  { prefix: "fas", iconName: "archive" },
  { prefix: "fas", iconName: "archway" },
  { prefix: "fas", iconName: "arrow-alt-circle-down" },
  { prefix: "far", iconName: "arrow-alt-circle-down" },
  { prefix: "fas", iconName: "arrow-alt-circle-left" },
  { prefix: "far", iconName: "arrow-alt-circle-left" },
  { prefix: "fas", iconName: "arrow-alt-circle-right" },
  { prefix: "far", iconName: "arrow-alt-circle-right" },
  { prefix: "fas", iconName: "arrow-alt-circle-up" },
  { prefix: "far", iconName: "arrow-alt-circle-up" },
  { prefix: "fas", iconName: "arrow-circle-down" },
  { prefix: "fas", iconName: "arrow-circle-left" },
  { prefix: "fas", iconName: "arrow-circle-right" },
  { prefix: "fas", iconName: "arrow-circle-up" },
  { prefix: "fas", iconName: "arrow-down" },
  { prefix: "fas", iconName: "arrow-left" },
  { prefix: "fas", iconName: "arrow-right" },
  { prefix: "fas", iconName: "arrows-alt" },
  { prefix: "fas", iconName: "arrows-alt-h" },
  { prefix: "fas", iconName: "arrows-alt-v" },
  { prefix: "fas", iconName: "arrow-up" },
  { prefix: "fas", iconName: "assistive-listening-systems" },
  { prefix: "fas", iconName: "asterisk" },
  { prefix: "fas", iconName: "at" },
  { prefix: "fas", iconName: "atlas" },
  { prefix: "fas", iconName: "atom" },
  { prefix: "fas", iconName: "audio-description" },
  { prefix: "fas", iconName: "award" },
  { prefix: "fas", iconName: "baby" },
  { prefix: "fas", iconName: "baby-carriage" },
  { prefix: "fas", iconName: "backspace" },
  { prefix: "fas", iconName: "backward" },
  { prefix: "fas", iconName: "bacon" },
  { prefix: "fas", iconName: "bacteria" },
  { prefix: "fas", iconName: "bacterium" },
  { prefix: "fas", iconName: "bahai" },
  { prefix: "fas", iconName: "balance-scale" },
  { prefix: "fas", iconName: "balance-scale-left" },
  { prefix: "fas", iconName: "balance-scale-right" },
  { prefix: "fas", iconName: "ban" },
  { prefix: "fas", iconName: "band-aid" },
  { prefix: "fas", iconName: "barcode" },
  { prefix: "fas", iconName: "bars" },
  { prefix: "fas", iconName: "baseball-ball" },
  { prefix: "fas", iconName: "basketball-ball" },
  { prefix: "fas", iconName: "bath" },
  { prefix: "fas", iconName: "battery-empty" },
  { prefix: "fas", iconName: "battery-full" },
  { prefix: "fas", iconName: "battery-half" },
  { prefix: "fas", iconName: "battery-quarter" },
  { prefix: "fas", iconName: "battery-three-quarters" },
  { prefix: "fas", iconName: "bed" },
  { prefix: "fas", iconName: "beer" },
  { prefix: "fas", iconName: "bell" },
  { prefix: "far", iconName: "bell" },
  { prefix: "fas", iconName: "bell-slash" },
  { prefix: "far", iconName: "bell-slash" },
  { prefix: "fas", iconName: "bezier-curve" },
  { prefix: "fas", iconName: "bible" },
  { prefix: "fas", iconName: "bicycle" },
  { prefix: "fas", iconName: "biking" },
  { prefix: "fas", iconName: "binoculars" },
  { prefix: "fas", iconName: "biohazard" },
  { prefix: "fas", iconName: "birthday-cake" },
  { prefix: "fas", iconName: "blender" },
  { prefix: "fas", iconName: "blender-phone" },
  { prefix: "fas", iconName: "blind" },
  { prefix: "fas", iconName: "blog" },
  { prefix: "fas", iconName: "bold" },
  { prefix: "fas", iconName: "bolt" },
  { prefix: "fas", iconName: "bomb" },
  { prefix: "fas", iconName: "bone" },
  { prefix: "fas", iconName: "bong" },
  { prefix: "fas", iconName: "book" },
  { prefix: "fas", iconName: "book-dead" },
  { prefix: "fas", iconName: "bookmark" },
  { prefix: "far", iconName: "bookmark" },
  { prefix: "fas", iconName: "book-medical" },
  { prefix: "fas", iconName: "book-open" },
  { prefix: "fas", iconName: "book-reader" },
  { prefix: "fas", iconName: "border-all" },
  { prefix: "fas", iconName: "border-none" },
  { prefix: "fas", iconName: "border-style" },
  { prefix: "fas", iconName: "bowling-ball" },
  { prefix: "fas", iconName: "box" },
  { prefix: "fas", iconName: "boxes" },
  { prefix: "fas", iconName: "box-open" },
  { prefix: "fas", iconName: "box-tissue" },
  { prefix: "fas", iconName: "braille" },
  { prefix: "fas", iconName: "brain" },
  { prefix: "fas", iconName: "bread-slice" },
  { prefix: "fas", iconName: "briefcase" },
  { prefix: "fas", iconName: "briefcase-medical" },
  { prefix: "fas", iconName: "broadcast-tower" },
  { prefix: "fas", iconName: "broom" },
  { prefix: "fas", iconName: "brush" },
  { prefix: "fas", iconName: "bug" },
  { prefix: "fas", iconName: "building" },
  { prefix: "far", iconName: "building" },
  { prefix: "fas", iconName: "bullhorn" },
  { prefix: "fas", iconName: "bullseye" },
  { prefix: "fas", iconName: "burn" },
  { prefix: "fas", iconName: "bus" },
  { prefix: "fas", iconName: "bus-alt" },
  { prefix: "fas", iconName: "business-time" },
  { prefix: "fas", iconName: "calculator" },
  { prefix: "fas", iconName: "calendar" },
  { prefix: "far", iconName: "calendar" },
  { prefix: "fas", iconName: "calendar-alt" },
  { prefix: "far", iconName: "calendar-alt" },
  { prefix: "fas", iconName: "calendar-day" },
  { prefix: "fas", iconName: "calendar-check" },
  { prefix: "far", iconName: "calendar-check" },
  { prefix: "fas", iconName: "calendar-minus" },
  { prefix: "far", iconName: "calendar-minus" },
  { prefix: "fas", iconName: "calendar-plus" },
  { prefix: "far", iconName: "calendar-plus" },
  { prefix: "fas", iconName: "calendar-times" },
  { prefix: "far", iconName: "calendar-times" },
  { prefix: "fas", iconName: "calendar-week" },
  { prefix: "fas", iconName: "camera" },
  { prefix: "fas", iconName: "camera-retro" },
  { prefix: "fas", iconName: "campground" },
  { prefix: "fas", iconName: "candy-cane" },
  { prefix: "fas", iconName: "cannabis" },
  { prefix: "fas", iconName: "capsules" },
  { prefix: "fas", iconName: "car" },
  { prefix: "fas", iconName: "car-alt" },
  { prefix: "fas", iconName: "caravan" },
  { prefix: "fas", iconName: "car-battery" },
  { prefix: "fas", iconName: "car-crash" },
  { prefix: "fas", iconName: "caret-down" },
  { prefix: "fas", iconName: "caret-left" },
  { prefix: "fas", iconName: "caret-right" },
  { prefix: "fas", iconName: "caret-square-down" },
  { prefix: "far", iconName: "caret-square-down" },
  { prefix: "fas", iconName: "caret-square-left" },
  { prefix: "far", iconName: "caret-square-left" },
  { prefix: "fas", iconName: "caret-square-right" },
  { prefix: "far", iconName: "caret-square-right" },
  { prefix: "fas", iconName: "caret-square-up" },
  { prefix: "far", iconName: "caret-square-up" },
  { prefix: "fas", iconName: "caret-up" },
  { prefix: "fas", iconName: "carrot" },
  { prefix: "fas", iconName: "car-side" },
  { prefix: "fas", iconName: "cart-arrow-down" },
  { prefix: "fas", iconName: "cart-plus" },
  { prefix: "fas", iconName: "cash-register" },
  { prefix: "fas", iconName: "cat" },
  { prefix: "fas", iconName: "certificate" },
  { prefix: "fas", iconName: "circle" },
  { prefix: "far", iconName: "circle" },
  { prefix: "fas", iconName: "circle-notch" },
  { prefix: "fas", iconName: "city" },
  { prefix: "fas", iconName: "clinic-medical" },
  { prefix: "fas", iconName: "clipboard" },
  { prefix: "far", iconName: "clipboard" },
  { prefix: "fas", iconName: "clipboard-check" },
  { prefix: "fas", iconName: "clipboard-list" },
  { prefix: "fas", iconName: "clock" },
  { prefix: "far", iconName: "clock" },
  { prefix: "fas", iconName: "clone" },
  { prefix: "far", iconName: "clone" },
  { prefix: "fas", iconName: "closed-captioning" },
  { prefix: "far", iconName: "closed-captioning" },
  { prefix: "fas", iconName: "cloud" },
  { prefix: "fas", iconName: "cloud-download-alt" },
  { prefix: "fas", iconName: "cloud-meatball" },
  { prefix: "fas", iconName: "cloud-moon" },
  { prefix: "fas", iconName: "cloud-moon-rain" },
  { prefix: "fas", iconName: "cloud-rain" },
  { prefix: "fas", iconName: "cloud-showers-heavy" },
  { prefix: "fas", iconName: "cloud-sun" },
  { prefix: "fas", iconName: "cloud-sun-rain" },
  { prefix: "fas", iconName: "cloud-upload-alt" },
  { prefix: "fas", iconName: "cocktail" },
  { prefix: "fas", iconName: "code" },
  { prefix: "fas", iconName: "code-branch" },
  { prefix: "fas", iconName: "coffee" },
  { prefix: "fas", iconName: "cog" },
  { prefix: "fas", iconName: "cogs" },
  { prefix: "fas", iconName: "coins" },
  { prefix: "fas", iconName: "columns" },
  { prefix: "fas", iconName: "comment" },
  { prefix: "far", iconName: "comment" },
  { prefix: "fas", iconName: "comment-alt" },
  { prefix: "far", iconName: "comment-alt" },
  { prefix: "fas", iconName: "comment-dollar" },
  { prefix: "fas", iconName: "comment-dots" },
  { prefix: "far", iconName: "comment-dots" },
  { prefix: "fas", iconName: "comment-medical" },
  { prefix: "fas", iconName: "comments" },
  { prefix: "far", iconName: "comments" },
  { prefix: "fas", iconName: "comments-dollar" },
  { prefix: "fas", iconName: "comment-slash" },
  { prefix: "fas", iconName: "compact-disc" },
  { prefix: "fas", iconName: "compass" },
  { prefix: "far", iconName: "compass" },
  { prefix: "fas", iconName: "compress" },
  { prefix: "fas", iconName: "compress-alt" },
  { prefix: "fas", iconName: "compress-arrows-alt" },
  { prefix: "fas", iconName: "concierge-bell" },
  { prefix: "fas", iconName: "cookie" },
  { prefix: "fas", iconName: "cookie-bite" },
  { prefix: "fas", iconName: "copy" },
  { prefix: "far", iconName: "copy" },
  { prefix: "fas", iconName: "copyright" },
  { prefix: "far", iconName: "copyright" },
  { prefix: "fas", iconName: "couch" },
  { prefix: "fas", iconName: "credit-card" },
  { prefix: "far", iconName: "credit-card" },
  { prefix: "fas", iconName: "crop" },
  { prefix: "fas", iconName: "crop-alt" },
  { prefix: "fas", iconName: "cross" },
  { prefix: "fas", iconName: "crosshairs" },
  { prefix: "fas", iconName: "crow" },
  { prefix: "fas", iconName: "crown" },
  { prefix: "fas", iconName: "crutch" },
  { prefix: "fas", iconName: "cube" },
  { prefix: "fas", iconName: "cubes" },
  { prefix: "fas", iconName: "cut" },
  { prefix: "fas", iconName: "database" },
  { prefix: "fas", iconName: "deaf" },
  { prefix: "fas", iconName: "democrat" },
  { prefix: "fas", iconName: "desktop" },
  { prefix: "fas", iconName: "dharmachakra" },
  { prefix: "fas", iconName: "diagnoses" },
  { prefix: "fas", iconName: "dice" },
  { prefix: "fas", iconName: "dice-d20" },
  { prefix: "fas", iconName: "dice-d6" },
  { prefix: "fas", iconName: "dice-five" },
  { prefix: "fas", iconName: "dice-four" },
  { prefix: "fas", iconName: "dice-one" },
  { prefix: "fas", iconName: "dice-six" },
  { prefix: "fas", iconName: "dice-three" },
  { prefix: "fas", iconName: "dice-two" },
  { prefix: "fas", iconName: "digital-tachograph" },
  { prefix: "fas", iconName: "directions" },
  { prefix: "fas", iconName: "disease" },
  { prefix: "fas", iconName: "divide" },
  { prefix: "fas", iconName: "dizzy" },
  { prefix: "far", iconName: "dizzy" },
  { prefix: "fas", iconName: "dna" },
  { prefix: "fas", iconName: "dog" },
  { prefix: "fas", iconName: "dollar-sign" },
  { prefix: "fas", iconName: "dolly" },
  { prefix: "fas", iconName: "dolly-flatbed" },
  { prefix: "fas", iconName: "donate" },
  { prefix: "fas", iconName: "door-closed" },
  { prefix: "fas", iconName: "door-open" },
  { prefix: "fas", iconName: "dot-circle" },
  { prefix: "far", iconName: "dot-circle" },
  { prefix: "fas", iconName: "dove" },
  { prefix: "fas", iconName: "download" },
  { prefix: "fas", iconName: "drafting-compass" },
  { prefix: "fas", iconName: "dragon" },
  { prefix: "fas", iconName: "draw-polygon" },
  { prefix: "fas", iconName: "drum" },
  { prefix: "fas", iconName: "drum-steelpan" },
  { prefix: "fas", iconName: "drumstick-bite" },
  { prefix: "fas", iconName: "dumbbell" },
  { prefix: "fas", iconName: "dumpster" },
  { prefix: "fas", iconName: "dumpster-fire" },
  { prefix: "fas", iconName: "dungeon" },
  { prefix: "fas", iconName: "edit" },
  { prefix: "far", iconName: "edit" },
  { prefix: "fas", iconName: "egg" },
  { prefix: "fas", iconName: "eject" },
  { prefix: "fas", iconName: "ellipsis-h" },
  { prefix: "fas", iconName: "ellipsis-v" },
  { prefix: "fas", iconName: "envelope" },
  { prefix: "far", iconName: "envelope" },
  { prefix: "fas", iconName: "envelope-open" },
  { prefix: "far", iconName: "envelope-open" },
  { prefix: "fas", iconName: "envelope-open-text" },
  { prefix: "fas", iconName: "envelope-square" },
  { prefix: "fas", iconName: "equals" },
  { prefix: "fas", iconName: "eraser" },
  { prefix: "fas", iconName: "ethernet" },
  { prefix: "fas", iconName: "euro-sign" },
  { prefix: "fas", iconName: "exclamation" },
  { prefix: "fas", iconName: "exclamation-circle" },
  { prefix: "fas", iconName: "exclamation-triangle" },
  { prefix: "fas", iconName: "exchange-alt" },
  { prefix: "fas", iconName: "expand" },
  { prefix: "fas", iconName: "expand-alt" },
  { prefix: "fas", iconName: "expand-arrows-alt" },
  { prefix: "fas", iconName: "external-link-alt" },
  { prefix: "fas", iconName: "external-link-square-alt" },
  { prefix: "fas", iconName: "eye" },
  { prefix: "far", iconName: "eye" },
  { prefix: "fas", iconName: "eye-dropper" },
  { prefix: "fas", iconName: "eye-slash" },
  { prefix: "far", iconName: "eye-slash" },
  { prefix: "fas", iconName: "fan" },
  { prefix: "fas", iconName: "fast-backward" },
  { prefix: "fas", iconName: "fast-forward" },
  { prefix: "fas", iconName: "faucet" },
  { prefix: "fas", iconName: "fax" },
  { prefix: "fas", iconName: "feather" },
  { prefix: "fas", iconName: "feather-alt" },
  { prefix: "fas", iconName: "female" },
  { prefix: "fas", iconName: "fighter-jet" },
  { prefix: "fas", iconName: "file" },
  { prefix: "far", iconName: "file" },
  { prefix: "fas", iconName: "file-alt" },
  { prefix: "far", iconName: "file-alt" },
  { prefix: "fas", iconName: "file-archive" },
  { prefix: "far", iconName: "file-archive" },
  { prefix: "fas", iconName: "file-audio" },
  { prefix: "far", iconName: "file-audio" },
  { prefix: "fas", iconName: "file-code" },
  { prefix: "far", iconName: "file-code" },
  { prefix: "fas", iconName: "file-contract" },
  { prefix: "fas", iconName: "file-csv" },
  { prefix: "fas", iconName: "file-download" },
  { prefix: "fas", iconName: "file-excel" },
  { prefix: "far", iconName: "file-excel" },
  { prefix: "fas", iconName: "file-export" },
  { prefix: "fas", iconName: "file-image" },
  { prefix: "far", iconName: "file-image" },
  { prefix: "fas", iconName: "file-import" },
  { prefix: "fas", iconName: "file-invoice" },
  { prefix: "fas", iconName: "file-invoice-dollar" },
  { prefix: "fas", iconName: "file-medical" },
  { prefix: "fas", iconName: "file-medical-alt" },
  { prefix: "fas", iconName: "file-pdf" },
  { prefix: "far", iconName: "file-pdf" },
  { prefix: "fas", iconName: "file-powerpoint" },
  { prefix: "far", iconName: "file-powerpoint" },
  { prefix: "fas", iconName: "file-prescription" },
  { prefix: "fas", iconName: "file-signature" },
  { prefix: "fas", iconName: "file-upload" },
  { prefix: "fas", iconName: "file-video" },
  { prefix: "far", iconName: "file-video" },
  { prefix: "fas", iconName: "file-word" },
  { prefix: "far", iconName: "file-word" },
  { prefix: "fas", iconName: "fill" },
  { prefix: "fas", iconName: "fill-drip" },
  { prefix: "fas", iconName: "film" },
  { prefix: "fas", iconName: "filter" },
  { prefix: "fas", iconName: "fingerprint" },
  { prefix: "fas", iconName: "fire" },
  { prefix: "fas", iconName: "fire-alt" },
  { prefix: "fas", iconName: "fire-extinguisher" },
  { prefix: "fas", iconName: "first-aid" },
  { prefix: "fas", iconName: "fish" },
  { prefix: "fas", iconName: "fist-raised" },
  { prefix: "fas", iconName: "flag" },
  { prefix: "far", iconName: "flag" },
  { prefix: "fas", iconName: "flag-checkered" },
  { prefix: "fas", iconName: "flag-usa" },
  { prefix: "fas", iconName: "flask" },
  { prefix: "fas", iconName: "flushed" },
  { prefix: "far", iconName: "flushed" },
  { prefix: "fas", iconName: "folder" },
  { prefix: "far", iconName: "folder" },
  { prefix: "fas", iconName: "folder-minus" },
  { prefix: "fas", iconName: "folder-open" },
  { prefix: "far", iconName: "folder-open" },
  { prefix: "fas", iconName: "folder-plus" },
  { prefix: "fas", iconName: "font" },
  //  { prefix: "fas", iconName: "font-awesome-logo-full" },
  //  { prefix: "far", iconName: "font-awesome-logo-full" },
  { prefix: "fas", iconName: "football-ball" },
  { prefix: "fas", iconName: "forward" },
  { prefix: "fas", iconName: "frog" },
  { prefix: "fas", iconName: "frown" },
  { prefix: "far", iconName: "frown" },
  { prefix: "fas", iconName: "frown-open" },
  { prefix: "far", iconName: "frown-open" },
  { prefix: "fas", iconName: "funnel-dollar" },
  { prefix: "fas", iconName: "futbol" },
  { prefix: "far", iconName: "futbol" },
  { prefix: "fas", iconName: "gamepad" },
  { prefix: "fas", iconName: "gas-pump" },
  { prefix: "fas", iconName: "gavel" },
  { prefix: "fas", iconName: "gem" },
  { prefix: "far", iconName: "gem" },
  { prefix: "fas", iconName: "genderless" },
  { prefix: "fas", iconName: "ghost" },
  { prefix: "fas", iconName: "gift" },
  { prefix: "fas", iconName: "gifts" },
  { prefix: "fas", iconName: "glasses" },
  { prefix: "fas", iconName: "glass-cheers" },
  { prefix: "fas", iconName: "glass-martini" },
  { prefix: "fas", iconName: "glass-martini-alt" },
  { prefix: "fas", iconName: "glass-whiskey" },
  { prefix: "fas", iconName: "globe" },
  { prefix: "fas", iconName: "globe-africa" },
  { prefix: "fas", iconName: "globe-americas" },
  { prefix: "fas", iconName: "globe-asia" },
  { prefix: "fas", iconName: "globe-europe" },
  { prefix: "fas", iconName: "golf-ball" },
  { prefix: "fas", iconName: "gopuram" },
  { prefix: "fas", iconName: "graduation-cap" },
  { prefix: "fas", iconName: "greater-than" },
  { prefix: "fas", iconName: "greater-than-equal" },
  { prefix: "fas", iconName: "grimace" },
  { prefix: "far", iconName: "grimace" },
  { prefix: "fas", iconName: "grin" },
  { prefix: "far", iconName: "grin" },
  { prefix: "fas", iconName: "grin-alt" },
  { prefix: "far", iconName: "grin-alt" },
  { prefix: "fas", iconName: "grin-beam" },
  { prefix: "far", iconName: "grin-beam" },
  { prefix: "fas", iconName: "grin-beam-sweat" },
  { prefix: "far", iconName: "grin-beam-sweat" },
  { prefix: "fas", iconName: "grin-hearts" },
  { prefix: "far", iconName: "grin-hearts" },
  { prefix: "fas", iconName: "grin-squint" },
  { prefix: "far", iconName: "grin-squint" },
  { prefix: "fas", iconName: "grin-squint-tears" },
  { prefix: "far", iconName: "grin-squint-tears" },
  { prefix: "fas", iconName: "grin-stars" },
  { prefix: "far", iconName: "grin-stars" },
  { prefix: "fas", iconName: "grin-tears" },
  { prefix: "far", iconName: "grin-tears" },
  { prefix: "fas", iconName: "grin-tongue" },
  { prefix: "far", iconName: "grin-tongue" },
  { prefix: "fas", iconName: "grin-tongue-squint" },
  { prefix: "far", iconName: "grin-tongue-squint" },
  { prefix: "fas", iconName: "grin-tongue-wink" },
  { prefix: "far", iconName: "grin-tongue-wink" },
  { prefix: "fas", iconName: "grin-wink" },
  { prefix: "far", iconName: "grin-wink" },
  { prefix: "fas", iconName: "grip-horizontal" },
  { prefix: "fas", iconName: "grip-lines" },
  { prefix: "fas", iconName: "grip-lines-vertical" },
  { prefix: "fas", iconName: "grip-vertical" },
  { prefix: "fas", iconName: "guitar" },
  { prefix: "fas", iconName: "hamburger" },
  { prefix: "fas", iconName: "hammer" },
  { prefix: "fas", iconName: "hamsa" },
  { prefix: "fas", iconName: "hand-holding" },
  { prefix: "fas", iconName: "hand-holding-heart" },
  { prefix: "fas", iconName: "hand-holding-medical" },
  { prefix: "fas", iconName: "hand-holding-usd" },
  { prefix: "fas", iconName: "hand-holding-water" },
  { prefix: "fas", iconName: "hand-lizard" },
  { prefix: "far", iconName: "hand-lizard" },
  { prefix: "fas", iconName: "hand-middle-finger" },
  { prefix: "fas", iconName: "hand-paper" },
  { prefix: "far", iconName: "hand-paper" },
  { prefix: "fas", iconName: "hand-peace" },
  { prefix: "far", iconName: "hand-peace" },
  { prefix: "fas", iconName: "hand-point-down" },
  { prefix: "far", iconName: "hand-point-down" },
  { prefix: "fas", iconName: "hand-pointer" },
  { prefix: "far", iconName: "hand-pointer" },
  { prefix: "fas", iconName: "hand-point-left" },
  { prefix: "far", iconName: "hand-point-left" },
  { prefix: "fas", iconName: "hand-point-right" },
  { prefix: "far", iconName: "hand-point-right" },
  { prefix: "fas", iconName: "hand-point-up" },
  { prefix: "far", iconName: "hand-point-up" },
  { prefix: "fas", iconName: "hand-rock" },
  { prefix: "far", iconName: "hand-rock" },
  { prefix: "fas", iconName: "hands" },
  { prefix: "fas", iconName: "hand-scissors" },
  { prefix: "far", iconName: "hand-scissors" },
  { prefix: "fas", iconName: "handshake" },
  { prefix: "far", iconName: "handshake" },
  { prefix: "fas", iconName: "handshake-alt-slash" },
  { prefix: "fas", iconName: "handshake-slash" },
  { prefix: "fas", iconName: "hands-helping" },
  { prefix: "fas", iconName: "hand-sparkles" },
  { prefix: "fas", iconName: "hand-spock" },
  { prefix: "far", iconName: "hand-spock" },
  { prefix: "fas", iconName: "hands-wash" },
  { prefix: "fas", iconName: "hanukiah" },
  { prefix: "fas", iconName: "hard-hat" },
  { prefix: "fas", iconName: "hashtag" },
  { prefix: "fas", iconName: "hat-cowboy" },
  { prefix: "fas", iconName: "hat-cowboy-side" },
  { prefix: "fas", iconName: "hat-wizard" },
  { prefix: "fas", iconName: "hdd" },
  { prefix: "far", iconName: "hdd" },
  { prefix: "fas", iconName: "heading" },
  { prefix: "fas", iconName: "headphones" },
  { prefix: "fas", iconName: "headphones-alt" },
  { prefix: "fas", iconName: "headset" },
  { prefix: "fas", iconName: "head-side-cough" },
  { prefix: "fas", iconName: "head-side-cough-slash" },
  { prefix: "fas", iconName: "head-side-mask" },
  { prefix: "fas", iconName: "head-side-virus" },
  { prefix: "fas", iconName: "heart" },
  { prefix: "far", iconName: "heart" },
  { prefix: "fas", iconName: "heartbeat" },
  { prefix: "fas", iconName: "heart-broken" },
  { prefix: "fas", iconName: "helicopter" },
  { prefix: "fas", iconName: "highlighter" },
  { prefix: "fas", iconName: "hiking" },
  { prefix: "fas", iconName: "hippo" },
  { prefix: "fas", iconName: "history" },
  { prefix: "fas", iconName: "hockey-puck" },
  { prefix: "fas", iconName: "holly-berry" },
  { prefix: "fas", iconName: "home" },
  { prefix: "fas", iconName: "horse" },
  { prefix: "fas", iconName: "horse-head" },
  { prefix: "fas", iconName: "hospital" },
  { prefix: "far", iconName: "hospital" },
  { prefix: "fas", iconName: "hospital-alt" },
  { prefix: "fas", iconName: "hospital-symbol" },
  { prefix: "fas", iconName: "hospital-user" },
  { prefix: "fas", iconName: "hotdog" },
  { prefix: "fas", iconName: "hotel" },
  { prefix: "fas", iconName: "hot-tub" },
  { prefix: "fas", iconName: "hourglass" },
  { prefix: "far", iconName: "hourglass" },
  { prefix: "fas", iconName: "hourglass-end" },
  { prefix: "fas", iconName: "hourglass-half" },
  { prefix: "fas", iconName: "hourglass-start" },
  { prefix: "fas", iconName: "house-damage" },
  { prefix: "fas", iconName: "house-user" },
  { prefix: "fas", iconName: "hryvnia" },
  { prefix: "fas", iconName: "h-square" },
  { prefix: "fas", iconName: "chair" },
  { prefix: "fas", iconName: "chalkboard" },
  { prefix: "fas", iconName: "chalkboard-teacher" },
  { prefix: "fas", iconName: "charging-station" },
  { prefix: "fas", iconName: "chart-area" },
  { prefix: "fas", iconName: "chart-bar" },
  { prefix: "far", iconName: "chart-bar" },
  { prefix: "fas", iconName: "chart-line" },
  { prefix: "fas", iconName: "chart-pie" },
  { prefix: "fas", iconName: "check" },
  { prefix: "fas", iconName: "check-circle" },
  { prefix: "far", iconName: "check-circle" },
  { prefix: "fas", iconName: "check-double" },
  { prefix: "fas", iconName: "check-square" },
  { prefix: "far", iconName: "check-square" },
  { prefix: "fas", iconName: "cheese" },
  { prefix: "fas", iconName: "chess" },
  { prefix: "fas", iconName: "chess-bishop" },
  { prefix: "fas", iconName: "chess-board" },
  { prefix: "fas", iconName: "chess-king" },
  { prefix: "fas", iconName: "chess-knight" },
  { prefix: "fas", iconName: "chess-pawn" },
  { prefix: "fas", iconName: "chess-queen" },
  { prefix: "fas", iconName: "chess-rook" },
  { prefix: "fas", iconName: "chevron-circle-down" },
  { prefix: "fas", iconName: "chevron-circle-left" },
  { prefix: "fas", iconName: "chevron-circle-right" },
  { prefix: "fas", iconName: "chevron-circle-up" },
  { prefix: "fas", iconName: "chevron-down" },
  { prefix: "fas", iconName: "chevron-left" },
  { prefix: "fas", iconName: "chevron-right" },
  { prefix: "fas", iconName: "chevron-up" },
  { prefix: "fas", iconName: "child" },
  { prefix: "fas", iconName: "church" },
  { prefix: "fas", iconName: "ice-cream" },
  { prefix: "fas", iconName: "icicles" },
  { prefix: "fas", iconName: "icons" },
  { prefix: "fas", iconName: "i-cursor" },
  { prefix: "fas", iconName: "id-badge" },
  { prefix: "far", iconName: "id-badge" },
  { prefix: "fas", iconName: "id-card" },
  { prefix: "far", iconName: "id-card" },
  { prefix: "fas", iconName: "id-card-alt" },
  { prefix: "fas", iconName: "igloo" },
  { prefix: "fas", iconName: "image" },
  { prefix: "far", iconName: "image" },
  { prefix: "fas", iconName: "images" },
  { prefix: "far", iconName: "images" },
  { prefix: "fas", iconName: "inbox" },
  { prefix: "fas", iconName: "indent" },
  { prefix: "fas", iconName: "industry" },
  { prefix: "fas", iconName: "infinity" },
  { prefix: "fas", iconName: "info" },
  { prefix: "fas", iconName: "info-circle" },
  { prefix: "fas", iconName: "italic" },
  { prefix: "fas", iconName: "jedi" },
  { prefix: "fas", iconName: "joint" },
  { prefix: "fas", iconName: "journal-whills" },
  { prefix: "fas", iconName: "kaaba" },
  { prefix: "fas", iconName: "key" },
  { prefix: "fas", iconName: "keyboard" },
  { prefix: "far", iconName: "keyboard" },
  { prefix: "fas", iconName: "khanda" },
  { prefix: "fas", iconName: "kiss" },
  { prefix: "far", iconName: "kiss" },
  { prefix: "fas", iconName: "kiss-beam" },
  { prefix: "far", iconName: "kiss-beam" },
  { prefix: "fas", iconName: "kiss-wink-heart" },
  { prefix: "far", iconName: "kiss-wink-heart" },
  { prefix: "fas", iconName: "kiwi-bird" },
  { prefix: "fas", iconName: "landmark" },
  { prefix: "fas", iconName: "language" },
  { prefix: "fas", iconName: "laptop" },
  { prefix: "fas", iconName: "laptop-code" },
  { prefix: "fas", iconName: "laptop-house" },
  { prefix: "fas", iconName: "laptop-medical" },
  { prefix: "fas", iconName: "laugh" },
  { prefix: "far", iconName: "laugh" },
  { prefix: "fas", iconName: "laugh-beam" },
  { prefix: "far", iconName: "laugh-beam" },
  { prefix: "fas", iconName: "laugh-squint" },
  { prefix: "far", iconName: "laugh-squint" },
  { prefix: "fas", iconName: "laugh-wink" },
  { prefix: "far", iconName: "laugh-wink" },
  { prefix: "fas", iconName: "layer-group" },
  { prefix: "fas", iconName: "leaf" },
  { prefix: "fas", iconName: "lemon" },
  { prefix: "far", iconName: "lemon" },
  { prefix: "fas", iconName: "less-than" },
  { prefix: "fas", iconName: "less-than-equal" },
  { prefix: "fas", iconName: "level-down-alt" },
  { prefix: "fas", iconName: "level-up-alt" },
  { prefix: "fas", iconName: "life-ring" },
  { prefix: "far", iconName: "life-ring" },
  { prefix: "fas", iconName: "lightbulb" },
  { prefix: "far", iconName: "lightbulb" },
  { prefix: "fas", iconName: "link" },
  { prefix: "fas", iconName: "lira-sign" },
  { prefix: "fas", iconName: "list" },
  { prefix: "fas", iconName: "list-alt" },
  { prefix: "far", iconName: "list-alt" },
  { prefix: "fas", iconName: "list-ol" },
  { prefix: "fas", iconName: "list-ul" },
  { prefix: "fas", iconName: "location-arrow" },
  { prefix: "fas", iconName: "lock" },
  { prefix: "fas", iconName: "lock-open" },
  { prefix: "fas", iconName: "long-arrow-alt-down" },
  { prefix: "fas", iconName: "long-arrow-alt-left" },
  { prefix: "fas", iconName: "long-arrow-alt-right" },
  { prefix: "fas", iconName: "long-arrow-alt-up" },
  { prefix: "fas", iconName: "low-vision" },
  { prefix: "fas", iconName: "luggage-cart" },
  { prefix: "fas", iconName: "lungs" },
  { prefix: "fas", iconName: "lungs-virus" },
  { prefix: "fas", iconName: "magic" },
  { prefix: "fas", iconName: "magnet" },
  { prefix: "fas", iconName: "mail-bulk" },
  { prefix: "fas", iconName: "male" },
  { prefix: "fas", iconName: "map" },
  { prefix: "far", iconName: "map" },
  { prefix: "fas", iconName: "map-marked" },
  { prefix: "fas", iconName: "map-marked-alt" },
  { prefix: "fas", iconName: "map-marker" },
  { prefix: "fas", iconName: "map-marker-alt" },
  { prefix: "fas", iconName: "map-pin" },
  { prefix: "fas", iconName: "map-signs" },
  { prefix: "fas", iconName: "marker" },
  { prefix: "fas", iconName: "mars" },
  { prefix: "fas", iconName: "mars-double" },
  { prefix: "fas", iconName: "mars-stroke" },
  { prefix: "fas", iconName: "mars-stroke-h" },
  { prefix: "fas", iconName: "mars-stroke-v" },
  { prefix: "fas", iconName: "mask" },
  { prefix: "fas", iconName: "medal" },
  { prefix: "fas", iconName: "medkit" },
  { prefix: "fas", iconName: "meh" },
  { prefix: "far", iconName: "meh" },
  { prefix: "fas", iconName: "meh-blank" },
  { prefix: "far", iconName: "meh-blank" },
  { prefix: "fas", iconName: "meh-rolling-eyes" },
  { prefix: "far", iconName: "meh-rolling-eyes" },
  { prefix: "fas", iconName: "memory" },
  { prefix: "fas", iconName: "menorah" },
  { prefix: "fas", iconName: "mercury" },
  { prefix: "fas", iconName: "meteor" },
  { prefix: "fas", iconName: "microchip" },
  { prefix: "fas", iconName: "microphone" },
  { prefix: "fas", iconName: "microphone-alt" },
  { prefix: "fas", iconName: "microphone-alt-slash" },
  { prefix: "fas", iconName: "microphone-slash" },
  { prefix: "fas", iconName: "microscope" },
  { prefix: "fas", iconName: "minus" },
  { prefix: "fas", iconName: "minus-circle" },
  { prefix: "fas", iconName: "minus-square" },
  { prefix: "far", iconName: "minus-square" },
  { prefix: "fas", iconName: "mitten" },
  { prefix: "fas", iconName: "mobile" },
  { prefix: "fas", iconName: "mobile-alt" },
  { prefix: "fas", iconName: "money-bill" },
  { prefix: "fas", iconName: "money-bill-alt" },
  { prefix: "far", iconName: "money-bill-alt" },
  { prefix: "fas", iconName: "money-bill-wave" },
  { prefix: "fas", iconName: "money-bill-wave-alt" },
  { prefix: "fas", iconName: "money-check" },
  { prefix: "fas", iconName: "money-check-alt" },
  { prefix: "fas", iconName: "monument" },
  { prefix: "fas", iconName: "moon" },
  { prefix: "far", iconName: "moon" },
  { prefix: "fas", iconName: "mortar-pestle" },
  { prefix: "fas", iconName: "mosque" },
  { prefix: "fas", iconName: "motorcycle" },
  { prefix: "fas", iconName: "mountain" },
  { prefix: "fas", iconName: "mouse" },
  { prefix: "fas", iconName: "mouse-pointer" },
  { prefix: "fas", iconName: "mug-hot" },
  { prefix: "fas", iconName: "music" },
  { prefix: "fas", iconName: "network-wired" },
  { prefix: "fas", iconName: "neuter" },
  { prefix: "fas", iconName: "newspaper" },
  { prefix: "far", iconName: "newspaper" },
  { prefix: "fas", iconName: "not-equal" },
  { prefix: "fas", iconName: "notes-medical" },
  { prefix: "fas", iconName: "object-group" },
  { prefix: "far", iconName: "object-group" },
  { prefix: "fas", iconName: "object-ungroup" },
  { prefix: "far", iconName: "object-ungroup" },
  { prefix: "fas", iconName: "oil-can" },
  { prefix: "fas", iconName: "om" },
  { prefix: "fas", iconName: "otter" },
  { prefix: "fas", iconName: "outdent" },
  { prefix: "fas", iconName: "pager" },
  { prefix: "fas", iconName: "paint-brush" },
  { prefix: "fas", iconName: "paint-roller" },
  { prefix: "fas", iconName: "palette" },
  { prefix: "fas", iconName: "pallet" },
  { prefix: "fas", iconName: "paperclip" },
  { prefix: "fas", iconName: "paper-plane" },
  { prefix: "far", iconName: "paper-plane" },
  { prefix: "fas", iconName: "paragraph" },
  { prefix: "fas", iconName: "parachute-box" },
  { prefix: "fas", iconName: "parking" },
  { prefix: "fas", iconName: "passport" },
  { prefix: "fas", iconName: "pastafarianism" },
  { prefix: "fas", iconName: "paste" },
  { prefix: "fas", iconName: "pause" },
  { prefix: "fas", iconName: "pause-circle" },
  { prefix: "far", iconName: "pause-circle" },
  { prefix: "fas", iconName: "paw" },
  { prefix: "fas", iconName: "peace" },
  { prefix: "fas", iconName: "pen" },
  { prefix: "fas", iconName: "pen-alt" },
  { prefix: "fas", iconName: "pencil-alt" },
  { prefix: "fas", iconName: "pencil-ruler" },
  { prefix: "fas", iconName: "pen-fancy" },
  { prefix: "fas", iconName: "pen-nib" },
  { prefix: "fas", iconName: "pen-square" },
  { prefix: "fas", iconName: "people-arrows" },
  { prefix: "fas", iconName: "people-carry" },
  { prefix: "fas", iconName: "pepper-hot" },
  { prefix: "fas", iconName: "percent" },
  { prefix: "fas", iconName: "percentage" },
  { prefix: "fas", iconName: "person-booth" },
  { prefix: "fas", iconName: "phone" },
  { prefix: "fas", iconName: "phone-alt" },
  { prefix: "fas", iconName: "phone-slash" },
  { prefix: "fas", iconName: "phone-square" },
  { prefix: "fas", iconName: "phone-square-alt" },
  { prefix: "fas", iconName: "phone-volume" },
  { prefix: "fas", iconName: "photo-video" },
  { prefix: "fas", iconName: "piggy-bank" },
  { prefix: "fas", iconName: "pills" },
  { prefix: "fas", iconName: "pizza-slice" },
  { prefix: "fas", iconName: "place-of-worship" },
  { prefix: "fas", iconName: "plane" },
  { prefix: "fas", iconName: "plane-arrival" },
  { prefix: "fas", iconName: "plane-departure" },
  { prefix: "fas", iconName: "plane-slash" },
  { prefix: "fas", iconName: "play" },
  { prefix: "fas", iconName: "play-circle" },
  { prefix: "far", iconName: "play-circle" },
  { prefix: "fas", iconName: "plug" },
  { prefix: "fas", iconName: "plus" },
  { prefix: "fas", iconName: "plus-circle" },
  { prefix: "fas", iconName: "plus-square" },
  { prefix: "far", iconName: "plus-square" },
  { prefix: "fas", iconName: "podcast" },
  { prefix: "fas", iconName: "poll" },
  { prefix: "fas", iconName: "poll-h" },
  { prefix: "fas", iconName: "poo" },
  { prefix: "fas", iconName: "poop" },
  { prefix: "fas", iconName: "poo-storm" },
  { prefix: "fas", iconName: "portrait" },
  { prefix: "fas", iconName: "pound-sign" },
  { prefix: "fas", iconName: "power-off" },
  { prefix: "fas", iconName: "pray" },
  { prefix: "fas", iconName: "praying-hands" },
  { prefix: "fas", iconName: "prescription" },
  { prefix: "fas", iconName: "prescription-bottle" },
  { prefix: "fas", iconName: "prescription-bottle-alt" },
  { prefix: "fas", iconName: "print" },
  { prefix: "fas", iconName: "procedures" },
  { prefix: "fas", iconName: "project-diagram" },
  { prefix: "fas", iconName: "pump-medical" },
  { prefix: "fas", iconName: "pump-soap" },
  { prefix: "fas", iconName: "puzzle-piece" },
  { prefix: "fas", iconName: "qrcode" },
  { prefix: "fas", iconName: "question" },
  { prefix: "fas", iconName: "question-circle" },
  { prefix: "far", iconName: "question-circle" },
  { prefix: "fas", iconName: "quidditch" },
  { prefix: "fas", iconName: "quote-left" },
  { prefix: "fas", iconName: "quote-right" },
  { prefix: "fas", iconName: "quran" },
  { prefix: "fas", iconName: "radiation" },
  { prefix: "fas", iconName: "radiation-alt" },
  { prefix: "fas", iconName: "rainbow" },
  { prefix: "fas", iconName: "random" },
  { prefix: "fas", iconName: "receipt" },
  { prefix: "fas", iconName: "record-vinyl" },
  { prefix: "fas", iconName: "recycle" },
  { prefix: "fas", iconName: "redo" },
  { prefix: "fas", iconName: "redo-alt" },
  { prefix: "fas", iconName: "registered" },
  { prefix: "far", iconName: "registered" },
  { prefix: "fas", iconName: "remove-format" },
  { prefix: "fas", iconName: "reply" },
  { prefix: "fas", iconName: "reply-all" },
  { prefix: "fas", iconName: "republican" },
  { prefix: "fas", iconName: "restroom" },
  { prefix: "fas", iconName: "retweet" },
  { prefix: "fas", iconName: "ribbon" },
  { prefix: "fas", iconName: "ring" },
  { prefix: "fas", iconName: "road" },
  { prefix: "fas", iconName: "robot" },
  { prefix: "fas", iconName: "rocket" },
  { prefix: "fas", iconName: "route" },
  { prefix: "fas", iconName: "rss" },
  { prefix: "fas", iconName: "rss-square" },
  { prefix: "fas", iconName: "ruble-sign" },
  { prefix: "fas", iconName: "ruler" },
  { prefix: "fas", iconName: "ruler-combined" },
  { prefix: "fas", iconName: "ruler-horizontal" },
  { prefix: "fas", iconName: "ruler-vertical" },
  { prefix: "fas", iconName: "running" },
  { prefix: "fas", iconName: "rupee-sign" },
  { prefix: "fas", iconName: "sad-cry" },
  { prefix: "far", iconName: "sad-cry" },
  { prefix: "fas", iconName: "sad-tear" },
  { prefix: "far", iconName: "sad-tear" },
  { prefix: "fas", iconName: "satellite" },
  { prefix: "fas", iconName: "satellite-dish" },
  { prefix: "fas", iconName: "save" },
  { prefix: "far", iconName: "save" },
  { prefix: "fas", iconName: "screwdriver" },
  { prefix: "fas", iconName: "scroll" },
  { prefix: "fas", iconName: "sd-card" },
  { prefix: "fas", iconName: "search" },
  { prefix: "fas", iconName: "search-dollar" },
  { prefix: "fas", iconName: "search-location" },
  { prefix: "fas", iconName: "search-minus" },
  { prefix: "fas", iconName: "search-plus" },
  { prefix: "fas", iconName: "seedling" },
  { prefix: "fas", iconName: "server" },
  { prefix: "fas", iconName: "shapes" },
  { prefix: "fas", iconName: "share" },
  { prefix: "fas", iconName: "share-alt" },
  { prefix: "fas", iconName: "share-alt-square" },
  { prefix: "fas", iconName: "share-square" },
  { prefix: "far", iconName: "share-square" },
  { prefix: "fas", iconName: "shekel-sign" },
  { prefix: "fas", iconName: "shield-alt" },
  { prefix: "fas", iconName: "shield-virus" },
  { prefix: "fas", iconName: "ship" },
  { prefix: "fas", iconName: "shipping-fast" },
  { prefix: "fas", iconName: "shoe-prints" },
  { prefix: "fas", iconName: "shopping-bag" },
  { prefix: "fas", iconName: "shopping-basket" },
  { prefix: "fas", iconName: "shopping-cart" },
  { prefix: "fas", iconName: "shower" },
  { prefix: "fas", iconName: "shuttle-van" },
  { prefix: "fas", iconName: "school" },
  { prefix: "fas", iconName: "sign" },
  { prefix: "fas", iconName: "signal" },
  { prefix: "fas", iconName: "signature" },
  { prefix: "fas", iconName: "sign-in-alt" },
  { prefix: "fas", iconName: "sign-language" },
  { prefix: "fas", iconName: "sign-out-alt" },
  { prefix: "fas", iconName: "sim-card" },
  { prefix: "fas", iconName: "sink" },
  { prefix: "fas", iconName: "sitemap" },
  { prefix: "fas", iconName: "skating" },
  { prefix: "fas", iconName: "skiing" },
  { prefix: "fas", iconName: "skiing-nordic" },
  { prefix: "fas", iconName: "skull" },
  { prefix: "fas", iconName: "skull-crossbones" },
  { prefix: "fas", iconName: "slash" },
  { prefix: "fas", iconName: "sleigh" },
  { prefix: "fas", iconName: "sliders-h" },
  { prefix: "fas", iconName: "smile" },
  { prefix: "far", iconName: "smile" },
  { prefix: "fas", iconName: "smile-beam" },
  { prefix: "far", iconName: "smile-beam" },
  { prefix: "fas", iconName: "smile-wink" },
  { prefix: "far", iconName: "smile-wink" },
  { prefix: "fas", iconName: "smog" },
  { prefix: "fas", iconName: "smoking" },
  { prefix: "fas", iconName: "smoking-ban" },
  { prefix: "fas", iconName: "sms" },
  { prefix: "fas", iconName: "snowboarding" },
  { prefix: "fas", iconName: "snowflake" },
  { prefix: "far", iconName: "snowflake" },
  { prefix: "fas", iconName: "snowman" },
  { prefix: "fas", iconName: "snowplow" },
  { prefix: "fas", iconName: "soap" },
  { prefix: "fas", iconName: "socks" },
  { prefix: "fas", iconName: "solar-panel" },
  { prefix: "fas", iconName: "sort" },
  { prefix: "fas", iconName: "sort-alpha-down" },
  { prefix: "fas", iconName: "sort-alpha-down-alt" },
  { prefix: "fas", iconName: "sort-alpha-up" },
  { prefix: "fas", iconName: "sort-alpha-up-alt" },
  { prefix: "fas", iconName: "sort-amount-down" },
  { prefix: "fas", iconName: "sort-amount-down-alt" },
  { prefix: "fas", iconName: "sort-amount-up" },
  { prefix: "fas", iconName: "sort-amount-up-alt" },
  { prefix: "fas", iconName: "sort-down" },
  { prefix: "fas", iconName: "sort-numeric-down" },
  { prefix: "fas", iconName: "sort-numeric-down-alt" },
  { prefix: "fas", iconName: "sort-numeric-up" },
  { prefix: "fas", iconName: "sort-numeric-up-alt" },
  { prefix: "fas", iconName: "sort-up" },
  { prefix: "fas", iconName: "spa" },
  { prefix: "fas", iconName: "space-shuttle" },
  { prefix: "fas", iconName: "spell-check" },
  { prefix: "fas", iconName: "spider" },
  { prefix: "fas", iconName: "spinner" },
  { prefix: "fas", iconName: "splotch" },
  { prefix: "fas", iconName: "spray-can" },
  { prefix: "fas", iconName: "square" },
  { prefix: "far", iconName: "square" },
  { prefix: "fas", iconName: "square-full" },
  { prefix: "fas", iconName: "square-root-alt" },
  { prefix: "fas", iconName: "stamp" },
  { prefix: "fas", iconName: "star" },
  { prefix: "far", iconName: "star" },
  { prefix: "fas", iconName: "star-and-crescent" },
  { prefix: "fas", iconName: "star-half" },
  { prefix: "far", iconName: "star-half" },
  { prefix: "fas", iconName: "star-half-alt" },
  { prefix: "fas", iconName: "star-of-david" },
  { prefix: "fas", iconName: "star-of-life" },
  { prefix: "fas", iconName: "step-backward" },
  { prefix: "fas", iconName: "step-forward" },
  { prefix: "fas", iconName: "stethoscope" },
  { prefix: "fas", iconName: "sticky-note" },
  { prefix: "far", iconName: "sticky-note" },
  { prefix: "fas", iconName: "stop" },
  { prefix: "fas", iconName: "stop-circle" },
  { prefix: "far", iconName: "stop-circle" },
  { prefix: "fas", iconName: "stopwatch" },
  { prefix: "fas", iconName: "stopwatch-20" },
  { prefix: "fas", iconName: "store" },
  { prefix: "fas", iconName: "store-alt" },
  { prefix: "fas", iconName: "store-alt-slash" },
  { prefix: "fas", iconName: "store-slash" },
  { prefix: "fas", iconName: "stream" },
  { prefix: "fas", iconName: "street-view" },
  { prefix: "fas", iconName: "strikethrough" },
  { prefix: "fas", iconName: "stroopwafel" },
  { prefix: "fas", iconName: "subscript" },
  { prefix: "fas", iconName: "subway" },
  { prefix: "fas", iconName: "suitcase" },
  { prefix: "fas", iconName: "suitcase-rolling" },
  { prefix: "fas", iconName: "sun" },
  { prefix: "far", iconName: "sun" },
  { prefix: "fas", iconName: "superscript" },
  { prefix: "fas", iconName: "surprise" },
  { prefix: "far", iconName: "surprise" },
  { prefix: "fas", iconName: "swatchbook" },
  { prefix: "fas", iconName: "swimmer" },
  { prefix: "fas", iconName: "swimming-pool" },
  { prefix: "fas", iconName: "synagogue" },
  { prefix: "fas", iconName: "sync" },
  { prefix: "fas", iconName: "sync-alt" },
  { prefix: "fas", iconName: "syringe" },
  { prefix: "fas", iconName: "table" },
  { prefix: "fas", iconName: "tablet" },
  { prefix: "fas", iconName: "tablet-alt" },
  { prefix: "fas", iconName: "table-tennis" },
  { prefix: "fas", iconName: "tablets" },
  { prefix: "fas", iconName: "tag" },
  { prefix: "fas", iconName: "tags" },
  { prefix: "fas", iconName: "tachometer-alt" },
  { prefix: "fas", iconName: "tape" },
  { prefix: "fas", iconName: "tasks" },
  { prefix: "fas", iconName: "taxi" },
  { prefix: "fas", iconName: "teeth" },
  { prefix: "fas", iconName: "teeth-open" },
  { prefix: "fas", iconName: "temperature-high" },
  { prefix: "fas", iconName: "temperature-low" },
  { prefix: "fas", iconName: "tenge" },
  { prefix: "fas", iconName: "terminal" },
  { prefix: "fas", iconName: "text-height" },
  { prefix: "fas", iconName: "text-width" },
  { prefix: "fas", iconName: "th" },
  { prefix: "fas", iconName: "theater-masks" },
  { prefix: "fas", iconName: "thermometer" },
  { prefix: "fas", iconName: "thermometer-empty" },
  { prefix: "fas", iconName: "thermometer-full" },
  { prefix: "fas", iconName: "thermometer-half" },
  { prefix: "fas", iconName: "thermometer-quarter" },
  { prefix: "fas", iconName: "thermometer-three-quarters" },
  { prefix: "fas", iconName: "th-large" },
  { prefix: "fas", iconName: "th-list" },
  { prefix: "fas", iconName: "thumbs-down" },
  { prefix: "far", iconName: "thumbs-down" },
  { prefix: "fas", iconName: "thumbs-up" },
  { prefix: "far", iconName: "thumbs-up" },
  { prefix: "fas", iconName: "thumbtack" },
  { prefix: "fas", iconName: "ticket-alt" },
  { prefix: "fas", iconName: "times" },
  { prefix: "fas", iconName: "times-circle" },
  { prefix: "far", iconName: "times-circle" },
  { prefix: "fas", iconName: "tint" },
  { prefix: "fas", iconName: "tint-slash" },
  { prefix: "fas", iconName: "tired" },
  { prefix: "far", iconName: "tired" },
  { prefix: "fas", iconName: "toggle-off" },
  { prefix: "fas", iconName: "toggle-on" },
  { prefix: "fas", iconName: "toilet" },
  { prefix: "fas", iconName: "toilet-paper" },
  { prefix: "fas", iconName: "toilet-paper-slash" },
  { prefix: "fas", iconName: "toolbox" },
  { prefix: "fas", iconName: "tools" },
  { prefix: "fas", iconName: "tooth" },
  { prefix: "fas", iconName: "torah" },
  { prefix: "fas", iconName: "torii-gate" },
  { prefix: "fas", iconName: "tractor" },
  { prefix: "fas", iconName: "trademark" },
  { prefix: "fas", iconName: "traffic-light" },
  { prefix: "fas", iconName: "trailer" },
  { prefix: "fas", iconName: "train" },
  { prefix: "fas", iconName: "tram" },
  { prefix: "fas", iconName: "transgender" },
  { prefix: "fas", iconName: "transgender-alt" },
  { prefix: "fas", iconName: "trash" },
  { prefix: "fas", iconName: "trash-alt" },
  { prefix: "far", iconName: "trash-alt" },
  { prefix: "fas", iconName: "trash-restore" },
  { prefix: "fas", iconName: "trash-restore-alt" },
  { prefix: "fas", iconName: "tree" },
  { prefix: "fas", iconName: "trophy" },
  { prefix: "fas", iconName: "truck" },
  { prefix: "fas", iconName: "truck-loading" },
  { prefix: "fas", iconName: "truck-monster" },
  { prefix: "fas", iconName: "truck-moving" },
  { prefix: "fas", iconName: "truck-pickup" },
  { prefix: "fas", iconName: "tshirt" },
  { prefix: "fas", iconName: "tty" },
  { prefix: "fas", iconName: "tv" },
  { prefix: "fas", iconName: "umbrella" },
  { prefix: "fas", iconName: "umbrella-beach" },
  { prefix: "fas", iconName: "underline" },
  { prefix: "fas", iconName: "undo" },
  { prefix: "fas", iconName: "undo-alt" },
  { prefix: "fas", iconName: "universal-access" },
  { prefix: "fas", iconName: "university" },
  { prefix: "fas", iconName: "unlink" },
  { prefix: "fas", iconName: "unlock" },
  { prefix: "fas", iconName: "unlock-alt" },
  { prefix: "fas", iconName: "upload" },
  { prefix: "fas", iconName: "user" },
  { prefix: "far", iconName: "user" },
  { prefix: "fas", iconName: "user-alt" },
  { prefix: "fas", iconName: "user-alt-slash" },
  { prefix: "fas", iconName: "user-astronaut" },
  { prefix: "fas", iconName: "user-circle" },
  { prefix: "far", iconName: "user-circle" },
  { prefix: "fas", iconName: "user-clock" },
  { prefix: "fas", iconName: "user-cog" },
  { prefix: "fas", iconName: "user-edit" },
  { prefix: "fas", iconName: "user-friends" },
  { prefix: "fas", iconName: "user-graduate" },
  { prefix: "fas", iconName: "user-check" },
  { prefix: "fas", iconName: "user-injured" },
  { prefix: "fas", iconName: "user-lock" },
  { prefix: "fas", iconName: "user-md" },
  { prefix: "fas", iconName: "user-minus" },
  { prefix: "fas", iconName: "user-ninja" },
  { prefix: "fas", iconName: "user-nurse" },
  { prefix: "fas", iconName: "user-plus" },
  { prefix: "fas", iconName: "users" },
  { prefix: "fas", iconName: "users-cog" },
  { prefix: "fas", iconName: "user-secret" },
  { prefix: "fas", iconName: "user-shield" },
  { prefix: "fas", iconName: "user-slash" },
  { prefix: "fas", iconName: "users-slash" },
  { prefix: "fas", iconName: "user-tag" },
  { prefix: "fas", iconName: "user-tie" },
  { prefix: "fas", iconName: "user-times" },
  { prefix: "fas", iconName: "utensils" },
  { prefix: "fas", iconName: "utensil-spoon" },
  { prefix: "fas", iconName: "vector-square" },
  { prefix: "fas", iconName: "venus" },
  { prefix: "fas", iconName: "venus-double" },
  { prefix: "fas", iconName: "venus-mars" },
  { prefix: "fas", iconName: "vest" },
  { prefix: "fas", iconName: "vest-patches" },
  { prefix: "fas", iconName: "vial" },
  { prefix: "fas", iconName: "vials" },
  { prefix: "fas", iconName: "video" },
  { prefix: "fas", iconName: "video-slash" },
  { prefix: "fas", iconName: "vihara" },
  { prefix: "fas", iconName: "virus" },
  { prefix: "fas", iconName: "viruses" },
  { prefix: "fas", iconName: "virus-slash" },
  { prefix: "fas", iconName: "voicemail" },
  { prefix: "fas", iconName: "volleyball-ball" },
  { prefix: "fas", iconName: "volume-down" },
  { prefix: "fas", iconName: "volume-mute" },
  { prefix: "fas", iconName: "volume-off" },
  { prefix: "fas", iconName: "volume-up" },
  { prefix: "fas", iconName: "vote-yea" },
  { prefix: "fas", iconName: "vr-cardboard" },
  { prefix: "fas", iconName: "walking" },
  { prefix: "fas", iconName: "wallet" },
  { prefix: "fas", iconName: "warehouse" },
  { prefix: "fas", iconName: "water" },
  { prefix: "fas", iconName: "wave-square" },
  { prefix: "fas", iconName: "weight" },
  { prefix: "fas", iconName: "weight-hanging" },
  { prefix: "fas", iconName: "wheelchair" },
  { prefix: "fas", iconName: "wifi" },
  { prefix: "fas", iconName: "wind" },
  { prefix: "fas", iconName: "window-close" },
  { prefix: "far", iconName: "window-close" },
  { prefix: "fas", iconName: "window-maximize" },
  { prefix: "far", iconName: "window-maximize" },
  { prefix: "fas", iconName: "window-minimize" },
  { prefix: "far", iconName: "window-minimize" },
  { prefix: "fas", iconName: "window-restore" },
  { prefix: "far", iconName: "window-restore" },
  { prefix: "fas", iconName: "wine-bottle" },
  { prefix: "fas", iconName: "wine-glass" },
  { prefix: "fas", iconName: "wine-glass-alt" },
  { prefix: "fas", iconName: "won-sign" },
  { prefix: "fas", iconName: "wrench" },
  { prefix: "fas", iconName: "x-ray" },
  { prefix: "fas", iconName: "yen-sign" },
  { prefix: "fas", iconName: "yin-yang" },
];
