import styled from "styled-components";

export const OrderCards = styled.span`
  display: inline-flex;
  align-items: center;
  flex-wrap: nowrap;

  padding: 0.25rem;

  border-radius: 4px;
  background-color: var(--secondary);
  font-weight: bold;

  svg {
    margin-right: 0.25rem;
  }
`;
