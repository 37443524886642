import React, { FC } from "react";
import { CheckboxContainer, Checkmark } from "./styles";

const Checkbox: FC = ({ children }) => {
  return (
    <CheckboxContainer>
      {children}
      <Checkmark />
    </CheckboxContainer>
  );
};

export default Checkbox;
