import styled from "styled-components";
import { Table } from "../../../../styles/table";

export const AdminEmployeesTableContainer = styled(Table)`
  td,
  th {
    &:not(:first-child) {
      width: max-content;
    }
  }

  tr {
    cursor: pointer;
  }
`;
