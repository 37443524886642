import React, { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Spinner from "react-loader-spinner";
import { toast } from "react-toastify";
import documentApi from "../../../../api/document";
import signQueueApi from "../../../../api/signQueue";
import { Button } from "../../../../styles/button";
import { COLORS } from "../../../../utils/constants";
import { getErrorMessage } from "../../../../utils/errors";
import { SetState } from "../../../../utils/types";
import CustomModal from "../../../common/CustomModal/CustomModal";
import Select from "../../../common/form/Select/Select";
import Loader from "../../../common/Loader/Loader";

const AdminClientsDetailSignModal: FC<{
  open: boolean;
  setOpen: SetState<boolean>;
  customerId: string;
}> = ({ open, setOpen, customerId }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [state, setState] = useState(1);
  const [options, setOptions] = useState<{ label: string; value: string }[]>(
    []
  );
  const [value, setValue] = useState<string>("");
  const [existsNonSignedDocument, setExistsNonSignedDocument] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleContinue = async () => {
    setSaving(true);
    try {
      await signQueueApi.sendToSign(customerId, value);
      toast.success(t("common.saveSuccess"));
    } catch (err) {
      toast.error(getErrorMessage(err, t("errors.saveError")));
    }
    setState(2);
    setSaving(false);
  };

  const checkState = useCallback(async () => {
    if (!open) {
      return;
    }

    if (state !== 2) {
      return;
    }

    const queueResponse = await signQueueApi.getQueue(value);
    if (queueResponse.data) {
      return;
    }

    const documentsResponse = await documentApi.getUserDocuments(customerId);
    const existsNonSignedDocument =
      documentsResponse.data.find((x) => x.mustSign && !x.signed) !== undefined;

    setExistsNonSignedDocument(existsNonSignedDocument);
    setState(3);
  }, [open, state, value, customerId]);

  const loadData = useCallback(async () => {
    if (!open) {
      return;
    }

    setLoading(true);
    try {
      const response = await signQueueApi.getTablets();
      const items = response.data.map((x) => {
        return {
          value: x.id.toString(),
          label: `${x.firstName} ${x.lastName}`,
        };
      });
      setOptions(items);
      setValue(items.length === 0 ? "" : items[0].value);
    } catch (err) {
      toast.error(getErrorMessage(err, t("errors.loadError")));
    }
    setLoading(false);
  }, [t, open]);

  useEffect(() => {
    loadData();
  }, [loadData, open]);

  useEffect(() => {
    let interval = setInterval(() => checkState(), 1500);

    return () => {
      clearInterval(interval);
    };
  }, [checkState]);

  if (loading) {
    return <Loader />;
  }

  return (
    <CustomModal
      title={t("adminClientsDetail.signModal.title")}
      open={open}
      setOpen={setOpen}
      actions={
        <>
          <Button ver="secondary" disabled={saving} onClick={handleClose}>
            {state === 1 && t("common.cancel")}
            {state === 2 && t("adminClientsDetail.signModal.continue")}
            {state === 3 && t("common.close")}
          </Button>
          {state === 1 && (
            <Button disabled={saving} onClick={handleContinue}>
              {t("common.continue")}
            </Button>
          )}
        </>
      }
    >
      {state === 1 && (
        <>
          <p>{t("adminClientsDetail.signModal.select")}</p>
          <Select
            key="tablet"
            name="tablet"
            value={options.find((x) => x.value === value)}
            options={options}
            onChange={(val) => setValue(val?.value ?? "")}
          />
        </>
      )}
      {state === 2 && (
        <>
          <p>{t("adminClientsDetail.signModal.progress")}</p>
          <Spinner
            type="TailSpin"
            color={COLORS.primary}
            width={48}
            height={48}
            style={{ textAlign: "center", marginTop: "1em" }}
          />
        </>
      )}
      {state === 3 && (
        <p>
          {t(
            "adminClientsDetail.signModal." +
              (existsNonSignedDocument ? "documentsNotOk" : "documentsOk")
          )}
        </p>
      )}
    </CustomModal>
  );
};

export default AdminClientsDetailSignModal;
