import styled from "styled-components";
import { FormGroup } from "../../../../styles/form";
import { size } from "../../../../utils/styles";

export const AdminBasicSettingsFormGroup = styled(FormGroup)`
  width: 34rem;
  margin-bottom: 2rem;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const AdminBasicSettingsInputRow = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;

  @media screen and (min-width: ${size.mobile}) {
    align-items: center;
    flex-flow: row;
  }
`;

export const AdminBasicSettingsInputCol = styled.div<{ hidden?: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 0.5rem;

  &:first-of-type {
    width: 34rem;
  }

  @media screen and (min-width: ${size.mobile}) {
    margin-bottom: 0;
    margin-right: 1rem;
  }
`;
