import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import additionalServicesApi from "../../../../api/additionalServices";
import boxApi from "../../../../api/box";
import { AdditionalService } from "../../../../models/additionalServices";
import { BoxPrice } from "../../../../models/box";
import { LinkButton } from "../../../../styles/button";
import { NoTableData } from "../../../../styles/table";
import { useUserHasRight } from "../../../../utils/auth";
import { COLORS } from "../../../../utils/constants";
import { PriceLevelState } from "../../../../utils/enums";
import { formatDate } from "../../../../utils/formats";
import { OverflowX } from "../../../../utils/styles";
import Loader from "../../../common/Loader/Loader";
import AdminBoxesSettingsDetailModal from "../AdminBoxesSettingsDetailModal/AdminBoxesSettingsDetailModal";
import {
  AdminBoxesSettingsDetailTableState,
  AdminBoxesSettingsTableContainer,
} from "./styles";

const cols = [
  "adminBoxesSettingsDetail.table.state",
  "adminBoxesSettingsDetail.table.valid",
];

const AdminBoxesSettingsDetailTable: FC<{ boxType: string }> = ({
  boxType,
}) => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<BoxPrice[]>([]);
  const [additionalServices, setAdditionalServices] = useState<
    AdditionalService[]
  >([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalEditData, setModalEditData] = useState<BoxPrice>();
  const boxId = parseInt(id);

  const { hasUserAllRights } = useUserHasRight();
  const canWrite = hasUserAllRights(["writeBoxPrices"]);

  const loadData = useCallback(() => {
    (async () => {
      setLoading(true);
      try {
        const responsePrices = await boxApi.getAllBoxPrices(boxId);
        const responseAdditionalServices = await additionalServicesApi.getAll();
        setData(responsePrices.data);
        setAdditionalServices(responseAdditionalServices.data);
      } catch (err) {
        toast.error(t("errors.boxPrices"));
      }
      setLoading(false);
    })();
  }, [t, boxId]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const getStateColor = (state: PriceLevelState) => {
    switch (state) {
      case PriceLevelState.NotActive:
        return COLORS.primaryDark;
      case PriceLevelState.Active:
        return COLORS.green;
      case PriceLevelState.Waiting:
        return COLORS.primary;
    }
  };

  const tableCols = cols.map((col) => <th key={col}>{t(col)}</th>);

  const tableRows = useMemo(
    () =>
      data.map((record, i) => (
        <tr
          key={i}
          onClick={() => {
            setModalEditData(record);
            setModalOpen(true);
          }}
        >
          <td>
            <AdminBoxesSettingsDetailTableState
              color={getStateColor(record.state!)}
            >
              {t(`adminBoxesSettingsDetail.priceLevelState.${record.state}`)}
            </AdminBoxesSettingsDetailTableState>
          </td>
          <td>
            <div>{formatDate(record.activeFrom)}</div>
          </td>
          <td>
            <FontAwesomeIcon
              icon={faEllipsisH}
              size="lg"
              color={COLORS.primary}
            />
          </td>
        </tr>
      )),
    [data, t]
  );

  if (loading) return <Loader />;
  return (
    <OverflowX>
      <AdminBoxesSettingsDetailModal
        open={modalOpen}
        setOpen={setModalOpen}
        onSuccess={loadData}
        boxType={boxType}
        editData={modalEditData}
        additionalServices={additionalServices}
        canEditActiveFrom={data && data.length > 1}
      />

      {data ? (
        <AdminBoxesSettingsTableContainer>
          <thead>
            <tr>
              {tableCols}
              <th>
                {canWrite && (
                  <LinkButton
                    onClick={() => {
                      setModalEditData(undefined);
                      setModalOpen(true);
                    }}
                  >
                    &#43;&nbsp;
                    {t("adminBoxesSettingsDetail.table.priceLevelButton")}
                  </LinkButton>
                )}
              </th>
            </tr>
          </thead>

          <tbody>{tableRows}</tbody>
        </AdminBoxesSettingsTableContainer>
      ) : (
        <NoTableData>{t("adminBoxesSettingsDetail.table.noData")}</NoTableData>
      )}
    </OverflowX>
  );
};

export default AdminBoxesSettingsDetailTable;
