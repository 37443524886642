import styled, { css } from "styled-components";
import { COLORS } from "../../../../utils/constants";
import { SearchIconWrapper, SearchInput } from "../Search/styles";

export const FilterContainer = styled.div`
  display: flex;
  column-gap: 0.5rem;
  justify-content: flex-end;
`;

export const FilterInputWrapper = styled.div`
  position: relative;
`;

export const FilterInputLabel = styled.label<{ width?: string }>`
  ${({ width }) =>
    width &&
    css`
      display: inline-block;
      width: ${width};
    `}
`;

export const FilterInput = styled(SearchInput)`
  padding: 0.5rem 2.75rem 0.5rem 0.75rem;
`;

export const ResetIconWrapper = styled(SearchIconWrapper)`
  right: 0;
  color: ${COLORS.gray};

  &:hover {
    cursor: pointer;
    color: ${COLORS.grayLight};
  }
`;
