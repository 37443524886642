import { Field, Form, useFormikContext } from "formik";
import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { UserData } from "../../../models/user";
import { Button } from "../../../styles/button";
import { FormGroup } from "../../../styles/form";
import { SetState } from "../../../utils/types";
import CustomModal from "../CustomModal/CustomModal";
import Checkbox from "../form/Checkbox/Checkbox";
import Loader from "../Loader/Loader";
import { UserInfoFormContainer } from "./styles";
import UserCompanyForm from "./UserCompanyForm";
import UserInfoForm from "./UserInfoForm";

const UserInfoModalContent: FC<{
  open: boolean;
  setOpen: SetState<boolean>;
  loading?: boolean;
  showNote?: boolean;
}> = ({ open, setOpen, loading, showNote }) => {
  const { t } = useTranslation();
  const { values, isSubmitting, submitForm, resetForm } =
    useFormikContext<UserData>();

  useEffect(() => {
    if (open) resetForm();
  }, [open, resetForm]);

  return (
    <CustomModal
      open={open}
      setOpen={setOpen}
      title={t("userInfo.info.edit")}
      actions={
        <>
          <Button
            type="button"
            ver="secondary"
            disabled={loading || isSubmitting}
            onClick={() => setOpen(false)}
          >
            {t("common.cancel")}
          </Button>
          <Button
            disabled={loading || isSubmitting}
            onClick={() => submitForm()}
          >
            {t("common.save")}
          </Button>
        </>
      }
    >
      <Form>
        {loading ? (
          <Loader />
        ) : (
          <UserInfoFormContainer>
            <UserInfoForm tooltipLeft showNote={showNote} />
            <FormGroup>
              <Checkbox>
                <Field type="checkbox" name="isCompany" />
                {t("userInfo.isCompany")}
              </Checkbox>
            </FormGroup>
            {values.isCompany && <UserCompanyForm tooltipLeft />}
          </UserInfoFormContainer>
        )}
      </Form>
    </CustomModal>
  );
};

export default UserInfoModalContent;
