import { Form } from "formik";
import styled, { css } from "styled-components";
import { Card } from "../../../../styles/card";
import { H3 } from "../../../../styles/title";
import { size } from "../../../../utils/styles";

export const AdminBoxesSettingsDetailInfoMain = styled(Card)`
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  @media screen and (min-width: ${size.tablet}) {
    flex-direction: row;
  }
`;

export const AdminBoxesSettingsDetailInfoImage = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;

  & > div {
    position: relative;
  }

  & > div > button {
    position: absolute;
    bottom: 0.5rem;
    right: 0.5rem;
  }

  @media screen and (min-width: ${size.tablet}) {
    width: calc(40% - 2rem);
    margin-right: 2rem;
    margin-bottom: 0;
  }
`;

export const AdminBoxesSettingsDetailInfoFormWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  & > form {
    width: 100%;
  }

  @media screen and (min-width: ${size.tablet}) {
    width: 60%;
  }
`;

export const AdminBoxesSettingsDetailInfoFormH3 = styled(H3)`
  margin-bottom: 1rem;
`;

export const AdminBoxesSettingsDetailInfoFormMain = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
`;

export const AdminBoxesSettingsDetailInfoFormRow = styled.div<{
  noMargin?: boolean;
}>`
  width: 100%;
  display: flex;
  flex-flow: column;

  ${({ noMargin }) =>
    !noMargin &&
    css`
      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }
    `}

  @media screen and (min-width: ${size.desktop}) {
    align-items: center;
    flex-flow: row;
  }
`;

export const AdminBoxesSettingsDetailInfoFormCol = styled.div<{
  hidden?: boolean;
}>`
  display: flex;
  align-items: center;
  width: 100%;

  &:first-of-type {
    width: 7.5rem;
    margin-right: 1.5rem;
    margin-bottom: 0.5rem;

    @media screen and (min-width: ${size.desktop}) {
      margin-bottom: 0;
    }
  }

  &:nth-of-type(2) {
    max-width: 100%;

    @media screen and (min-width: ${size.desktop}) {
      max-width: 60%;
    }
  }
`;

export const AdminBoxesSettingsDetailInfoFormControls = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;

  @media screen and (min-width: ${size.desktop}) {
    width: calc(9rem + 60%);
  }
`;

export const AdminBoxesSettingsDetailInfoFormGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 0.5rem;

  @media screen and (min-width: ${size.mobile}) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 1rem;
  }
`;

export const AdminBoxesSettingsDetailInfoTotalNumberWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const AdminBoxesSettingsDetailInfoTotalNumber = styled.div`
  margin-right: 0.5rem;
`;
