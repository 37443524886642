import styled from "styled-components";

export const BoxTypeLabel = styled.span`
  display: inline-block;
  padding: 0.25rem 0.5rem;

  background-color: var(--primary);
  color: var(--white);
  font-weight: bold;
  line-height: 1;

  border-radius: 4px;
`;

export const BoxTypeNameLabelContainer = styled.div`
  ${BoxTypeLabel} {
    margin-right: 0.5rem;
  }
`;
