import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import calendarApi from "../../../../api/calendar";
import storageApi from "../../../../api/storage";
import { EmployeeCalendarOverview } from "../../../../models/calendar";
import { Paged } from "../../../../models/common";
import { FadeInBottom } from "../../../../styles/fadeIn";
import { PageSection } from "../../../../styles/layout";
import { NoTableData } from "../../../../styles/table";
import { DEFAULT_PAGE_SIZE } from "../../../../utils/constants";
import { getErrorMessage } from "../../../../utils/errors";
import { formatDate, formatTime } from "../../../../utils/formats";
import { OverflowX } from "../../../../utils/styles";
import { SetState } from "../../../../utils/types";
import Loader from "../../../common/Loader/Loader";
import Pagination from "../../../common/Pagination/Pagination";
import MeetingCancelButton from "../../../user/Dashboard/DashboardMeetings/MeetingCancelButton";
import {
  AdminCalendarTablePagination,
  AdminHistoryTableContainer,
} from "./styles";

const cols = [
  "adminCalendarScene.table.date",
  "adminCalendarScene.table.user",
  "adminCalendarScene.table.orderName",
];

const AdminCalendarTable: FC<{
  filters: { searchText: string; page: number };
  setFilters: SetState<{ searchText: string; page: number }>;
}> = ({ filters, setFilters }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const [reservations, setReservations] =
    useState<Paged<EmployeeCalendarOverview>>();

  const handleLoad = useCallback(async () => {
    setLoading(true);
    try {
      const response = await calendarApi.getEmployeeCalendarReservation(
        1,
        storageApi.getCurrentUser()!.id,
        {
          searchText: filters.searchText,
          pageSize: DEFAULT_PAGE_SIZE,
          page: filters.page,
          orderBy: "DateTime",
        }
      );
      setReservations(response.data);
    } catch (err) {
      toast.error(getErrorMessage(err, t("errors.loadError")));
    }
    setLoading(false);
  }, [filters.page, filters.searchText, t]);

  useEffect(() => {
    handleLoad();
  }, [handleLoad]);

  const tableCols = cols.map((col) => <th key={col}>{t(col)}</th>);

  const tableRows = useMemo(
    () =>
      reservations?.data.map((record) => (
        <tr key={record.id}>
          <td>
            <div>
              <b>{formatDate(record.date)}</b>&nbsp;
              {formatTime(record.date)}
            </div>
          </td>
          <td>
            {record.customer && (
              <Link to={`/admin/clients/${record.customer.id}`}>
                {record.customer.firstName}&nbsp;{record.customer.lastName}
              </Link>
            )}
            {record.note}
          </td>
          <td>
            {record.orderId && (
              <Link to={`/admin/orders/${record.orderId}`}>
                {record.orderName}
              </Link>
            )}
            {record.note && record.employee ? (
              <Link to={`/admin/settings/employees/${record.employee.id}`}>
                {record.employee.firstName}&nbsp;{record.employee.lastName}
              </Link>
            ) : null}
          </td>
          <td>
            {record.note && (
              <MeetingCancelButton
                id={record.id}
                notClient={true}
                reload={handleLoad}
              />
            )}
          </td>
        </tr>
      )),
    [handleLoad, reservations?.data]
  );

  useEffect(() => {
    setFilters({ searchText: "", page: 1 });
  }, [setFilters]);

  if (loading) return <Loader />;
  return (
    <PageSection>
      <FadeInBottom>
        {reservations?.data?.length ? (
          <>
            <OverflowX>
              <AdminHistoryTableContainer>
                <thead>
                  <tr>
                    {tableCols}
                    <td />
                  </tr>
                </thead>
                <tbody>{tableRows}</tbody>
              </AdminHistoryTableContainer>
            </OverflowX>
            <AdminCalendarTablePagination>
              <Pagination
                initialPage={filters.page}
                totalCount={reservations.count}
                onPageChange={(n: number) =>
                  setFilters((prev) => ({ ...prev, page: n }))
                }
              />
            </AdminCalendarTablePagination>
          </>
        ) : (
          <NoTableData>{t("adminCalendarScene.table.noData")}</NoTableData>
        )}
      </FadeInBottom>
    </PageSection>
  );
};

export default AdminCalendarTable;
