import React, { FC } from "react";
import Page from "../../common/layout/Page/Page";
import UserSidebar from "../UserSidebar/UserSidebar";
import CustomerCalendar from "../Reservation/CustomerCalendar/CustomerCalendar";

const Meetings: FC = () => {
  return (
    <Page sidebar={<UserSidebar />}>
      <CustomerCalendar />
    </Page>
  );
};

export default Meetings;
