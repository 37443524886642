import React, { FC, Ref, useState } from "react";
import Header from "../Header/Header";
import { PageContainer, PageContent, PageContentWrapper } from "./styles";
import { Sidebar } from "../../../../styles/sidebar";

const Page: FC<{
  sidebar?: any;
  wideSidebar?: boolean;
  sidebarInfo?: any;
  contentWapperRef?: Ref<HTMLDivElement>;
}> = ({ sidebar, wideSidebar, children, sidebarInfo, contentWapperRef }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <>
      <Header
        sidebar={!!sidebar}
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        sidebarInfo={sidebarInfo}
      />
      <PageContainer>
        {sidebar ? (
          <Sidebar open={sidebarOpen} wideSidebar={wideSidebar}>
            {sidebar}
          </Sidebar>
        ) : (
          <div />
        )}
        <PageContent id="page-content">
          <PageContentWrapper ref={contentWapperRef}>
            {children}
          </PageContentWrapper>
        </PageContent>
      </PageContainer>
    </>
  );
};

export default Page;
