import styled from "styled-components";
import { Table } from "../../../../styles/table";

export const AdminHistoryTableContainer = styled(Table)`
  th,
  td {
    &:not(:first-child) {
      width: max-content;
    }
  }
`;

export const AdminCalendarTablePagination = styled.div`
  margin-top: 1rem;
`;
