import { debounce } from "lodash";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import historyApi from "../../../api/history";
import { Paged } from "../../../models/common";
import { History } from "../../../models/history";
import { FadeInBottom } from "../../../styles/fadeIn";
import { PageSection } from "../../../styles/layout";
import { TableHeaderActions } from "../../../styles/table";
import { H2 } from "../../../styles/title";
import {
  DEBOUNCE_TIMEOUT_MS,
  DEFAULT_PAGE_SIZE,
} from "../../../utils/constants";
import { getErrorMessage } from "../../../utils/errors";
import Search from "../../common/form/Search/Search";
import Page from "../../common/layout/Page/Page";
import Loader from "../../common/Loader/Loader";
import Pagination from "../../common/Pagination/Pagination";
import AdminSidebar from "../AdminSidebar/AdminSidebar";
import AdminHistoryTable from "./AdminHistoryTable";

const AdminHistory: FC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [historyData, setHistoryData] = useState<Paged<History>>({
    count: 0,
    data: [],
  });
  const [filters, setFilters] = useState<{
    searchText: string;
    page: number;
  }>({ searchText: "", page: 1 });

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const response = await historyApi.getHistory({
          searchText: filters.searchText,
          pageSize: DEFAULT_PAGE_SIZE,
          page: filters.page,
          orderDesc: true,
        });
        setHistoryData(response.data);
      } catch (err) {
        toast.error(getErrorMessage(err, t("errors.loadError")));
      }
      setLoading(false);
    })();
  }, [t, filters.searchText, filters.page]);

  return (
    <Page sidebar={<AdminSidebar />}>
      <PageSection>
        <H2>{t("adminHistory.title")}</H2>
        <TableHeaderActions>
          <div>
            <b>
              {!loading &&
                `${historyData.count} ${t("adminHistory.countTextPlural")}`}
            </b>
          </div>
          <div>
            <Search
              name={"allHistorySearch"}
              onInputChange={debounce(
                (event) =>
                  setFilters({ searchText: event.target.value, page: 1 }),
                DEBOUNCE_TIMEOUT_MS
              )}
            />
          </div>
        </TableHeaderActions>
        {loading ? (
          <Loader />
        ) : (
          <FadeInBottom>
            <AdminHistoryTable data={historyData.data} />
            <Pagination
              initialPage={filters.page}
              totalCount={historyData.count}
              onPageChange={(n: number) =>
                setFilters((prev) => ({ ...prev, page: n }))
              }
            />
          </FadeInBottom>
        )}
      </PageSection>
    </Page>
  );
};

export default AdminHistory;
