import React, { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OverflowX } from "../../../../utils/styles";
import { formatDate } from "../../../../utils/formats";
import OrderStateLabel from "../../../common/orders/OrderState/OrderStateLabel";
import OrderBoxData from "../OrderBoxData/OrderBoxData";
import { OrdersTableAutoRenew, OrdersTableContainer } from "./styles";
import { Order } from "../../../../models/order";
import { COLORS } from "../../../../utils/constants";
import { NoTableData } from "../../../../styles/table";
import OrdersTableMenu from "./OrdersTableMenu";
import OrdersTableDetailModal from "./OrdersTableDetailModal/OrdersTableDetailModal";

const OrdersTable: FC<{ data: Order[]; admin?: boolean }> = ({
  data,
  admin,
}) => {
  const { t } = useTranslation();
  const [detailOpen, setDetailOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<Order>();

  const openDetailModal = (row: Order) => {
    setSelectedRow(row);
    setDetailOpen(true);
  };

  const tableCols = (
    <tr>
      {[
        t("orders.table.head.state"),
        t("orders.table.head.box"),
        "", // empty column for spacing purposes
        t("orders.table.head.dateTo"),
        t("orders.table.head.autoRenew"),
        "",
      ].map((col, i) => (
        <th key={i} colSpan={i === 1 ? 4 : undefined}>
          {col}
        </th>
      ))}
    </tr>
  );

  const tableData = useMemo(
    () =>
      data.map((row) => (
        <tr key={row.id}>
          <td>
            <OrderStateLabel state={row.soonExpires ? "Expiring" : row.state} />
          </td>
          <OrderBoxData order={row} admin={admin} />
          <td />
          <td>
            <div>{formatDate(row.dateTo)}</div>
          </td>
          <td>
            <OrdersTableAutoRenew>
              {row.autoExtend ? (
                <FontAwesomeIcon
                  icon={faCheck}
                  size="lg"
                  color={COLORS.green}
                />
              ) : (
                <FontAwesomeIcon icon={faTimes} size="lg" color={COLORS.red} />
              )}
            </OrdersTableAutoRenew>
          </td>
          <td>
            <OrdersTableMenu
              openDetailModal={() => openDetailModal(row)}
              orderId={row.id}
              admin={admin}
            />
          </td>
        </tr>
      )),
    [data, admin]
  );

  return data.length ? (
    <>
      {selectedRow && selectedRow.box && (
        <OrdersTableDetailModal
          open={detailOpen}
          setOpen={setDetailOpen}
          box={selectedRow.box}
        />
      )}
      <OverflowX>
        <OrdersTableContainer>
          <thead>{tableCols}</thead>
          <tbody>{tableData}</tbody>
        </OrdersTableContainer>
      </OverflowX>
    </>
  ) : (
    <NoTableData>{t("orders.table.noData")}</NoTableData>
  );
};

export default OrdersTable;
