import { AxiosResponse } from "axios";
import { FiltersParams, Paged } from "../models/common";
import { QueueHistory, QueueItem } from "../models/queue";
import api from "../utils/api";

export const getQueue = (
  branchId: number = 1
): Promise<AxiosResponse<QueueItem[]>> =>
  api.get(`/api/Branch/${branchId}/CustomerQueue`);

export const dequeue = (
  customerQueueId: string
): Promise<AxiosResponse<boolean>> =>
  api.delete(`api/CustomerQueue/${customerQueueId}`);

export const getUserHistory = (
  customerId: string,
  params: FiltersParams
): Promise<AxiosResponse<Paged<QueueHistory>>> =>
  api.get(`/api/Customer/${customerId}/CustomerQueueHistory`, {
    params,
  });

const queueApi = {
  getUserHistory,
  getQueue,
  dequeue,
};

export default queueApi;
