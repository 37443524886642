import React, { FC } from "react";
import {
  CalendarMeetingConfirmationAddr,
  CalendarMeetingConfirmationContainer,
  CalendarMeetingConfirmationIconWrapper,
  CalendarMeetingConfirmationInfo,
  CalendarMeetingConfirmationLabel,
  CalendarMeetingConfirmationRow,
} from "../../../../styles/calendar";
import { useTranslation } from "react-i18next";
import { faClock, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { COLORS } from "../../../../utils/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card } from "../../../../styles/card";
import { format } from "date-fns";
import { CalendarMeetingConfirmationButton } from "./styles";

const CalendarMeetingConfirmation: FC<{
  reservationDate: Date;
  onClickConfirmReservation: (
    event: React.MouseEvent<HTMLButtonElement>
  ) => void;
  saving?: boolean;
  reserved?: boolean;
}> = ({ reservationDate, onClickConfirmReservation, saving, reserved }) => {
  const { t } = useTranslation();

  return (
    <CalendarMeetingConfirmationContainer>
      <Card>
        <CalendarMeetingConfirmationInfo>
          <CalendarMeetingConfirmationRow>
            <CalendarMeetingConfirmationLabel>
              <CalendarMeetingConfirmationIconWrapper>
                <FontAwesomeIcon icon={faClock} color={COLORS.primary} />
              </CalendarMeetingConfirmationIconWrapper>
              <div>
                {t("reservation.calendar.meetingDate")}:{" "}
                <strong>
                  {t(`common.day.${reservationDate.getDay()}`)}{" "}
                  {format(reservationDate, "d/M/yyyy")}{" "}
                  {t("reservation.calendar.meetingDateAt")}{" "}
                  {format(reservationDate, "HH:mm")}
                </strong>
              </div>
            </CalendarMeetingConfirmationLabel>
          </CalendarMeetingConfirmationRow>
          <CalendarMeetingConfirmationRow>
            <CalendarMeetingConfirmationLabel>
              <CalendarMeetingConfirmationIconWrapper>
                <FontAwesomeIcon icon={faMapMarkerAlt} color={COLORS.primary} />
              </CalendarMeetingConfirmationIconWrapper>
              <div>
                <strong>{t("reservation.calendar.branch")}</strong>
              </div>
            </CalendarMeetingConfirmationLabel>
            <CalendarMeetingConfirmationAddr>
              {t("reservation.calendar.addr")}
            </CalendarMeetingConfirmationAddr>
          </CalendarMeetingConfirmationRow>
        </CalendarMeetingConfirmationInfo>
      </Card>
      <CalendarMeetingConfirmationButton
        type={"submit"}
        onClick={reserved ? undefined : onClickConfirmReservation}
        disabled={saving}
        reserved={reserved}
      >
        {t(`reservation.calendar.${reserved ? "reservedDate" : "reserveDate"}`)}
      </CalendarMeetingConfirmationButton>
    </CalendarMeetingConfirmationContainer>
  );
};

export default CalendarMeetingConfirmation;
