import React, { FC } from "react";
import {
  AdminBoxesSettingsTrafficInfoGrid,
  AdminBoxesSettingsTrafficItem,
  AdminBoxesSettingsTrafficThumbnail,
} from "./styles";
import { ImgResponsive } from "../../../../styles/img";
import AdminBoxesSettingsTrafficDesc from "../AdminBoxesSettingsTrafficDesc/AdminBoxesSettingsTrafficDesc";
import AdminBoxesSettingsTrafficStats from "../AdminBoxesSettingsTrafficStats/AdminBoxesSettingsTrafficStats";
import { BoxType } from "../../../../models/box";
import { Card, NoCardData } from "../../../../styles/card";
import { FadeInBottom } from "../../../../styles/fadeIn";
import { useTranslation } from "react-i18next";
import { getBoxImg } from "../../../../utils/images";

const AdminBoxesSettingsTraffic: FC<{ data: BoxType[] }> = ({ data }) => {
  const { t } = useTranslation();

  const trafficItems = data.map((item) => (
    <Card key={item.id}>
      <AdminBoxesSettingsTrafficItem>
        <AdminBoxesSettingsTrafficThumbnail>
          <ImgResponsive src={getBoxImg(item.type)} alt={item.type} />
        </AdminBoxesSettingsTrafficThumbnail>
        <AdminBoxesSettingsTrafficInfoGrid>
          <AdminBoxesSettingsTrafficDesc boxType={item} />
          <AdminBoxesSettingsTrafficStats data={item} />
        </AdminBoxesSettingsTrafficInfoGrid>
      </AdminBoxesSettingsTrafficItem>
    </Card>
  ));

  return (
    <FadeInBottom>
      {data.length ? (
        trafficItems
      ) : (
        <NoCardData>{t("adminBoxesSettingsTraffic.noData")}</NoCardData>
      )}
    </FadeInBottom>
  );
};

export default AdminBoxesSettingsTraffic;
