import styled from "styled-components";
import { Table } from "../../../../styles/table";

export const OrdersTableContainer = styled(Table)`
  th:nth-child(3) {
    width: max-content;
  }

  th,
  td {
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5) {
      padding-right: 0;
    }
    &:nth-child(6) {
      padding: 0;
    }
  }
`;

export const OrdersTableAutoRenew = styled.div`
  width: 2rem;
  margin: 0 auto;
`;
