import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import documentApi from "../../../../api/document";
import { Document } from "../../../../models/document";
import { FadeInBottom } from "../../../../styles/fadeIn";
import { getErrorMessage } from "../../../../utils/errors";
import Loader from "../../../common/Loader/Loader";
import DocumentsTable from "../../../user/Documents/DocumentsTable/DocumentsTable";
import { AdminClientsDetailTabsLoader } from "./styles";

const AdminClientsDetailDocuments: FC<{ reload: boolean }> = ({ reload }) => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [data, setData] = useState<Document[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const response = await documentApi.getUserDocuments(id);
        setData(response.data);
      } catch (err) {
        toast.error(getErrorMessage(err, t("errors.loadError")));
      }
      setLoading(false);
    })();
  }, [id, t, reload]);

  return loading ? (
    <AdminClientsDetailTabsLoader>
      <Loader />
    </AdminClientsDetailTabsLoader>
  ) : (
    <FadeInBottom>
      <DocumentsTable data={data} />
    </FadeInBottom>
  );
};

export default AdminClientsDetailDocuments;
