import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useDebounce } from "usehooks-ts";
import userApi from "../../../../api/user";
import { FiltersSearchParams, Paged } from "../../../../models/common";
import { UserList } from "../../../../models/user";
import { Button } from "../../../../styles/button";
import { FadeInBottom } from "../../../../styles/fadeIn";
import { PageSection } from "../../../../styles/layout";
import { TableHeaderActions } from "../../../../styles/table";
import { H2 } from "../../../../styles/title";
import {
  DEBOUNCE_TIMEOUT_MS,
  DEFAULT_PAGE_SIZE,
} from "../../../../utils/constants";
import { getErrorMessage } from "../../../../utils/errors";
import { useAdminClientsFilterStore } from "../../../../utils/store";
import {
  OnChangeOrder,
  OrderType,
  WithRequired,
} from "../../../../utils/types";
import FilterTextInput from "../../../common/form/Filters/FilterTextInput";
import { FilterContainer } from "../../../common/form/Filters/styles";
import Pagination from "../../../common/Pagination/Pagination";
import AdminClientsDeleteModal from "../AdminClientsTable/AdminClientsDeleteModal";
import AdminClientsTable from "../AdminClientsTable/AdminClientsTable";

export interface AdminClientsFilter
  extends WithRequired<FiltersSearchParams, "page"> {
  name?: string;
  email?: string;
  phone?: string;
  cardNumber?: string;
}

export const DEFAULT_ADMIN_CLIENTS_FILTER: AdminClientsFilter = { page: 1 };

const AdminClientsContent: FC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const [clientsData, setClientsData] = useState<Paged<UserList>>({
    count: 0,
    data: [],
  });
  const { filters, setFilters } = useAdminClientsFilterStore();
  const debouncedFilters = useDebounce(filters, DEBOUNCE_TIMEOUT_MS);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [deleteCustomerId, setDeleteCustomerId] = useState<number>();

  const order: OrderType = useMemo(
    () => ({ orderBy: filters.orderBy, orderDesc: filters.orderDesc }),
    [filters.orderBy, filters.orderDesc]
  );
  const handleChangeOrder = useCallback<OnChangeOrder>(
    (orderBy, orderDesc) => {
      setFilters({ ...filters, orderBy, orderDesc });
    },
    [filters, setFilters]
  );

  const handleDelete = useCallback((customerId: number) => {
    setDeleteCustomerId(customerId);
    setDeleteModal(true);
  }, []);

  const handleDeleteConfirmed = async () => {
    if (!deleteCustomerId) {
      return;
    }

    try {
      await userApi.deleteUser(deleteCustomerId);
      setClientsData((clients) => ({
        count: clients.count - 1,
        data: clients.data.filter(({ id }) => id !== deleteCustomerId),
      }));
      toast.success(t("common.deleteSuccess"));
    } catch (err) {
      toast.error(getErrorMessage(err, t("errors.deleteError")));
    }
  };

  const handleResetFilter = useCallback(() => {
    setFilters(DEFAULT_ADMIN_CLIENTS_FILTER);
  }, [setFilters]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const response = await userApi.getClients(1, {
          ...(debouncedFilters.name && { name: debouncedFilters.name }),
          ...(debouncedFilters.email && { email: debouncedFilters.email }),
          ...(debouncedFilters.phone && { phone: debouncedFilters.phone }),
          ...(debouncedFilters.cardNumber && {
            cardNumber: debouncedFilters.cardNumber,
          }),
          ...(debouncedFilters.orderBy && {
            orderBy: debouncedFilters.orderBy,
          }),
          ...(debouncedFilters.orderDesc && {
            orderDesc: debouncedFilters.orderDesc,
          }),
          pageSize: DEFAULT_PAGE_SIZE,
          page: debouncedFilters.page,
        });
        setClientsData(response.data);
      } catch (err) {
        toast.error(getErrorMessage(err, t("errors.loadError")));
      }
      setLoading(false);
    })();
  }, [t, debouncedFilters]);

  return (
    <>
      <FadeInBottom>
        <PageSection>
          <H2>{t("adminClients.title")}</H2>
          <TableHeaderActions>
            <FilterContainer>
              <FilterTextInput
                value={filters.name}
                label={t("adminClients.filter.name")}
                onInputChange={(value) => {
                  setFilters({
                    ...filters,
                    name: value,
                    page: 1,
                  });
                }}
              />
              <FilterTextInput
                value={filters.email}
                label={t("adminClients.filter.email")}
                onInputChange={(value) => {
                  setFilters({
                    ...filters,
                    email: value,
                    page: 1,
                  });
                }}
              />
              <FilterTextInput
                value={filters.phone}
                label={t("adminClients.filter.phone")}
                onInputChange={(value) => {
                  setFilters({
                    ...filters,
                    phone: value,
                    page: 1,
                  });
                }}
              />
              <FilterTextInput
                value={filters.cardNumber}
                label={t("adminClients.filter.cardNumber")}
                onInputChange={(value) => {
                  setFilters({
                    ...filters,
                    cardNumber: value,
                    page: 1,
                  });
                }}
              />
              <Button
                onClick={handleResetFilter}
                style={{ alignSelf: "flex-end" }}
              >
                {t("common.resetFilter")}
              </Button>
            </FilterContainer>
          </TableHeaderActions>
          <TableHeaderActions>
            <b>
              {(!loading || clientsData.count > 0) &&
                `${clientsData.count} ${t("adminClients.countTextPlural")}`}
            </b>
          </TableHeaderActions>
          <FadeInBottom>
            <AdminClientsTable
              isLoading={loading}
              data={clientsData.data}
              onDelete={handleDelete}
              order={order}
              onChangeOrder={handleChangeOrder}
            />
            <Pagination
              initialPage={filters.page}
              totalCount={clientsData.count}
              onPageChange={(n: number) => setFilters({ ...filters, page: n })}
            />
          </FadeInBottom>
        </PageSection>
      </FadeInBottom>
      <AdminClientsDeleteModal
        open={deleteModal}
        setOpen={setDeleteModal}
        confirm={handleDeleteConfirmed}
      />
    </>
  );
};

export default AdminClientsContent;
