export interface QueueHistory {
  id: string;
  date: string;
  branchName: string;
  cardName: string;
  cardId: string;
  cardNumber: string;
}

export interface QueueItem {
  id: string;
  idBranch: string;
  idCard: string;
  cardNumber: string;
  state: QueueItemState;
  doorNumber: string;
}

export enum QueueItemState {
  Waiting = "Waiting",
  Go = "Go",
  Inside = "Inside",
}
