import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { COLORS } from "../../../utils/constants";
import {
  CircleStateContainer,
  CircleStateIconCircle,
} from "../../../styles/state";

const CircleStateIsActive: FC<{
  isActive: boolean;
}> = ({ isActive }) => {
  const style = isActive
    ? { color: COLORS.green, icon: faCheck }
    : { color: COLORS.secondaryDark, icon: faTimes };

  return (
    <CircleStateContainer>
      <CircleStateIconCircle backgroundColor={style.color}>
        <FontAwesomeIcon icon={style.icon} color={COLORS.white} size="sm" />
      </CircleStateIconCircle>
    </CircleStateContainer>
  );
};

export default CircleStateIsActive;
