import { Form, Formik, FormikErrors } from "formik";
import React, { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import additionalServicesApi from "../../api/additionalServices";
import boxApi from "../../api/box";
import orderApi from "../../api/order";
import storageApi from "../../api/storage";
import { AdditionalService } from "../../models/additionalServices";
import { BoxType } from "../../models/box";
import { OrderFormData } from "../../models/order";
import { H2 } from "../../styles/title";
import { CUSTOMER_ID_KEY } from "../../utils/constants";
import { formatCurrency } from "../../utils/formats";
import { useStore } from "../../utils/store";
import Page from "../common/layout/Page/Page";
import Loader from "../common/Loader/Loader";
import Ecommerce from "./Ecommerce";
import {
  getTransformedOrderData,
  isOrderValid,
  orderInitValues,
  orderValidation,
} from "./formUtils";
import OrderBoxTypes from "./OrderBoxTypes/OrderBoxTypes";

import OrderParameterLength from "./OrderParameters/OrderParameterLength";
import OrderParameterServices from "./OrderParameters/OrderParameterServices";
import OrderPaymentButtons from "./OrderParameters/OrderPaymentButtons";
import OrderSidebar from "./OrderSidebar/OrderSidebar";
import OrderUser from "./OrderUser/OrderUser";
import { getTotalPriceWithVat } from "./priceUtils";
import {
  BottomText,
  OrderGroup,
  OrderGroupNumber,
  SpaceBetween,
  SubmitButton,
  TotalPriceHeader,
} from "./styles";

const Order: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [boxTypes, setBoxTypes] = useState<BoxType[]>([]);
  const [boxType, setBoxType] = useState<BoxType | null>(null);
  const [additionalServices, setAdditionalServices] = useState<
    AdditionalService[]
  >([]);
  const user = useStore((store) => store.user);
  const pageContentWapperRef = useRef<HTMLDivElement>(null);
  const orderUserRef = useRef<HTMLDivElement>(null);

  const handleSubmit = async (data: OrderFormData) => {
    const transformedData = getTransformedOrderData(data, user);

    try {
      const id = user ? user.id : "0"; // 0 -> new user
      const response = await orderApi.createOrder(transformedData, id);
      if (data.paymentType === "Card") {
        window.location.href = response.data.url;
      } else {
        if (!user) {
          storageApi.setToken(response.data.token);
          localStorage.setItem(CUSTOMER_ID_KEY, response.data.customerId);
        }
        history.push(`/reservation`);
      }
    } catch (err) {
      toast.error(err?.response?.data?.ErrorMessage ?? t("errors.createOrder"));
      if (err?.response?.data?.AdditionalData) {
        toast.error(err.response.data.AdditionalData);
      }
    }
  };

  const handleCheckAndSubmit = async (
    errors: FormikErrors<OrderFormData>,
    submitForm: () => Promise<void>
  ) => {
    await submitForm();

    if (isOrderValid(errors)) {
      return;
    }

    if (errors.boxId) {
      toast.error(t("order.validationBoxType"));
      pageContentWapperRef.current &&
        pageContentWapperRef.current.scrollIntoView();
      return;
    }

    toast.error(t("order.validationOther"));
    orderUserRef.current && orderUserRef.current.scrollIntoView();
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const boxesResponse = await boxApi.getAllBoxes();
        const additionalServicesResponse = await additionalServicesApi.getAll();

        setBoxTypes(boxesResponse.data);
        setAdditionalServices(additionalServicesResponse.data);
      } catch (err) {
        toast.error(t("errors.loadBoxes"));
      }
      setLoading(false);
    })();
  }, [t]);

  if (loading) {
    return <Loader />;
  }

  return (
    <Formik
      initialValues={{
        ...orderInitValues,
        ...user,
        isCompany: !!user?.companyName,
      }}
      validateOnMount
      validateOnChange
      enableReinitialize
      validationSchema={orderValidation(t)}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue, errors, submitForm, isSubmitting }) => (
        <Page
          contentWapperRef={pageContentWapperRef}
          sidebar={
            <OrderSidebar
              values={values}
              boxType={boxType}
              additionalServices={additionalServices}
            />
          }
          sidebarInfo={
            boxType && (
              <TotalPriceHeader>
                {formatCurrency(
                  getTotalPriceWithVat(
                    values,
                    boxType,
                    values.additionalServices
                  )
                )}
              </TotalPriceHeader>
            )
          }
          wideSidebar
        >
          <Form>
            <Ecommerce
              values={values}
              boxType={boxType}
              additionalServices={values.additionalServices}
            />
            <OrderBoxTypes
              boxTypes={boxTypes}
              selectedBoxId={values.boxId}
              selectBoxType={(boxType: BoxType | null) => {
                setFieldValue("boxId", boxType ? boxType.id : null);
                setBoxType(boxType);
              }}
            />
            <SpaceBetween />
            <OrderParameterLength
              boxType={boxType}
              value={values.lengthOfLeaseType}
              setValue={(value) => setFieldValue("lengthOfLeaseType", value)}
            />
            <SpaceBetween />
            <OrderParameterServices
              boxType={boxType ?? boxTypes[0]}
              additionalServices={additionalServices}
              fingerPrint={values.fingerPrint}
              setFingerPrint={(value) => setFieldValue("fingerPrint", value)}
              smsCheck={values.smsCheck}
              setSmsCheck={(value) => setFieldValue("smsCheck", value)}
              additionalServicesValue={values.additionalServices}
              setAdditionalServicesValue={(value) =>
                setFieldValue("additionalServices", value)
              }
            />
            <SpaceBetween ref={orderUserRef} />
            <OrderUser />
            <SpaceBetween />
            <OrderPaymentButtons
              value={values.paymentType}
              setValue={(value) => setFieldValue("paymentType", value)}
            />
            <SpaceBetween />
            <OrderGroup>
              <OrderGroupNumber>6</OrderGroupNumber>
              <H2 bold>{t("order.sendTitle")}</H2>
            </OrderGroup>
            <SubmitButton
              type="submit"
              disabled={isSubmitting}
              onClick={() => handleCheckAndSubmit(errors, submitForm)}
            >
              {t("order.send")}
            </SubmitButton>
            <BottomText>
              <a
                href="https://www.safeboxes.cz/files/2021/03/vseobecne-obchodni-podminky.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("order.bottomText")}
              </a>
            </BottomText>
            <SpaceBetween />
          </Form>
        </Page>
      )}
    </Formik>
  );
};

export default Order;
