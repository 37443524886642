import styled from "styled-components";
import { Table } from "../../../../styles/table";
import { COLORS } from "../../../../utils/constants";

export const InvoicesTableContainer = styled(Table)`
  td:first-child {
    svg {
      margin-right: 0.5rem;
    }
  }
`;

export const PayText = styled.p`
  color: ${COLORS.red};
  cursor: pointer;
`;
