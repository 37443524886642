import { IconName, IconPrefix } from "@fortawesome/fontawesome-svg-core";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { AdditionalService } from "../../../models/additionalServices";
import { IconButton } from "../../../styles/button";
import { useUserHasRight } from "../../../utils/auth";
import { COLORS } from "../../../utils/constants";
import { OverflowX } from "../../../utils/styles";
import { AdditionalServicesTable, AdditionalServiceState } from "./style";

const cols = [
  "adminAdditionalServices.isActive",
  "adminAdditionalServices.icon",
  "adminAdditionalServices.name",
  "adminAdditionalServices.description",
  "adminAdditionalServices.notificationOnRenewal",
];

const AdminAdditionalServicesTable: FC<{
  data: AdditionalService[];
  onEdit: (id: number) => void;
}> = ({ data, onEdit }) => {
  const { t } = useTranslation();
  const { hasUserAllRights } = useUserHasRight();

  const tableCols = cols.map((col) => <th key={col}>{t(col)}</th>);

  const tableRows = useMemo(
    () =>
      data.map((row) => (
        <tr key={row.id}>
          <td>
            <AdditionalServiceState isActive={row.isActive}>
              {t(
                `adminAdditionalServices.${row.isActive ? "active" : "inactive"
                }`
              )}
            </AdditionalServiceState>
          </td>
          <td>
            <FontAwesomeIcon
              icon={{
                prefix: row.icon.substring(0, 3) as IconPrefix,
                iconName: row.icon.substring(3) as IconName,
              }}
              color={COLORS.primary}
            />
          </td>
          <td>{row.name}</td>
          <td>{row.description}</td>
          <td>
            <AdditionalServiceState isActive={row.shouldSendNotificationOnRenewal}>
              {t(
                `adminAdditionalServices.${row.shouldSendNotificationOnRenewal ? "active" : "inactive"
                }`
              )}
            </AdditionalServiceState>
          </td>
          <td>
            <div>
              {hasUserAllRights(["writeAdditionalServices"]) && (
                <IconButton icon={faPen} onClick={() => onEdit(row.id)} />
              )}
            </div>
          </td>

        </tr>
      )),
    [data, t, hasUserAllRights, onEdit]
  );

  return (
    <OverflowX>
      <AdditionalServicesTable>
        <thead>
          <tr>
            {tableCols}
            <th></th>
          </tr>
        </thead>
        <tbody>{tableRows}</tbody>
      </AdditionalServicesTable>
    </OverflowX>
  );
};

export default AdminAdditionalServicesTable;
