import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled, { css } from "styled-components";
import logoSlider from "../../../images/logo-slider.svg";
import { CheckButton } from "../../../styles/button";
import { H2 } from "../../../styles/title";
import { COLORS } from "../../../utils/constants";
import { fadeIn, size } from "../../../utils/styles";

export const OrderForm = styled.section`
  > :not(:last-child) {
    margin-bottom: 2rem;
  }
`;

export const OrderRadioLabel = styled.div<{ checked: boolean }>`
  display: flex;
  justify-content: space-between;
  font-size: 0.9rem;

  span {
    font-weight: bold;

    &:nth-child(2) {
      ${({ checked }) =>
        checked
          ? css`
              font-weight: bold !important;
              color: ${COLORS.primary} !important;
              text-align: end;
            `
          : "font-weight: normal;"}
    }
  }

  @media screen and (min-width: ${size.mobile}) {
    font-size: 1rem;
  }
`;

export const OrderCheckboxLabel = styled(OrderRadioLabel)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > span:first-child {
    margin-right: 1rem;
  }
`;

export const OrderLeaseLengthLabel = styled.span`
  font-weight: bold !important;
  display: flex;
  align-items: center;
`;

export const OrderLeaseLengthPrice = styled.span`
  text-align: end;

  div:last-child {
    color: ${COLORS.grayLight};
    font-size: 0.75rem;
  }
`;

export const SliderContainer = styled.div`
  margin-bottom: 2rem;

  .slider {
    width: 100%;
    height: 70px;
  }

  .slider-thumb {
    cursor: pointer;
    top: 1px;
    width: 50px;
    height: 48px;
    background: url("${logoSlider}") no-repeat center;
    transition: all 0.3s ease-in-out;
  }

  .slider-thumb :focus {
    outline: none;
  }

  .slider-track {
    position: relative;
    top: 17px;
    height: 16px;
    background: ${COLORS.primary};
    border-radius: 100px;
    transition: all 0.3s ease-in-out;
  }

  .slider-track.slider-track-1 {
    background: ${COLORS.secondary};
    margin-left: 10px;
  }

  .slider-mark {
    position: absolute;
    top: 45px;
    margin-left: 20px;
    transform: translate(-50%);
    white-space: nowrap;
  }

  @media screen and (max-width: ${size.desktop}) {
    .slider-mark {
      visibility: hidden;
    }
  }
`;

export const OrderLeaseLengthText = styled(H2)`
  margin-left: 2rem;
`;

export const ServicesContainer = styled.div`
  display: grid;
  row-gap: 2rem;

  @media screen and (min-width: ${size.mobile}) {
    grid-template-columns: 1fr;
    column-gap: 0;
  }

  @media screen and (min-width: ${size.tablet}) {
    grid-template-columns: 1fr;
    column-gap: 0;
  }

  @media screen and (min-width: ${size.desktop}) {
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
  }

  @media screen and (min-width: ${size.desktopL}) {
    grid-template-columns: 1fr 1fr;
    column-gap: 4rem;
  }
`;

export const ServiceContainer = styled.div<{
  active: boolean;
}>`
  transition: all 0.15s ease-in-out;
  background-color: ${COLORS.whitesmoke};
  border: ${({ active }) =>
    active ? "5px solid var(--primary)" : "2px solid var(--secondary)"};
  border-radius: 15px;
  animation: ${fadeIn} 0.3s ease-in-out;
  margin-bottom: ${({ active }) => (active ? "0px" : "6px")};
  overflow: hidden;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 0.5rem;
  padding-bottom: 1rem;
  cursor: pointer;
`;

export const ServiceTitle = styled(H2)`
  text-align: center;
`;

export const ServiceColumns = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 2rem;
`;

export const PaymentButton = styled(CheckButton)`
  display: block;
  width: 17rem;
  text-align: left;
  margin-bottom: 1rem;
  margin-left: 1rem;
  font-size: 1rem;

  @media screen and (min-width: ${size.tablet}) {
    width: 24rem;
    font-size: 1.5rem;
  }
`;

export const ServiceIconContainer = styled.div`
  width: 90px;
  height: 90px;
  background-color: ${COLORS.white};
  border: 1px solid ${COLORS.primary};
  border-radius: 50%;
`;

export const ServiceIcon = styled(FontAwesomeIcon)`
  position: relative;
  top: 13px;
  display: block;
  margin: auto;
`;

export const ServiceDetail = styled.div`
  color: ${COLORS.black};
  font-weight: 700;
`;

export const ServicePrice = styled.div`
  background-color: ${COLORS.white};
  font-weight: 700;
  border: 1px solid ${COLORS.primary};
  border-radius: 15px;
  margin-top: 1rem;
  padding: 8px 35px;
  display: inline-block;
`;
