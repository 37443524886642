import axios from "axios";
import storageApi from "../api/storage";
import authApi from "../api/auth";
import i18n from "../utils/i18n";
import { toast } from "react-toastify";
import { addMinutes, isAfter } from "date-fns";

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
});

api.interceptors.request.use(
  async (config) => {
    const tokenRefreshTimeout = storageApi.getTokenRefreshTimeout();
    const tokenTimeout = storageApi.getTokenTimeout();
    const token = storageApi.getToken();

    if (token && tokenRefreshTimeout && tokenTimeout) {
      // logout if token expired
      if (isAfter(new Date(), tokenTimeout)) {
        authApi.logout(true);
        return new Promise(() => false);
      }

      // renew token if it's about to expire
      if (isAfter(new Date(), tokenRefreshTimeout)) {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/Auth/TokenRenew`,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        const renewData = await response.json();
        storageApi.setToken(renewData.token);
        storageApi.setIdleMinutes(renewData.userMaxIdleMinutes);
        storageApi.setTokenRefreshTimeout(
          addMinutes(new Date(), renewData.userMaxIdleMinutes)
        );
        storageApi.setTokenTimeout(
          addMinutes(new Date(), renewData.tokenValidMinutes)
        );
      }
    }

    config.headers = {
      Authorization: storageApi.getToken(),
    };

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.request && !error.response) {
      // The request was made but no response was received
      toast.error(i18n.t("errors.noResponse"));
    } else if (!error.request && !error.response) {
      // Something happened in setting up the request that triggered an Error
      toast.error(i18n.t("errors.requestSetup"));
    } else if (error.response.status === 401 || error.response.status === 403) {
      const token = storageApi.getToken();
      if (!token) {
        authApi.logout();
      }
    }
    return Promise.reject(error);
  }
);

export default api;
