import styled from "styled-components";
import { size } from "../../../../utils/styles";

export const CardBoxesOccupancyMain = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const CardBoxesOccupancyContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 12.5rem;
`;

export const CardBoxesOccupancyLegend = styled.div`
  display: none;

  @media screen and (min-width: ${size.mobile}) {
    display: flex;
    justify-content: space-around;
    margin: 0.5rem 0 0.8rem 0;
  }
`;

export const CardBoxesOccupancyLabel = styled.div`
  display: flex;
  align-items: center;
`;

export const CardBoxesOccupancySquare = styled.div<{
  color: string;
  borderColor?: string;
}>`
  height: 1.25rem;
  width: 1.25rem;
  margin-right: 0.5rem;
  border-radius: 4px;

  background-color: ${({ color }) => color};
  border: 1px solid ${({ borderColor }) => borderColor || "transparent"};
`;

export const CardBoxesOccupancyChart = styled.div`
  height: 100%;
  position: relative;
`;
