import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SidebarLink, SidebarLinkIcon, SidebarLinks } from "./styles";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { useTranslation } from "react-i18next";
import { COLORS } from "../../../../../utils/constants";

interface Props {
  links: {
    id: string;
    link: string;
    icon: IconDefinition;
  }[];
}

const BasicLinks: FC<Props> = ({ links }) => {
  const { t } = useTranslation();

  return (
    <SidebarLinks>
      {links.map((l) => (
        <SidebarLink
          key={l.id}
          to={l.link}
          activeClassName="active"
          exact={l.link === "/" || l.link === "/admin"}
        >
          {t(`sidebar.user.${l.id}`)}{" "}
          <SidebarLinkIcon>
            <FontAwesomeIcon icon={l.icon} size="1x" color={COLORS.grayLight} />
          </SidebarLinkIcon>
        </SidebarLink>
      ))}
    </SidebarLinks>
  );
};

export default BasicLinks;
