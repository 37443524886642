import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const SidebarLinks = styled.nav`
  display: flex;
  flex-flow: column;

  padding: 0.5rem;

  background-color: var(--gray);

  .active {
    background-color: var(--gray-light);

    svg {
      color: var(--white);
    }
  }
`;

export const SidebarLink = styled(NavLink)`
  width: 100%;

  padding: 0.5rem 0.75rem;
  border-radius: 0.3rem;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: end;

  color: var(--secondary) !important;
  text-transform: uppercase;
  text-decoration: none;

  &:hover {
    &,
    svg {
      color: var(--white) !important;
    }
  }
`;

export const SidebarLinkIcon = styled.span`
  display: inline-block;
  width: 2.5rem;
  text-align: center;
  margin-left: 1rem;
  font-size: 1.75rem;

  svg,
  span {
    transition: color 0.2s ease-in-out;
  }
`;
