import styled from "styled-components";
import { Button } from "../../../styles/button";
import { size } from "../../../utils/styles";

export const AdminCalendarSceneButtons = styled.div`
  flex-direction: column;

  & > ${Button} {
    width: 100%;
  }

  & > ${Button}:first-child {
    margin-bottom: 0.5rem;
  }

  @media screen and (min-width: ${size.tablet}) {
    flex-direction: row;

    & > ${Button}:first-child {
      margin-right: 0.5rem;
      margin-bottom: 0;
    }
  }
`;
