import React, { FC } from "react";
import { SidebarContentHeader } from "../../../styles/sidebar";
import SidebarHeader from "../../common/layout/sidebar/SidebarHeader/SidebarHeader";
import {
  faBox,
  faKey,
  faCreditCard,
  faFileAlt,
  faFileInvoice,
  faThLarge,
  faUserCircle,
  faUserShield,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  UserSidebarFooter,
  UserSidebarFooterA,
  UserSidebarFooterIcon,
  UserSidebarFooterText,
} from "./styles";
import SidebarBasicLinks from "../../common/layout/sidebar/SidebarBasicLinks/SidebarBasicLinks";
import { COLORS } from "../../../utils/constants";
import { useStore } from "../../../utils/store";

const links = [
  { id: "dashboard", link: "/", icon: faThLarge },
  { id: "orders", link: "/orders", icon: faBox },
  { id: "cards", link: "/cards", icon: faCreditCard },
  { id: "profile", link: "/profile", icon: faUserCircle },
  { id: "documents", link: "/documents", icon: faFileAlt },
  { id: "invoices", link: "/invoices", icon: faFileInvoice },
  { id: "changePassword", link: "/settings", icon: faKey },
];

const UserSidebar: FC = () => {
  const { t } = useTranslation();
  const user = useStore((store) => store.user);

  return (
    <SidebarContentHeader>
      <SidebarHeader name={`${user?.firstName} ${user?.lastName}`} />
      <SidebarBasicLinks links={links} />
      <UserSidebarFooter>
        <UserSidebarFooterA href="https://www.safeboxes.cz/files/2021/04/ochrana-osobnich-udaju_safeboxes-1.pdf">
          <UserSidebarFooterIcon>
            <FontAwesomeIcon icon={faUserShield} color={COLORS.grayLight} />
          </UserSidebarFooterIcon>
          {t("sidebar.user.privacyPolicy")}
        </UserSidebarFooterA>
        <UserSidebarFooterText>
          <b>{t("sidebar.user.contact")}</b>
        </UserSidebarFooterText>
        <UserSidebarFooterText>
          {t("sidebar.user.copyright")}
        </UserSidebarFooterText>
      </UserSidebarFooter>
    </SidebarContentHeader>
  );
};

export default UserSidebar;
