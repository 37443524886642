import styled, { css } from "styled-components";

interface Props {
  bold?: boolean;
  uppercase?: boolean;
  color?: string;
}

const setBold = (props: { bold?: boolean }) =>
  props.bold &&
  css`
    font-weight: bold;
  `;

const setUppercase = (props: { uppercase?: boolean }) =>
  props.uppercase &&
  css`
    text-transform: uppercase;
  `;

const setColor = (props: { color?: string }) =>
  props.color &&
  css`
    color: ${props.color};
  `;

export const H1 = styled.h1<Props>`
  margin-bottom: 0.4rem;
  font-size: 1.8rem;

  ${setBold}
  ${setUppercase}
  ${setColor}
`;

export const H2 = styled.h2<Props>`
  margin-bottom: 0.8rem;
  font-size: 1.4rem;

  ${setBold}
  ${setUppercase}
  ${setColor}
`;

export const H3 = styled.h3<Props>`
  margin-bottom: 0.5rem;
  font-size: 1.2rem;

  ${setBold}
  ${setUppercase}
  ${setColor}
`;

export const H4 = styled.h4<Props>`
  margin-bottom: 0.35rem;
  font-size: 1.1rem;

  ${setBold}
  ${setUppercase}
  ${setColor}
`;
