import create from "zustand";
import storageApi from "../api/storage";
import {
  AdminClientsFilter,
  DEFAULT_ADMIN_CLIENTS_FILTER,
} from "../components/admin/AdminClients/AdminClientsContent/AdminClientsContent";
import {
  AdminEmployeesFilter,
  DEFAULT_ADMIN_EMPLOYEES_FILTER,
} from "../components/admin/AdminEmloyees/AdminEmployeesContent/AdminEmployeesContent";
import {
  AdminOrdersFilter,
  DEFAULT_ADMIN_ORDERS_FILTER,
} from "../components/admin/AdminOrders/AdminOrdersContent/AdminOrdersContent";
import { User } from "../models/user";

export type Store = {
  locale: string;
  user: User | null;
  setUser: (user: User | null) => void;
};

export const useStore = create<Store>((set) => ({
  locale: "cs",
  user: storageApi.getCurrentUser(),
  setUser: (newUser: User | null) => set({ user: newUser }),
}));

export const useReservationStore = create<{
  reserved: boolean;
  note?: string;
  setReserved: () => void;
  setNote: (note?: string) => void;
}>((set) => ({
  reserved: false,
  setReserved: () => set({ reserved: true }),
  setNote: (note?: string) => set({ note }),
}));

export type AdminClientsFilterStore = {
  filters: AdminClientsFilter;
  setFilters: (filter: AdminClientsFilter) => void;
};

export const useAdminClientsFilterStore = create<AdminClientsFilterStore>(
  (set) => ({
    filters: DEFAULT_ADMIN_CLIENTS_FILTER,
    setFilters: (filters: AdminClientsFilter) => set({ filters }),
  })
);

export type AdminEmployeesFilterStore = {
  filters: AdminEmployeesFilter;
  setFilters: (filter: AdminEmployeesFilter) => void;
};

export const useAdminEmployeesFilterStore = create<AdminEmployeesFilterStore>(
  (set) => ({
    filters: DEFAULT_ADMIN_EMPLOYEES_FILTER,
    setFilters: (filters: AdminEmployeesFilter) => set({ filters }),
  })
);

export type AdminOrdersFilterStore = {
  filters: AdminOrdersFilter;
  setFilters: (filter: AdminOrdersFilter) => void;
};

export const useAdminOrdersFilterStore = create<AdminOrdersFilterStore>(
  (set) => ({
    filters: DEFAULT_ADMIN_ORDERS_FILTER,
    setFilters: (filters: AdminOrdersFilter) => set({ filters }),
  })
);

export type BasicAdminFilter = {
  searchText: string;
  page: number;
};

export type AdminPromoCodesFilterStore = {
  filters: BasicAdminFilter;
  setFilters: (filter: BasicAdminFilter) => void;
};

export const useAdminPromoCodesFilterStore = create<AdminPromoCodesFilterStore>(
  (set) => ({
    filters: { page: 1, searchText: "" },
    setFilters: (filters: BasicAdminFilter) => set({ filters }),
  })
);
