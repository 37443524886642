import styled from "styled-components";
import { COLORS } from "../utils/constants";

export const DropdownMenu = styled.div`
  display: flex;
  flex-flow: column;
  background-color: ${COLORS.white};
  border: 1px solid ${COLORS.secondary};
  box-shadow: 0 3px 4px 0 ${COLORS.whitesmoke};
`;

export const DropdownMenuItem = styled.div`
  min-width: 8rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;

  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: ${COLORS.primary};
    color: ${COLORS.white};
    cursor: pointer;
  }
`;
