import React, { FC, useCallback, useEffect, useState } from "react";
import { FiltersParams, Paged } from "../../../models/common";
import Page from "../../common/layout/Page/Page";
import Pagination from "../../common/Pagination/Pagination";
import UserSidebar from "../UserSidebar/UserSidebar";
import CardsAccesses from "./CardsAccesses/CardsAccesses";
import { H2 } from "../../../styles/title";
import { useTranslation } from "react-i18next";
import CardsTable from "./CardsTable/CardsTable";
import { PageSection, PageSectionHeader } from "../../../styles/layout";
import { Button } from "../../../styles/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { COLORS } from "../../../utils/constants";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Card } from "../../../models/card";
import cardApi from "../../../api/card";
import { toast } from "react-toastify";
import Loader from "../../common/Loader/Loader";
import { FadeInBottom } from "../../../styles/fadeIn";
import queueApi from "../../../api/queue";
import { QueueHistory } from "../../../models/queue";
import { Link } from "react-router-dom";
import storageApi from "../../../api/storage";

const Cards: FC = () => {
  const { t } = useTranslation();
  const [loadingCards, setLoadingCards] = useState(true);
  const [reloadCards, setReloadCards] = useState(false);
  const [loadingHistory, setLoadingHistory] = useState(true);

  const [cards, setCards] = useState<Card[]>([]);
  const [historyData, setHistoryData] = useState<Paged<QueueHistory>>();
  const [historyPaging, setHistoryPaging] = useState<FiltersParams>({
    page: 1,
    pageSize: 5,
  });

  const handleLoadCards = useCallback(async () => {
    setLoadingCards(true);
    try {
      const cardsRes = await cardApi.getUserCards(
        storageApi.getCurrentUser()!.id
      );
      setCards(cardsRes.data);
    } catch (err) {
      toast.error(t("errors.cards"));
    }
    setLoadingCards(false);
  }, [t]);

  const handleLoadHistory = useCallback(async () => {
    setLoadingHistory(true);
    try {
      const historyRes = await queueApi.getUserHistory(
        storageApi.getCurrentUser()!.id,
        historyPaging
      );
      setHistoryData(historyRes.data);
    } catch (err) {
      toast.error(t("errors.cards"));
    }
    setLoadingHistory(false);
  }, [historyPaging, t]);

  useEffect(() => {
    handleLoadCards();
  }, [handleLoadCards, reloadCards]);

  useEffect(() => {
    handleLoadHistory();
  }, [handleLoadHistory]);

  return (
    <Page sidebar={<UserSidebar />}>
      <FadeInBottom>
        <PageSection>
          <PageSectionHeader>
            <H2>{t("cards.table.title")}</H2>
            <Button as={Link} to="/meetings">
              <FontAwesomeIcon icon={faPlus} color={COLORS.white} />
              {t("cards.newCard")}
            </Button>
          </PageSectionHeader>
          {loadingCards ? (
            <Loader />
          ) : (
            <CardsTable
              data={cards}
              onSuccess={() => setReloadCards((prev) => !prev)}
            />
          )}
        </PageSection>
        <PageSection>
          <H2>{t("dashboard.accesses.title")}</H2>
          <CardsAccesses
            flat
            data={historyData?.data}
            isLoading={loadingHistory}
          />
          <Pagination
            initialPage={historyPaging.page!}
            totalCount={historyData?.count ?? 0}
            pageSize={5}
            onPageChange={(n: number) =>
              setHistoryPaging({ ...historyPaging, page: n })
            }
          />
        </PageSection>
      </FadeInBottom>
    </Page>
  );
};

export default Cards;
