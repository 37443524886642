import React, { FC } from "react";
import Input from "../form/Input/Input";
import { FormGroup } from "../../../styles/form";
import { useFormikContext } from "formik";
import { UserData } from "../../../models/user";
import { useTranslation } from "react-i18next";
import { Card } from "../../../styles/card";

const fields: Array<keyof UserData> = [
  "companyName",
  "companyNo",
  "companyVatNo",
];

const UserCompanyForm: FC<{
  tooltipLeft?: boolean;
}> = ({ tooltipLeft }) => {
  const { t } = useTranslation();
  const { errors, touched } = useFormikContext<UserData>();

  return (
    <FormGroup>
      {fields.map((f) => (
        <Input
          key={f}
          name={f}
          label={t(`userInfo.info.${f}`)}
          error={touched?.[f] && !!errors?.[f]}
          tooltipLeft={tooltipLeft}
        />
      ))}
      <Card>
        {t("userInfo.companyHint1")}{" "}
        <a
          href="https://safeboxes.cz/cs/dokumenty-ke-stazeni"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("userInfo.companyHint2")}
        </a>
        {"."}
      </Card>
    </FormGroup>
  );
};

export default UserCompanyForm;
