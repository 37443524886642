import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookOpen, faPlus } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { TableHeaderActions } from "../../../../styles/table";
import { H2 } from "../../../../styles/title";
import { COLORS } from "../../../../utils/constants";
import { OrdersTableHeaderButton } from "./styles";

const OrdersTableHeader: FC<{ dashboard?: boolean }> = ({ dashboard }) => {
  const { t } = useTranslation();

  return (
    <TableHeaderActions>
      <div>
        <H2>{t("orders.title")}</H2>
        {dashboard && (
          <Link to="/orders">
            <b>{t("orders.showOrders")}</b>
          </Link>
        )}
      </div>
      <div>
        {dashboard && (
          <OrdersTableHeaderButton ver="secondary" as={Link} to="/cards">
            <FontAwesomeIcon icon={faBookOpen} color={COLORS.primaryDark} />
            {t("orders.showHistory")}
          </OrdersTableHeaderButton>
        )}
        <OrdersTableHeaderButton as={Link} to="/order">
          <FontAwesomeIcon icon={faPlus} color={COLORS.white} />
          {t("orders.newOrder")}
        </OrdersTableHeaderButton>
      </div>
    </TableHeaderActions>
  );
};

export default OrdersTableHeader;
