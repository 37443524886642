import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import storageApi from "../../../api/storage";
import userApi from "../../../api/user";
import { User, UserData } from "../../../models/user";
import { ContactCard, ContactCardHeader } from "../../../styles/card";
import { H3 } from "../../../styles/title";
import { COLORS } from "../../../utils/constants";
import UserInfoCardContent from "./UserInfoCardContent";
import UserInfoModal from "./UserInfoModal";
import { transformUserData } from "./utils";

const initialValues: User = {
  id: "",
  firstName: "",
  lastName: "",
  street: "",
  city: "",
  zipCode: "",
  country: "",
  email: "",
  phone: "",
  companyName: "",
  companyNo: "",
  companyVatNo: "",
  note: "",
  isCustomer: true,
  role: "Customer",
  ownCalendar: true,
  isActive: true,
  isCustomerActive: false,
  rights: {},
};

const UserInfoCard: FC<{
  userInfo: UserData;
  editable?: boolean;
  reload?: () => void;
}> = ({ userInfo, editable, reload }) => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<User>(initialValues);

  useEffect(() => {
    if (modalOpen) {
      (async () => {
        setLoading(true);
        try {
          const user = await userApi.getUser(storageApi.getCurrentUser()!.id);
          setUser(user.data);
        } catch (err) {
          toast.error(t("errors.userInfo"));
        }
        setLoading(false);
      })();
    }
  }, [modalOpen, t]);

  const handleModalFormSubmit = async (data: User, isCompany: boolean) => {
    try {
      await userApi.updateUser(transformUserData(data, isCompany));
      toast.success(t("userInfo.editSuccess"));
      if (reload) reload();
      setModalOpen(false);
    } catch (err) {
      toast.error(t("errors.userUpdate"));
    }
  };

  return (
    <ContactCard>
      <UserInfoModal
        open={modalOpen}
        setOpen={setModalOpen}
        handleSubmit={handleModalFormSubmit}
        loading={loading}
        user={user}
      />
      <ContactCardHeader>
        <H3>
          {t("userInfo.info.title")}
          {editable && (
            <FontAwesomeIcon
              icon={faPen}
              size="sm"
              color={COLORS.primary}
              onClick={() => setModalOpen(true)}
            />
          )}
        </H3>
      </ContactCardHeader>
      <UserInfoCardContent userInfo={userInfo} />
    </ContactCard>
  );
};

export default UserInfoCard;
