import styled from "styled-components";
import { H1, H2, H4 } from "../../../../styles/title";
import { COLORS } from "../../../../utils/constants";

export const InvoiceSidebarContainer = styled.div`
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  padding: 2rem;
`;

export const InvoiceSidebarState = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;

  ${H2} {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    text-align: center;
    font-size: 2.5rem;
  }

  ${H4} {
    text-align: center;
  }
`;

export const InvoiceSidebarPrice = styled.div`
  display: flex;
  flex-flow: column;

  b {
    font-size: 2rem;
  }
`;

export const InvoiceSidebarTitle = styled.div`
  ${H1} {
    margin-top: 0.25rem;
  }
`;

export const NumberSymbol = styled.span`
  display: inline-flex;
  background-color: ${COLORS.black};
  justify-content: center;
  align-items: center;

  padding: 0 0.25rem;
  margin-right: 0.25rem;

  color: ${COLORS.white};
  border-radius: 4px;
`;

export const InvoiceSidebarLink = styled.a`
  color: ${COLORS.primaryDark};
`;
