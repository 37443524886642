import React, { FC } from "react";
import { BoxTypeLabel, BoxTypeNameLabelContainer } from "./styles";
import { useTranslation } from "react-i18next";

const BoxTypeNameLabel: FC<{ type: string; belaBoxNo?: number | null }> = ({
  type,
  belaBoxNo,
}) => {
  const { t } = useTranslation();

  return (
    <BoxTypeNameLabelContainer>
      <BoxTypeLabel>{type}</BoxTypeLabel>
      <b>{belaBoxNo ? `#${belaBoxNo}` : `${t("common.box")} ${type}`}</b>
    </BoxTypeNameLabelContainer>
  );
};

export default BoxTypeNameLabel;
