import React, { FC, useRef } from "react";
import { SetState } from "../../../utils/types";
import { Modal } from "react-responsive-modal";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  ModalActions,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalMain,
} from "./styles";

const CustomModal: FC<{
  open: boolean;
  setOpen: SetState<boolean>;
  title: string;
  actions?: JSX.Element;
}> = ({ open, setOpen, title, actions, children }) => {
  const modalMainEl = useRef<HTMLDivElement>(null);

  return (
    <>
      <ModalMain ref={modalMainEl} />
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        showCloseIcon={false}
        container={modalMainEl.current}
        center
      >
        <ModalHeader>
          {title}
          <ModalCloseButton icon={faTimes} onClick={() => setOpen(false)} />
        </ModalHeader>
        <ModalContent>{children}</ModalContent>
        {actions && <ModalActions>{actions}</ModalActions>}
      </Modal>
    </>
  );
};

export default CustomModal;
