import styled, { css } from "styled-components";
import { Button } from "../../../../styles/button";
import { COLORS } from "../../../../utils/constants";

export const CalendarMeetingConfirmationButton = styled(Button)<{
  reserved?: boolean;
}>`
  min-height: 4rem;
  border-radius: 0;

  background-color: ${COLORS.green};
  &:hover {
    background-color: ${COLORS.green};
    ${({ reserved }) =>
      css`
        filter: ${reserved ? "none" : "brightness(0.9)"};
        cursor: ${reserved ? "default" : "pointer"};
      `}
  }
`;
