import React, { FC } from "react";
import GlobalStyles from "../../../styles/globalStyles/globalStyles";
import { ToastContainer } from "react-toastify";
import IdleHandler from "../IdleHandler/IdleHandler";
import "../../../utils/i18n";
import "react-toastify/dist/ReactToastify.css";
import "rc-dropdown/assets/index.css";
import "react-responsive-modal/styles.css";

const Providers: FC = ({ children }) => {
  return (
    <>
      <GlobalStyles />
      <ToastContainer />
      <IdleHandler />
      {children}
    </>
  );
};

export default Providers;
