import React, { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import boxApi from "../../../../api/box";
import documentApi from "../../../../api/document";
import orderApi from "../../../../api/order";
import { BoxPrice } from "../../../../models/box";
import { Order } from "../../../../models/order";
import { Button } from "../../../../styles/button";
import { FadeInBottom } from "../../../../styles/fadeIn";
import { PageSection } from "../../../../styles/layout";
import { DocumentTemplateType } from "../../../../utils/enums";
import { getErrorMessage } from "../../../../utils/errors";
import EditOrderDocumentsModal from "../../../admin/AdminClientsDetail/AddDocumentModal/EditOrderDocumentsModal";
import AdminInvoicePayModal from "../../../admin/AdminInvoices/AdminInvoicePayModal";
import AdminSidebar from "../../../admin/AdminSidebar/AdminSidebar";
import Page from "../../../common/layout/Page/Page";
import Loader from "../../../common/Loader/Loader";
import Tabs from "../../../common/Tabs/Tabs";
import CardsTable from "../../Cards/CardsTable/CardsTable";
import DocumentsTable from "../../Documents/DocumentsTable/DocumentsTable";
import InvoicesTable from "../../Invoices/InvoicesTable/InvoicesTable";
import UserSidebar from "../../UserSidebar/UserSidebar";
import OrderDetailCard from "./OrderDetailCard/OrderDetailCard";

const OrderDetail: FC<{ admin?: boolean }> = ({ admin }) => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState<Order>();
  const [boxPrice, setBoxPrice] = useState<BoxPrice>();
  const [editDoc, setEditDoc] = useState(false);
  const [payOpen, setPayOpen] = useState(false);
  const [payInvoiceId, setPayInvoiceId] = useState("");

  const handlePay = (invoiceId: string) => {
    setPayInvoiceId(invoiceId);
    setPayOpen(true);
  };

  const handlePayConfirm = async () => {
    try {
      await documentApi.payUserInvoice(payInvoiceId);
      const newOrder = order;
      newOrder!.invoices = newOrder!.invoices.map((x) => {
        if (x.id === payInvoiceId) {
          x.paid = new Date().toString();
          x.canPay = false;
        }
        return x;
      });
      setOrder(newOrder);
      toast.success(t("common.saveSuccess"));
    } catch (err) {
      toast.error(getErrorMessage(err, t("errors.saveError")));
    }
  };

  const loadData = useCallback(async () => {
    setLoading(true);
    try {
      const orderResponse = await orderApi.getOrder(id);
      const boxPriceResponse = await boxApi.getBox(
        orderResponse.data.box.id,
        id
      );
      setBoxPrice(boxPriceResponse.data.boxPriceDto);
      setOrder(orderResponse.data);
    } catch (err) {
      toast.error(getErrorMessage(err, t("errors.loadError")));
    }
    setLoading(false);
  }, [id, t]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <Page sidebar={admin ? <AdminSidebar /> : <UserSidebar />}>
      <PageSection>
        {loading ? (
          <Loader />
        ) : (
          order &&
          boxPrice && (
            <FadeInBottom>
              {admin && (
                <>
                  <EditOrderDocumentsModal
                    type={DocumentTemplateType.Order}
                    open={editDoc}
                    setOpen={setEditDoc}
                    pairedDocs={order.documents}
                    onSuccess={loadData}
                  />
                  <AdminInvoicePayModal
                    open={payOpen}
                    setOpen={setPayOpen}
                    confirm={handlePayConfirm}
                  />
                </>
              )}
              <OrderDetailCard
                order={order}
                boxPrice={boxPrice}
                reload={loadData}
              />
              <Tabs
                tabItems={[
                  {
                    tab: (
                      <InvoicesTable
                        data={order.invoices}
                        onPay={admin ? handlePay : undefined}
                      />
                    ),
                    tabLabel: t("orders.detail.tabs.invoices"),
                  },
                  {
                    tab: (
                      <DocumentsTable
                        data={order.documents}
                        hideOrderColumn={true}
                      />
                    ),
                    tabLabel: t("orders.detail.tabs.documents"),
                    tabAction: admin ? (
                      <Button $small onClick={() => setEditDoc(true)}>
                        {t("orders.detail.tabs.addDocument")}
                      </Button>
                    ) : undefined,
                  },
                  {
                    tab: (
                      <CardsTable
                        data={order.cards}
                        onSuccess={loadData}
                        admin={admin}
                      />
                    ),
                    tabLabel: t("orders.detail.tabs.cards"),
                  },
                ]}
              />
            </FadeInBottom>
          )
        )}
      </PageSection>
    </Page>
  );
};

export default OrderDetail;
