import styled from "styled-components";
import { COLORS } from "../../../../utils/constants";

export const CalendarDayContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% / 7);
  min-width: 8rem;
  margin: 0.25rem;
`;

export const CalendarDayLabel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  height: 4.5rem;
  padding: 0.25rem;
  margin-bottom: 0.5rem;
`;

export const CalendarDayNumber = styled.div<{ isSelected?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  width: 2.5rem;
  font-size: 1.6rem;
  border-radius: 50%;
  ${({ isSelected }) =>
    isSelected &&
    `background-color: ${COLORS.primary}; color: ${COLORS.white};`}
`;
