import styled, { css } from "styled-components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { InputContainer, textInput } from "../../../../styles/form";
import { COLORS } from "../../../../utils/constants";
import { rgba } from "polished";
import { size } from "../../../../utils/styles";

export const DatePickerWrapper = styled(InputContainer)`
  & .react-datepicker {
    border: 1px solid ${COLORS.secondary};
    border-radius: 0;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.05);
  }

  & .react-datepicker__triangle {
    border-bottom-color: ${COLORS.white} !important;

    &::before {
      border-bottom-color: ${COLORS.secondary} !important;
    }
  }

  & .react-datepicker__navigation--previous {
    border-right-color: ${COLORS.primary};
  }

  & .react-datepicker__navigation--next {
    border-left-color: ${COLORS.primary};
  }

  & .react-datepicker__header {
    background-color: ${COLORS.transparent};
    border-bottom: 1px solid ${COLORS.secondary};
  }

  & .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    color: ${COLORS.gray};
  }

  & .react-datepicker__day-name {
    font-weight: bold;
    color: ${COLORS.gray};
  }

  & .react-datepicker__day {
    border-radius: 0.3rem;
    color: ${COLORS.primaryDark};

    &:hover {
      border-radius: 50%;
      background-color: ${COLORS.secondary};
      transition: background-color 0.2s ease-in-out;
    }
  }

  & .react-datepicker__day--selected {
    border-radius: 0.3rem;
    background-color: ${COLORS.primary};
    color: ${COLORS.white};

    &:hover {
      border-radius: 0.3rem;
      background-color: ${rgba(COLORS.primary, 0.85)};
    }
  }

  & .react-datepicker__day--disabled {
    background-color: ${COLORS.secondary};
    color: ${COLORS.grayLight};
    outline: none;

    &:hover {
      border-radius: 0.3rem;
      background-color: ${COLORS.secondary};
    }
  }

  & .react-datepicker__day--keyboard-selected {
    border-radius: 0.3rem;
    background-color: ${rgba(COLORS.primary, 0.7)};
    color: ${COLORS.white};

    &:hover {
      border-radius: 0.3rem;
      background-color: ${rgba(COLORS.primary, 0.85)};
    }
  }

  & .react-datepicker__time-container {
    border-left: 1px solid ${COLORS.secondary};
  }

  & .react-datepicker__time-list-item {
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${COLORS.primaryDark};

    &:hover {
      background-color: ${COLORS.secondary};
      transition: background-color 0.2s ease-in-out;
    }
  }

  & .react-datepicker__time-list-item--selected {
    background-color: ${COLORS.primary} !important;

    &:hover {
      background-color: ${rgba(COLORS.primary, 0.85)} !important;
    }
  }
`;

export const StyledDatePicker = styled(DatePicker)<{
  $error?: boolean;
  width?: string;
}>`
  ${(props) => textInput(props)}

  @media screen and (min-width: ${size.mobile}) {
    padding-left: 2.75rem;
  }
`;

export const DatePickerLabel = styled.label<{ width?: string }>`
  ${({ width }) =>
    width &&
    css`
      display: inline-block;
      width: ${width};
    `}
`;

export const DatePickerIconWrapper = styled.div`
  display: none;
  position: absolute;
  padding: 0 1rem;
  top: 50%;
  transform: translateY(-50%);

  @media screen and (min-width: ${size.mobile}) {
    display: block;
  }
`;
