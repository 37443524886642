import styled from "styled-components";
import { size } from "../../../../utils/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";
import { COLORS } from "../../../../utils/constants";
import { Button } from "../../../../styles/button";

export const HeaderContainer = styled.header`
  height: var(--header-height);
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: var(--gray);

  a {
    text-decoration: none;
  }

  img {
    transition: all 0.15s ease-in-out;
  }

  .active {
    color: var(--primary);
    text-decoration: none;
  }

  > *:first-child {
    margin-left: 1.5rem;
  }
`;

export const HeaderLogo = styled.img`
  max-height: var(--header-height);
  width: auto;
  display: block;
  padding: 0.25rem 0;

  @media screen and (min-width: ${size.laptop}) {
    margin-left: 60px;
  }
`;

export const HeaderNav = styled.nav`
  height: auto;
  display: flex;
  justify-content: center;

  @media screen and (min-width: ${size.laptop}) {
    margin-right: 84px;
  }
`;

export const HeaderLinks = styled.ul<{ open: boolean }>`
  height: auto;
  flex-flow: row;
  align-items: center;
  padding-bottom: 1rem;

  position: absolute;
  top: var(--header-height);
  right: 0;
  z-index: 999;

  border-radius: 0 0 4px 4px;
  list-style: none;
  background-color: ${COLORS.gray};
  font-weight: 600;
  transition: all 0.25s ease-in-out;

  display: ${({ open }) => (open ? "block" : "none")};

  @media screen and (min-width: ${size.laptop}) {
    position: initial;
    transform: translateX(0);
    padding-bottom: 0;

    height: 100%;

    display: flex;
    flex-flow: row;

    background-color: transparent;

    li {
      height: 100%;
    }
  }
`;

export const HeaderLink = styled(NavLink)`
  height: 100%;
  width: 100%;
  padding: 0.75rem 1rem;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;

  text-transform: uppercase;
  color: ${COLORS.blueLight};
  font-size: 14px;
  transition: all 0.15s ease-in-out;

  &::after {
    display: block;
    content: "";
    height: 2px;
    background-color: ${COLORS.primary};
    position: absolute;
    left: 1em;
    bottom: 0;
    right: 1em;
    opacity: 0;
    transition: 0.3s;
    transform: scale(0, 1);
  }

  &:hover {
    color: ${COLORS.white} !important;

    &::after {
      opacity: 1;
      transform: scale(1, 1);
    }
  }

  @media screen and (min-width: ${size.laptop}) {
    padding: 8px 14px;
  }
`;

export const HeaderLinkButton = styled(Button)`
  display: block;
  margin: 14px;

  &:hover {
    background-color: ${COLORS.blue};
  }
`;

export const HeaderIcon = styled(FontAwesomeIcon)`
  margin-right: 0.5rem;
`;

export const HeaderIconButton = styled.button`
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;

  width: 2.5rem;
  height: auto;

  margin-right: 1.5rem;

  @media screen and (min-width: ${size.laptop}) {
    display: none;
  }
`;

export const HeaderSidebarButton = styled(HeaderIconButton)`
  margin-right: 0;
  width: auto;
  display: flex;
  align-items: center;
`;

export const HeaderLoginButton = styled(NavLink)`
  display: block;

  svg {
    display: block;
    margin: 0px auto;
    width: 26px;
    height: 26px;
    transition: 0.2s;
    fill: #20c1f1;
  }

  svg :hover {
    fill: #2560e6;
  }
`;
