import React, { FC } from "react";
import { H2 } from "../../../styles/title";
import { useTranslation } from "react-i18next";
import { Field } from "formik";
import { OrderRadioLabel } from "./styles";
import { PaymentMethod } from "../../../models/order";
import Radio from "../../common/form/Radio/Radio";
import { FormGroup } from "../../../styles/form";

const paymentMethods: PaymentMethod[] = ["Card", "Transfer", "Cash"];

const OrderPayment: FC<{ values: { paymentType: PaymentMethod } }> = ({
  values,
}) => {
  const { t } = useTranslation();

  return (
    <section>
      <H2 bold>{t("order.parameters.payment.title")}</H2>
      <FormGroup>
        {paymentMethods.map((pt) => (
          <Radio key={pt}>
            <Field
              type="radio"
              name="paymentType"
              value={pt}
              checked={values.paymentType === pt}
            />
            <OrderRadioLabel checked={values.paymentType === pt}>
              <span>{t(`order.parameters.payment.${pt}`)}</span>
            </OrderRadioLabel>
          </Radio>
        ))}
      </FormGroup>
    </section>
  );
};

export default OrderPayment;
