import { ChartData } from "chart.js";
import React, { FC, useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import boxApi from "../../../../api/box";
import { H4 } from "../../../../styles/title";
import { COLORS } from "../../../../utils/constants";
import { getErrorMessage } from "../../../../utils/errors";
import Loader from "../../../common/Loader/Loader";
import {
  CardBoxesOccupancyChart,
  CardBoxesOccupancyContent,
  CardBoxesOccupancyLabel,
  CardBoxesOccupancyLegend,
  CardBoxesOccupancyMain,
  CardBoxesOccupancySquare,
} from "./styles";

const options = {
  responsive: true,
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
};

const CardBoxesOccupancy: FC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const [chartData, setChartData] = useState<ChartData>({});

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const response = await boxApi.getAllBoxes();
        const allBoxesData = response.data;
        const labels = allBoxesData.map((box) => box.type);
        const reservedBoxes = allBoxesData.map((box) => box.usedReservedCount);
        const activeBoxes = allBoxesData.map((box) => box.usedActiveCount);
        const availableBoxes = allBoxesData.map((box) => box.availableCount);
        const data = {
          labels,
          datasets: [
            {
              label: t("adminDashboard.boxesOccupancy.reserved"),
              backgroundColor: COLORS.orange,
              data: reservedBoxes,
            },
            {
              label: t("adminDashboard.boxesOccupancy.active"),
              backgroundColor: COLORS.green,
              data: activeBoxes,
            },
            {
              label: t("adminDashboard.boxesOccupancy.available"),
              backgroundColor: COLORS.primary,
              data: availableBoxes,
            },
          ],
        };

        setChartData(data);
      } catch (err) {
        toast.error(getErrorMessage(err, t("errors.loadError")));
      }
      setLoading(false);
    })();
  }, [t]);

  return (
    <CardBoxesOccupancyMain>
      <H4>{t("adminDashboard.boxesOccupancy.title")}</H4>
      <CardBoxesOccupancyContent>
        <CardBoxesOccupancyLegend>
          <CardBoxesOccupancyLabel>
            <CardBoxesOccupancySquare color={COLORS.orange} />
            <div>{t("adminDashboard.boxesOccupancy.reserved")}</div>
          </CardBoxesOccupancyLabel>
          <CardBoxesOccupancyLabel>
            <CardBoxesOccupancySquare color={COLORS.green} />
            <div>{t("adminDashboard.boxesOccupancy.active")}</div>
          </CardBoxesOccupancyLabel>
          <CardBoxesOccupancyLabel>
            <CardBoxesOccupancySquare color={COLORS.primary} />
            <div>{t("adminDashboard.boxesOccupancy.available")}</div>
          </CardBoxesOccupancyLabel>
        </CardBoxesOccupancyLegend>
        <CardBoxesOccupancyChart>
          {loading ? (
            <Loader />
          ) : (
            <Bar data={chartData} width={100} height={100} options={options} />
          )}
        </CardBoxesOccupancyChart>
      </CardBoxesOccupancyContent>
    </CardBoxesOccupancyMain>
  );
};

export default CardBoxesOccupancy;
