import styled from "styled-components";

export const CircleStateContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const CircleStateIconCircle = styled.div<{
  backgroundColor: string;
}>`
  width: 1.5rem;
  height: 1.5rem;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 50%;

  svg {
    font-size: 0.8rem;
  }
`;

export const CircleStateLabel = styled.div<{ color?: string }>`
  margin-left: 0.5rem;
  color: ${({ color }) => color || "initial"};
`;
