import styled from "styled-components";
import { H1 } from "../../../styles/title";
import { size } from "../../../utils/styles";
import { COLORS } from "../../../utils/constants";

export const NotFoundContainer = styled.div`
  display: flex;
  flex-flow: column;

  ${H1} {
    font-size: 2.5rem;
  }

  a {
    display: inline-block;
    margin-top: 1rem;
  }

  > div {
    display: flex;
    justify-content: center;
    flex-flow: column;

    &:first-child {
      font-size: 8rem;
    }

    &:last-child {
      > span:first-of-type {
        margin-bottom: 0.25rem;
      }
    }
  }

  @media screen and (min-width: ${size.tablet}) {
    flex-flow: row;

    ${H1} {
      width: max-content;
    }

    > div:first-child {
      padding-right: 2rem;
      margin-right: 2rem;
      border-right: 2px solid ${COLORS.whitesmoke};
    }
  }
`;
