import {
  faCalendarAlt,
  faCheck,
  faExclamation,
  faPause,
  faStar,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import {
  CircleStateContainer,
  CircleStateIconCircle,
  CircleStateLabel,
} from "../../../../styles/state";
import { COLORS } from "../../../../utils/constants";
import { OrderState } from "../../../../utils/enums";

const getStateStyle = (state: OrderState | "Expiring") => {
  switch (state) {
    case OrderState.New:
      return { color: COLORS.primary, icon: faStar };
    case OrderState.Scheduled:
      return { color: COLORS.primary, icon: faCalendarAlt };
    case OrderState.Ok:
      return { color: COLORS.green, icon: faCheck };
    case OrderState.Expired:
      return { color: COLORS.secondaryDark, icon: faTimes };
    case OrderState.Paused:
      return { color: COLORS.red, icon: faPause };
    case "Expiring":
      return { color: COLORS.orange, icon: faExclamation };
  }
};

const OrderStateLabel: FC<{
  state: OrderState | "Expiring";
  noLabel?: boolean;
}> = ({ state, noLabel }) => {
  const { t } = useTranslation();

  const stateStyle = getStateStyle(state);

  return (
    <CircleStateContainer>
      <CircleStateIconCircle backgroundColor={stateStyle?.color}>
        <FontAwesomeIcon
          icon={stateStyle?.icon}
          color={COLORS.white}
          size="sm"
        />
      </CircleStateIconCircle>
      {!noLabel && (
        <CircleStateLabel color={stateStyle?.color}>
          {t(`orders.table.state.${state}`)}
        </CircleStateLabel>
      )}
    </CircleStateContainer>
  );
};

export default OrderStateLabel;
