import { Formik } from "formik";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import cardApi from "../../../../../api/card";
import { CardCreateData } from "../../../../../models/card";
import { getErrorMessage } from "../../../../../utils/errors";
import { SetState } from "../../../../../utils/types";
import AddCardModalContent from "./AddCardModalContent";

const initialValues: CardCreateData = {
  cardNumber: "000000",
  rfid: "",
};

const AddCardModal: FC<{
  open: boolean;
  setOpen: SetState<boolean>;
  reload: () => void;
}> = ({ open, setOpen, reload }) => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();

  const handleSubmit = async (data: CardCreateData) => {
    try {
      await cardApi.createCard(id, data);
      toast.success(t("adminClientsDetail.addCardModal.success"));
      setOpen(false);
      reload();
    } catch (err) {
      toast.error(getErrorMessage(err, t("errors.saveError")));
    }
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      validateOnBlur={false}
      initialValues={initialValues}
      validationSchema={Yup.object({
        cardNumber: Yup.string().test(
          "isCardNumberValid",
          t("adminClientsDetail.addCardModal.cardNumberNotValid"),
          (value: any) =>
            value &&
            value.length === 6 &&
            /^\d*$/.test(value) &&
            Number(value) !== 0
        ),
        rfid: Yup.string().required(t("errors.required")),
      })}
    >
      <AddCardModalContent open={open} setOpen={setOpen} />
    </Formik>
  );
};

export default AddCardModal;
