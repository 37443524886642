import React, { FC } from "react";
import PageNoSidebar from "../../common/layout/Page/PageNoSidebar";
import {
  LoginButton,
  LoginCard,
  LoginCardActions,
  LoginContainer,
} from "../login/styles";
import { H1 } from "../../../styles/title";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { FormGroup } from "../../../styles/form";
import Input from "../../common/form/Input/Input";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ForgottenPasswordData } from "../../../models/auth";
import authApi from "../../../api/auth";
import { toast } from "react-toastify";
import { ForgottenPasswordBox } from "./styles";
import { useHistory } from "react-router";

const initialValues: ForgottenPasswordData = {
  email: "",
};

const ForgottenPassword: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleSubmit = async (data: ForgottenPasswordData) => {
    try {
      await authApi.sendPasswordResetLink(data);
      toast.success(t("forgottenPassword.success"));
      history.push("/login");
    } catch (err) {
      toast.error(t("errors.sendResetPasswordLink"));
    }
  };

  return (
    <PageNoSidebar>
      <LoginContainer>
        <H1 bold uppercase>
          {t("forgottenPassword.title")}
        </H1>
        <span>{t("forgottenPassword.subtitle")}</span>
        <LoginCard>
          <ForgottenPasswordBox>
            {t("forgottenPassword.info")}
          </ForgottenPasswordBox>
          <Formik
            initialValues={initialValues}
            validationSchema={Yup.object({
              email: Yup.string().required(t("errors.required")),
            })}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, isSubmitting }) => (
              <Form>
                <FormGroup>
                  <Input
                    name="email"
                    label={t("forgottenPassword.email")}
                    error={touched.email && !!errors.email}
                  />
                </FormGroup>
                <LoginButton type="submit" disabled={isSubmitting}>
                  {t("common.send")}
                </LoginButton>
              </Form>
            )}
          </Formik>
          <LoginCardActions>
            <Link to="/login">{t("forgottenPassword.backToLogin")}</Link>
          </LoginCardActions>
        </LoginCard>
      </LoginContainer>
    </PageNoSidebar>
  );
};

export default ForgottenPassword;
