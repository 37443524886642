import { Formik } from "formik";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import documentApi from "../../../api/document";
import { DocumentType, DocumentTypeEditData } from "../../../models/document";
import { DocumentTemplateType } from "../../../utils/enums";
import { getErrorMessage } from "../../../utils/errors";
import { SetState } from "../../../utils/types";
import Loader from "../../common/Loader/Loader";
import DocumentTypeEditModalContent from "./DocumentTypeEditModalContent";

const DocumentTypeEditModal: FC<{
  documentTypeId: string;
  open: boolean;
  setOpen: SetState<boolean>;
  onSuccess: () => void;
}> = ({ documentTypeId, open, setOpen, onSuccess }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<DocumentType>({
    id: documentTypeId,
    name: "",
    created: "",
    type: DocumentTemplateType.Order,
    mustSign: false,
  });

  const loadData = useCallback(async () => {
    if (!open) {
      return;
    }

    setLoading(true);
    try {
      const response = await documentApi.getDocumentType(documentTypeId);
      setData(response.data);
    } catch (err) {
      toast.error(getErrorMessage(err, t("errors.loadError")));
    }
    setLoading(false);
  }, [t, documentTypeId, open]);

  useEffect(() => {
    loadData();
  }, [loadData, documentTypeId, open]);

  const handleSubmit = async (data: DocumentTypeEditData) => {
    try {
      await documentApi.updateDocumentType(documentTypeId, data);
      toast.success(t("common.saveSuccess"));
      setOpen(false);
      onSuccess();
    } catch (err) {
      toast.error(getErrorMessage(err, t("errors.saveError")));
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Formik
      initialValues={{
        name: data.name,
        type: data.type,
        mustSign: data.mustSign,
      }}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      <DocumentTypeEditModalContent open={open} setOpen={setOpen} />
    </Formik>
  );
};

export default DocumentTypeEditModal;
