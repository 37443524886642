import React, { FC } from "react";
import Page from "../../common/layout/Page/Page";
import CustomerCalendar from "./CustomerCalendar/CustomerCalendar";
import OrderInvoiceSidebar from "../../order/OrderSidebar/OrderInvoiceSidebar/OrderInvoiceSidebar";
import storageApi from "../../../api/storage";
import { Redirect } from "react-router-dom";

const Reservation: FC = () => {
  const token = storageApi.getToken();

  if (!token) return <Redirect to="/login" />;

  return (
    <Page sidebar={<OrderInvoiceSidebar />}>
      <CustomerCalendar signContract />
    </Page>
  );
};

export default Reservation;
