import { isSameDay } from "date-fns";
import React, { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import {
  CalendarDayContainer,
  CalendarDayLabel,
  CalendarDayNumber,
} from "./styles";

const CalendarDay: FC<{ date: Date; cells: ReactElement[] }> = ({
  date,
  cells,
}) => {
  const { t } = useTranslation();

  return (
    <CalendarDayContainer>
      <CalendarDayLabel>
        <div>{t(`common.day.${date.getDay()}`)}</div>
        {/* possible wrong highlight (day by client) */}
        <CalendarDayNumber isSelected={isSameDay(date, new Date())}>
          {date.getDate()}
        </CalendarDayNumber>
      </CalendarDayLabel>
      {cells}
    </CalendarDayContainer>
  );
};

export default CalendarDay;
