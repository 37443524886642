import React, { FC } from "react";
import Page from "../../common/layout/Page/Page";
import AdminSidebar from "../AdminSidebar/AdminSidebar";
import AdminPromoCodesDetailContent from "./AdminPromoCodesDetailContent/AdminPromoCodesDetailContent";

const AdminPromoCodesDetail: FC = () => (
  <Page sidebar={<AdminSidebar />}>
    <AdminPromoCodesDetailContent />
  </Page>
);

export default AdminPromoCodesDetail;
