import React, { FC, useState } from "react";
import CustomModal from "../../../common/CustomModal/CustomModal";
import { SetState } from "../../../../utils/types";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../styles/button";

const AdminClientsDetailImagesModal: FC<{
  open: boolean;
  setOpen: SetState<boolean>;
  confirm: () => Promise<void>;
}> = ({ open, setOpen, confirm }) => {
  const { t } = useTranslation();
  const [saving, setSaving] = useState(false);

  const handleConfirm = async () => {
    setSaving(true);
    await confirm();
    setSaving(false);
    setOpen(false);
  };

  return (
    <CustomModal
      title={t("adminClientsDetail.addIdPicture.deleteTitle")}
      open={open}
      setOpen={setOpen}
      actions={
        <>
          <Button
            ver="secondary"
            disabled={saving}
            onClick={() => setOpen(false)}
          >
            {t("common.no")}
          </Button>
          <Button disabled={saving} onClick={handleConfirm}>
            {t("common.yes")}
          </Button>
        </>
      }
    >
      {t("adminClientsDetail.addIdPicture.deleteText")}
    </CustomModal>
  );
};

export default AdminClientsDetailImagesModal;
