import React, { FC, useEffect, useState } from "react";
import { format } from "date-fns";
import { BoxType } from "../../models/box";
import {
  getBoxPriceWithVat,
  getLeaseMonths,
  getTotalPriceWithVat,
} from "./priceUtils";
import { OrderParametersData } from "../../models/order";

export const ecommerceGenerateId = () =>
  format(new Date(), "yyyy_MM_dd_HH_mm_ss_SSS") +
  "_" +
  getRandomArbitrary(100000, 999999);

function getRandomArbitrary(min: number, max: number) {
  return Math.floor(Math.random() * (max - min) + min);
}

const Ecommerce: FC<{
  values: OrderParametersData;
  boxType: BoxType | null;
  additionalServices: number[];
}> = ({ values, boxType, additionalServices }) => {
  const [id, setId] = useState<string>();

  useEffect(() => {
    const id = ecommerceGenerateId();
    setId(id);
  }, []);

  useEffect(() => {
    try {
      if (!id) {
        return;
      }

      if (!boxType) {
        return;
      }

      const months = getLeaseMonths(values.lengthOfLeaseType);
      const totalValue = getTotalPriceWithVat(
        values,
        boxType,
        additionalServices
      );
      const boxPrice = getBoxPriceWithVat(
        values.lengthOfLeaseType,
        boxType.boxPriceDto
      );

      const products = [];

      products.push({
        name: boxType.type,
        sku: boxType.type,
        price: boxPrice,
        quantity: 1,
      });

      products.push({
        name: "guarantee",
        sku: "guarantee",
        price: boxType.boxPriceDto.guaranteeWithVat,
        quantity: 1,
      });

      if (values.fingerPrint) {
        products.push({
          name: "fingerPrint",
          sku: "fingerPrint",
          price: boxType.boxPriceDto.fingerPrintWithVat * months,
          quantity: 1,
        });
      }

      if (values.smsCheck) {
        products.push({
          name: "smsCheck",
          sku: "smsCheck",
          price: boxType.boxPriceDto.smsCheckWithVat * months,
          quantity: 1,
        });
      }

      for (let i = 0; i < additionalServices.length; i++) {
        const serviceId = additionalServices[i];
        const service = boxType.boxPriceDto.additionalServices.find(
          (x) => x.id === serviceId
        )!;

        products.push({
          name: service.name,
          sku: service.id,
          price: service.priceWithVat * months,
          quantity: 1,
        });
      }

      // @ts-ignore
      dataLayer.push({
        transactionId: id,
        transactionTotal: totalValue,
        transactionProducts: products,
      });
    } catch (err) {}
  }, [values, id, boxType, additionalServices]);

  return <></>;
};

export default Ecommerce;
