import React, { FC } from "react";
import { SidebarContent } from "../../../../styles/sidebar";
import {
  faCheckCircle,
  faPauseCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { H1, H2, H4 } from "../../../../styles/title";
import { useTranslation } from "react-i18next";
import {
  InvoiceSidebarContainer,
  InvoiceSidebarLink,
  InvoiceSidebarPrice,
  InvoiceSidebarState,
  InvoiceSidebarTitle,
  NumberSymbol,
} from "./styles";
import { InvoiceState, InvoiceStateResponse } from "../../../../models/order";
import { COLORS } from "../../../../utils/constants";
import { PrimaryText } from "../../../../styles/text";
import { formatCurrency } from "../../../../utils/formats";
import { useReservationStore } from "../../../../utils/store";

const OrderInvoiceSidebar: FC<{
  invoice?: InvoiceStateResponse;
  loading?: boolean;
}> = ({ invoice, loading }) => {
  const { t } = useTranslation();
  const reserved = useReservationStore((store) => store.reserved);

  const invoiceStateMessage = () => {
    return invoice?.state === InvoiceState.NotPaidYet
      ? "orderNotPaidYet"
      : invoice?.state === InvoiceState.Continue
      ? "orderContinue"
      : "orderSent";
  };

  const isNotPaidYet = invoice?.state === InvoiceState.NotPaidYet;

  return (
    <SidebarContent>
      {reserved ? (
        <div />
      ) : (
        <InvoiceSidebarContainer>
          {!loading && (
            <>
              <InvoiceSidebarTitle>
                {invoice && (
                  <>
                    <PrimaryText>{t("common.order")}</PrimaryText>
                    <H1>
                      <NumberSymbol>#</NumberSymbol>
                      <InvoiceSidebarLink
                        href={invoice.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {invoice.invoiceNumber}
                      </InvoiceSidebarLink>
                    </H1>
                  </>
                )}
              </InvoiceSidebarTitle>
              <InvoiceSidebarState>
                <FontAwesomeIcon
                  icon={isNotPaidYet ? faPauseCircle : faCheckCircle}
                  color={isNotPaidYet ? COLORS.primaryDark : COLORS.green}
                  size="10x"
                />
                <H2
                  uppercase
                  color={isNotPaidYet ? COLORS.primaryDark : COLORS.green}
                >
                  {t(`reservation.sidebar.${invoiceStateMessage()}`)}
                </H2>
                {invoice ? (
                  !isNotPaidYet && (
                    <H4>{t("reservation.sidebar.makeReservation")}</H4>
                  )
                ) : (
                  <H4>{t("reservation.sidebar.makePayAndReservation")}</H4>
                )}
              </InvoiceSidebarState>
              <InvoiceSidebarPrice>
                {invoice && (
                  <>
                    <PrimaryText>{t("order.summary.priceWithVat")}</PrimaryText>
                    <b>{formatCurrency(invoice.priceInclVat)}</b>
                  </>
                )}
              </InvoiceSidebarPrice>
            </>
          )}
        </InvoiceSidebarContainer>
      )}
    </SidebarContent>
  );
};

export default OrderInvoiceSidebar;
