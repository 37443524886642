import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import documentApi from "../../../api/document";
import { DocumentType } from "../../../models/document";
import { Button } from "../../../styles/button";
import { FadeInBottom } from "../../../styles/fadeIn";
import { PageHeader } from "../../../styles/layout";
import { H2 } from "../../../styles/title";
import { useUserHasRight } from "../../../utils/auth";
import { getErrorMessage } from "../../../utils/errors";
import Page from "../../common/layout/Page/Page";
import Loader from "../../common/Loader/Loader";
import AdminSidebar from "../AdminSidebar/AdminSidebar";
import DocumentTypeEditModal from "./DocumentTypeEditModal";
import DocumentTypesTable from "./DocumentTypesTable";

const AdminDocumentsSettings: FC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [uploading, setUploading] = useState(false);
  const [data, setData] = useState<DocumentType[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);
  const [editDocumentId, setEditDocumentId] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const { hasUserAllRights } = useUserHasRight();

  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleUpload = async (files: FileList | null) => {
    if (!files) return;

    const file = files[0];
    if (file.type !== "application/pdf") {
      toast.error(t("errors.fileType"));
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async function () {
      if (typeof reader.result === "string") {
        setUploading(true);
        try {
          const response = await documentApi.uploadDocumentType({
            name: file.name,
            contentBase64: reader.result.split(",")[1],
          });
          toast.success(t("common.saveSuccess"));
          await loadData();
          setEditDocumentId(response.data);
          setModalOpen(true);
        } catch (err) {
          toast.error(getErrorMessage(err, t("errors.saveError")));
        }
        setUploading(false);
      } else {
        toast.error(t("errors.upload"));
      }
    };
    reader.onerror = function () {
      toast.error(t("errors.upload"));
    };
  };

  const loadData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await documentApi.getAllDocumentTypes();
      setData(response.data);
    } catch (err) {
      toast.error(getErrorMessage(err, t("errors.loadError")));
    }
    setLoading(false);
  }, [t]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <Page sidebar={<AdminSidebar />}>
      {loading ? (
        <Loader />
      ) : (
        <FadeInBottom>
          <DocumentTypeEditModal
            documentTypeId={editDocumentId}
            open={modalOpen}
            setOpen={setModalOpen}
            onSuccess={loadData}
          />
          <PageHeader>
            <H2>{t("adminDocumentsSettings.title")}</H2>
            {hasUserAllRights(["createDocumentTemplates"]) && (
              <Button onClick={handleClick} disabled={uploading}>
                {t("adminDocumentsSettings.upload")}
              </Button>
            )}
            <input
              ref={inputRef}
              type="file"
              hidden
              onChange={({ target }) => handleUpload(target.files)}
            />
          </PageHeader>
          <DocumentTypesTable data={data} onSuccess={loadData} />
        </FadeInBottom>
      )}
    </Page>
  );
};

export default AdminDocumentsSettings;
