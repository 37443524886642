import styled from "styled-components";
import { COLORS } from "../../../../utils/constants";
import { size } from "../../../../utils/styles";

export const AdminBoxesSettingsTrafficStatsMain = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  font-size: 0.8rem;

  @media screen and (min-width: ${size.mobile}) {
    font-size: 1rem;
  }

  @media screen and (min-width: ${size.desktopL}) {
    margin-left: 1.5rem;
    border-left: 1px solid ${COLORS.whitesmoke};
    grid-column: initial;
  }
`;

export const AdminBoxesSettingsTrafficStatsGrid = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto;
  row-gap: 0.75rem;
  column-gap: 0.75rem;
  align-items: center;
  width: 100%;

  @media screen and (min-width: ${size.mobile}) {
    grid-template-columns: auto 1fr 3fr;
  }

  @media screen and (min-width: ${size.desktopL}) {
    row-gap: 1rem;
  }
`;

export const AdminBoxesSettingsTrafficStatsLabel = styled.div`
  grid-column: 1 / span 2;

  @media screen and (min-width: ${size.mobile}) {
    grid-column: initial;
  }
`;

export const AdminBoxesSettingsTrafficStatsNumber = styled.div`
  font-weight: 700;
  text-align: right;
`;

export const AdminBoxesSettingsTrafficStatsGraph = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;

export const AdminBoxesSettingsTrafficStatsBar = styled.div<{
  color: string;
  percentage: number;
}>`
  height: 1.2rem;
  background-color: ${({ color }) => color};
  width: ${({ percentage }) => percentage}%;
`;
