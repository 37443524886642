import React, { FC } from "react";
import Page from "../../common/layout/Page/Page";
import AdminSidebar from "../AdminSidebar/AdminSidebar";
import AdminBoxesSettingsContent from "./AdminBoxesSettingsContent/AdminBoxesSettingsContent";

const AdminBoxesSettings: FC = () => (
  <Page sidebar={<AdminSidebar />}>
    <AdminBoxesSettingsContent />
  </Page>
);

export default AdminBoxesSettings;
