import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { PromoCodeList } from "../../../../models/promoCode";
import { NoTableData } from "../../../../styles/table";
import { formatDateTime } from "../../../../utils/formats";
import { OverflowX } from "../../../../utils/styles";
import CircleStateIsActive from "../../../common/CircleStateIsActive/CircleStateIsActive";
import { AdminPromoCodesTableContainer } from "./styles";

const cols = [
  "adminPromoCodes.table.state",
  "adminPromoCodes.table.code",
  "adminPromoCodes.table.discount",
  "adminPromoCodes.table.description",
  "adminPromoCodes.table.validUntil",
];

const AdminPromoCodesTable: FC<{
  data: PromoCodeList[];
  canWrite: boolean;
}> = ({ data, canWrite }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const tableCols = cols.map((col) => <th key={col}>{t(col)}</th>);

  const tableRows = useMemo(
    () =>
      data.map((promoCode) => (
        <tr
          key={promoCode.id}
          onClick={
            canWrite
              ? () => history.push(`/admin/settings/promo-code/${promoCode.id}`)
              : undefined
          }
        >
          <td>
            <CircleStateIsActive
              isActive={
                promoCode.validUntil == null ||
                new Date(promoCode.validUntil) >= new Date()
              }
            />
          </td>
          <td>
            <div>{promoCode.code}</div>
          </td>
          <td>
            <div>{promoCode.discount}%</div>
          </td>
          <td>
            <div>{promoCode.description}</div>
          </td>
          <td>
            <div>{formatDateTime(promoCode.validUntil)}</div>
          </td>
        </tr>
      )),
    [canWrite, data, history]
  );

  return (
    <OverflowX>
      {data.length ? (
        <AdminPromoCodesTableContainer>
          <thead>
            <tr>{tableCols}</tr>
          </thead>
          <tbody>{tableRows}</tbody>
        </AdminPromoCodesTableContainer>
      ) : (
        <NoTableData>{t("adminPromoCodes.noData")}</NoTableData>
      )}
    </OverflowX>
  );
};

export default AdminPromoCodesTable;
