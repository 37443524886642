import styled from "styled-components";
import { FormGroup } from "../../../../styles/form";
import { size } from "../../../../utils/styles";

export const AdminCalendarModalFormGroup = styled(FormGroup)``;

export const AdminCalendarModalInfoText = styled.div`
  font-size: 0.9rem;
`;

export const AdminCalendarModalDays = styled.div`
  display: flex;
  flex-wrap: wrap;

  > * {
    margin: 0.5rem;
  }
`;

export const CalendarModalTimesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1rem;
  grid-row-gap: 0.5rem;
  align-items: center;

  @media screen and (min-width: ${size.mobile}) {
    grid-template-columns: 6rem 1fr;
  }
`;

export const CalendarModalTimes = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;

  > div {
    display: flex;
    align-items: center;
  }
`;

export const CalendarModalTimesLabel = styled.div`
  margin-right: 0.5rem;
`;
