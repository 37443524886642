import { format } from "date-fns";
import { Form, useFormikContext } from "formik";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { BoxPrice } from "../../../../../models/box";
import { priceWithoutVat } from "../../../../../utils/formats";
import DatePicker from "../../../../common/form/DatePicker/DatePicker";
import Input from "../../../../common/form/Input/Input";
import {
  AdminBoxesSettingsDetailModalFormContent,
  AdminBoxesSettingsDetailModalFormHeader,
  AdminBoxesSettingsDetailModalFormRow,
  AdminBoxesSettingsDetailModalFormSelectors,
} from "./styles";

export const priceLevelFields: Array<keyof BoxPrice> = [
  "price1WithVat",
  "price1WithoutVat",
  "price2WithVat",
  "price2WithoutVat",
  "price3WithVat",
  "price3WithoutVat",
  "price4WithVat",
  "price4WithoutVat",
  "fingerPrintWithVat",
  "fingerPrintWithoutVat",
  "smsCheckWithVat",
  "smsCheckWithoutVat",
  "guaranteeWithVat",
  "guaranteeWithoutVat",
];

const AdminBoxesSettingsDetailModalForm: FC<{
  currentDate: Date;
  canEditActiveFrom: boolean;
}> = ({ currentDate, canEditActiveFrom }) => {
  const { t } = useTranslation();
  const {
    errors,
    touched,
    values,
    setFieldValue,
  } = useFormikContext<BoxPrice>();

  const formRows = () => {
    let rows = [];
    if (priceLevelFields.length % 2 !== 0) {
      //console.warn(`Incorrect count of fields.`);
      //return [];
    }

    for (let i = 0; i < priceLevelFields.length; i += 2) {
      rows.push(
        <AdminBoxesSettingsDetailModalFormRow key={priceLevelFields[i]}>
          <div>{t(`adminBoxesSettingsDetail.modal.fields.${i / 2}`)}</div>
          <div>
            <Input
              name={priceLevelFields[i]}
              type="number"
              mark={t("adminBoxesSettingsDetail.modal.currencyLabel")}
              markPosition={"right"}
              error={
                touched[priceLevelFields[i]] && !!errors[priceLevelFields[i]]
              }
              onInputChange={(e) =>
                setFieldValue(
                  priceLevelFields[i + 1],
                  priceWithoutVat(parseInt(e.target.value) || 0).toFixed(2)
                )
              }
            />
          </div>
          <div>
            {priceLevelFields[i + 1] !== "guaranteeWithoutVat" && (
              <Input
                name={priceLevelFields[i + 1]}
                type="number"
                mark={t("adminBoxesSettingsDetail.modal.currencyLabel")}
                markPosition={"right"}
                disabled={true}
              />
            )}
          </div>
        </AdminBoxesSettingsDetailModalFormRow>
      );
    }

    for (let i = 0; i < values.additionalServices.length; i++) {
      const additionalService = values.additionalServices[i];
      rows.push(
        <AdminBoxesSettingsDetailModalFormRow key={"additionalService" + i}>
          <div>{additionalService.name}</div>
          <div>
            <Input
              name={`additionalServices.${i}.priceWithVat`}
              type="number"
              mark={t("adminBoxesSettingsDetail.modal.currencyLabel")}
              markPosition={"right"}
              error={
                touched["additionalServices"]?.[i]?.priceWithVat &&
                !!errors["additionalServices"]?.[i]
              }
              onInputChange={(e) =>
                setFieldValue(
                  `additionalServices.${i}.priceWithoutVat`,
                  priceWithoutVat(parseInt(e.target.value) || 0).toFixed(2)
                )
              }
            />
          </div>
          <div>
            <Input
              name={`additionalServices.${i}.priceWithoutVat`}
              type="number"
              mark={t("adminBoxesSettingsDetail.modal.currencyLabel")}
              markPosition={"right"}
              disabled={true}
            />
          </div>
        </AdminBoxesSettingsDetailModalFormRow>
      );
    }

    return rows;
  };

  return (
    <Form>
      <AdminBoxesSettingsDetailModalFormContent>
        <AdminBoxesSettingsDetailModalFormSelectors>
          <DatePicker
            name={"activeFrom"}
            label={t("adminBoxesSettingsDetail.modal.activeFrom")}
            labelWidth={"8rem"}
            inputWidth={"9rem"}
            popperProps={{ placement: "top-start", position: "absolute" }}
            selected={new Date(values.activeFrom)}
            minDate={currentDate}
            error={!!errors["activeFrom"]}
            disabled={!canEditActiveFrom}
            onChange={(date) =>
              setFieldValue(
                "activeFrom",
                format((date as Date) || currentDate, "yyyy-MM-dd")
              )
            }
          />
        </AdminBoxesSettingsDetailModalFormSelectors>
        <AdminBoxesSettingsDetailModalFormHeader>
          <div>&nbsp;</div>
          <div>{t("adminBoxesSettingsDetail.modal.priceWithVat")}</div>
          <div>{t("adminBoxesSettingsDetail.modal.priceWithoutVat")}</div>
        </AdminBoxesSettingsDetailModalFormHeader>
        {formRows()}
      </AdminBoxesSettingsDetailModalFormContent>
    </Form>
  );
};
export default AdminBoxesSettingsDetailModalForm;
