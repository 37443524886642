import { format } from "date-fns";
import { DATE_FORMAT, VAT } from "./constants";

export const formatDate = (date?: string | null): string => {
  if (!date) return "-";
  const dt = new Date(date);
  return format(dt, DATE_FORMAT.date);
};

export const formatTime = (date?: string | null): string => {
  if (!date) return "-";
  const dt = new Date(date);
  return format(dt, DATE_FORMAT.time);
};

export const formatDateTime = (date?: string | Date | null): string => {
  if (!date) return "-";
  const dt = new Date(date);
  return format(dt, DATE_FORMAT.dateTime);
};

export const spaceToNbsp = (text: string): string =>
  text.replaceAll(" ", "\u00a0");

export const priceWithoutVat = (priceWithVat: number) =>
  priceWithVat / ((VAT + 100) / 100);

export const formatCurrency = (amount?: number | string): string => {
  if (amount === undefined) return "-";
  try {
    const num = typeof amount === "number" ? amount : parseInt(amount);
    return num
      ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " Kč"
      : "-";
  } catch (err) {
    return "";
  }
};
