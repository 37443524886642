import { createGlobalStyle } from "styled-components";
import { COLORS } from "../../utils/constants";

const GlobalStyles = createGlobalStyle`
  :root {
    font-size: 16px;
    
    // Colors
    --primary: ${COLORS.primary};
    --primary-dark: ${COLORS.primaryDark};
    
    --secondary: ${COLORS.secondary};
    --secondary-dark: ${COLORS.secondaryDark};
    
    --gray: ${COLORS.gray};
    --gray-dark: ${COLORS.grayDark};
    --gray-light: ${COLORS.grayLight}; 
    
    --whitesmoke: ${COLORS.whitesmoke};
    --green: ${COLORS.green};
    --orange: ${COLORS.orange};
    --red: ${COLORS.red};
    --white: ${COLORS.white};
    --black: ${COLORS.black}; 
    --transparent: ${COLORS.transparent};
    
    // Sizes
    --header-height: 82px;
    --sidebar-width: 22rem;
    --sidebar-width-wide: 28rem;
    
    // Other
    --font-color: ${COLORS.primaryDark};
  }

  *, *::after, *::before {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: "Montserrat", sans-serif;
  }
  
  body {
    height: 100vh;
    color: var(--font-color);
    background-color: ${COLORS.white};
    font-size: 1rem;
    font-family: "Montserrat", sans-serif;
    line-height: 1.2;
  }
  
  h1, h2, h3, h4, h5 {
    color: ${COLORS.gray};
  }
  
  a {
    color: ${COLORS.primary};
    text-decoration: underline;
    
    &:hover {
      color: var(--font-color);
      cursor: pointer;
    }
  }
  
  button {
    -webkit-tap-highlight-color: ${COLORS.transparent};
  }
  
  // modal
  .react-responsive-modal-modal {
    padding: 0;
  }
  
  // toast 
  .Toastify__toast--default {
    background-color: ${COLORS.primary};
  }
  .Toastify__toast--info {
    background-color: ${COLORS.primary};
  }
  .Toastify__toast--success {
    background-color: ${COLORS.green};
  }
  .Toastify__toast--warning {
    background-color: ${COLORS.orange};
  }
  .Toastify__toast--error {
    background-color: ${COLORS.red};
  }
`;

export default GlobalStyles;
