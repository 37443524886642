import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { BoxTypeLabel } from "../../../common/BoxTypeLabel/styles";
import { OrderCards } from "../../../../styles/orders";
import { Order } from "../../../../models/order";
import { COLORS } from "../../../../utils/constants";
import { LinkNoStyle } from "../../../../styles/text";

const OrderBoxData: FC<{ order: Order; admin?: boolean }> = ({
  order,
  admin,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <td>
        <div>
          <BoxTypeLabel>{order.box.type}</BoxTypeLabel>
        </div>
      </td>
      <td>
        <div>
          <b>
            {order.belaBoxNo
              ? `#${order.belaBoxNo}`
              : `${t("common.box")} ${order.box.type}`}
          </b>
        </div>
      </td>
      <td>
        <div>
          <LinkNoStyle
            to={admin ? `/admin/orders/${order.id}` : `/orders/${order.id}`}
          >
            {order.name}
          </LinkNoStyle>
        </div>
      </td>
      <td>
        <div>
          <OrderCards>
            <FontAwesomeIcon icon={faCreditCard} color={COLORS.primaryDark} />
            {order.cards.length}
          </OrderCards>
        </div>
      </td>
    </>
  );
};

export default OrderBoxData;
