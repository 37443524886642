import React, { FC, useEffect } from "react";
import OrderBoxType from "./OrderBoxType";
import { BoxTypeCards } from "./styles";
import { H2 } from "../../../styles/title";
import { useTranslation } from "react-i18next";
import { BoxType } from "../../../models/box";
import { useParams } from "react-router-dom";
import { OrderGroup, OrderGroupNumber } from "../styles";

const OrderBoxTypes: FC<{
  boxTypes: BoxType[];
  selectedBoxId: number | null;
  selectBoxType: (boxType: BoxType | null) => void;
}> = ({ boxTypes, selectedBoxId, selectBoxType }) => {
  const { t } = useTranslation();
  const { box } = useParams<{ box: string }>();

  useEffect(() => {
    if (selectedBoxId === null && box) {
      var paramsSelected = boxTypes.find((x) => x.type === box.toUpperCase());
      if (paramsSelected) {
        selectBoxType(paramsSelected);
      }
    }
    // eslint-disable-next-line
  }, [t]);

  return (
    <section>
      <OrderGroup>
        <OrderGroupNumber>1</OrderGroupNumber>
        <H2 bold>{t("order.boxTypes.title")}</H2>
      </OrderGroup>
      <BoxTypeCards delay={75}>
        {boxTypes.map((bt) => (
          <OrderBoxType
            key={bt.id}
            boxType={bt}
            active={selectedBoxId === bt.id}
            onClick={() =>
              selectedBoxId === bt.id ? selectBoxType(null) : selectBoxType(bt)
            }
          />
        ))}
      </BoxTypeCards>
    </section>
  );
};

export default OrderBoxTypes;
