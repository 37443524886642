import styled from "styled-components";
import { CheckboxContainer } from "../../../common/form/Checkbox/styles";

export const DocumentTypeListItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  ${CheckboxContainer} {
    margin-left: 2rem;
  }
`;
