import { Formik } from "formik";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import userApi from "../../../../api/user";
import { UserRights } from "../../../../models/auth";
import { Employee, EmployeeRole } from "../../../../models/employee";
import { Card } from "../../../../styles/card";
import { FadeInBottom } from "../../../../styles/fadeIn";
import { PageSection } from "../../../../styles/layout";
import { getErrorMessage } from "../../../../utils/errors";
import Breadcrumbs from "../../../common/Breadcrumbs/Breadcrumbs";
import Loader from "../../../common/Loader/Loader";
import AdminEmployeesDetailForm from "../AdminEmployeesDetailForm/AdminEmployeesDetailForm";

const employeeInitial: Employee = {
  firstName: "",
  lastName: "",
  email: "",
  role: "Employee",
  isActive: true,
  dailyReport: true,
  ownCalendar: true,
  calendarEmail: true,
  notificationAdditionalServiceRenewal: false,
  userRights: {},
  roleRights: {},
};

const AdminEmployeesDetailContent: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const isCreate = id === "new";
  const [loading, setLoading] = useState<boolean>(false);
  const [employeeData, setEmployeeData] = useState<Employee>(employeeInitial);
  const [roleRights, setRoleRights] = useState<UserRights>({});

  const validationSchema = Yup.object({
    firstName: Yup.string().required(t("errors.required")),
    lastName: Yup.string().required(t("errors.required")),
    email: Yup.string()
      .email(t("errors.emailValidation"))
      .required(t("errors.required")),
    role: Yup.string()
      .oneOf(
        Object.keys(EmployeeRole),
        t("adminEmployeesDetail.form.roleInvalidValue")
      )
      .required(t("errors.required")),
    isActive: Yup.boolean().required(t("errors.required")),
    dailyReport: Yup.boolean().required(t("errors.required")),
    ownCalendar: Yup.boolean().required(t("errors.required")),
    calendarEmail: Yup.boolean().required(t("errors.required")),
    notificationAdditionalServiceRenewal: Yup.boolean().required(t("errors.required")),
    userRights: Yup.object().required(t("errors.required")),
  });

  const handleGetEmployee = useCallback(
    async (id: string) => {
      setLoading(true);
      try {
        const response = await userApi.getEmployee(id);
        const dataWithoutNulls = JSON.parse(
          JSON.stringify(response.data, (k, v) => (v === null ? "" : v))
        );
        setRoleRights(dataWithoutNulls.roleRights);
        delete dataWithoutNulls.roleRights;
        setEmployeeData(dataWithoutNulls);
      } catch (err) {
        toast.error(getErrorMessage(err, t("errors.loadError")));
      }
      setLoading(false);
    },
    [t]
  );

  const handleSetRoleRights = useCallback(
    async (roleName: string) => {
      try {
        const response = await userApi.getRoleRights(roleName);
        const dataWithoutNulls = JSON.parse(
          JSON.stringify(response.data, (k, v) => (v === null ? "" : v))
        );
        setRoleRights(dataWithoutNulls);
      } catch (err) {
        toast.error(getErrorMessage(err, t("errors.loadError")));
      }
    },
    [t]
  );

  const handlePersistEmployee = useCallback(
    async (data: Employee) => {
      try {
        if (isCreate) {
          await userApi.createEmployee(data);
          toast.success(t("adminEmployeesDetail.form.createSuccess"));
        } else {
          await userApi.updateEmployee(id, data);
          toast.success(t("adminEmployeesDetail.form.updateSuccess"));
        }
        history.push("/admin/settings/employees");
      } catch (err) {
        toast.error(getErrorMessage(err, t("errors.saveError")));
      }
    },
    [history, id, isCreate, t]
  );

  useEffect(() => {
    if (id !== "new") {
      handleGetEmployee(id);
    }
  }, [id, t, history, handleGetEmployee]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <FadeInBottom>
          <PageSection>
            <Breadcrumbs backBtn>
              <Link to={"/admin/settings/employees"}>
                {t("adminEmployeesDetail.breadcrumbs.employees")}
              </Link>
              <span>
                {t(
                  `adminEmployeesDetail.breadcrumbs.${
                    isCreate ? "employeeNew" : "employeeEdit"
                  }`
                )}
              </span>
            </Breadcrumbs>
            <Card>
              <Formik
                initialValues={employeeData}
                enableReinitialize
                validateOnMount
                onSubmit={handlePersistEmployee}
                validationSchema={validationSchema}
              >
                <AdminEmployeesDetailForm
                  roleRights={roleRights}
                  isCreate={isCreate}
                  onRoleChanged={handleSetRoleRights}
                />
              </Formik>
            </Card>
          </PageSection>
        </FadeInBottom>
      )}
    </>
  );
};

export default AdminEmployeesDetailContent;
