import React, { FC } from "react";
import Page from "../../common/layout/Page/Page";
import UserSidebar from "../UserSidebar/UserSidebar";
import { H2, H3 } from "../../../styles/title";
import { useTranslation } from "react-i18next";
import { Card } from "../../../styles/card";
import { Form, Formik } from "formik";
import { FormActions, FormGroup } from "../../../styles/form";
import Input from "../../common/form/Input/Input";
import { ChangePasswordData } from "../../../models/auth";
import * as Yup from "yup";
import { Button } from "../../../styles/button";
import { PageSection } from "../../../styles/layout";
import { toast } from "react-toastify";
import authApi from "../../../api/auth";
import { FadeInBottom } from "../../../styles/fadeIn";
import storageApi from "../../../api/storage";
import { addMinutes } from "date-fns";

const initialValues: ChangePasswordData = {
  oldPassword: "",
  newPassword: "",
  newPasswordRepeat: "",
};

const fields: Array<keyof ChangePasswordData> = [
  "oldPassword",
  "newPassword",
  "newPasswordRepeat",
];

const Settings: FC = () => {
  const { t } = useTranslation();

  const handleSubmit = async (data: ChangePasswordData) => {
    try {
      const response = await authApi.changePassword(data);
      storageApi.setToken(response.data.token);
      storageApi.setIdleMinutes(response.data.userMaxIdleMinutes);
      storageApi.setTokenRefreshTimeout(
        addMinutes(new Date(), response.data.userMaxIdleMinutes)
      );
      storageApi.setTokenTimeout(
        addMinutes(new Date(), response.data.tokenValidMinutes)
      );

      toast.success(t("settings.passwordChangeSuccess"));
    } catch (err) {
      toast.error(t("errors.changePassword"));
    }
  };

  return (
    <Page sidebar={<UserSidebar />}>
      <FadeInBottom>
        <H2>{t("settings.title")}</H2>
        <PageSection>
          <Card>
            <H3>{t("settings.passwordChange")}</H3>
            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validationSchema={Yup.object({
                oldPassword: Yup.string().required(t("errors.required")),
                newPassword: Yup.string()
                  .matches(
                    /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
                    t("errors.passwordStrength")
                  )
                  .required(t("errors.required")),
                newPasswordRepeat: Yup.string()
                  .required(t("errors.required"))
                  .oneOf(
                    [Yup.ref("newPassword")],
                    t("errors.passwordMismatch")
                  ),
              })}
            >
              {({ errors, touched, isSubmitting }) => (
                <Form>
                  <FormGroup labelWidth="12rem">
                    {fields.map((f) => (
                      <Input
                        key={f}
                        type="password"
                        name={f}
                        label={t(`settings.${f}`)}
                        error={touched[f] && !!errors[f]}
                      />
                    ))}
                    <FormActions>
                      <Button type="submit" disabled={isSubmitting} $small>
                        {t("settings.savePassword")}
                      </Button>
                    </FormActions>
                  </FormGroup>
                </Form>
              )}
            </Formik>
          </Card>
        </PageSection>
      </FadeInBottom>
    </Page>
  );
};

export default Settings;
