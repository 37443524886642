export enum OrderState {
  New = "New",
  Scheduled = "Scheduled",
  Ok = "Ok",
  Paused = "Paused",
  Expired = "Expired",
}

export enum CalendarCustomerTimeState {
  Closed = "Closed",
  Available = "Available",
  Unavailable = "Unavailable",
  Past = "Past",
}

export enum CalendarAdminTimeState {
  Available = "Available",
  Unavailable = "Unavailable",
}

export enum PriceLevelState {
  Active = "Active",
  NotActive = "NotActive",
  Waiting = "Waiting",
}

export enum DocumentTemplateType {
  Order = "Order",
  Customer = "Customer",
}

export const getEnumVals = (inputEnum: any) =>
  Object.keys(inputEnum)
    .filter((val) => parseInt(val) >= 0)
    .map((val) => parseInt(val));
