import React, { FC, useEffect, useState } from "react";
import { Card, CardEditNameData } from "../../../../models/card";
import { SetState } from "../../../../utils/types";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { OrderSimple } from "../../../../models/order";
import { toast } from "react-toastify";
import cardApi from "../../../../api/card";
import CardEditModalContent from "./CardEditModalContent";

const CardEditModal: FC<{
  card: Card;
  open: boolean;
  setOpen: SetState<boolean>;
  onSuccess: () => void;
  admin?: boolean;
  userId?: string;
}> = ({ card, open, setOpen, onSuccess, admin, userId }) => {
  const { t } = useTranslation();
  const [orders, setOrders] = useState<OrderSimple[]>([]);
  const [reload, setReload] = useState(false);

  const handleSubmit = async (data: CardEditNameData) => {
    if (admin) {
      return;
    }

    try {
      await cardApi.updateUserCardName(card.id, data);
      toast.success(t("cards.modal.nameUpdateSuccess"));
      setOpen(false);
      onSuccess();
    } catch (err) {
      toast.error(t("errors.cardUpdate"));
    }
  };

  useEffect(() => {
    setOrders(card.orders);
  }, [open, card.orders]);

  useEffect(() => {
    if (!open && reload) {
      onSuccess();
    }
  }, [open, reload, onSuccess]);

  return (
    <Formik
      initialValues={{
        name: card.name,
        number: card.number,
      }}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      <CardEditModalContent
        open={open}
        setOpen={setOpen}
        card={card}
        orders={orders}
        setOrders={setOrders}
        userId={userId}
        admin={admin}
        setReload={setReload}
      />
    </Formik>
  );
};

export default CardEditModal;
