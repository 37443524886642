import styled from "styled-components";
import { H1, H2, H3, H4 } from "./title";
import { size } from "../utils/styles";

export const PageSection = styled.section`
  position: relative;

  &:not(:last-child) {
    margin-bottom: 2rem;
  }
`;

export const PageSectionHeader = styled.div<{ mb?: string }>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: ${({ mb }) => mb || "0.5rem"};

  ${H1}, ${H2}, ${H3}, ${H4} {
    margin-bottom: 0;
  }
`;

export const PageHeader = styled.div<{
  marginBottom?: string;
  breakSize?: string;
}>`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 0.5rem;
  align-items: flex-end;

  margin-bottom: ${({ marginBottom }) => marginBottom || "0.8rem"};

  > div {
    display: flex;
    align-items: center;
  }

  ${H2} {
    margin-bottom: 0;
  }

  @media screen and (min-width: ${({ breakSize }) =>
      breakSize || size.mobile}) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;
