import styled, { css } from "styled-components";
import { Table, TableFlat } from "../../../../styles/table";
import { BoxTypeLabel } from "../../../common/BoxTypeLabel/styles";

export const CardsAccessesTableContainer = styled(Table)`
  th:first-child {
    width: 1%;
  }

  td:nth-child(2) {
    width: 1%;
    padding-right: 0;
  }

  th:not(first-child) {
    width: max-content;
  }
`;
export const CardsAccessesTableFlat = styled(TableFlat)`
  th,
  td {
    padding-right: 3rem;

    &:nth-child(2) {
      padding-right: 1rem;
    }
  }
`;

export const CardsAccessesData = styled.div<{ color?: string }>`
  display: flex;
  align-items: center;

  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}

  svg,
  ${BoxTypeLabel} {
    margin-right: 0.5rem;
  }

  svg {
    font-size: 1.5rem;
  }
`;
