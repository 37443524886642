import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import orderApi from "../../../../../api/order";
import { Order } from "../../../../../models/order";
import { Button } from "../../../../../styles/button";
import { getErrorMessage } from "../../../../../utils/errors";
import { SetState } from "../../../../../utils/types";
import CustomModal from "../../../../common/CustomModal/CustomModal";

const OrderCancelAutoExtendModal: FC<{
  open: boolean;
  setOpen: SetState<boolean>;
  order: Order;
  reload: () => Promise<void>;
}> = ({ open, setOpen, order, reload }) => {
  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = async () => {
    setSubmitting(true);
    try {
      await orderApi.updateOrderSubscription(order.id, {
        autoExtend: false,
      });
      toast.success(t("common.saveSuccess"));
      setOpen(false);
      reload();
    } catch (err) {
      toast.error(getErrorMessage(err, t("errors.saveError")));
    }
    setSubmitting(false);
  };

  return (
    <CustomModal
      open={open}
      setOpen={setOpen}
      title={t("orders.detail.editAutoRenewModal.title")}
      actions={
        <>
          <Button
            type="button"
            ver="secondary"
            onClick={() => setOpen(false)}
            disabled={submitting}
          >
            {t("common.no")}
          </Button>
          <Button onClick={handleSubmit} disabled={submitting}>
            {t("common.yes")}
          </Button>
        </>
      }
    >
      {t(`orders.detail.editAutoRenewModal.cancel`)}
    </CustomModal>
  );
};

export default OrderCancelAutoExtendModal;
