import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { UserList } from "../../../../models/user";
import { NoTableData } from "../../../../styles/table";
import { OverflowX } from "../../../../utils/styles";
import { OnChangeOrder, OrderType } from "../../../../utils/types";
import CircleStateIsActive from "../../../common/CircleStateIsActive/CircleStateIsActive";
import Loader from "../../../common/Loader/Loader";
import { AdminEmployeesTableContainer } from "./styles";

const cols: Array<[string, string]> = [
  ["adminEmployees.table.state", "isActive"],
  ["adminEmployees.table.fullName", "lastname"],
  ["adminEmployees.table.email", "email"],
];

const AdminEmployeesTable: FC<{
  data: UserList[];
  isLoading?: boolean;
  order?: OrderType;
  onChangeOrder?: OnChangeOrder;
}> = ({ data, isLoading, order, onChangeOrder }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleOrderChange = useCallback(
    (orderName: string) => {
      if (orderName) {
        onChangeOrder?.(orderName, !Boolean(order?.orderDesc));
      }
    },
    [onChangeOrder, order?.orderDesc]
  );

  return (
    <OverflowX>
      {data.length || isLoading ? (
        <>
          {isLoading && <Loader />}
          <AdminEmployeesTableContainer>
            <thead>
              <tr>
                {cols.map(([col, orderName]) => (
                  <th key={col} onClick={() => handleOrderChange(orderName)}>
                    {t(col)}{" "}
                    {order?.orderBy === orderName && (
                      <FontAwesomeIcon
                        icon={order?.orderDesc ? faCaretDown : faCaretUp}
                      />
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((employee) => (
                <tr
                  key={employee.id}
                  onClick={() =>
                    history.push(`/admin/settings/employees/${employee.id}`)
                  }
                >
                  <td>
                    <CircleStateIsActive isActive={employee.isActive} />
                  </td>
                  <td>
                    <div>
                      {employee.firstName}&nbsp;{employee.lastName}
                    </div>
                  </td>
                  <td>
                    <div>{employee.email}</div>
                  </td>
                </tr>
              ))}
            </tbody>
          </AdminEmployeesTableContainer>
        </>
      ) : (
        <NoTableData>{t("adminEmployees.noData")}</NoTableData>
      )}
    </OverflowX>
  );
};

export default AdminEmployeesTable;
