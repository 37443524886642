import { rgba } from "polished";
import styled from "styled-components";
import { PageSection } from "../../../styles/layout";
import { COLORS } from "../../../utils/constants";

export const DashboardListCard = styled(PageSection)`
  overflow-x: auto;
  display: grid;
  grid-template-columns: max-content;
`;

export const ExpiringOrderWarning = styled.div`
  width: 100%;
  padding: 1rem;
  margin-bottom: 0.5rem;

  color: ${COLORS.orange};
  background-color: ${rgba(COLORS.orange, 0.05)};
  border: 2px solid ${COLORS.orange};
  border-radius: 4px;

  svg {
    margin-right: 1rem;
  }
`;
