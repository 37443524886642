import styled from "styled-components";
import { FormGroup } from "../../../../../styles/form";

export const OrderEditSubscriptionForm = styled(FormGroup)`
  width: 18rem;
`;

export const OrderAutoExtendTitle = styled.div`
  margin-bottom: 2rem !important;
`;
