import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled, { css } from "styled-components";
import { COLORS } from "../utils/constants";

export const Button = styled.button<{
  ver?: "primary" | "secondary" | "white" | "primary-dark";
  $small?: boolean;
}>`
  padding: ${({ $small }) => ($small ? "0.5rem 0.75rem" : "8px 35px")};
  background-color: var(--${({ ver }) => ver || "primary"});
  color: var(
    --${({ ver }) => (ver === "secondary" || ver === "white" ? "font-color" : "white")}
  ) !important;

  outline: none;
  border: none;
  border-radius: 100px;

  line-height: 1.5;
  font-size: ${({ $small }) => ($small ? "0.8rem" : "0.9rem")};
  text-decoration: none;
  white-space: pre;
  text-transform: uppercase;

  svg {
    margin-right: 0.5rem;
  }

  &:not(:disabled) {
    transition: all 0.2s ease-in-out;
  }

  &:hover {
    background-color: var(
      --${({ ver }) => (ver === "secondary" || ver === "white" || ver === "primary-dark" ? "secondary-dark" : "primary-dark")}
    );
  }

  &:hover {
    cursor: pointer;
  }

  &:disabled,
  &:disabled &:focus,
  &:disabled &:active {
    background-color: var(--secondary) !important;
    color: var(--secondary-dark) !important;

    &:hover {
      cursor: initial;
    }
  }
`;

export const LinkButton = styled.button`
  outline: none;
  border: none;
  padding: 0;
  background: none;

  text-decoration: underline;
  color: ${COLORS.primary};
  font-size: 1rem;
  transition: all 0.2s ease-in-out;

  &:hover {
    cursor: pointer;
    color: ${COLORS.primaryDark};
  }
`;

export const CheckButton = styled.button<{
  checked: boolean;
}>`
  padding: 8px 35px;
  background-color: ${({ checked }) =>
    checked ? COLORS.primary : COLORS.white};
  color: ${({ checked }) =>
    checked ? COLORS.white : COLORS.primary} !important;
  border: 1px solid ${COLORS.primary};
  outline: none;
  border-radius: 100px;
  line-height: 1.5;
  font-size: 0.9rem;
  text-decoration: none;
  white-space: pre;
  text-transform: uppercase;

  svg {
    margin-right: 0.5rem;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const IconButton = styled(FontAwesomeIcon)<{
  $secondary?: boolean;
  disabled?: boolean;
}>`
  color: ${({ $secondary }) =>
    $secondary ? COLORS.secondary : COLORS.primary};
  transition: all 0.2s ease-in-out;

  &:hover {
    cursor: pointer;
    color: ${({ $secondary }) =>
      $secondary ? COLORS.secondaryDark : COLORS.primaryDark};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${COLORS.secondaryDark} !important;
    `}
`;

export const IconButtonDelete = styled(FontAwesomeIcon)<{
  disabled?: boolean;
}>`
  color: ${COLORS.red};

  &:hover {
    cursor: pointer;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${COLORS.secondaryDark} !important;
    `}
`;
