import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { H2 } from "../../../../styles/title";
import { PageSection } from "../../../../styles/layout";
import AdminBoxesSettingsTraffic from "../AdminBoxesSettingsTraffic/AdminBoxesSettingsTraffic";
import { FadeInBottom } from "../../../../styles/fadeIn";
import boxApi from "../../../../api/box";
import { BoxType } from "../../../../models/box";
import { toast } from "react-toastify";
import Loader from "../../../common/Loader/Loader";

const AdminBoxesSettingsContent: FC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const [boxesData, setBoxesData] = useState<BoxType[]>([]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const response = await boxApi.getAllBoxes();
        setBoxesData(response.data);
      } catch (err) {
        toast.error(t("errors.loadBoxes"));
      }
      setLoading(false);
    })();
  }, [t]);

  if (loading) return <Loader />;
  return (
    <PageSection>
      <FadeInBottom>
        <H2>{t("adminBoxesSettings.title")}</H2>
        <AdminBoxesSettingsTraffic data={boxesData} />
      </FadeInBottom>
    </PageSection>
  );
};

export default AdminBoxesSettingsContent;
