import styled from "styled-components";
import { OrderForm } from "../OrderParameters/styles";

export const Container = styled(OrderForm)`
  margin-left: 1rem;
`;

export const DivLeft = styled.div`
  float: left;
`;

export const ConsentValidationError = styled.div`
  float: left;
  position: relative;
  top: -0.4rem;
`;
