import { AxiosResponse } from "axios";
import { BasicSettings } from "../models/settings";
import api from "../utils/api";

export const getBasicSettings = (): Promise<AxiosResponse<BasicSettings>> =>
  api.get(`/api/BasicSettings`);

export const updateBasicSettings = async (
  data: BasicSettings
): Promise<AxiosResponse<boolean>> => api.put(`/api/BasicSettings`, data);

const settingsApi = { getBasicSettings, updateBasicSettings };

export default settingsApi;
