import { OrderState } from "../utils/enums";
import { AdditionalServiceOrder } from "./additionalServices";
import { BoxType, OrderBox } from "./box";
import { Card } from "./card";
import { Document, Invoice } from "./document";
import { PromoCodeList } from "./promoCode";
import { UserData, UserNameId } from "./user";

export interface Order {
  id: string;
  branchId: string;
  state: OrderState;
  name: string;
  createdDate: string;
  dateFrom: string | null;
  dateTo: string | null;
  autoExtend: boolean;
  fingerprint: boolean;
  smsCheck: boolean;
  lengthOfLeaseType: number;
  soonExpires: boolean;
  box: OrderBox;
  belaBoxNo: number | null;
  cards: Card[];
  user: UserNameId;
  invoices: Invoice[];
  documents: Document[];
  additionalServices: AdditionalServiceOrder[];
  promoCode?: PromoCodeList;
}

export interface OrderFormData extends UserData, OrderParametersData {
  branchId: string;
  hasPromoCode: boolean;
  discount?: number;
  promoCode: string | null;
  boxId: number | null;
  consent: boolean;
  additionalServices: number[];
}

export interface OrderCreateData {
  branchId: number;
  boxId: number | null;
  promoCode: string | null;
  lengthOfLeaseType: number;
  autoExtend: boolean;
  fingerPrint: boolean;
  smsCheck: boolean;
  paymentType: PaymentMethod;
  userData: OrderUser;
  additionalServices: number[];
}

export interface OrderUser extends UserData {
  id?: number;
  isCustomer?: boolean;
  role?: string;
}

export interface OrderEditParametersData {
  fingerprint: boolean;
  smsCheck: boolean;
  paymentType: PaymentMethod;
  additionalServices: number[];
}

export interface OrderParametersData {
  discount?: number;
  fingerPrint: boolean;
  smsCheck: boolean;
  paymentType: PaymentMethod;
  lengthOfLeaseType: string;
  autoExtend: boolean;
  promoCode: string | null;
}

export interface OrderSubscriptionData {
  lengthOfLeaseType?: string;
  autoExtend?: boolean;
}

export interface OrderCreateResponse {
  url: string;
  token: string;
  customerId: string;
}

export interface OrderSimple {
  id: string;
  name: string;
}

export type PaymentMethod = "Cash" | "Transfer" | "Card";

export enum InvoiceState {
  NotPaidYet = "NotPaidYet",
  Continue = "Continue",
  AlreadyProcessed = "AlreadyProcessed",
  StillWithoutLogin = "StillWithoutLogin",
  NotFound = "NotFound",
}

export interface InvoiceStateResponse {
  state: InvoiceState;
  token: string;
  invoiceNumber: string;
  priceInclVat: number;
  url: string;
  customerId: string;
}

export interface OrderStateOverview {
  id: number;
  name: string;
  branchId: number;
  user: UserNameId;
  dateTo: string | null;
  state: OrderState;
  createdDate: string;
  branchBox: {
    boxType: BoxType;
  };
}

export interface OrderStateOverviews {
  paused: OrderStateOverview[];
  expired: OrderStateOverview[];
  soonExpired: OrderStateOverview[];
}
