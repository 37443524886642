import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { SearchContainer, SearchIconWrapper, SearchInput } from "./styles";

interface Props {
  name: string;
  onInputChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Search: FC<Props> = ({ name, onInputChange }) => {
  const { t } = useTranslation();

  return (
    <SearchContainer>
      <SearchIconWrapper>
        <FontAwesomeIcon icon={faSearch} />
      </SearchIconWrapper>
      <SearchInput
        placeholder={t("common.searchPlaceholder")}
        type="text"
        id={name}
        name={name}
        onChange={onInputChange}
      />
    </SearchContainer>
  );
};

export default Search;
