import { AxiosResponse } from "axios";
import {
  ChangePasswordData,
  ChangePasswordResult,
  ForgottenPasswordData,
  LoginData,
  LoginResponse,
  ResetPasswordData,
} from "../models/auth";
import api from "../utils/api";
import history from "../utils/history";

export const login = async (
  data: LoginData
): Promise<AxiosResponse<LoginResponse>> => api.post(`/api/Auth/Login`, data);

export const logout = (inactivity?: boolean) => {
  localStorage.clear();
  history.push(`/login${inactivity ? `?inactivity=true` : ""}`);
};

export const sendPasswordResetLink = (
  data: ForgottenPasswordData
): Promise<AxiosResponse<boolean>> =>
  api.post(`/api/Auth/ForgottenPassword`, data);

export const resetPassword = (
  data: ResetPasswordData
): Promise<AxiosResponse<boolean>> =>
  api.post(`/api/Auth/ForgottenPasswordChange`, data);

export const changePassword = (
  data: ChangePasswordData
): Promise<AxiosResponse<ChangePasswordResult>> =>
  api.post(`/api/Auth/ChangePassword`, data);

export const renewToken = (): Promise<AxiosResponse<string>> =>
  api.get(`/api/Auth/TokenRenew`);

const authApi = {
  login,
  logout,
  sendPasswordResetLink,
  resetPassword,
  changePassword,
  renewToken,
};

export default authApi;
