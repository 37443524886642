import React, { FC, useState } from "react";
import OrderDetailCardHeader from "../OrderDetailCardHeader/OrderDetailCardHeader";
import { GreenText, PrimaryText } from "../../../../../styles/text";
import OrderStateLabel from "../../../../common/orders/OrderState/OrderStateLabel";
import { formatCurrency, formatDate } from "../../../../../utils/formats";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { IconButton, LinkButton } from "../../../../../styles/button";
import OrderDetailCardParameters from "../OrderDetailCardParameters/OrderDetailCardParameters";
import { useTranslation } from "react-i18next";
import { SetState } from "../../../../../utils/types";
import { COLORS } from "../../../../../utils/constants";
import {
  OrderDetailCardBoxAutoRenew,
  OrderDetailCardBoxContainer,
  OrderDetailCardBoxLease,
  OrderDetailCardBoxList,
} from "./styles";
import { Order } from "../../../../../models/order";
import OrderEditNameModal from "../OrderEditNameModal/OrderEditNameModal";
import { BoxPrice } from "../../../../../models/box";
import { getBoxPriceWithVat } from "../../../../order/priceUtils";
import { useStore } from "../../../../../utils/store";
import OrderEditSubscriptionModal from "../OrderEditSubscriptionModal/OrderEditSubscriptionModal";
import OrderCancelAutoExtendModal from "../OrderCancelAutoExtendModal/OrderCancelAutoExtendModal";

const OrderDetailCardBox: FC<{
  order: Order;
  boxPrice: BoxPrice;
  setEditParams: SetState<boolean>;
  reload: () => Promise<void>;
}> = ({ order, boxPrice, setEditParams, reload }) => {
  const { t } = useTranslation();
  const user = useStore((store) => store.user);
  const [editName, setEditName] = useState(false);
  const [editSubscription, setEditSubscription] = useState(false);
  const [cancelAutoExtend, setCancelAutoExtend] = useState(false);
  const [enableAutoExtend, setEnableAutoExtend] = useState(false);

  return (
    <>
      <OrderEditNameModal
        open={editName}
        setOpen={setEditName}
        orderId={order.id}
        orderName={order.name}
        reload={reload}
      />
      <OrderEditSubscriptionModal
        open={editSubscription}
        setOpen={setEditSubscription}
        order={order}
        boxPrice={boxPrice}
        reload={reload}
        enableAutoExtend={enableAutoExtend}
        setEnableAutoExtend={setEnableAutoExtend}
      />
      <OrderCancelAutoExtendModal
        open={cancelAutoExtend}
        setOpen={setCancelAutoExtend}
        order={order}
        reload={reload}
      />
      <OrderDetailCardBoxContainer>
        <OrderDetailCardHeader
          setEditSubscription={setEditSubscription}
          order={order}
          reload={reload}
        />
        <OrderDetailCardBoxList>
          <>
            <PrimaryText>{t("orders.detail.name")}</PrimaryText>
            <b>
              {order.name}
              {user?.role === "Customer" && order.state !== "Expired" && (
                <IconButton
                  icon={faPen}
                  size="sm"
                  onClick={() => setEditName(true)}
                />
              )}
            </b>
          </>
          <>
            <PrimaryText>{t("orders.detail.state")}</PrimaryText>
            <OrderStateLabel state={order.state} />
          </>
          {order.dateFrom && order.dateTo && (
            <>
              <PrimaryText>{t("orders.detail.validThrough")}</PrimaryText>
              <OrderDetailCardBoxLease>
                <b>
                  {formatDate(order.dateFrom)} - {formatDate(order.dateTo)}
                </b>
              </OrderDetailCardBoxLease>
            </>
          )}
        </OrderDetailCardBoxList>
        <div>
          <OrderDetailCardBoxList>
            <PrimaryText>{t("orders.detail.leaseLength")}</PrimaryText>
            <OrderDetailCardBoxLease>
              <b>
                {t(
                  `order.parameters.lengthOfLeaseType.${order.lengthOfLeaseType}`
                )}
              </b>
              <GreenText>
                {formatCurrency(
                  getBoxPriceWithVat(`${order.lengthOfLeaseType}`, boxPrice)
                )}
              </GreenText>
            </OrderDetailCardBoxLease>
          </OrderDetailCardBoxList>
          <OrderDetailCardBoxAutoRenew>
            <div>
              <FontAwesomeIcon icon={faSyncAlt} color={COLORS.primary} />
              {t("orders.detail.autoRenew")}{" "}
              <b>{t(`orders.detail.${order.autoExtend ? "on" : "off"}`)}</b>
            </div>
            {user?.role === "Customer" && order.state !== "Expired" && (
              <LinkButton
                onClick={() => {
                  if (order.autoExtend) {
                    setCancelAutoExtend(true);
                  } else {
                    setEnableAutoExtend(true);
                    setEditSubscription(true);
                  }
                }}
              >
                {t("common.change")}
              </LinkButton>
            )}
          </OrderDetailCardBoxAutoRenew>
        </div>
        <OrderDetailCardParameters
          order={order}
          boxPrice={boxPrice}
          setEditParams={setEditParams}
        />
      </OrderDetailCardBoxContainer>
    </>
  );
};

export default OrderDetailCardBox;
