import styled from "styled-components";

export const OrderSidebarPriceContainer = styled.div`
  margin-top: 0.5rem;
  padding: 0.5rem;
  font-size: 1.2rem;

  span,
  b {
    display: inline-block;
  }
`;
