import styled, { css } from "styled-components";
import { PageSection } from "../../../styles/layout";
import { size } from "../../../utils/styles";

export const TabsContainer = styled(PageSection)`
  width: 100%;
`;

export const Tab = styled.button<{ active: boolean; breakSize?: string }>`
  width: max-content;
  white-space: pre;

  width: 100%;
  text-align: left;
  padding: 0.75rem 1rem;

  background-color: ${({ active }) =>
    active ? "var(--primary-dark)" : "var(--white)"};
  ${({ active }) =>
    active &&
    css`
      color: var(--white);
    `};

  font-size: 1.2rem;

  outline: none;
  border: none;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: var(--primary);
    color: var(--white);
    cursor: pointer;
  }

  @media screen and (min-width: ${({ breakSize }) =>
      breakSize || size.tablet}) {
    width: initial;
    text-align: initial;
  }
`;

export const TabsHeader = styled.div<{ breakSize?: string }>`
  display: flex;
  flex-direction: column;

  margin-bottom: 0.25rem;

  overflow-x: auto;

  @media screen and (min-width: ${({ breakSize }) =>
      breakSize || size.tablet}) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

export const TabList = styled.div<{ breakSize?: string }>`
  margin-bottom: 0.25rem;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${({ breakSize }) =>
      breakSize || size.tablet}) {
    flex-direction: row;
    margin-bottom: 0;
  }
`;

export const TabActions = styled.div<{ breakSize?: string }>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
