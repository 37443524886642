import { canvasToBlob } from "blob-util";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactResizeDetector from "react-resize-detector";
import { useHistory } from "react-router-dom";
import SignatureCanvas from "react-signature-canvas";
import { toast } from "react-toastify";
import authApi from "../../api/auth";
import signQueueApi from "../../api/signQueue";
import LogoImg from "../../images/logo.png";
import { SignQueueItem } from "../../models/signQueue";
import { Button } from "../../styles/button";
import { getErrorMessage } from "../../utils/errors";
import { useStore } from "../../utils/store";
import {
  BottomPanel,
  ButtonsPanel,
  CancelButton,
  CanvasContainer,
  DashboardButton,
  Logo,
  LogoutButton,
  PdfContainer,
  RightPanel,
  TabletContainer,
} from "./styles";

const Tablet: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [user, setUser] = useStore((store) => [store.user, store.setUser]);
  const [queue, setQueue] = useState<SignQueueItem>();
  const [showActions, setShowActions] = useState(false);
  const [mouseTimeout, setMouseTimeout] = useState<number | null>(null);
  const canvasRef = useRef<SignatureCanvas>(null);
  const [isEmpty, setIsEmpty] = useState(true);
  const [saving, setSaving] = useState(false);
  const fullscreenButtonRef = useRef<HTMLButtonElement>(null);
  const [showFullScreenButton, setShowFullScreenButton] = useState(false);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const handleLogout = () => {
    setUser(null);
    authApi.logout();
  };

  const handleMouseMove = () => {
    if (mouseTimeout) {
      clearTimeout(mouseTimeout);
    }
    setShowActions(true);
    const newMouseTimeout = window.setTimeout(() => {
      setShowActions(false);
    }, 5000);
    setMouseTimeout(newMouseTimeout);
  };

  const handleClear = () => {
    canvasRef.current?.clear();
    setIsEmpty(true);
  };

  const handleSignEnd = () => {
    if (!isEmpty) {
      return;
    }

    if (!canvasRef.current) {
      return;
    }

    if (!canvasRef.current?.isEmpty()) {
      setIsEmpty(false);
    }
  };

  const handleCancel = async () => {
    setSaving(true);
    try {
      await signQueueApi.cancel(user!.id);
      setQueue(undefined);
      toast.success(t("common.saveSuccess"));
    } catch (err) {
      toast.error(getErrorMessage(err, t("errors.saveError")));
    }
    setSaving(false);
  };

  const handleConfirm = async () => {
    setSaving(true);
    try {
      const image = await canvasToBlob(
        canvasRef.current!.getTrimmedCanvas(),
        "image/png"
      );
      await signQueueApi.sign(queue!.id, image);
      handleClear();
      const response = await signQueueApi.getQueue(user!.id);
      setQueue(response.data);
      toast.success(t("common.saveSuccess"));
    } catch (err) {
      toast.error(getErrorMessage(err, t("errors.saveError")));
    }
    setSaving(false);
  };

  const handleEnterFullScreen = async () => {
    try {
      await document.body.requestFullscreen();
      setShowFullScreenButton(false);
    } catch (e) {
      setShowFullScreenButton(true);
    }
  };

  const loadItems = useCallback(async () => {
    if (saving) {
      return;
    }

    try {
      const response = await signQueueApi.getQueue(user!.id);
      const newQueue = response.data;

      if (newQueue.id === queue?.id) {
        //Repair not loaded content. If loaded then CORS error thrown.
        if (queue?.id) {
          try {
            if (
              iframeRef.current?.src &&
              (iframeRef.current?.contentDocument?.readyState === "complete" ||
                iframeRef.current?.contentWindow?.document?.readyState ===
                  "complete") &&
              !iframeRef.current?.contentDocument?.body.innerText
            ) {
              window.location.reload();
            }
          } catch (e) {}
        }

        return;
      }

      handleClear();
      setQueue(newQueue);
    } catch (err) {
      toast.error(getErrorMessage(err, t("errors.loadError")));
    }
  }, [t, user, saving, queue?.id]);

  useEffect(() => {
    document.onfullscreenchange = () => {
      setShowFullScreenButton(document.fullscreenElement === null);
    };

    const timer1 = setTimeout(() => loadItems, 0);
    const timer2 = setTimeout(() => fullscreenButtonRef.current?.click(), 1000);
    const interval = setInterval(() => loadItems(), 1500);

    return () => {
      document.onfullscreenchange = null;
      clearTimeout(timer1);
      clearTimeout(timer2);
      clearInterval(interval);
    };
  }, [loadItems]);

  return (
    <TabletContainer onMouseMove={handleMouseMove}>
      <Button
        onClick={handleEnterFullScreen}
        ref={fullscreenButtonRef}
        style={{ display: showFullScreenButton ? undefined : "none" }}
      >
        FullScreen
      </Button>
      {showActions && (
        <>
          {user?.role !== "SignManager" && (
            <DashboardButton onClick={() => history.push("/")}>
              {t("common.home")}
            </DashboardButton>
          )}
          <LogoutButton ver="secondary" onClick={handleLogout}>
            {t("common.logout")}
          </LogoutButton>
        </>
      )}
      {queue ? (
        <>
          <PdfContainer
            ref={iframeRef}
            src={`https://docs.google.com/viewerng/viewer?url=${process.env.REACT_APP_API_URL}/api/SignQueue/${queue.hash}/Data&embedded=true`}
          />
          <BottomPanel>
            <CancelButton
              ver="secondary"
              onClick={handleCancel}
              disabled={saving}
            >
              {t("tablet.cancel")}
            </CancelButton>
            <RightPanel>
              <ReactResizeDetector handleWidth handleHeight>
                {({ width, height }) => (
                  <CanvasContainer>
                    <SignatureCanvas
                      canvasProps={{ width: width, height: height }}
                      ref={canvasRef}
                      onEnd={handleSignEnd}
                    />
                  </CanvasContainer>
                )}
              </ReactResizeDetector>
              <ButtonsPanel>
                <Button
                  ver="secondary"
                  onClick={handleClear}
                  disabled={isEmpty || saving}
                >
                  {t("tablet.clear")}
                </Button>
                <Button onClick={handleConfirm} disabled={isEmpty || saving}>
                  {t("tablet.confirm")}
                </Button>
              </ButtonsPanel>
            </RightPanel>
          </BottomPanel>
        </>
      ) : (
        <Logo src={LogoImg} alt="Safeboxes" />
      )}
    </TabletContainer>
  );
};

export default Tablet;
