import styled from "styled-components";
import { size } from "../../../../../utils/styles";
import { PageSection } from "../../../../../styles/layout";
import { COLORS } from "../../../../../utils/constants";

export const OrderBoxCard = styled(PageSection)`
  padding: 1rem;

  background-color: ${COLORS.white};
  border: 2px solid ${COLORS.secondary};
`;

export const OrderBoxDetails = styled.div`
  display: flex;
  flex-flow: column;

  @media screen and (min-width: ${size.laptop}) {
    flex-flow: row;
  }
`;

export const OrderBoxImgContainer = styled.div`
  width: 100%;

  margin-bottom: 1rem;

  text-align: center;

  @media screen and (min-width: ${size.laptop}) {
    max-width: 28rem;
    width: 50%;
    margin-bottom: 0;
    margin-right: 3rem;
  }
`;

export const OrderBoxImg = styled.img`
  width: 100%;
  max-width: 32rem;
  height: auto;
`;
