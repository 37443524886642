import React, { FC } from "react";
import Page from "../../common/layout/Page/Page";
import AdminSidebar from "../AdminSidebar/AdminSidebar";
import AdminBasicSettingsContent from "./AdminBasicSettingsContent/AdminBasicSettingsContent";

const AdminBasicSettings: FC = () => (
  <Page sidebar={<AdminSidebar />}>
    <AdminBasicSettingsContent />
  </Page>
);

export default AdminBasicSettings;
