import styled from "styled-components";
import { H3 } from "../../../../../styles/title";
import { Button } from "../../../../../styles/button";

export const OrderDetailCardHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  > div {
    width: max-content;
  }

  ${H3} {
    margin-bottom: 0.5rem;
    margin-right: 1rem;
  }

  ${Button} {
    margin-bottom: 0.5rem;
  }
`;
