import { isAfter } from "date-fns";
import React, { FC } from "react";
import { Redirect, Route } from "react-router";
import storageApi from "../../api/storage";
import { UserRight } from "../../models/auth";
import { Role } from "../../models/user";
import { useUserHasRight } from "../../utils/auth";
import { useStore } from "../../utils/store";

const AuthRoute: FC<{
  path: string;
  exact?: boolean;
  requiredRoles?: Role[];
  requiredRights?: UserRight[];
}> = ({ path, exact, requiredRoles, requiredRights, children }) => {
  const [user, setUser] = useStore((store) => [store.user, store.setUser]);
  const tokenRefreshTimeout = storageApi.getTokenRefreshTimeout();
  const tokenTimeout = storageApi.getTokenTimeout();
  const token = storageApi.getToken();
  const { hasUserAllRights } = useUserHasRight();

  return (
    <Route
      path={path}
      exact={exact}
      render={() => {
        if (token && tokenRefreshTimeout && tokenTimeout) {
          // logout if token expired
          if (isAfter(new Date(), tokenTimeout)) {
            setUser(null);
            localStorage.clear();
            return <Redirect to="/login?inactivity=true" />;
          }
        }

        if (path === "/") {
          if (user?.role === "QueueManager") return <Redirect to="/queue" />;
          if (user?.role === "SignManager") return <Redirect to="/tablet" />;
          if (user?.role !== "Customer") return <Redirect to="/admin" />;
        }

        if (path === "/order" && !(!user || user?.role === "Customer"))
          return <Redirect to="/admin" />;

        if (path === "/reservation" && !token) return <Redirect to="/login" />;

        if (path.includes("/invoice?token=")) return children;

        if ((!user || !token) && requiredRoles !== undefined)
          return <Redirect to="/login" />;

        if (
          user &&
          requiredRoles !== undefined &&
          !requiredRoles.includes(user.role) &&
          requiredRights !== undefined &&
          !hasUserAllRights(requiredRights)
        )
          return <Redirect to="/not-found" />;

        return children;
      }}
    />
  );
};

export default AuthRoute;
