import styled from "styled-components";
import { COLORS } from "../../../utils/constants";
import { IconButton } from "../../../styles/button";

export const ModalMain = styled.div`
  .react-responsive-modal-container {
    overflow-x: auto;
  }

  .react-responsive-modal-modal {
    overflow-y: visible;
  }
`;

export const ModalHeader = styled.div`
  position: relative;
  padding: 1rem 3rem 1rem 1rem;

  background-color: ${COLORS.primary};
  color: ${COLORS.white};
  font-size: 1.2rem;
`;

export const ModalCloseButton = styled(IconButton)`
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  color: ${COLORS.white};
`;

export const ModalContent = styled.div`
  padding: 2rem;
  background-color: ${COLORS.white};
`;

export const ModalActions = styled.div`
  width: 100%;

  padding: 0 1rem 1rem 1rem;

  display: flex;
  justify-content: center;
  align-items: center;

  > *:not(:last-child) {
    margin-right: 0.75rem;
  }
`;
