import {
  faCreditCard,
  faPen,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import cardApi from "../../../../api/card";
import { Card } from "../../../../models/card";
import { IconButton } from "../../../../styles/button";
import { NoTableData, TableLabels } from "../../../../styles/table";
import { LinkNoStyle } from "../../../../styles/text";
import { useUserHasRight } from "../../../../utils/auth";
import { COLORS } from "../../../../utils/constants";
import { getErrorMessage } from "../../../../utils/errors";
import { OverflowX } from "../../../../utils/styles";
import CardEditModal from "../CardEditModal/CardEditModal";
import { CardsAccessesData } from "../CardsAccesses/styles";
import CardState from "../CardState/CardState";
import CardDeleteModal from "./CardDeleteModal";
import { CardsTableContainer, CardsTableDelete } from "./styles";

const CardsTable: FC<{
  data: Card[];
  onSuccess: () => void;
  admin?: boolean;
}> = ({ data, onSuccess, admin }) => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [card, setCard] = useState<Card>({
    id: "",
    idUser: "",
    name: "",
    number: "",
    orders: [],
  });
  const { hasUserAllRights, isAdmin } = useUserHasRight();
  const canDelete = isAdmin || hasUserAllRights(["deleteCards"]);

  const handleDeleteConfirmed = async () => {
    try {
      await cardApi.deleteCard(card.id);
      onSuccess();
      toast.success(t("common.saveSuccess"));
    } catch (err) {
      toast.error(getErrorMessage(err, t("errors.saveError")));
    }
  };

  const tableCols = (
    <tr>
      {[
        "cards.table.card",
        "cards.table.state.title",
        "cards.table.orders",
        "common.actions",
      ].map((col) => (
        <th key={col}>{t(col)}</th>
      ))}
    </tr>
  );

  const tableData = useMemo(
    () =>
      data.map((row) => (
        <tr key={row.id}>
          <td>
            <CardsAccessesData>
              <FontAwesomeIcon icon={faCreditCard} color={COLORS.primary} />
              <b>{row.name}</b>
            </CardsAccessesData>
          </td>
          <td>
            <CardState active={!!row.orders.length} />
          </td>
          <td>
            {row.orders.length ? (
              <TableLabels>
                {row.orders.map((o) => (
                  <LinkNoStyle
                    key={o.id}
                    to={`${admin ? "/admin" : ""}/orders/${o.id}`}
                  >
                    {o.name}
                  </LinkNoStyle>
                ))}
              </TableLabels>
            ) : (
              "-"
            )}
          </td>
          <td>
            <div>
              <IconButton
                icon={faPen}
                onClick={() => {
                  setCard(row);
                  setModalOpen(true);
                }}
              />
              {admin && canDelete && (
                <CardsTableDelete
                  icon={faTimes}
                  size="lg"
                  disabled={row.orders.length !== 0}
                  onClick={() => {
                    if (row.orders.length === 0) {
                      setCard(row);
                      setDeleteModalOpen(true);
                    }
                  }}
                />
              )}
            </div>
          </td>
        </tr>
      )),
    [data, admin, canDelete]
  );

  return data.length ? (
    <OverflowX>
      <CardEditModal
        card={card}
        open={modalOpen}
        setOpen={setModalOpen}
        onSuccess={onSuccess}
        admin={admin}
        userId={id}
      />
      {admin && (
        <CardDeleteModal
          open={deleteModalOpen}
          setOpen={setDeleteModalOpen}
          confirm={handleDeleteConfirmed}
        />
      )}
      <CardsTableContainer>
        <thead>{tableCols}</thead>
        <tbody>{tableData}</tbody>
      </CardsTableContainer>
    </OverflowX>
  ) : (
    <NoTableData>{t("cards.table.noData")}</NoTableData>
  );
};

export default CardsTable;
