import { IconName, IconPrefix } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, useFormikContext } from "formik";
import { debounce } from "lodash";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { AdditionalService } from "../../../models/additionalServices";
import { Button } from "../../../styles/button";
import { FormGroup, InputContainer, InputRow } from "../../../styles/form";
import { useUserHasRight } from "../../../utils/auth";
import { COLORS, DEBOUNCE_TIMEOUT_MS } from "../../../utils/constants";
import { IconNames } from "../../../utils/icons";
import { SetState } from "../../../utils/types";
import CustomModal from "../../common/CustomModal/CustomModal";
import Input from "../../common/form/Input/Input";
import Search from "../../common/form/Search/Search";
import { IconItem, IconPreview, IconSelector, SearchContainer } from "./style";

const AdminAdditionalServicesModalContent: FC<{
  open: boolean;
  setOpen: SetState<boolean>;
}> = ({ open, setOpen }) => {
  const { t } = useTranslation();
  const {
    isSubmitting,
    isValid,
    submitForm,
    errors,
    touched,
    resetForm,
    values,
    setFieldValue,
  } = useFormikContext<AdditionalService>();
  const [filterText, setFiterText] = useState("");
  const { hasUserAllRights } = useUserHasRight();

  const filteredIcons = useMemo(
    () =>
      (filterText
        ? IconNames.filter((x) => x.iconName.includes(filterText))
        : IconNames
      ).map((icon) => (
        <IconItem
          key={icon.prefix + icon.iconName}
          icon={{
            prefix: icon.prefix as IconPrefix,
            iconName: icon.iconName as IconName,
          }}
          size="4x"
          color={COLORS.primary}
          onClick={() => setFieldValue("icon", icon.prefix + icon.iconName)}
        />
      )),
    [filterText, setFieldValue]
  );

  useEffect(() => {
    if (open) {
      resetForm();
      setFiterText("");
    }
  }, [open, resetForm]);

  return (
    <CustomModal
      title={t(
        values.id
          ? "adminAdditionalServices.modal.edit"
          : "adminAdditionalServices.modal.add"
      )}
      open={open}
      setOpen={setOpen}
      actions={
        <>
          <Button
            type="button"
            ver="secondary"
            disabled={isSubmitting}
            onClick={() => setOpen(false)}
          >
            {t("common.cancel")}
          </Button>
          <Button
            disabled={
              !isValid ||
              isSubmitting ||
              !hasUserAllRights(["writeAdditionalServices"])
            }
            onClick={() => submitForm()}
          >
            {t("common.save")}
          </Button>
        </>
      }
    >
      <Form style={{ minWidth: "26rem" }}>
        <FormGroup>
          <Input
            key="isActive"
            name="isActive"
            type="checkbox"
            label={t(`adminAdditionalServices.modal.isActive`)}
            inputWidth="1.5rem"
            inputHeight="1.5rem"
          />
          <Input
            key="name"
            name="name"
            label={t(`adminAdditionalServices.modal.name`)}
            error={!!errors["name"] && touched["name"]}
            maxLength={30}
          />
          <Input
            key="description"
            name="description"
            label={t(`adminAdditionalServices.modal.description`)}
            error={!!errors["description"] && touched["description"]}
            maxLength={60}
          />
          {!values.id && (
            <Input
              key="priceWithVat"
              name="priceWithVat"
              type="number"
              label={t(`adminAdditionalServices.modal.price`)}
              mark={t("adminAdditionalServices.modal.currencyLabel")}
              markPosition={"right"}
            />
          )}
          <Input
            key="shouldSendNotificationOnRenewal"
            name="shouldSendNotificationOnRenewal"
            type="checkbox"
            label={t(`adminAdditionalServices.modal.notificationOnRenewal`)}
            inputWidth="1.5rem"
            inputHeight="1.5rem"
          />
          <InputRow key="iconPreview">
            <label htmlFor={"icon"}>
              {t("adminAdditionalServices.modal.icon")}
            </label>
            <InputContainer>
              <IconPreview>
                {values.icon && (
                  <FontAwesomeIcon
                    icon={{
                      prefix: values.icon.substring(0, 3) as IconPrefix,
                      iconName: values.icon.substring(3) as IconName,
                    }}
                    size="4x"
                    color={COLORS.primary}
                  />
                )}
              </IconPreview>
              <SearchContainer>
                <Search
                  name="filter"
                  onInputChange={debounce(
                    (event) =>
                      setFiterText(
                        (event.target.value as string).toLowerCase()
                      ),
                    DEBOUNCE_TIMEOUT_MS
                  )}
                />
              </SearchContainer>
            </InputContainer>
          </InputRow>
          <IconSelector key="iconSelector">{filteredIcons}</IconSelector>
        </FormGroup>
      </Form>
    </CustomModal>
  );
};

export default AdminAdditionalServicesModalContent;
