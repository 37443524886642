import { useFormikContext } from "formik";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { UserData } from "../../../models/user";
import { FormGroup } from "../../../styles/form";
import FormTextArea from "../form/Input/FormTextArea";
import Input from "../form/Input/Input";
import PhoneInput from "../form/PhoneInput/PhoneInput";

const fields1: Array<keyof UserData> = ["firstName", "lastName", "email"];

const fields2: Array<keyof UserData> = ["street", "city", "zipCode", "country"];

const UserInfoForm: FC<{
  tooltipLeft?: boolean;
  showNote?: boolean;
}> = ({ tooltipLeft, showNote }) => {
  const { t } = useTranslation();
  const { errors, touched } = useFormikContext<UserData>();

  return (
    <FormGroup>
      {fields1.map((f) => (
        <Input
          key={f}
          name={f}
          label={t(`userInfo.info.${f}`)}
          error={touched?.[f] && !!errors?.[f]}
          tooltipLeft={tooltipLeft}
        />
      ))}
      <PhoneInput tooltipLeft={tooltipLeft} />
      {fields2.map((f) => (
        <Input
          key={f}
          name={f}
          type={f === "zipCode" ? "number" : undefined}
          label={t(`userInfo.info.${f}`)}
          error={touched?.[f] && !!errors?.[f]}
          tooltipLeft={tooltipLeft}
        />
      ))}
      {showNote && (
        <FormTextArea
          key={"note"}
          name={"note"}
          label={t(`userInfo.info.note`)}
          error={touched?.["note"] && !!errors?.["note"]}
          tooltipLeft={tooltipLeft}
          inputHeight={"100px"}
        />
      )}
    </FormGroup>
  );
};

export default UserInfoForm;
