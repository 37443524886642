import styled from "styled-components";

export const SearchContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const SearchInput = styled.input`
  height: 2.5rem;
  width: 100%;

  padding: 0.5rem 0.75rem 0.5rem 2.75rem;

  border: 1px solid var(--secondary-dark);
  border-radius: 3px;
  outline: none;
  color: var(--font-color);
  font-size: 1rem;

  transition: all 0.15s ease-in-out;

  &:focus {
    border-color: var(--primary-dark);
    box-shadow: 0 0 3px 0 var(--primary);
  }

  &:disabled {
    background-color: var(--secondary) !important;
    color: var(--gray-light) !important;
  }
`;

export const SearchIconWrapper = styled.div`
  position: absolute;
  padding: 0 1rem;
  top: 50%;
  transform: translateY(-50%);
`;
