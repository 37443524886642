import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useCallback, useEffect, useState } from "react";
import {
  FilterInput,
  FilterInputLabel,
  FilterInputWrapper,
  ResetIconWrapper,
} from "./styles";

interface Props {
  value?: string;
  onInputChange: (value?: string) => void;
  label?: string;
  labelWidth?: string;
  width?: number | string;
}

const FilterTextInput: FC<Props> = ({
  value,
  onInputChange,
  label,
  labelWidth,
  width,
}) => {
  const [inputValue, setInputValue] = useState("");

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setInputValue(value);
      onInputChange(value);
    },
    [onInputChange]
  );

  const handleReset = useCallback(() => {
    setInputValue("");
    onInputChange(undefined);
  }, [onInputChange]);

  useEffect(() => {
    setInputValue(value ?? "");
  }, [value]);

  return (
    <div style={{ width }}>
      {label && (
        <FilterInputLabel width={labelWidth} htmlFor={value}>
          {label}
        </FilterInputLabel>
      )}
      <FilterInputWrapper>
        <FilterInput type="text" value={inputValue} onChange={handleChange} />
        {inputValue && (
          <ResetIconWrapper onClick={handleReset}>
            <FontAwesomeIcon icon={faTimes} />
          </ResetIconWrapper>
        )}
      </FilterInputWrapper>
    </div>
  );
};

export default FilterTextInput;
