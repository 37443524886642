import { AxiosResponse } from "axios";
import {
  DocumentType,
  Document,
  Invoice,
  DocumentUploadData,
  DocumentTypeEditData,
} from "../models/document";
import api from "../utils/api";

export const getDocumentType = (
  documentTemplateId: string
): Promise<AxiosResponse<DocumentType>> =>
  api.get(`/api/DocumentTemplate/${documentTemplateId}`);

export const getAllDocumentTypes = (): Promise<AxiosResponse<DocumentType[]>> =>
  api.get(`/api/DocumentTemplate`);

export const uploadDocumentType = (
  data: DocumentUploadData
): Promise<AxiosResponse<string>> => api.post(`/api/DocumentTemplate`, data);

export const updateDocumentType = (
  documentTemplateId: string,
  data: DocumentTypeEditData
): Promise<AxiosResponse<boolean>> =>
  api.put(`/api/DocumentTemplate/${documentTemplateId}`, data);

export const deleteDocumentType = (
  documentTemplateId: string
): Promise<AxiosResponse<boolean>> =>
  api.delete(`/api/DocumentTemplate/${documentTemplateId}`);

export const downloadDocumentType = (
  documentTemplateId: string
): Promise<AxiosResponse<Blob>> =>
  api.get(`/api/DocumentTemplate/${documentTemplateId}/Data`, {
    headers: {
      "Content-Type": "application/pdf",
    },
    responseType: "blob",
  });

export const pairDocumentWithOrder = (
  documentId: string,
  orderId: string
): Promise<AxiosResponse<boolean>> =>
  api.post(`/api/Document/${documentId}/Order/${orderId}`);

export const pairDocumentWithCustomer = (
  documentId: string,
  customerId: string
): Promise<AxiosResponse<boolean>> =>
  api.post(`/api/Document/${documentId}/Customer/${customerId}`);

export const getUserInvoices = (
  customerId: string
): Promise<AxiosResponse<Invoice[]>> =>
  api.get(`/api/Customer/${customerId}/Invoice`);

export const payUserInvoice = (
  invoiceId: string
): Promise<AxiosResponse<boolean>> => api.post(`/api/Invoice/${invoiceId}/Pay`);

export const getUserDocuments = (
  customerId: string
): Promise<AxiosResponse<Document[]>> =>
  api.get(`/api/Customer/${customerId}/Document`);

export const downloadDocument = (
  documentId: string
): Promise<AxiosResponse<Blob>> =>
  api.get(`/api/Document/${documentId}`, {
    headers: {
      "Content-Type": "application/pdf",
    },
    responseType: "blob",
  });

const documentApi = {
  getDocumentType,
  getAllDocumentTypes,
  uploadDocumentType,
  updateDocumentType,
  deleteDocumentType,
  downloadDocumentType,
  pairDocumentWithOrder,
  pairDocumentWithCustomer,
  getUserInvoices,
  payUserInvoice,
  getUserDocuments,
  downloadDocument,
};

export default documentApi;
