import React, { FC, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import orderApi from "../../../../../api/order";
import { AdminRoles } from "../../../../../models/employee";
import { Order } from "../../../../../models/order";
import { Button } from "../../../../../styles/button";
import { Flex } from "../../../../../styles/flex";
import { H3 } from "../../../../../styles/title";
import { useUserHasRight } from "../../../../../utils/auth";
import { getErrorMessage } from "../../../../../utils/errors";
import { useStore } from "../../../../../utils/store";
import { SetState } from "../../../../../utils/types";
import BoxTypeNameLabel from "../../../../common/BoxTypeLabel/BoxTypeLabel";
import OrderDetailExpireModal from "../OrderDetailExpireModal";
import OrderCancelModal from "./OrderCancelModal";
import { OrderDetailCardHeaderContainer } from "./styles";

const OrderDetailCardHeader: FC<{
  setEditSubscription: SetState<boolean>;
  order: Order;
  reload: () => Promise<void>;
}> = ({ setEditSubscription, order, reload }) => {
  const { id, box, autoExtend } = order;
  const { t } = useTranslation();
  const history = useHistory();
  const [saving, setSaving] = useState(false);
  const [modal, setModal] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const user = useStore((store) => store.user);
  const { hasUserAllRights } = useUserHasRight();

  const canCancelOrder = useMemo(() => {
    const { invoices, documents, state, cards } = order;

    const hasState = state === "New" || state === "Scheduled";
    const hasNoSignedDocument = !documents.some(
      ({ signed, orderId }) => Boolean(signed) && orderId === order.id
    );
    const hasNoPaidInvoice = !invoices.some(({ paid }) => Boolean(paid));

    return (
      hasState && hasNoSignedDocument && hasNoPaidInvoice && cards.length === 0
    );
  }, [order]);

  const handleCancelOrder = useCallback(async () => {
    try {
      await orderApi.cancelOrder(id);
      toast.success(t("common.saveSuccess"));
      history.push("/");
    } catch (err) {
      toast.error(err?.response?.data?.AdditionalData ?? t("errors.saveError"));
    }
    setSaving(false);
  }, [history, id, t]);

  const handleFinish = async () => {
    setSaving(true);
    try {
      await orderApi.finishOrder(id);
      toast.success(t("common.saveSuccess"));
      reload();
    } catch (err) {
      toast.error(getErrorMessage(err, t("errors.saveError")));
    }
    setSaving(false);
  };

  return (
    <>
      <OrderDetailCardHeaderContainer>
        <div>
          <H3>
            <BoxTypeNameLabel type={box.type} belaBoxNo={order.belaBoxNo} />
          </H3>
        </div>
        <Flex style={{ columnGap: 4 }}>
          {user?.role === "Customer" &&
            order.state !== "Expired" &&
            (autoExtend ||
              order.state === "Ok" ||
              order.state === "Paused") && (
              <Button
                onClick={() => {
                  setEditSubscription(true);
                }}
              >
                {autoExtend
                  ? t("orders.detail.changeSubscription")
                  : t("common.extend")}
              </Button>
            )}
          {user &&
            AdminRoles.includes(user.role) &&
            hasUserAllRights(["finishOrders"]) &&
            (order.state === "New" || order.state === "Scheduled" ? (
              <Button onClick={handleFinish} disabled={saving}>
                {t("orders.detail.finishOrder")}
              </Button>
            ) : (
              (user.role === "Admin" || hasUserAllRights(["expireOrders"])) &&
              order.state !== "Expired" && (
                <Button onClick={() => setModal(true)} disabled={saving}>
                  {t("orders.detail.expireOrder")}
                </Button>
              )
            ))}
          {canCancelOrder &&
            (user?.isCustomer || hasUserAllRights(["cancelOrder"])) && (
              <>
                <OrderCancelModal
                  open={cancelModal}
                  setOpen={setCancelModal}
                  confirm={handleCancelOrder}
                />
                <Button
                  onClick={() => {
                    setCancelModal(true);
                  }}
                >
                  {t("orders.detail.cancelOrder")}
                </Button>
              </>
            )}
        </Flex>
      </OrderDetailCardHeaderContainer>
      {user && (
        <>
          <OrderDetailExpireModal
            open={modal}
            setOpen={setModal}
            id={id}
            reload={reload}
          />
        </>
      )}
    </>
  );
};

export default OrderDetailCardHeader;
