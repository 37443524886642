import React, { FC, useEffect } from "react";
import { Button } from "../../../../../styles/button";
import { Form, useFormikContext } from "formik";
import Input from "../../../../common/form/Input/Input";
import CustomModal from "../../../../common/CustomModal/CustomModal";
import { SetState } from "../../../../../utils/types";
import { useTranslation } from "react-i18next";

const OrderEditNameModalContent: FC<{
  open: boolean;
  setOpen: SetState<boolean>;
}> = ({ open, setOpen }) => {
  const { t } = useTranslation();
  const {
    isSubmitting,
    submitForm,
    errors,
    touched,
    resetForm,
  } = useFormikContext<{ name: string }>();

  useEffect(() => {
    if (open) resetForm();
  }, [open, resetForm]);

  return (
    <CustomModal
      open={open}
      setOpen={setOpen}
      title={t("orders.detail.editNameModal.title")}
      actions={
        <>
          <Button
            type="button"
            ver="secondary"
            disabled={isSubmitting}
            onClick={() => setOpen(false)}
          >
            {t("common.cancel")}
          </Button>
          <Button disabled={isSubmitting} onClick={submitForm}>
            {t("common.save")}
          </Button>
        </>
      }
    >
      <Form>
        <Input
          name="name"
          label={t(`orders.detail.editNameModal.name`)}
          error={touched.name && !!errors.name}
        />
      </Form>
    </CustomModal>
  );
};

export default OrderEditNameModalContent;
