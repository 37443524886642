import styled from "styled-components";
import { QueueItemState } from "../../../models/queue";
import { IconButton } from "../../../styles/button";
import { COLORS } from "../../../utils/constants";
import { fadeIn } from "../../../utils/styles";

export const QueueRowDequeue = styled(IconButton)`
  display: none;
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  animation: 0.3s ease-in-out ${fadeIn};
`;

export const QueueRowContainer = styled.div<{ state: QueueItemState }>`
  width: 100%;
  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 1.5rem 2.25rem;
  background-color: ${({ state }) =>
    state === QueueItemState.Inside
      ? COLORS.primary
      : state === QueueItemState.Go
      ? COLORS.green
      : COLORS.whitesmoke};
  box-shadow: 0 4px 16px -4px ${COLORS.grayLight};
  border-radius: 1rem;

  animation: 0.3s ease-in-out ${fadeIn};

  &,
  svg {
    color: ${({ state }) =>
      state === QueueItemState.Waiting ? COLORS.fontColor : COLORS.white};
  }

  &:hover {
    ${QueueRowDequeue} {
      display: block;
    }
  }
`;

export const QueueRowInfo = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: space-between;
`;

export const QueueRowNumber = styled.div`
  font-size: 6rem;
  font-weight: bold;
  letter-spacing: 0.4rem;
  color: ${COLORS.black};
`;

export const QueueRowStateContainer = styled.div<{ inside: boolean }>`
  font-size: ${({ inside }) => (inside ? "6rem" : "4rem")};
  font-weight: bold;
  color: ${COLORS.black};
`;

export const QueueRowDoor = styled.div`
  font-size: 6rem;
  font-weight: bold;
  line-height: 1;
  color: ${COLORS.black};
`;
