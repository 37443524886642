import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { COLORS } from "../../../../utils/constants";
import { Invoice } from "../../../../models/document";
import { InvoiceNameContainer } from "./styles";

const InvoiceName: FC<{ invoice: Invoice }> = ({ invoice }) => {
  return (
    <InvoiceNameContainer>
      <a href={invoice.url} target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faFileAlt} color={COLORS.primary} size="lg" />
        {invoice.apiNumber}
      </a>
    </InvoiceNameContainer>
  );
};

export default InvoiceName;
