import { Formik } from "formik";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import boxApi from "../../../../api/box";
import { BoxType, BoxTypeForm, BoxUpdate } from "../../../../models/box";
import { Divider } from "../../../../styles/divider";
import { ImgResponsive } from "../../../../styles/img";
import { getErrorMessage } from "../../../../utils/errors";
import { getBoxImg } from "../../../../utils/images";
import AdminBoxesSettingsDetailInfoForm from "./AdminBoxesSettingsDetailInfoForm";
import AdminBoxesSettingsDetailInfoState from "./AdminBoxesSettingsDetailInfoState";
import AdminBoxesSettingsDetailInfoTotal from "./AdminBoxesSettingsDetailInfoTotal";
import {
  AdminBoxesSettingsDetailInfoFormH3,
  AdminBoxesSettingsDetailInfoFormWrapper,
  AdminBoxesSettingsDetailInfoImage,
  AdminBoxesSettingsDetailInfoMain,
} from "./styles";

const defaultValues: BoxTypeForm = {
  width: "",
  length: "",
  height: "",
  volume: "",
  loadCapacity: "",
};

const AdminBoxesSettingsDetailInfo: FC<{ data: BoxType }> = ({ data }) => {
  const { t } = useTranslation();
  const [initialValues, setInitialValues] =
    useState<BoxTypeForm>(defaultValues);

  useEffect(() => {
    const [width, length, height] = data.dimensions.slice(0, -3).split(" x ");
    const formValues: BoxTypeForm = {
      width,
      length,
      height,
      volume: data.volume.slice(0, -4),
      loadCapacity: data.loadCapacity.slice(0, -3),
    };
    setInitialValues(formValues);
  }, [data]);

  const handleSubmit = async (values: BoxTypeForm) => {
    const updatedData: BoxUpdate = {
      dimensions: `${values.width} x ${values.length} x ${values.height} cm`,
      volume: `${values.volume} cm\u00b3`,
      loadCapacity: `${values.loadCapacity} kg`,
    };

    try {
      await boxApi.updateBox(data.id, updatedData);
      toast.success(t("common.saveSuccess"));
    } catch (err) {
      toast.error(getErrorMessage(err, t("errors.saveError")));
    }
  };

  return (
    <AdminBoxesSettingsDetailInfoMain>
      <AdminBoxesSettingsDetailInfoImage>
        <div>
          <ImgResponsive src={getBoxImg(data.type)} />
        </div>
      </AdminBoxesSettingsDetailInfoImage>

      <AdminBoxesSettingsDetailInfoFormWrapper>
        <AdminBoxesSettingsDetailInfoFormH3>
          {t("common.box")}&nbsp;{data.type}
        </AdminBoxesSettingsDetailInfoFormH3>
        <AdminBoxesSettingsDetailInfoState
          boxId={data.id}
          defaultState={data.isActive}
        />
        <Divider my={"0.75rem"} />
        <AdminBoxesSettingsDetailInfoTotal
          defaultTotalCount={data.totalCount}
        />
        <Divider my={"0.75rem"} />
        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={handleSubmit}
        >
          <AdminBoxesSettingsDetailInfoForm />
        </Formik>
      </AdminBoxesSettingsDetailInfoFormWrapper>
    </AdminBoxesSettingsDetailInfoMain>
  );
};

export default AdminBoxesSettingsDetailInfo;
