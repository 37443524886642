import styled from "styled-components";
import { PrimaryText } from "../../../../../styles/text";
import { size } from "../../../../../utils/styles";
import { OrderDetailCardParameter } from "../OrderDetailCardParameters/styles";
import { IconButton } from "../../../../../styles/button";

export const OrderDetailCardBoxContainer = styled.div`
  width: 100%;

  > *:not(:last-child) {
    margin-bottom: 1.5rem;
  }
`;

export const OrderDetailCardBoxList = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  grid-column-gap: 2rem;
  grid-row-gap: 0.25rem;
  align-items: center;

  ${PrimaryText} {
    margin-top: 0.25rem;
  }

  ${IconButton} {
    margin-left: 0.5rem;
  }

  @media screen and (min-width: ${size.mobile}) {
    grid-template-columns: 12rem 1fr;
    grid-row-gap: 0.5rem;

    ${PrimaryText} {
      margin-top: 0;
    }

    ${OrderDetailCardParameter} {
      text-align: end;
    }
  }
`;

export const OrderDetailCardBoxLease = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const OrderDetailCardBoxAutoRenew = styled.div`
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-between;

  svg {
    margin-right: 0.25rem;
  }
`;
