import React, { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import authApi from "../../api/auth";
import queueApi from "../../api/queue";
import { QueueItem, QueueItemState } from "../../models/queue";
import { getErrorMessage } from "../../utils/errors";
import { useStore } from "../../utils/store";
import QueueRow from "./QueueRow/QueueRow";
import {
  DashboardButton,
  LogoutButton,
  QueueContainer,
  QueueItemsNotWaiting,
  QueueItemsWaiting,
} from "./styles";

const Queue: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [user, setUser] = useStore((store) => [store.user, store.setUser]);
  const [queue, setQueue] = useState<QueueItem[]>();
  const [showActions, setShowActions] = useState(false);
  const [mouseTimeout, setMouseTimeout] = useState<number | null>(null);

  const handleLogout = () => {
    setUser(null);
    authApi.logout();
  };

  const handleMouseMove = () => {
    if (mouseTimeout) {
      clearTimeout(mouseTimeout);
    }
    setShowActions(true);
    const newMouseTimeout = window.setTimeout(() => {
      setShowActions(false);
    }, 5000);
    setMouseTimeout(newMouseTimeout);
  };

  const getItem = (state: QueueItemState, door: "A" | "B") => {
    const item = queue?.find(
      (qi) => qi.state === state && qi.doorNumber === door
    );
    return item ? (
      <QueueRow key={item.id} item={item} dequeue={dequeue} />
    ) : (
      <div />
    );
  };

  const loadItems = useCallback(async () => {
    try {
      const response = await queueApi.getQueue();
      setQueue(response.data);
    } catch (err) {
      toast.error(getErrorMessage(err, t("errors.loadError")));
    }
  }, [t]);

  const dequeue = async (id: string) => {
    try {
      await queueApi.dequeue(id);
      await loadItems();
    } catch (err) {
      toast.error(t("errors.dequeue"));
    }
  };

  useEffect(() => {
    let interval = setInterval(() => loadItems(), 1500);

    return () => {
      clearInterval(interval);
    };
  }, [loadItems]);

  return (
    <QueueContainer onMouseMove={handleMouseMove}>
      {showActions && (
        <>
          {user?.role !== "QueueManager" && (
            <DashboardButton onClick={() => history.push("/")}>
              {t("common.home")}
            </DashboardButton>
          )}
          <LogoutButton ver="secondary" onClick={handleLogout}>
            {t("common.logout")}
          </LogoutButton>
        </>
      )}
      {queue && queue?.length !== 0 && (
        <>
          {queue.some((qi) => qi.state === QueueItemState.Inside) && (
            <QueueItemsNotWaiting>
              {getItem(QueueItemState.Inside, "A")}
              {getItem(QueueItemState.Inside, "B")}
            </QueueItemsNotWaiting>
          )}
          {queue.some((qi) => qi.state === QueueItemState.Go) && (
            <QueueItemsNotWaiting>
              {getItem(QueueItemState.Go, "A")}
              {getItem(QueueItemState.Go, "B")}
            </QueueItemsNotWaiting>
          )}
          <QueueItemsWaiting>
            {queue
              .filter((q) => q.state === QueueItemState.Waiting)
              .map((item) => (
                <QueueRow key={item.id} item={item} dequeue={dequeue} />
              ))}
          </QueueItemsWaiting>
        </>
      )}
    </QueueContainer>
  );
};

export default Queue;
