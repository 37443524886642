import React, { FC } from "react";
import {
  SidebarHeaderContainer,
  SidebarHeaderUsername,
  SidebarLogout,
  SidebarLogoutButton,
} from "../../../../../styles/sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import authApi from "../../../../../api/auth";
import { useStore } from "../../../../../utils/store";

const SidebarHeader: FC<{ name: string; color?: string }> = ({
  name,
  color,
}) => {
  const { t } = useTranslation();
  const setUser = useStore((store) => store.setUser);

  const handleLogout = () => {
    setUser(null);
    authApi.logout();
  };

  return (
    <SidebarHeaderContainer color={color}>
      <SidebarHeaderUsername>
        <div>
          <FontAwesomeIcon icon={faUserCircle} color="var(--white)" size="lg" />
        </div>
        <div>{name}</div>
      </SidebarHeaderUsername>
      <SidebarLogout>
        <SidebarLogoutButton $small ver="white" onClick={handleLogout}>
          {t("common.logout")}
        </SidebarLogoutButton>
        <FontAwesomeIcon
          icon={faSignOutAlt}
          color="var(--white)"
          size="lg"
          onClick={handleLogout}
        />
      </SidebarLogout>
    </SidebarHeaderContainer>
  );
};

export default SidebarHeader;
