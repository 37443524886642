import React, { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import cardApi from "../../../../api/card";
import { Card } from "../../../../models/card";
import { FadeInBottom } from "../../../../styles/fadeIn";
import { getErrorMessage } from "../../../../utils/errors";
import Loader from "../../../common/Loader/Loader";
import CardsTable from "../../../user/Cards/CardsTable/CardsTable";
import { AdminClientsDetailTabsLoader } from "./styles";

const AdminClientsDetailCards: FC<{ reload: boolean }> = ({ reload }) => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [data, setData] = useState<Card[]>([]);
  const [loading, setLoading] = useState(true);

  const loadData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await cardApi.getUserCards(id);
      setData(response.data);
    } catch (err) {
      toast.error(getErrorMessage(err, t("errors.loadError")));
    }
    setLoading(false);
  }, [id, t]);

  useEffect(() => {
    loadData();
  }, [loadData, reload]);

  return loading ? (
    <AdminClientsDetailTabsLoader>
      <Loader />
    </AdminClientsDetailTabsLoader>
  ) : (
    <FadeInBottom>
      <CardsTable data={data} onSuccess={loadData} admin />
    </FadeInBottom>
  );
};

export default AdminClientsDetailCards;
