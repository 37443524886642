import styled from "styled-components";
import { H2 } from "./title";
import { Button } from "./button";
import { size } from "../utils/styles";

// everything inside <td> should be wrapped around by a <div> to prevent collapsing
export const Table = styled.table<{ size?: "small" | "middle" }>`
  width: 100%;
  border-spacing: 0;

  tr {
    transition: background-color 0.2s ease-in-out;
  }

  thead {
    tr {
      background-color: var(--secondary);
    }
  }

  tbody {
    tr {
      background-color: var(--white);

      &:hover {
        background-color: var(--whitesmoke);
      }
    }
  }

  th {
    font-weight: normal;
    white-space: pre;
    width: 1%;

    &:first-child {
      font-weight: bold;
    }
  }

  td {
    border-bottom: 2px solid var(--secondary);

    > div {
      width: max-content;
    }
  }

  td,
  th {
    text-align: start;
    padding: ${({ size }) => {
      switch (size) {
        case "small":
          return "0.4rem 0.2rem";
        default:
          return "0.75rem 1rem";
      }
    }};
  }

  th {
    padding: ${({ size }) => {
      switch (size) {
        case "small":
          return "0.4rem";
        default:
          return "1rem";
      }
    }};
  }
`;

export const TableHeaderActions = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 0.5rem;
  align-items: flex-end;

  margin-bottom: 0.8rem;

  > div {
    display: flex;
    align-items: center;
  }

  ${H2} {
    margin-bottom: 0;
    margin-right: 1rem;
  }

  ${Button}:not(:last-child) {
    margin-right: 1rem;
  }

  @media screen and (min-width: ${size.tablet}) {
    grid-template-columns: 1fr auto;
    grid-template-rows: 1fr;
  }
`;
export const TableFlat = styled.table`
  width: min-content;
  border-spacing: 0;

  th {
    font-weight: bold;
    white-space: pre;
    width: 1%;
  }

  td {
    > div {
      width: max-content;
    }
  }

  td,
  th {
    text-align: start;
    padding: 0.5rem 1.5rem 0.5rem 0;
  }
`;

export const NoTableData = styled.div`
  width: 100%;
  padding-top: 0.5rem;
  text-align: center;
  font-size: 1.1rem;
`;

export const TableLabels = styled.div`
  > * {
    display: inline-block;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    background-color: var(--secondary);

    &:not(:last-child) {
      margin-right: 0.5rem;
    }
  }
`;
