import React, { FC } from "react";

const UserInfoNote: FC<{ note?: string }> = ({ note }) => {
  if (!note) {
    return null;
  }

  return (
    <div>
      <div>Poznámka</div>
      <div style={{ maxHeight: 200, overflowY: "auto", fontWeight: "bold" }}>
        {note}
      </div>
    </div>
  );
};

export default UserInfoNote;
