import styled from "styled-components";
import { size } from "../../../../utils/styles";

export const AdminPromoCodesFilters = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media screen and (min-width: ${size.tablet}) {
    flex-direction: row;
    align-items: center;
  }
`;

export const AdminPromoCodesFilterElements = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 0.8rem;

  @media screen and (min-width: ${size.tablet}) {
    flex-direction: row;
    margin-top: 0;
  }
`;

export const AdminPromoCodesFilterElement = styled.div`
  width: 100%;
  margin-bottom: 0.8rem;

  @media screen and (min-width: ${size.tablet}) {
    max-width: 15rem;
  }
`;
