import { addMinutes } from "date-fns";
import { Form, Formik } from "formik";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import authApi from "../../../api/auth";
import storageApi from "../../../api/storage";
import { LoginData } from "../../../models/auth";
import { FormGroup } from "../../../styles/form";
import { H1 } from "../../../styles/title";
import { useStore } from "../../../utils/store";
import CustomModal from "../../common/CustomModal/CustomModal";
import Input from "../../common/form/Input/Input";
import PageNoSidebar from "../../common/layout/Page/PageNoSidebar";
import {
  LoginButton,
  LoginCard,
  LoginCardActions,
  LoginContainer,
} from "./styles";

const initialValues: LoginData = {
  email: "",
  password: "",
};

const Login: FC = () => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const history = useHistory();
  const [modalOpen, setModalOpen] = useState(false);
  const store = useStore();

  const handleSubmit = async (data: LoginData) => {
    try {
      const response = await authApi.login(data);

      storageApi.setToken(response.data.token);
      storageApi.setIdleMinutes(response.data.userMaxIdleMinutes);
      storageApi.setTokenRefreshTimeout(
        addMinutes(new Date(), response.data.userMaxIdleMinutes)
      );
      storageApi.setTokenTimeout(
        addMinutes(new Date(), response.data.tokenValidMinutes)
      );

      const user = {
        ...response.data.userData,
        rights: response.data.rights,
      };
      storageApi.setCurrentUser(user);
      store.setUser(user);
      history.push("/");
    } catch (err) {
      toast.error(t("errors.login"));
    }
  };

  useEffect(() => {
    store.setUser(null);
    localStorage.clear();

    if (search.includes("inactivity")) {
      setModalOpen(true);
      history.replace(history.location.pathname);
    }
    // don't fire on `store` or `history` change
    // eslint-disable-next-line
  }, []);

  return (
    <PageNoSidebar>
      <CustomModal
        open={modalOpen}
        setOpen={setModalOpen}
        title={t("login.logoutModal.title")}
      >
        {t("login.logoutModal.message")}
      </CustomModal>
      <LoginContainer>
        <H1 bold uppercase>
          {t("login.title")}
        </H1>
        <span>{t("login.subtitle")}</span>
        <LoginCard>
          <Formik
            initialValues={initialValues}
            validationSchema={Yup.object({
              email: Yup.string().required(t("errors.required")),
              password: Yup.string().required(t("errors.required")),
            })}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, isSubmitting }) => (
              <Form>
                <FormGroup>
                  <Input
                    name="email"
                    label={t("login.email")}
                    error={touched.email && !!errors.email}
                  />
                  <Input
                    type="password"
                    name="password"
                    label={t("login.password")}
                    error={touched.password && !!errors.password}
                  />
                </FormGroup>
                <LoginButton type="submit" disabled={isSubmitting}>
                  {t("common.login")}
                </LoginButton>
              </Form>
            )}
          </Formik>
          <LoginCardActions>
            <Link to="/order">{t("login.firstVisit")}</Link>
            <Link to="/forgotten-password">{t("login.forgottenPassword")}</Link>
          </LoginCardActions>
        </LoginCard>
      </LoginContainer>
    </PageNoSidebar>
  );
};

export default Login;
