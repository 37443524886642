import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { H2 } from "../../../../styles/title";
import { formatDate } from "../../../../utils/formats";
import { OverflowX } from "../../../../utils/styles";
import { PageSection } from "../../../../styles/layout";
import { OrdersHistoryTable } from "./styles";
import { Order } from "../../../../models/order";
import OrderBoxData from "../OrderBoxData/OrderBoxData";
import { NoTableData } from "../../../../styles/table";

const OrdersHistory: FC<{ data: Order[] }> = ({ data }) => {
  const { t } = useTranslation();

  const tableCols = (
    <tr>
      {["orders.history.duration", "orders.history.box"].map((col, i) => (
        <th key={col} colSpan={i === 1 ? 4 : undefined}>
          {t(col)}
        </th>
      ))}
    </tr>
  );

  const tableData = useMemo(
    () =>
      data.map((row) => (
        <tr key={row.id}>
          <td>
            <div>
              {formatDate(row.dateFrom)} - {formatDate(row.dateTo)}
            </div>
          </td>
          <OrderBoxData order={row} />
        </tr>
      )),
    [data]
  );

  return (
    <PageSection>
      <H2>{t("orders.history.title")}</H2>
      {data.length ? (
        <OverflowX>
          <OrdersHistoryTable>
            <thead>{tableCols}</thead>
            <tbody>{tableData}</tbody>
          </OrdersHistoryTable>
        </OverflowX>
      ) : (
        <NoTableData>{t("orders.history.noData")}</NoTableData>
      )}
    </PageSection>
  );
};

export default OrdersHistory;
