import { Formik } from "formik";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import additionalServicesApi from "../../../../api/additionalServices";
import boxApi from "../../../../api/box";
import orderApi from "../../../../api/order";
import { AdditionalService } from "../../../../models/additionalServices";
import { BoxType } from "../../../../models/box";
import { Order, OrderEditParametersData } from "../../../../models/order";
import { getErrorMessage } from "../../../../utils/errors";
import { SetState } from "../../../../utils/types";
import OrderEditParametersModalContent from "./OrderEditParametersModalContent";

const OrderEditParametersModal: FC<{
  open: boolean;
  setOpen: SetState<boolean>;
  order: Order;
  onSuccess: () => Promise<void>;
}> = ({ open, setOpen, order, onSuccess }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [boxType, setBoxType] = useState<BoxType>();
  const [additionalServices, setAdditionalServices] = useState<
    AdditionalService[]
  >([]);

  const initialValues: OrderEditParametersData = {
    smsCheck: order.smsCheck,
    fingerprint: order.fingerprint,
    paymentType: "Card",
    additionalServices: order.additionalServices
      .filter((x) => x.inOrder)
      .map((x) => x.id),
  };

  const handleSubmit = async (data: OrderEditParametersData) => {
    try {
      const response = await orderApi.updateOrderParams(data, order.id);
      if (data.paymentType === "Card" && response.data.url) {
        window.location.href = response.data.url;
      }
      toast.success(t("order.editSuccess"));
      await onSuccess();
      setOpen(false);
    } catch (err) {
      toast.error(t("errors.createOrder"));
    }
    setLoading(false);
  };

  useEffect(() => {
    if (open) {
      (async () => {
        setLoading(true);
        try {
          const boxResponse = await boxApi.getBox(order.box.id, order.id);
          const additionalServicesResponse =
            await additionalServicesApi.getAll();
          setBoxType(boxResponse.data);
          setAdditionalServices(additionalServicesResponse.data);
        } catch (err) {
          toast.error(getErrorMessage(err, t("errors.loadError")));
        }
        setLoading(false);
      })();
    }
  }, [t, open, order.box.id, order.id]);

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      <OrderEditParametersModalContent
        open={open}
        setOpen={setOpen}
        loading={loading}
        boxType={boxType}
        additionalServices={additionalServices}
      />
    </Formik>
  );
};

export default OrderEditParametersModal;
