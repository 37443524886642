import { faCheck, faPause, faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChartData } from "chart.js";
import React, { FC, useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import boxApi from "../../../../api/box";
import { H4 } from "../../../../styles/title";
import { COLORS } from "../../../../utils/constants";
import { getErrorMessage } from "../../../../utils/errors";
import Loader from "../../../common/Loader/Loader";
import {
  CardBoxOverviewChart,
  CardBoxOverviewCircle,
  CardBoxOverviewContent,
  CardBoxOverviewLabel,
  CardBoxOverviewLegend,
  CardBoxOverviewMain,
} from "./styles";

const options = {
  responsive: true,
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
};

const reducer = (acc: number, val: number) => acc + val;

const CardBoxOverview: FC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const [chartData, setChartData] = useState<ChartData>({});

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const response = await boxApi.getAllBoxes();
        const allBoxesData = response.data;
        const reservedBoxesTotal = allBoxesData
          .map((box) => box.usedReservedCount ?? 0)
          .reduce(reducer);
        const activeBoxesTotal = allBoxesData
          .map((box) => box.usedActiveCount ?? 0)
          .reduce(reducer);
        const pausedBoxesTotal = allBoxesData
          .map((box) => box.usedPausedCount ?? 0)
          .reduce(reducer);
        const availableBoxesTotal = allBoxesData
          .map((box) => box.availableCount ?? 0)
          .reduce(reducer);
        const data = {
          labels: [
            t("adminDashboard.boxOverview.reservedBox"),
            t("adminDashboard.boxOverview.activeBox"),
            t("adminDashboard.boxOverview.pausedBox"),
            t("adminDashboard.boxOverview.availableBox"),
          ],
          datasets: [
            {
              data: [
                reservedBoxesTotal,
                activeBoxesTotal,
                pausedBoxesTotal,
                availableBoxesTotal,
              ],
              backgroundColor: [
                COLORS.orange,
                COLORS.green,
                COLORS.red,
                COLORS.primary,
              ],
            },
          ],
        };

        setChartData(data);
      } catch (err) {
        toast.error(getErrorMessage(err, t("errors.loadError")));
      }
      setLoading(false);
    })();
  }, [t]);

  return (
    <CardBoxOverviewMain>
      <H4>{t("adminDashboard.boxOverview.title")}</H4>
      <CardBoxOverviewContent>
        <CardBoxOverviewLegend>
          <CardBoxOverviewLabel>
            <CardBoxOverviewCircle color={COLORS.orange}>
              <FontAwesomeIcon icon={faStar} size={"xs"} />
            </CardBoxOverviewCircle>
            <div>{t("adminDashboard.boxOverview.reservedBox")}</div>
          </CardBoxOverviewLabel>
          <CardBoxOverviewLabel>
            <CardBoxOverviewCircle color={COLORS.green}>
              <FontAwesomeIcon icon={faCheck} size={"xs"} />
            </CardBoxOverviewCircle>
            <div>{t("adminDashboard.boxOverview.activeBox")}</div>
          </CardBoxOverviewLabel>
          <CardBoxOverviewLabel>
            <CardBoxOverviewCircle color={COLORS.red}>
              <FontAwesomeIcon icon={faPause} size={"xs"} />
            </CardBoxOverviewCircle>
            <div>{t("adminDashboard.boxOverview.pausedBox")}</div>
          </CardBoxOverviewLabel>
          <CardBoxOverviewLabel>
            <CardBoxOverviewCircle color={COLORS.primary} />
            <div>{t("adminDashboard.boxOverview.availableBox")}</div>
          </CardBoxOverviewLabel>
        </CardBoxOverviewLegend>
        <CardBoxOverviewChart>
          {loading ? (
            <Loader />
          ) : (
            <Pie data={chartData} width={100} height={100} options={options} />
          )}
        </CardBoxOverviewChart>
      </CardBoxOverviewContent>
    </CardBoxOverviewMain>
  );
};

export default CardBoxOverview;
