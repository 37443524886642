import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { debounce } from "lodash";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import promoCodesApi from "../../../../api/promoCode";
import { Paged } from "../../../../models/common";
import { PromoCodeList } from "../../../../models/promoCode";
import { Button } from "../../../../styles/button";
import { FadeInBottom } from "../../../../styles/fadeIn";
import { PageSection } from "../../../../styles/layout";
import { TableHeaderActions } from "../../../../styles/table";
import { H2 } from "../../../../styles/title";
import { useUserHasRight } from "../../../../utils/auth";
import {
  COLORS,
  DEBOUNCE_TIMEOUT_MS,
  DEFAULT_PAGE_SIZE,
} from "../../../../utils/constants";
import { getErrorMessage } from "../../../../utils/errors";
import { useAdminPromoCodesFilterStore } from "../../../../utils/store";
import Search from "../../../common/form/Search/Search";
import Loader from "../../../common/Loader/Loader";
import Pagination from "../../../common/Pagination/Pagination";
import AdminPromoCodesTable from "../AdminPromoCodesTable/AdminPromoCodesTable";
import {
  AdminPromoCodesFilterElement,
  AdminPromoCodesFilterElements,
  AdminPromoCodesFilters,
} from "./styles";

const AdminPromoCodesContent: FC = () => {
  const { t } = useTranslation();
  const { hasUserAllRights } = useUserHasRight();
  const canWrite = hasUserAllRights(["writePromoCodes"]);

  const [loading, setLoading] = useState<boolean>(true);
  const [promoCodesData, setPromoCodesData] = useState<Paged<PromoCodeList>>({
    count: 0,
    data: [],
  });
  const { filters, setFilters } = useAdminPromoCodesFilterStore();

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const response = await promoCodesApi.getPromoCodes({
          searchText: filters.searchText,
          pageSize: DEFAULT_PAGE_SIZE,
          page: filters.page,
        });
        setPromoCodesData(response.data);
      } catch (err) {
        toast.error(getErrorMessage(err, t("errors.loadError")));
      }
      setLoading(false);
    })();
  }, [t, filters.searchText, filters.page]);

  return (
    <FadeInBottom>
      <PageSection>
        <TableHeaderActions>
          <div>
            <H2>{t("adminPromoCodes.title")}</H2>
          </div>
          <div>
            {canWrite && (
              <Button as={Link} to={"/admin/settings/promo-code/new"}>
                <FontAwesomeIcon icon={faPlus} color={COLORS.white} />
                {t("adminPromoCodes.new")}
              </Button>
            )}
          </div>
        </TableHeaderActions>
        <AdminPromoCodesFilters>
          <div>
            <b>
              {!loading &&
                `${promoCodesData.count} ${t(
                  "adminPromoCodes.countTextPlural"
                )}`}
            </b>
          </div>
          <AdminPromoCodesFilterElements>
            <AdminPromoCodesFilterElement>
              <Search
                name={"allCodesSearch"}
                onInputChange={debounce(
                  (event) =>
                    setFilters({ searchText: event.target.value, page: 1 }),
                  DEBOUNCE_TIMEOUT_MS
                )}
              />
            </AdminPromoCodesFilterElement>
          </AdminPromoCodesFilterElements>
        </AdminPromoCodesFilters>
        {loading ? (
          <Loader />
        ) : (
          <FadeInBottom>
            <AdminPromoCodesTable
              data={promoCodesData.data}
              canWrite={canWrite}
            />
            <Pagination
              initialPage={filters.page}
              totalCount={promoCodesData.count}
              onPageChange={(n: number) => setFilters({ ...filters, page: n })}
            />
          </FadeInBottom>
        )}
      </PageSection>
    </FadeInBottom>
  );
};

export default AdminPromoCodesContent;
