import React, { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../../styles/button";
import { SetState } from "../../../../../utils/types";
import CustomModal from "../../../../common/CustomModal/CustomModal";

const OrderCancelModal: FC<{
  open: boolean;
  setOpen: SetState<boolean>;
  confirm: () => Promise<void>;
}> = ({ open, setOpen, confirm }) => {
  const { t } = useTranslation();
  const [isCancelling, setIsCancelling] = useState(false);

  const handleConfirm = useCallback(async () => {
    setIsCancelling(true);
    await confirm();
    setIsCancelling(false);
    setOpen(false);
  }, [confirm, setOpen]);

  return (
    <CustomModal
      title={t("orders.detail.cancelOrder")}
      open={open}
      setOpen={setOpen}
      actions={
        <>
          <Button
            ver="secondary"
            disabled={isCancelling}
            onClick={() => setOpen(false)}
          >
            {t("common.no")}
          </Button>
          <Button disabled={isCancelling} onClick={handleConfirm}>
            {t("common.yes")}
          </Button>
        </>
      }
    >
      {t("orders.detail.cancelOrderMessage")}
    </CustomModal>
  );
};

export default OrderCancelModal;
