import React, { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import scheduledTasksApi from "../../../api/scheduledTasks";
import { ScheduledTask } from "../../../models/scheduledTask";
import { FadeInBottom } from "../../../styles/fadeIn";
import { PageSection } from "../../../styles/layout";
import { H2 } from "../../../styles/title";
import Page from "../../common/layout/Page/Page";
import Loader from "../../common/Loader/Loader";
import AdminSidebar from "../AdminSidebar/AdminSidebar";
import AdminScheduledTasksTable from "./AdminScheduledTasksTable/AdminScheduledTasksTable";

const AdminScheduledTasks: FC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ScheduledTask[]>([]);

  const loadData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await scheduledTasksApi.getScheduledTasks();
      setData(response.data);
    } catch (err) {
      toast.error(t("errors.scheduledTasks"));
    }
    setLoading(false);
  }, [t]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <Page sidebar={<AdminSidebar />}>
      {loading ? (
        <Loader />
      ) : (
        <FadeInBottom>
          <H2>{t("adminScheduledTasks.title")}</H2>
          <PageSection>
            <AdminScheduledTasksTable data={data} reload={loadData} />
          </PageSection>
        </FadeInBottom>
      )}
    </Page>
  );
};

export default AdminScheduledTasks;
