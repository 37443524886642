import { isSameDay, isSameHour, isSameMinute } from "date-fns";
import React, { FC } from "react";
import { CalendarCustomerDay } from "../../../../models/calendar";
import CalendarDay from "../../../common/calendar/CalendarDay/CalendarDay";
import CustomerCalendarCell from "../CustomerCalendarCell/CustomerCalendarCell";
import { DateAndLabel } from "../utils";

const CustomerCalendarDay: FC<{
  day: CalendarCustomerDay;
  labels: Date[];
  reservationDate?: DateAndLabel;
  setReservationDate: (dateAndLabel: DateAndLabel) => void;
}> = ({ day, labels, reservationDate, setReservationDate }) => {
  const date = new Date(day.day);

  return (
    <CalendarDay
      date={date}
      cells={day.times.map((time, i) => (
        <CustomerCalendarCell
          key={i}
          timeState={time}
          isReserved={
            !!reservationDate &&
            isSameDay(reservationDate.date, date) &&
            isSameHour(reservationDate.timeLabel, labels[i]) &&
            isSameMinute(reservationDate.timeLabel, labels[i])
          }
          onClickReservation={() =>
            setReservationDate({
              date,
              timeLabel: labels[i],
            })
          }
        />
      ))}
    />
  );
};

export default CustomerCalendarDay;
