import React, { FC } from "react";
import IdleTimer from "react-idle-timer";
import storageApi from "../../../api/storage";
import { DEFAULT_IDLE_TIMEOUT } from "../../../utils/constants";
import authApi from "../../../api/auth";
import { useStore } from "../../../utils/store";
import { useLocation } from "react-router-dom";

const IdleHandler: FC = () => {
  const [user, setUser] = useStore((store) => [store.user, store.setUser]);
  const location = useLocation();

  const getTimeout = (): number => {
    const timeout = storageApi.getIdleMinutes();
    return timeout ? timeout * 60 * 1000 : DEFAULT_IDLE_TIMEOUT;
  };

  const handleIdle = () => {
    if (
      !!user &&
      location.pathname !== "/queue" &&
      location.pathname !== "/tablet"
    ) {
      setUser(null);
      authApi.logout(true);
    }
  };

  return <IdleTimer timeout={getTimeout()} onIdle={handleIdle} />;
};

export default IdleHandler;
