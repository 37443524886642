import React, { FC } from "react";
import { LoaderContainer } from "./styles";
import Spinner from "react-loader-spinner";
import { COLORS } from "../../../utils/constants";

const Loader: FC<{ size?: number }> = ({ size }) => {
  return (
    <LoaderContainer>
      <Spinner
        type="TailSpin"
        color={COLORS.primary}
        width={size || 48}
        height={size || 48}
      />
    </LoaderContainer>
  );
};

export default Loader;
