import React, { FC } from "react";
import Header from "../Header/Header";
import { PageContainerNoSidebar } from "./styles";

const PageNoSidebar: FC = ({ children }) => {
  return (
    <>
      <Header />
      <PageContainerNoSidebar>{children}</PageContainerNoSidebar>
    </>
  );
};

export default PageNoSidebar;
