import { faCalendarAlt, faCheck } from "@fortawesome/free-solid-svg-icons";
import React, { FC } from "react";
import {
  CalendarCellEventAvailable,
  CalendarCellEventIcon,
  CalendarCellEventText,
  CalendarCellEventUnavailable,
} from "../../../../styles/calendar";
import { CalendarAdminTimeState } from "../../../../utils/enums";

const AdminCellEvent: FC<{
  timeState: CalendarAdminTimeState;
  count: number;
  onCellEventClick?: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
}> = ({ timeState, count, onCellEventClick }) => {
  if (count === 0) return null;
  switch (timeState) {
    case CalendarAdminTimeState.Available:
      return (
        <CalendarCellEventAvailable onClick={onCellEventClick}>
          <CalendarCellEventText>
            <CalendarCellEventIcon icon={faCheck} size={"xs"} />
            {count}
          </CalendarCellEventText>
        </CalendarCellEventAvailable>
      );
    case CalendarAdminTimeState.Unavailable:
      return (
        <CalendarCellEventUnavailable onClick={onCellEventClick}>
          <CalendarCellEventText>
            <CalendarCellEventIcon icon={faCalendarAlt} size={"xs"} />
            {count}
          </CalendarCellEventText>
        </CalendarCellEventUnavailable>
      );
  }
};

export default AdminCellEvent;
