import React, {FC, useCallback, useState} from "react";
import {useTranslation} from "react-i18next";
import {Button} from "../../../../styles/button";
import {SetState} from "../../../../utils/types";
import CustomModal from "../../../common/CustomModal/CustomModal";

const AdminClientsDeleteModal: FC<{
  open: boolean;
  setOpen: SetState<boolean>;
  confirm: () => Promise<void>;
}> = ({ open, setOpen, confirm }) => {
  const { t } = useTranslation();
  const [deleting, setDeleting] = useState(false);

  const handleConfirm = useCallback(async () => {
    setDeleting(true);
    await confirm();
    setDeleting(false);
    setOpen(false);
  }, [confirm, setOpen]);

  return (
    <CustomModal
      title={t("adminClients.deleteTitle")}
      open={open}
      setOpen={setOpen}
      actions={
        <>
          <Button
            ver="secondary"
            disabled={deleting}
            onClick={() => setOpen(false)}
          >
            {t("common.no")}
          </Button>
          <Button disabled={deleting} onClick={handleConfirm}>
            {t("common.yes")}
          </Button>
        </>
      }
    >
      {t("adminClients.deleteText")}
    </CustomModal>
  );
};

export default AdminClientsDeleteModal;
