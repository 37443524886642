import api from "../utils/api";
import { AxiosResponse } from "axios";
import { PromoCode, PromoCodeList } from "../models/promoCode";
import { FiltersSearchBranchParams, Paged } from "../models/common";

export const getDiscount = (
  promoCode: string
): Promise<AxiosResponse<PromoCode>> =>
  api.get(`/api/PromoCode/${promoCode}`);

export const getPromoCode = (
  promoCodeId: string
): Promise<AxiosResponse<PromoCode>> =>
  api.get(`/api/PromoCode/Get/${promoCodeId}`);

export const getPromoCodes = async (
  params?: FiltersSearchBranchParams
): Promise<AxiosResponse<Paged<PromoCodeList>>> =>
  api.get(`/api/PromoCode/`, { params });

export const updatePromoCode = async (data: PromoCodeList): Promise<AxiosResponse<boolean>> =>
  api.put(`/api/PromoCode/${data.id}`, data);

export const createPromoCode = async (
  data: PromoCodeList
): Promise<AxiosResponse<boolean>> => api.post(`/api/PromoCode`, data);

const promoCodeApi = {
  getDiscount,
  getPromoCode,
  getPromoCodes,
  createPromoCode,
  updatePromoCode
};

export default promoCodeApi;
