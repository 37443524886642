export const TOKEN_KEY = "token";
export const USER_KEY = "user";
export const IDLE_MINUTES_KEY = "idleMinutes";
export const TOKEN_REFRESH_TIMEOUT_KEY = "tokenRefreshTimeout";
export const TOKEN_TIMEOUT_KEY = "tokenTimeout";
export const CUSTOMER_ID_KEY = "customerId";

export const DEFAULT_IDLE_TIMEOUT = 24 * 60 * 60 * 1000;

export const DEBOUNCE_TIMEOUT_MS = 500;

export const DEFAULT_PAGE_SIZE = 10;

export const VAT = 21;

export const DATE_FORMAT = {
  date: "d/M/yyyy",
  time: "HH:mm",
  dateTime: "d/M/yyyy HH:mm",
};

export const TIME_SLOT_MINUTES = 15;

export const COLORS = {
  primary: "#20c1f1",
  primaryDark: "#0c2b3f",
  secondary: "#e6e7e8",
  secondaryDark: "#b0b0b0",
  gray: "#0c2b3f",
  grayDark: "#2c2e2d",
  grayLight: "#384D64",
  grayLighter: "#627085",
  whitesmoke: "#f5f5f5",
  blue: "#2560e6",
  blueLight: "#d3dbe0",
  green: "#00ae26",
  orange: "#e09400",
  red: "#e00000",
  white: "#FFFFFF",
  black: "#000000",
  transparent: "transparent",

  fontColor: "#0c2b3f",
};
