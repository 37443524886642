import styled from "styled-components";
import { size } from "../../../../../utils/styles";

export const AdminBoxesSettingsDetailModalFormContent = styled.div`
  display: block;
`;

export const AdminBoxesSettingsDetailModalFormSelectors = styled.div`
  margin-bottom: 1.5rem;
`;

export const AdminBoxesSettingsDetailModalFormHeader = styled.div`
  display: none;
  margin-bottom: 0.5rem;

  @media screen and (min-width: ${size.mobile}) {
    display: grid;
    grid-template-columns: auto 10rem 10rem;
    align-items: center;
    column-gap: 1.5rem;
  }

  & > div {
    font-size: 0.9rem;
    text-align: center;
  }
`;

export const AdminBoxesSettingsDetailModalFormRow = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: auto auto;
  column-gap: 1.5rem;
  row-gap: 0.25rem;

  @media screen and (min-width: ${size.mobile}) {
    grid-template-columns: auto 10rem 10rem;
  }

  &:not(:last-child) {
    margin-bottom: 0.25rem;
  }

  & > div {
    font-size: 0.9rem;
  }

  & > div:first-child {
    grid-column: 1 / span 2;

    @media screen and (min-width: ${size.mobile}) {
      grid-column: initial;
    }
  }
`;
