import { rgba } from "polished";
import styled from "styled-components";
import { COLORS } from "../../../utils/constants";
import { size } from "../../../utils/styles";

export const ReactPaginateContainer = styled.div``;

export const ReactPaginateCurrentPage = styled.div`
  margin-bottom: 0.5rem;
  text-align: right;

  @media screen and (min-width: ${size.mobile}) {
    display: none;
  }
`;

export const ReactPaginateWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  ul {
    display: flex;
    list-style-type: none;
    user-select: none;
  }

  li:not(.previous):not(.next) {
    display: flex;
    border: 1px solid ${COLORS.secondaryDark};
    border-right: 0;

    &:nth-child(2) {
      border-radius: 3px 0px 0px 3px;
    }

    &:nth-last-child(2) {
      border-radius: 0px 3px 3px 0px;
      border: 1px solid ${COLORS.secondaryDark};
    }
  }

  li:not(.previous):not(.next):not(:nth-child(2)):not(:nth-last-child(2)) {
    display: none;

    @media screen and (min-width: ${size.mobile}) {
      display: flex;
    }
  }

  a {
    display: block;
    text-decoration: none;
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
    text-align: center;
    outline: none;
    color: ${COLORS.fontColor};
    transition: 0.2s ease-in-out;

    &:hover {
      background-color: ${COLORS.whitesmoke};
    }
  }

  .previous {
    border: 1px solid ${COLORS.secondaryDark};
    border-radius: 3px;
    margin-right: 0.25rem;

    @media screen and (min-width: ${size.mobile}) {
      margin-right: 1rem;
    }

    svg {
      vertical-align: -0.2rem;
    }
  }

  .selected {
    border: none;

    a {
      background-color: ${COLORS.primary};
      color: ${COLORS.white};
    }

    a:hover {
      background-color: ${rgba(COLORS.primary, 0.9)};
    }
  }

  .next {
    border: 1px solid ${COLORS.secondaryDark};
    border-radius: 3px;
    margin-left: 0.25rem;

    @media screen and (min-width: ${size.mobile}) {
      margin-left: 1rem;
    }

    svg {
      vertical-align: -0.2rem;
    }
  }

  .disabled {
    a {
      color: ${COLORS.secondaryDark};
    }
  }
`;
