import styled from "styled-components";
import { Button } from "../../styles/button";
import { COLORS } from "../../utils/constants";
import { fadeIn } from "../../utils/styles";

export const LogoutButton = styled(Button)`
  position: absolute;
  top: 2rem;
  right: 2rem;
  z-index: 999;

  font-size: 1.5rem;
  border-radius: 0.5rem;

  animation: ${fadeIn} 0.2s ease-in-out;
`;

export const DashboardButton = styled(LogoutButton)`
  left: 2rem;
  right: auto;
`;

export const TabletContainer = styled.main`
  display: flex;
  align-items: center;
  flex-flow: column;

  position: relative;
  height: 100vh;
  width: 100vw;

  overflow-y: auto;
  background-color: ${COLORS.white};
`;

export const PdfContainer = styled.iframe`
  width: 100%;
  height: 100%;
  margin-bottom: 9rem;
  border: 0px;
`;

export const BottomPanel = styled.div`
  position: absolute;
  bottom: 0px;
  height: 7rem;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: ${COLORS.white};
  margin-bottom: 2rem;
`;

export const CancelButton = styled(Button)`
  position: relative;
  left: 2rem;
`;

export const RightPanel = styled.div`
  position: absolute;
  right: 2rem;
  height: 100%;
`;

export const CanvasContainer = styled.div`
  height: 100%;
  width: 300px;
  border: 2px solid black;
  float: left;
`;

export const ButtonsPanel = styled.div`
  height: 100%;
  float: right;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 2rem;

  button:nth-child(1) {
    margin-bottom: 0.25rem;
  }
`;

export const Logo = styled.img`
  width: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
