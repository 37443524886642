import React, { FC } from "react";
import {
  CalendarAdminTime,
  CalendarAdminUser,
} from "../../../../models/calendar";
import {
  CalendarCellEventText,
  CalendarCellEventWrapper,
  CalendarCellWrapperClosed,
} from "../../../../styles/calendar";
import AdminCellEvent from "../AdminCellEvent/AdminCellEvent";
import { CalendarCell } from "../../../../styles/calendar";
import { useTranslation } from "react-i18next";
import { CalendarAdminTimeState } from "../../../../utils/enums";

const getAvailableUsersCount = (users: CalendarAdminUser[]) =>
  users.filter((user) => user.isAvailable === true).length;

const AdminCalendarCell: FC<{
  time: CalendarAdminTime;
  onCellEventClick?: (state: CalendarAdminTimeState) => void;
}> = ({ time, onCellEventClick }) => {
  const { t } = useTranslation();
  const availableUsersCount = getAvailableUsersCount(time.users);
  const notAvailableUsersCount = time.users.length - availableUsersCount;
  const isClosed = availableUsersCount === 0 && notAvailableUsersCount === 0;

  if (isClosed)
    return (
      <CalendarCell>
        <CalendarCellWrapperClosed>
          <CalendarCellEventText>
            {t("adminCalendarScene.calendar.closed")}
          </CalendarCellEventText>
        </CalendarCellWrapperClosed>
      </CalendarCell>
    );

  return (
    <CalendarCell>
      <CalendarCellEventWrapper>
        <AdminCellEvent
          timeState={CalendarAdminTimeState.Available}
          count={availableUsersCount}
          onCellEventClick={() =>
            onCellEventClick &&
            onCellEventClick(CalendarAdminTimeState.Available)
          }
        />
        <AdminCellEvent
          timeState={CalendarAdminTimeState.Unavailable}
          count={notAvailableUsersCount}
          onCellEventClick={() =>
            onCellEventClick &&
            onCellEventClick(CalendarAdminTimeState.Unavailable)
          }
        />
      </CalendarCellEventWrapper>
    </CalendarCell>
  );
};

export default AdminCalendarCell;
