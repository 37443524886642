import React, { FC } from "react";
import Page from "../../common/layout/Page/Page";
import AdminSidebar from "../AdminSidebar/AdminSidebar";
import AdminPromoCodesContent from "./AdminPromoCodesContent/AdminPromoCodesContent";

const AdminPromoCodes: FC = () => (
  <Page sidebar={<AdminSidebar />}>
    <AdminPromoCodesContent />
  </Page>
);

export default AdminPromoCodes;
