import styled from "styled-components";
import { LinkButton } from "../../../../styles/button";
import { Table } from "../../../../styles/table";
import { COLORS } from "../../../../utils/constants";

export const ScheduledTasksTable = styled(Table)`
  th,
  td:not(:last-child) {
    vertical-align: top;
  }

  td,
  th {
    &:nth-child(2) {
      min-width: 16rem;
    }
  }

  th,
  td {
    &:nth-child(3) {
      width: max-content;
      min-width: 16rem;
    }
  }

  td:last-child {
    text-align: end;
  }
`;

export const ScheduledTaskState = styled(LinkButton)<{ isActive: boolean }>`
  color: ${({ isActive }) => (isActive ? COLORS.green : COLORS.red)};
  text-decoration: ${({ disabled }) => (disabled ? "none" : "underline")};
  font-weight: bold;

  ${({ isActive, disabled }) =>
    disabled &&
    `
    &:hover {
      color: ${isActive ? COLORS.green : COLORS.red};
      cursor: default;
    }
  `}
`;
