import React, { FC, useEffect, useState } from "react";
import UserSidebar from "../UserSidebar/UserSidebar";
import { H2 } from "../../../styles/title";
import Page from "../../common/layout/Page/Page";
import { useTranslation } from "react-i18next";
import InvoicesTable from "./InvoicesTable/InvoicesTable";
import { FadeInBottom } from "../../../styles/fadeIn";
import documentApi from "../../../api/document";
import { Invoice } from "../../../models/document";
import Loader from "../../common/Loader/Loader";
import storageApi from "../../../api/storage";

const Invoices: FC = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<Invoice[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const response = await documentApi.getUserInvoices(
          storageApi.getCurrentUser()!.id
        );
        setData(response.data);
      } catch (err) {}
      setLoading(false);
    })();
  }, []);

  return (
    <Page sidebar={<UserSidebar />}>
      {loading ? (
        <Loader />
      ) : (
        <FadeInBottom>
          <H2>{t("invoices.title")}</H2>
          <InvoicesTable data={data} />
        </FadeInBottom>
      )}
    </Page>
  );
};

export default Invoices;
