import styled from "styled-components";
import { size } from "../../../../utils/styles";

export const Checkmark = styled.span`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;

  height: 1.5rem;
  width: 1.5rem;

  background-color: var(--white);
  border: 1px solid var(--secondary-dark);
  border-radius: 3px;
  transition: background-color 0.2s ease-in-out;

  &::after {
    content: "";
    position: absolute;
    display: none;

    left: 0.5rem;
    top: 0.2rem;
    transform: rotate(45deg);
    width: 0.5rem;
    height: 0.75rem;
    border: solid var(--white);
    border-width: 0 0.2rem 0.2rem 0;
  }
`;

export const CheckboxContainer = styled.label`
  display: block;
  position: relative;
  padding-left: 2rem;
  cursor: pointer;
  user-select: none;
  font-size: 0.9rem;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ ${Checkmark} {
      background-color: var(--primary);
      border-color: transparent;

      &:hover {
        background-color: var(--primary-dark);
      }

      &::after {
        display: block;
      }
    }

    &:not(:checked) ~ ${Checkmark}:hover {
      background-color: var(--secondary);
    }

    &:disabled ~ ${Checkmark} {
      background-color: var(--secondary) !important;
      border-color: var(--secondary-dark) !important;

      &::after {
        border-color: var(--secondary-dark) !important;
      }
    }
  }

  @media screen and (min-width: ${size.mobile}) {
    font-size: 1rem;
  }
`;
