import { faBox } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import cardApi from "../../../../api/card";
import orderApi from "../../../../api/order";
import storageApi from "../../../../api/storage";
import { OrderSimple } from "../../../../models/order";
import { PageSectionHeader } from "../../../../styles/layout";
import { H4 } from "../../../../styles/title";
import { useUserHasRight } from "../../../../utils/auth";
import { COLORS } from "../../../../utils/constants";
import { SetState } from "../../../../utils/types";
import Checkbox from "../../../common/form/Checkbox/Checkbox";
import Loader from "../../../common/Loader/Loader";
import { CardAccessRow, CardAccessRowName } from "../CardEditModal/styles";
import { CardEditOrdersLoader } from "./styles";

const CardEditOrders: FC<{
  cardId: string;
  cardOrders: OrderSimple[];
  setCardOrders: SetState<OrderSimple[]>;
  isSubmitting: boolean;
  setSubmitting: (val: boolean) => void;
  userId?: string;
  setReload: (val: boolean) => void;
}> = ({
  cardId,
  cardOrders,
  setCardOrders,
  isSubmitting,
  setSubmitting,
  userId,
  setReload,
}) => {
  const { t } = useTranslation();
  const [orders, setOrders] = useState<OrderSimple[]>([]);
  const [loading, setLoading] = useState(false);
  const { hasUserAllRights } = useUserHasRight();
  const canPair = hasUserAllRights(["cardsPairing"]);

  const isActive = (order: OrderSimple) =>
    cardOrders.some((cardOrder: OrderSimple) => cardOrder.id === order.id);

  const handleChecked = async (
    order: OrderSimple,
    target: HTMLInputElement
  ) => {
    setSubmitting(true);
    const { checked } = target;
    try {
      if (checked) await cardApi.pairUserCardWithOrder(cardId, order.id);
      else await cardApi.unpairUserCardWithOrder(cardId, order.id);

      setCardOrders((prev) => {
        return checked
          ? [...prev, order]
          : prev.filter((prevOrder) => prevOrder.id !== order.id);
      });

      toast.success(t("cards.modal.accessUpdateSuccess"));
      setReload(true);
    } catch (err) {
      toast.error(t("errors.cardUpdate"));
    }
    setSubmitting(false);
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const response = await orderApi.getUserOrders(
          userId ?? storageApi.getCurrentUser()!.id
        );
        setOrders(response.data);
      } catch (err) {
        toast.error(t("errors.orders"));
      }
      setLoading(false);
    })();
  }, [t, userId]);

  return loading ? (
    <CardEditOrdersLoader>
      <Loader />
    </CardEditOrdersLoader>
  ) : (
    <>
      <PageSectionHeader mb="1rem">
        <H4>{t("cards.modal.accesses")}</H4>
      </PageSectionHeader>
      {orders.map((order) => (
        <CardAccessRow key={order.id}>
          <CardAccessRowName isActive={isActive(order)}>
            <FontAwesomeIcon
              icon={faBox}
              color={isActive(order) ? COLORS.primary : COLORS.secondary}
              size="lg"
            />
            {order.name}
          </CardAccessRowName>
          <Checkbox>
            <input
              type="checkbox"
              checked={isActive(order)}
              onChange={({ target }) => handleChecked(order, target)}
              disabled={isSubmitting || !canPair}
            />
          </Checkbox>
        </CardAccessRow>
      ))}
    </>
  );
};

export default CardEditOrders;
