import { OrderParametersData } from "../../models/order";
import { BoxPrice, BoxType } from "../../models/box";

export const getLeaseMonths = (leaseType: string): number => {
  switch (leaseType) {
    case "1":
      return 1; // 1 month
    case "2":
      return 3; // 3 months
    case "3":
      return 6; // 6 months
    case "4":
      return 12; // 1 year
    case "5":
      return 24; // 2 years
    case "6":
      return 36; // 3 years
    case "7":
      return 48; // 4 years
    case "8":
      return 60; // 5 years
    case "9":
      return 72; // 6 years
    case "10":
      return 84; // 7 years
    default:
      return 0;
  }
};

export const getBoxPriceWithVat = (
  leaseType: string,
  price: BoxPrice
): number => {
  switch (leaseType) {
    case "1":
      return price.price1WithVat;
    case "2":
      return price.price2WithVat;
    case "3":
      return price.price3WithVat;
    case "4":
      return price.price4WithVat;
    case "5":
      return price.price4WithVat * 2;
    case "6":
      return price.price4WithVat * 3;
    case "7":
      return price.price4WithVat * 4;
    case "8":
      return price.price4WithVat * 5;
    case "9":
      return price.price4WithVat * 6;
    case "10":
      return price.price4WithVat * 7;
    default:
      return 0;
  }
};

export const getBaseTotalPriceWithVat = (
  values: OrderParametersData,
  boxPrice: BoxPrice,
) => {
  const months       = getLeaseMonths(values.lengthOfLeaseType);
  const totalPrice   = getBoxPriceWithVat(values.lengthOfLeaseType, boxPrice);

  let total = 0;
  total += totalPrice;

  return total;
};

export const getPromoCodeDiscountCoeficient = (discount: number) => ((100 - discount)/100);
export const getPromoCodeDiscountWithVat = (total: number, discount: number) => total * getPromoCodeDiscountCoeficient(discount);

export const getTotalPriceWithVat = (
  values: OrderParametersData,
  boxType: BoxType,
  additionalServices: number[]
) => {
  const months       = getLeaseMonths(values.lengthOfLeaseType);

  let total = getBaseTotalPriceWithVat(values, boxType.boxPriceDto);

  if (values.discount) {
    total = getPromoCodeDiscountWithVat(total, values.discount);
  }
  
  total += boxType.boxPriceDto.guaranteeWithVat;
  if (values.fingerPrint)
    total += boxType.boxPriceDto.fingerPrintWithVat * months;
  if (values.smsCheck) total += boxType.boxPriceDto.smsCheckWithVat * months;

  total += additionalServices.reduce(
    (accumulator, currentValue) =>
      accumulator +
      boxType.boxPriceDto.additionalServices.find((x) => x.id === currentValue)!
        .priceWithVat *
        months,
    0
  );

  return total;
};
