import styled, { css } from "styled-components";
import ReactSelect from "react-select";
import { COLORS } from "../../../../utils/constants";
import { rgba } from "polished";

export const StyledSelect = styled(ReactSelect)<{
  $error: boolean;
  width: string;
}>`
  width: ${({ width }) => width ?? "100%"};

  & .Select__control {
    width: 100%;
    height: 2.5rem;

    border: 1px solid
      ${({ $error }) => ($error ? COLORS.red : COLORS.secondaryDark)};
    border-radius: 3px;
    outline: none;
    color: var(--font-color);
    font-size: 1rem;

    transition: all 0.15s ease-in-out;
  }

  & .Select__control:hover {
    border-color: ${({ $error }) =>
      $error ? COLORS.red : COLORS.secondaryDark};
  }

  & .Select__control--is-focused {
    border-color: var(--primary-dark);
    box-shadow: 0 0 3px 0 var(--primary);
  }

  & .Select__control--is-focused:hover {
    border-color: var(--primary-dark);
    box-shadow: 0 0 3px 0 var(--primary);
  }

  & .Select__control--is-disabled {
    background-color: var(--secondary);
    color: var(--gray-light);
  }

  & .Select__option--is-selected {
    background-color: var(--primary);
  }

  & .Select__option--is-focused {
    color: var(--font-color);
    background-color: ${rgba(COLORS.primary, 0.2)};
  }

  & .Select__option:hover {
    background-color: ${rgba(COLORS.primary, 0.2)};
  }

  & .Select__option--is-selected:hover {
    color: var(--white);
    background-color: ${rgba(COLORS.primary, 0.85)};
  }

  & .Select__option--is-selected.Select__option--is-focused {
    color: var(--white);
    background-color: ${rgba(COLORS.primary, 0.85)};
  }
`;

export const SelectLabel = styled.label<{ width?: string }>`
  ${({ width }) =>
    width &&
    css`
      display: inline-block;
      width: ${width};
    `}
`;
