import { AxiosResponse } from "axios";
import { SignQueueItem } from "../models/signQueue";
import { UserList } from "../models/user";
import api from "../utils/api";

export const getTablets = (): Promise<AxiosResponse<UserList[]>> =>
  api.get(`/api/Tablet`);

export const sendToSign = (
  customerId: string,
  tabletId: string
): Promise<AxiosResponse<boolean>> =>
  api.post(`/api/Customer/${customerId}/Tablet/${tabletId}/SendToSign`);

export const getQueue = (
  tabletId: string
): Promise<AxiosResponse<SignQueueItem>> =>
  api.get(`/api/Tablet/${tabletId}/SignQueue`);

export const getData = (signQueueHash: string): Promise<AxiosResponse<Blob>> =>
  api.get(`/api/SignQueue/${signQueueHash}/Data`, {
    headers: {
      "Content-Type": "application/pdf",
    },
    responseType: "blob",
  });

export const cancel = (tabletId: string): Promise<AxiosResponse<boolean>> =>
  api.post(`/api/Tablet/${tabletId}/Cancel`);

export const sign = (
  signQueueId: string,
  image: Blob
): Promise<AxiosResponse<boolean>> => {
  const formData = new FormData();
  formData.append("image", image);
  return api.post(`/api/SignQueue/${signQueueId}/Sign`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const signQueueApi = {
  getTablets,
  sendToSign,
  getQueue,
  getData,
  cancel,
  sign,
};

export default signQueueApi;
