import { format } from "date-fns";
import { Formik } from "formik";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import * as Yup from "yup";
import boxApi from "../../../../api/box";
import { AdditionalService } from "../../../../models/additionalServices";
import { BoxPrice } from "../../../../models/box";
import { Button } from "../../../../styles/button";
import { useUserHasRight } from "../../../../utils/auth";
import { getErrorMessage } from "../../../../utils/errors";
import { SetState } from "../../../../utils/types";
import BoxTypeNameLabel from "../../../common/BoxTypeLabel/BoxTypeLabel";
import CustomModal from "../../../common/CustomModal/CustomModal";
import AdminBoxesSettingsDetailModalForm from "./AdminBoxesSettingsDetailModalForm/AdminBoxesSettingsDetailModalForm";
import { AdminBoxesSettingsDetailModalHeading } from "./styles";

const formDefaultValues: BoxPrice = {
  activeFrom: "1970-01-01",
  price1WithoutVat: 0,
  price1WithVat: 0,
  price2WithoutVat: 0,
  price2WithVat: 0,
  price3WithoutVat: 0,
  price3WithVat: 0,
  price4WithoutVat: 0,
  price4WithVat: 0,
  fingerPrintWithoutVat: 0,
  fingerPrintWithVat: 0,
  smsCheckWithoutVat: 0,
  smsCheckWithVat: 0,
  guaranteeWithoutVat: 0,
  guaranteeWithVat: 0,
  additionalServices: [],
};

const AdminBoxesSettingsDetailModal: FC<{
  open: boolean;
  setOpen: SetState<boolean>;
  onSuccess: () => void;
  boxType: string;
  editData?: BoxPrice;
  additionalServices: AdditionalService[];
  canEditActiveFrom: boolean;
}> = ({
  open,
  setOpen,
  onSuccess,
  boxType,
  editData,
  additionalServices,
  canEditActiveFrom,
}) => {
  const { t } = useTranslation();
  const currentDate = useMemo(() => new Date(), []);
  const [initialValues, setInitialValues] = useState<BoxPrice>({
    ...formDefaultValues,
    activeFrom: format(currentDate, "yyyy-MM-dd"),
  });
  const { id } = useParams<{ id: string }>();
  const boxId = parseInt(id);
  const isCreate = !!!editData;

  const { hasUserAllRights } = useUserHasRight();
  const canWrite = hasUserAllRights(["writeBoxPrices"]);

  const validator = Yup.number()
    .positive(t("errors.positive"))
    .required(t("errors.required"));

  const validationSchema = Yup.object({
    price1WithVat: validator,
    price2WithVat: validator,
    price3WithVat: validator,
    price4WithVat: validator,
    fingerPrintWithVat: validator,
    smsCheckWithVat: validator,
    guaranteeWithVat: validator,
    additionalServices: Yup.array().of(
      Yup.object().shape({ priceWithVat: validator })
    ),
  });

  useEffect(() => {
    if (isCreate) {
      setInitialValues({
        ...formDefaultValues,
        activeFrom: format(currentDate, "yyyy-MM-dd"),
        additionalServices: additionalServices.map((x) => {
          x.priceWithVat = 0;
          x.priceWithoutVat = 0;
          return x;
        }),
      });
      return;
    }

    setInitialValues(editData!);
  }, [isCreate, editData, currentDate, additionalServices]);

  const handleSubmit = async (values: BoxPrice) => {
    const data: BoxPrice = {
      activeFrom: values.activeFrom,
      price1WithoutVat: 0,
      price1WithVat: values.price1WithVat,
      price2WithoutVat: 0,
      price2WithVat: values.price2WithVat,
      price3WithoutVat: 0,
      price3WithVat: values.price3WithVat,
      price4WithoutVat: 0,
      price4WithVat: values.price4WithVat,
      fingerPrintWithoutVat: 0,
      fingerPrintWithVat: values.fingerPrintWithVat,
      smsCheckWithoutVat: 0,
      smsCheckWithVat: values.smsCheckWithVat,
      guaranteeWithoutVat: 0,
      guaranteeWithVat: values.guaranteeWithVat,
      additionalServices: values.additionalServices,
    };

    try {
      isCreate
        ? await boxApi.addBoxPrices(boxId, 1, data)
        : await boxApi.updateBoxPrices(boxId, 1, editData!.id!, data);
      toast.success(t("common.saveSuccess"));
      setOpen(false);
      onSuccess();
    } catch (err) {
      toast.error(getErrorMessage(err, t("errors.saveError")));
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ isSubmitting, isValid, submitForm }) => (
        <CustomModal
          title={t(
            `adminBoxesSettingsDetail.modal.${
              isCreate ? "titleAdd" : "titleEdit"
            }`
          )}
          open={open}
          setOpen={setOpen}
          actions={
            <>
              <Button
                type="button"
                ver="secondary"
                disabled={isSubmitting}
                onClick={() => setOpen(false)}
              >
                {t("common.cancel")}
              </Button>
              <Button
                type={"submit"}
                disabled={!isValid || isSubmitting || !canWrite}
                onClick={submitForm}
              >
                {t("common.save")}
              </Button>
            </>
          }
        >
          {boxType && (
            <AdminBoxesSettingsDetailModalHeading>
              <BoxTypeNameLabel type={boxType} />
            </AdminBoxesSettingsDetailModalHeading>
          )}
          <AdminBoxesSettingsDetailModalForm
            currentDate={currentDate}
            canEditActiveFrom={
              (canEditActiveFrom && editData?.state !== "Active") || isCreate
            }
          />
        </CustomModal>
      )}
    </Formik>
  );
};

export default AdminBoxesSettingsDetailModal;
