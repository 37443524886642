import React, { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import calendarApi from "../../../../api/calendar";
import { CalendarOverview } from "../../../../models/calendar";
import { getErrorMessage } from "../../../../utils/errors";
import Loader from "../../../common/Loader/Loader";
import MeetingsTable from "../../../user/Dashboard/DashboardMeetings/MeetingsTable";
import { AdminClientsDetailTabsLoader } from "./styles";

const AdminClientsDetailMeetings: FC = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [data, setData] = useState<CalendarOverview[]>([]);
  const [loading, setLoading] = useState(true);

  const loadData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await calendarApi.getUserCalendarOverview(id);
      setData(response.data.data);
    } catch (err) {
      toast.error(getErrorMessage(err, t("errors.loadError")));
    }
    setLoading(false);
  }, [id, t]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return loading ? (
    <AdminClientsDetailTabsLoader>
      <Loader />
    </AdminClientsDetailTabsLoader>
  ) : (
    <>
      <MeetingsTable
        data={data}
        table
        canCancel={true}
        reload={loadData}
        userId={Number(id)}
      />
    </>
  );
};

export default AdminClientsDetailMeetings;
