import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC } from "react";
import { ReactDatePickerProps } from "react-datepicker";
import cs from "date-fns/locale/cs";
import en from "date-fns/locale/en-US";
import "react-datepicker/dist/react-datepicker.css";
import { InputRow } from "../../../../styles/form";
import FormError from "../FormError/FormError";
import {
  DatePickerIconWrapper,
  DatePickerLabel,
  DatePickerWrapper,
  StyledDatePicker,
} from "./styles";
import { COLORS, DATE_FORMAT } from "../../../../utils/constants";
import i18n from "../../../../utils/i18n";

interface Props extends ReactDatePickerProps {
  name?: string;
  label?: string;
  labelWidth?: string;
  error?: boolean;
  inputWidth?: string;
  innerIcon?: IconDefinition;
  innerIconColor?: string;
}

const DatePicker: FC<Props> = ({
  name,
  label,
  labelWidth,
  error,
  inputWidth,
  innerIcon = faCalendarAlt,
  innerIconColor = COLORS.primary,
  dateFormat = DATE_FORMAT.date,
  ...restProps
}) => {
  const currentLanguage = i18n.language || window.localStorage.i18nextLng || "";

  return (
    <InputRow>
      {label && (
        <DatePickerLabel width={labelWidth} htmlFor={name}>
          {label}
        </DatePickerLabel>
      )}
      <DatePickerWrapper>
        <StyledDatePicker
          $error={error}
          width={inputWidth}
          name={name}
          locale={currentLanguage === "cs" ? cs : en}
          dateFormat={dateFormat}
          {...restProps}
        />
        <DatePickerIconWrapper>
          <FontAwesomeIcon icon={innerIcon} color={innerIconColor} />
        </DatePickerIconWrapper>
        {name && error && <FormError name={name} />}
      </DatePickerWrapper>
    </InputRow>
  );
};
export default DatePicker;
