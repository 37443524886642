import React, { FC, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { UserData } from "../../../models/user";
import { ContactCardContent } from "../../../styles/card";
import { Flex } from "../../../styles/flex";
import UserInfoNote from "./UserInfoNote";

const fields: Array<keyof UserData> = [
  "street",
  "city",
  "zipCode",
  "country",
  "email",
  "phone",
];

const UserInfoCardContent: FC<{
  userInfo: UserData;
  showNote?: boolean;
}> = ({ userInfo, showNote }) => {
  const { t } = useTranslation();

  return (
    <Flex style={{ columnGap: 30 }}>
      <div>
        <ContactCardContent>
          <span>{t("userInfo.info.fullName")}</span>
          <b>
            {userInfo.firstName} {userInfo.lastName}
          </b>
          {fields.map((f) => (
            <Fragment key={f}>
              <span>{t(`userInfo.info.${f}`)}</span>
              <b>{userInfo[f]}</b>
            </Fragment>
          ))}
        </ContactCardContent>
      </div>
      {showNote && <UserInfoNote note={userInfo.note} />}
    </Flex>
  );
};

export default UserInfoCardContent;
