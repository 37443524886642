import React, { FC } from "react";
import { RadioButton, RadioContainer } from "./styles";

const Radio: FC = ({ children }) => {
  return (
    <RadioContainer>
      {children}
      <RadioButton />
    </RadioContainer>
  );
};

export default Radio;
