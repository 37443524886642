import React, { FC, useEffect, useState } from "react";
import UserSidebar from "../UserSidebar/UserSidebar";
import Page from "../../common/layout/Page/Page";
import OrdersTable from "./OrdersTable/OrdersTable";
import { PageSection } from "../../../styles/layout";
import { useTranslation } from "react-i18next";
import { Order } from "../../../models/order";
import { toast } from "react-toastify";
import orderApi from "../../../api/order";
import Loader from "../../common/Loader/Loader";
import OrdersTableHeader from "./OrdersTableHeader/OrdersTableHeader";
import { FadeInBottom } from "../../../styles/fadeIn";
import { OrderState } from "../../../utils/enums";
import OrdersHistory from "./OrdersHistory/OrdersHistory";
import storageApi from "../../../api/storage";

const Orders: FC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState<Order[]>([]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const response = await orderApi.getUserOrders(
          storageApi.getCurrentUser()!.id
        );
        setOrders(response.data);
      } catch (err) {
        toast.error(t("errors.orders"));
      }
      setLoading(false);
    })();
  }, [t]);

  return (
    <Page sidebar={<UserSidebar />}>
      {loading ? (
        <Loader />
      ) : (
        <FadeInBottom>
          <PageSection>
            <OrdersTableHeader />
            <OrdersTable
              data={orders.filter((o) => o.state !== OrderState.Expired)}
            />
          </PageSection>
          <OrdersHistory
            data={orders.filter((o) => o.state === OrderState.Expired)}
          />
        </FadeInBottom>
      )}
    </Page>
  );
};

export default Orders;
