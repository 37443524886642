import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import AdminBoxesSettingsDetailTable from "../AdminBoxesSettingsDetailTable/AdminBoxesSettingsDetailTable";
import AdminBoxesSettingsDetailInfo from "../AdminBoxesSettingsDetailInfo/AdminBoxesSettingsDetailInfo";
import Breadcrumbs from "../../../common/Breadcrumbs/Breadcrumbs";
import { FadeInBottom } from "../../../../styles/fadeIn";
import { BoxType } from "../../../../models/box";
import boxApi from "../../../../api/box";
import { toast } from "react-toastify";
import Loader from "../../../common/Loader/Loader";

const AdminBoxesSettingsDetailContent: FC = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [data, setData] = useState<BoxType>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const response = await boxApi.getBox(id);
        setData(response.data);
      } catch (err) {
        toast.error(t("errors.boxPrices"));
      }
      setLoading(false);
    })();
  }, [t, id]);

  if (loading) return <Loader />;
  return (
    <FadeInBottom>
      <Breadcrumbs>
        <Link to={"/admin/settings/boxes"}>
          {t("adminBoxesSettingsDetail.breadcrumbs.parent")}
        </Link>
        <span>{t("adminBoxesSettingsDetail.breadcrumbs.boxEdit")}</span>
      </Breadcrumbs>

      {!!data && <AdminBoxesSettingsDetailInfo data={data} />}
      {!!data && <AdminBoxesSettingsDetailTable boxType={data.type} />}
    </FadeInBottom>
  );
};

export default AdminBoxesSettingsDetailContent;
