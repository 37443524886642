import styled from "styled-components";
import { Table } from "../../../../styles/table";

export const AdminBoxesSettingsTableContainer = styled(Table)`
  th,
  td {
    &:last-child {
      text-align: right;
    }
  }

  td {
    cursor: pointer;
  }
`;

export const AdminBoxesSettingsDetailTableState = styled.div<{
  color: string;
}>`
  font-weight: 700;
  color: ${({ color }) => color};
`;
