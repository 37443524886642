import styled from "styled-components";
import { CardList } from "../../../../../styles/card";
import { size } from "../../../../../utils/styles";

export const OrdersTableDetailContainer = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;

  img {
    display: inline-block;
    width: 10rem;
    height: auto;
    margin-right: 0;
    margin-bottom: 2rem;
  }

  ${CardList} {
    margin-top: 1rem;
  }

  @media screen and (min-width: ${size.tablet}) {
    flex-flow: row;

    img {
      margin-right: 2rem;
      margin-bottom: 0;
    }
  }
`;
