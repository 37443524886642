import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { BoxPrice } from "../../../../../models/box";
import { Order } from "../../../../../models/order";
import { LinkButton } from "../../../../../styles/button";
import { GreenText, PrimaryText } from "../../../../../styles/text";
import { formatCurrency } from "../../../../../utils/formats";
import { useStore } from "../../../../../utils/store";
import { SetState } from "../../../../../utils/types";
import Empty from "../../../../common/Empty";
import { OrderDetailCardBoxList } from "../OrderDetailCardBox/styles";
import { OrderDetailCardParameter } from "./styles";

const OrderDetailCardParameters: FC<{
  order: Order;
  boxPrice: BoxPrice;
  setEditParams: SetState<boolean>;
}> = ({ order, boxPrice, setEditParams }) => {
  const { t } = useTranslation();
  const user = useStore((store) => store.user);
  const history = useHistory();

  return (
    <OrderDetailCardBoxList>
      <>
        <PrimaryText>{t("orders.detail.parameters")}</PrimaryText>
        <OrderDetailCardParameter>
          {user?.role === "Customer" && order.state !== "Expired" && (
            <LinkButton onClick={() => setEditParams(true)}>
              {t("common.change")}
            </LinkButton>
          )}
        </OrderDetailCardParameter>
      </>
      {order.fingerprint && (
        <>
          <b>{t("orders.detail.fingerPrint")}</b>
          <OrderDetailCardParameter>
            <GreenText>
              {formatCurrency(boxPrice.fingerPrintWithVat)}&nbsp;
            </GreenText>
          </OrderDetailCardParameter>
        </>
      )}
      {order.smsCheck && (
        <>
          <b>{t("orders.detail.smsCheck")}</b>
          <OrderDetailCardParameter>
            <GreenText>
              {formatCurrency(boxPrice.smsCheckWithVat)}&nbsp;
            </GreenText>
          </OrderDetailCardParameter>
        </>
      )}
      {order.additionalServices.map((additionalService) => (
        <Empty key={"additionalService" + additionalService.id}>
          <b>{additionalService.name}</b>
          <OrderDetailCardParameter>
            <GreenText>
              {formatCurrency(
                boxPrice.additionalServices.find(
                  (x) => x.id === additionalService.id
                )!.priceWithVat
              )}
              &nbsp;
            </GreenText>
          </OrderDetailCardParameter>
        </Empty>
      ))}
      <>
        <b>{t("orders.detail.guarantee")}</b>
        <OrderDetailCardParameter>
          <GreenText>
            {formatCurrency(boxPrice.guaranteeWithVat)}&nbsp;
          </GreenText>
        </OrderDetailCardParameter>
      </>
      {user?.role !== "Customer" && order.promoCode && (
        <>
          <PrimaryText>{t("orders.detail.promoCode")}</PrimaryText>
          <OrderDetailCardParameter>
            <PrimaryText
              onClick={() =>
                history.push(
                  `/admin/settings/promo-code/${order.promoCode?.id}`
                )
              }
              style={{ textDecoration: "underline", cursor: "pointer" }}
            >
              {order.promoCode.code}
            </PrimaryText>
          </OrderDetailCardParameter>
        </>
      )}
    </OrderDetailCardBoxList>
  );
};

export default OrderDetailCardParameters;
