import React, { FC } from "react";
import { InputContainer, InputRow } from "../../../../styles/form";
import { COLORS } from "../../../../utils/constants";
import FormError from "../FormError/FormError";
import { PhoneInputWrapper } from "./styles";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import PhoneInputComponent from "react-phone-input-2";
import "react-phone-input-2/lib/high-res.css";

const PhoneInput: FC<{ tooltipLeft?: boolean }> = ({ tooltipLeft }) => {
  const { t } = useTranslation();
  const {
    errors,
    touched,
    values,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext<{ phone: string }>();

  return (
    <InputRow>
      <label htmlFor="phone">{t("userInfo.info.phone")}</label>
      <PhoneInputWrapper error={touched.phone && !!errors.phone}>
        <InputContainer>
          <PhoneInputComponent
            value={values.phone}
            onChange={(value, country, e, formattedValue) => {
              setFieldTouched("phone");
              setFieldValue("phone", formattedValue);
            }}
            onBlur={() => setFieldTouched("phone")}
            inputProps={{ name: "phone" }}
            preferredCountries={["cz", "sk"]}
            countryCodeEditable={false}
            buttonStyle={{
              borderColor:
                touched.phone && !!errors.phone
                  ? COLORS.red
                  : COLORS.secondaryDark,
            }}
          />
          {touched.phone && errors.phone && (
            <FormError name="phone" tooltipLeft={tooltipLeft} />
          )}
        </InputContainer>
      </PhoneInputWrapper>
    </InputRow>
  );
};

export default PhoneInput;
