import React, { FC } from "react";
import { OrderFormData } from "../../../../models/order";
import { useTranslation } from "react-i18next";
import { BoxType } from "../../../../models/box";
import { getTotalPriceWithVat } from "../../priceUtils";
import { formatCurrency } from "../../../../utils/formats";
import { OrderSidebarPriceContainer } from "./styles";
import { OrderSidebarBoxDetails } from "../OrderSidebarBoxInfo/styles";
import { PrimaryText } from "../../../../styles/text";

const OrderSidebarTotalPrice: FC<{
  values: OrderFormData;
  boxType: BoxType;
  additionalServices: number[];
}> = ({ values, boxType, additionalServices }) => {
  const { t } = useTranslation();

  return (
    <OrderSidebarPriceContainer>
      <OrderSidebarBoxDetails>
        <PrimaryText>
          {t("sidebar.order.selected.priceFor")}{" "}
          {t(`order.parameters.lengthOfLeaseType.${values.lengthOfLeaseType}`)}{" "}
          {t("common.price.withVat")}:
        </PrimaryText>
        <b>
          {formatCurrency(
            getTotalPriceWithVat(values, boxType, additionalServices)
          )}
        </b>
      </OrderSidebarBoxDetails>
    </OrderSidebarPriceContainer>
  );
};

export default OrderSidebarTotalPrice;
