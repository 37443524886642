import styled from "styled-components";
import { COLORS } from "../../../../utils/constants";

export const PhoneInputWrapper = styled.div<{ error?: boolean }>`
  input {
    height: 2.5rem !important;
    width: 100% !important;
    border: 1px solid
      ${({ error }) => (error ? COLORS.red : COLORS.secondaryDark)} !important;
    border-radius: 3px !important;
    outline: none !important;
    color: ${COLORS.fontColor} !important;
    font-size: 1rem !important;
  }
`;
