import { pickBy } from "lodash";
import { useCallback, useMemo } from "react";
import { UserRight } from "../models/auth";
import { useStore } from "./store";

export const useUserHasRight = () => {
  const user = useStore((store) => store.user);
  const userRights = useMemo(() => {
    if (!user?.rights) {
      return [];
    }

    const givenRights = pickBy(user.rights, (value) => Boolean(value));

    return Object.keys(givenRights) as UserRight[];
  }, [user?.rights]);

  const hasUserAllRights = useCallback(
    (testedRights: UserRight[]) =>
      testedRights.every((right) => userRights.includes(right)),
    [userRights]
  );

  const hasUserAnyRight = useCallback(
    (testedRights: UserRight[]) =>
      testedRights.some((right) => userRights.includes(right)),
    [userRights]
  );

  const isAdmin = useMemo(() => user?.role === "Admin", [user?.role]);

  return { hasUserAllRights, hasUserAnyRight, isAdmin };
};
