import { useFormikContext } from "formik";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { BoxTypeForm } from "../../../../models/box";
import { Button } from "../../../../styles/button";
import { useUserHasRight } from "../../../../utils/auth";
import Input from "../../../common/form/Input/Input";
import {
  AdminBoxesSettingsDetailInfoFormCol,
  AdminBoxesSettingsDetailInfoFormControls,
  AdminBoxesSettingsDetailInfoFormGrid,
  AdminBoxesSettingsDetailInfoFormMain,
  AdminBoxesSettingsDetailInfoFormRow,
} from "./styles";

const dimensionFields: Array<keyof BoxTypeForm> = ["width", "length", "height"];
const otherFields: Array<keyof BoxTypeForm> = ["volume", "loadCapacity"];

const AdminBoxesSettingsDetailInfoForm: FC = () => {
  const { t } = useTranslation();
  const { errors, touched, isValid, isSubmitting } =
    useFormikContext<BoxTypeForm>();
  const { hasUserAllRights } = useUserHasRight();
  const canWrite = hasUserAllRights(["writeBoxTypeInfo"]);

  const dimensionInputs = dimensionFields.map((f) => (
    <Input
      key={f}
      name={f}
      mark={t(`adminBoxesSettingsDetail.form.${f}Shortcut`)}
      markPosition={"left"}
      error={touched[f] && !!errors[f]}
    />
  ));

  const innerInputs = otherFields.map((f) => (
    <AdminBoxesSettingsDetailInfoFormRow key={f}>
      <AdminBoxesSettingsDetailInfoFormCol>
        <label htmlFor={f}>{t(`adminBoxesSettingsDetail.form.${f}`)}</label>
      </AdminBoxesSettingsDetailInfoFormCol>
      <AdminBoxesSettingsDetailInfoFormCol>
        <AdminBoxesSettingsDetailInfoFormGrid>
          <Input name={f} error={touched[f] && !!errors[f]} />
        </AdminBoxesSettingsDetailInfoFormGrid>
      </AdminBoxesSettingsDetailInfoFormCol>
    </AdminBoxesSettingsDetailInfoFormRow>
  ));

  return (
    <AdminBoxesSettingsDetailInfoFormMain>
      <AdminBoxesSettingsDetailInfoFormRow>
        <AdminBoxesSettingsDetailInfoFormCol>
          <label>{t("adminBoxesSettingsDetail.form.dimensions")}</label>
        </AdminBoxesSettingsDetailInfoFormCol>
        <AdminBoxesSettingsDetailInfoFormCol>
          <AdminBoxesSettingsDetailInfoFormGrid>
            {dimensionInputs}
          </AdminBoxesSettingsDetailInfoFormGrid>
        </AdminBoxesSettingsDetailInfoFormCol>
      </AdminBoxesSettingsDetailInfoFormRow>
      {innerInputs}
      <AdminBoxesSettingsDetailInfoFormRow>
        <AdminBoxesSettingsDetailInfoFormControls>
          <Button
            type="submit"
            disabled={!isValid || isSubmitting || !canWrite}
          >
            {t("common.save")}
          </Button>
        </AdminBoxesSettingsDetailInfoFormControls>
      </AdminBoxesSettingsDetailInfoFormRow>
    </AdminBoxesSettingsDetailInfoFormMain>
  );
};
export default AdminBoxesSettingsDetailInfoForm;
