import styled from "styled-components";
import { Button, IconButton } from "../../../../styles/button";
import { COLORS } from "../../../../utils/constants";

export const AdminClientsDetailTabsLoader = styled.div`
  height: 4rem;
  position: relative;
`;

export const WebcamContainer = styled.div`
  position: relative;
  width: 640px;
  height: 480px;
  background-color: ${COLORS.secondary};
  border-radius: 8px;
  margin-top: 1rem;

  video {
    border-radius: 8px;
  }
`;

export const WebcamButton = styled(Button)`
  position: absolute;
  z-index: 10;
  top: 0.5rem;
  right: 0.5rem;
`;

export const WebcamDeleteIcon = styled(IconButton)`
  position: absolute;
  z-index: 10;
  top: 0.5rem;
  right: 0.75rem;
`;

export const WebcamRotateIcons = styled.div`
  position: absolute;
  z-index: 10;
  top: 0.7rem;
  left: 0.75rem;
`;

export const WebcamRotateIcon = styled(IconButton)`
  margin-right: 0.2rem;
`;

export const WebcamError = styled.div`
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  font-size: 1.25rem;
  font-weight: bold;
  color: ${COLORS.red};
  text-align: center;
`;

export const WebcamScreenshots = styled.div`
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  max-width: 640px;
`;

export const WebcamScreenshot = styled.img`
  display: inline-block;
  border-radius: 8px;
  width: 100%;
  height: auto;
`;

export const WebcamScreenshotContainer = styled.div`
  position: relative;
`;
