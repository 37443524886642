import { Form, Formik } from "formik";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import * as Yup from "yup";
import boxApi from "../../../../api/box";
import { Button } from "../../../../styles/button";
import { getErrorMessage } from "../../../../utils/errors";
import { SetState } from "../../../../utils/types";
import CustomModal from "../../../common/CustomModal/CustomModal";
import Input from "../../../common/form/Input/Input";

const AdminBoxesSettingsDetailInfoTotalModal: FC<{
  open: boolean;
  setOpen: SetState<boolean>;
  defaultTotalCount: number;
  setCurrentTotalCount: React.Dispatch<React.SetStateAction<number>>;
}> = ({ open, setOpen, defaultTotalCount, setCurrentTotalCount }) => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const boxId = id;

  const handleSubmit = async (values: { totalCount: number }) => {
    try {
      await boxApi.setBoxTotalCount(parseInt(boxId), 1, values.totalCount);
      toast.success(t("common.saveSuccess"));
      setCurrentTotalCount(values.totalCount);
      setOpen(false);
    } catch (err) {
      toast.error(getErrorMessage(err, t("errors.saveError")));
    }
  };

  return (
    <Formik
      initialValues={{ totalCount: defaultTotalCount }}
      enableReinitialize
      onSubmit={handleSubmit}
      validationSchema={Yup.object({
        totalCount: Yup.number()
          .positive(t("errors.positiveInteger"))
          .integer(t("errors.positiveInteger"))
          .required(t("errors.required")),
      })}
    >
      {({ isSubmitting, isValid, touched, errors, submitForm, resetForm }) => (
        <CustomModal
          title={t("adminBoxesSettingsDetail.totalModal.title")}
          open={open}
          setOpen={(state) => {
            resetForm();
            setOpen(state);
          }}
          actions={
            <>
              <Button
                type="button"
                ver="secondary"
                disabled={isSubmitting}
                onClick={() => {
                  resetForm();
                  setOpen(false);
                }}
              >
                {t("common.cancel")}
              </Button>
              <Button
                type={"submit"}
                disabled={!isValid || isSubmitting}
                onClick={submitForm}
              >
                {t("common.save")}
              </Button>
            </>
          }
        >
          <Form>
            <Input
              name={"totalCount"}
              type="number"
              tooltipLeft
              error={touched["totalCount"] && !!errors["totalCount"]}
            />
          </Form>
        </CustomModal>
      )}
    </Formik>
  );
};

export default AdminBoxesSettingsDetailInfoTotalModal;
