import styled from "styled-components";
import { size } from "../utils/styles";
import { H3, H4 } from "./title";
import { COLORS } from "../utils/constants";

export const Card = styled.div<{
  overflowX?: string;
  background?: string;
  padding?: string;
}>`
  padding: ${({ padding }) => padding || "1rem"};

  background-color: ${({ background }) => background || COLORS.whitesmoke};
  overflow-x: ${({ overflowX }) => overflowX || "initial"};
`;

export const CardHeader = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 1rem;

  ${H3} {
    margin-bottom: 0;
  }

  > *:not(:last-child) {
    margin-right: 1rem;
  }
`;

export const CardList = styled.div<{ cols: number; rowGap?: string }>`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(${({ cols }) => cols}, auto);
  grid-column-gap: 1.5rem;
  grid-row-gap: ${({ rowGap }) => rowGap || "1rem"};
`;

export const NoCardData = styled.div`
  width: 100%;
  padding-top: 0.5rem;
  text-align: center;
  font-size: 1.1rem;
`;

export const ContactCard = styled.section`
  height: 100%;
  width: 100%;
  padding: 0.75rem 1rem;
  background-color: var(--whitesmoke);

  overflow-x: auto;

  &:not(:last-child) {
    margin-bottom: 0.75rem;
  }
`;

export const ContactCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  flex-flow: column;

  margin-bottom: 0.75rem;

  ${H3} {
    margin-bottom: 0.25rem;

    svg {
      margin-left: 0.75rem;
      transition: all 0.2s ease-in-out;

      &:hover {
        color: var(--primary-dark);
        cursor: pointer;
      }
    }
  }

  @media screen and (min-width: ${size.mobile}) {
    flex-flow: row;

    ${H3} {
      margin-bottom: 0;
    }
  }
`;

export const ContactCardSubHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;

  ${H4} {
    margin-bottom: 0;
  }
`;

export const ContactCardContent = styled.div`
  display: flex;
  flex-flow: column;

  b {
    margin-bottom: 0.5rem;
  }

  @media screen and (min-width: ${size.mobile}) {
    display: grid;
    grid-template-columns: 10rem 1fr;
    grid-gap: 0.4rem;

    b {
      margin-bottom: 0;
    }
  }
`;

// keeping of original code
export const ContactCardDescriptions = styled(ContactCardContent)``;

export const ContactCardContentNoGrid = styled.div`
  position: relative;
  min-height: 3rem;
`;

export const ContactCardItems = styled.div`
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
`;
