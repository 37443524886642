import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { NamedProps } from "react-select";
import { InputContainer, InputRow } from "../../../../styles/form";
import FormError from "../FormError/FormError";
import { SelectLabel, StyledSelect } from "./styles";

interface Props extends NamedProps {
  name?: string;
  label?: string;
  labelWidth?: string;
  error?: boolean;
  inputWidth?: string;
}

const Select: FC<Props> = ({
  name,
  label,
  labelWidth,
  error,
  inputWidth,
  placeholder,
  ...restProps
}) => {
  const { t } = useTranslation();

  return (
    <InputRow>
      {label && (
        <SelectLabel width={labelWidth} htmlFor={name}>
          {label}
        </SelectLabel>
      )}
      <InputContainer>
        <StyledSelect
          classNamePrefix={"Select"}
          $error={error}
          width={inputWidth}
          name={name}
          placeholder={placeholder || t("common.searchPlaceholder")}
          menuPosition="fixed"
          {...restProps}
        />
        {name && error && <FormError name={name} />}
      </InputContainer>
    </InputRow>
  );
};

export default Select;
