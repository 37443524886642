import styled, { css } from "styled-components";

export const Divider = styled.div<{ my?: string }>`
  width: 100%;
  height: 1px;
  background-color: var(--secondary);

  ${({ my }) =>
    my &&
    css`
      margin: ${my} 0;
    `}
`;

export const DividerVert = styled.div<{ mx?: boolean }>`
  height: auto;
  width: 1px;
  background-color: var(--secondary);

  ${({ mx }) =>
    mx &&
    css`
      margin: 0 1rem;
    `}
`;
