import styled from "styled-components";
import { Button } from "../../styles/button";
import { COLORS } from "../../utils/constants";
import { size } from "../../utils/styles";

export const OrderPageActions = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 1.5rem;
`;

export const SpaceBetween = styled.div`
  height: 6rem;
`;

export const SubmitButton = styled(Button)`
  font-size: 1rem;
  margin-left: 1rem;

  @media screen and (min-width: ${size.tablet}) {
    font-size: 1.5rem;
  }
`;

export const OrderGroup = styled.div`
  position: relative;
`;

export const OrderGroupNumber = styled.div`
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 0;
  left: -3rem;
  border: 2px solid var(--secondary);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;

  @media screen and (max-width: 1700px) {
    display: none;
  }
`;

export const BottomText = styled.p`
  margin-top: 2rem;
`;

export const TotalPriceHeader = styled.div`
  color: ${COLORS.white};
  font-weight: bold;
  font-size: 1.5rem;
  margin-right: 0.5rem;
`;
