import { useFormikContext } from "formik";
import React, { FC } from "react";
import FormError from "../FormError/FormError";
import { InputField } from "./styles";

export interface FormTextAreaProps {
  name: string;
  label?: string;
  error?: boolean;
  inputWidth?: string;
  inputHeight?: string;
  disabled?: boolean;
  tooltipLeft?: boolean;
  maxLength?: number;
  onChange?: (value?: string) => void;
}

const FormTextArea: FC<FormTextAreaProps> = ({
  name,
  label,
  error,
  inputWidth,
  inputHeight,
  disabled,
  tooltipLeft,
  maxLength,
  onChange,
}) => {
  const { handleChange } = useFormikContext<any>();

  return (
    <div>
      {label && (
        <div>
          <label htmlFor={name}>{label}</label>
        </div>
      )}
      <InputField
        component={"textarea"}
        type={"textarea"}
        style={{ resize: "none" }}
        id={name}
        name={name}
        $error={error}
        width={inputWidth ?? "100%"}
        height={inputHeight}
        disabled={disabled}
        maxLength={maxLength}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          handleChange(event);
          onChange?.(event.target.value);
        }}
      />
      {error && <FormError name={name} tooltipLeft={tooltipLeft} />}
    </div>
  );
};

export default FormTextArea;
