import styled from "styled-components";
import { COLORS } from "../../../../utils/constants";

export const CardStateIcon = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;

  svg {
    font-size: 0.8rem;
  }
`;

export const CardStateContainer = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  color: ${({ active }) => (active ? COLORS.green : COLORS.secondaryDark)};

  ${CardStateIcon} {
    background-color: ${({ active }) =>
      active ? COLORS.green : COLORS.secondaryDark};
  }
`;
