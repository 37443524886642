import api from "../utils/api";
import { AxiosResponse } from "axios";
import { ScheduledTask } from "../models/scheduledTask";

const getScheduledTasks = (): Promise<AxiosResponse<ScheduledTask[]>> =>
  api.get(`/api/ScheduledTask`);

const setScheduledTask = (
  id: string,
  isActive: boolean
): Promise<AxiosResponse<boolean>> =>
  api.put(`/api/ScheduledTask/${id}/IsActive`, { isActive });

const scheduledTasksApi = {
  getScheduledTasks,
  setScheduledTask,
};

export default scheduledTasksApi;
