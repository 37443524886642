import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { faCreditCard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatDateTime } from "../../../../utils/formats";
import { OverflowX } from "../../../../utils/styles";
import { AdminClientsDetailTabsLoader } from "../../../admin/AdminClientsDetail/AdminClientsDetailTabs/styles";
import Loader from "../../../common/Loader/Loader";
import {
  CardsAccessesData,
  CardsAccessesTableContainer,
  CardsAccessesTableFlat,
} from "./styles";
import { QueueHistory } from "../../../../models/queue";
import { COLORS } from "../../../../utils/constants";
import { NoTableData } from "../../../../styles/table";

const CardsAccesses: FC<{
  flat?: boolean;
  data?: QueueHistory[];
  isLoading?: boolean;
}> = ({ flat, data, isLoading }) => {
  const { t } = useTranslation();

  const tableCols = (
    <tr>
      {[
        t("dashboard.accesses.table.head.date"),
        t("dashboard.accesses.table.head.card"),
      ].map((col) => (
        <th key={col} style={{ width: "50%" }}>
          {col}
        </th>
      ))}
    </tr>
  );

  const tableData = useMemo(
    () =>
      data?.map((row) => (
        <tr key={row.id}>
          <td>
            <div>{formatDateTime(row.date)}</div>
          </td>
          <td>
            <CardsAccessesData>
              <FontAwesomeIcon icon={faCreditCard} color={COLORS.primary} />
              <b>{row.cardName}</b>
            </CardsAccessesData>
          </td>
        </tr>
      )),
    [data]
  );

  const TableContainer: FC = ({ children }) =>
    flat ? (
      <CardsAccessesTableFlat>{children}</CardsAccessesTableFlat>
    ) : (
      <CardsAccessesTableContainer>{children}</CardsAccessesTableContainer>
    );

  return data?.length || isLoading ? (
    <OverflowX>
      <TableContainer>
        <thead>{tableCols}</thead>
        <tbody>
          {isLoading ? (
            <AdminClientsDetailTabsLoader>
              <Loader />
            </AdminClientsDetailTabsLoader>
          ) : (
            tableData
          )}
        </tbody>
      </TableContainer>
    </OverflowX>
  ) : (
    <NoTableData>{t("dashboard.accesses.table.noData")}</NoTableData>
  );
};

export default CardsAccesses;
