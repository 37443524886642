import styled from "styled-components";
import { Card } from "../../../../styles/card";
import { H4 } from "../../../../styles/title";

export const CardOrdersContainer = styled.div`
  height: 100%;
  overflow-y: auto;
  position: relative;
`;

export const CardOrdersOverviews = styled.div`
  font-size: 0.9rem;

  > ${Card}:not(:last-child) {
    margin-bottom: 0.5rem;
  }
`;

export const CardOrdersOverviewsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.75rem;

  ${H4} {
    margin-bottom: 0;
  }
`;

export const CardOrdersOverviewHeader = styled.div<{ color: string }>`
  margin-bottom: 0.25rem;
  font-weight: bold;
  color: ${({ color }) => color};
`;

export const CardOrdersOverviewContent = styled.div`
  width: max-content;
  min-width: 100%;
  display: grid;
  grid-template-columns: repeat(4, calc(25% - 0.5rem));
  grid-column-gap: 0.5rem;
  grid-row-gap: 0.25rem;
  align-items: center;

  > div {
    overflow: hidden;
    white-space: pre;
    text-overflow: ellipsis;
  }
`;
