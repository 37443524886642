import { faTimes } from "@fortawesome/free-solid-svg-icons";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { QueueItem, QueueItemState } from "../../../models/queue";
import {
  QueueRowContainer,
  QueueRowDequeue,
  QueueRowDoor,
  QueueRowInfo,
  QueueRowNumber,
  QueueRowStateContainer,
} from "./styles";

const QueueRow: FC<{
  item: QueueItem;
  dequeue: (id: string) => void;
}> = ({ item, dequeue }) => {
  const { t } = useTranslation();

  return (
    <QueueRowContainer state={item.state}>
      <QueueRowDequeue
        icon={faTimes}
        size="2x"
        onClick={() => dequeue(item.id)}
      />
      <QueueRowInfo>
        {item.state !== QueueItemState.Inside && (
          <QueueRowNumber>**{item.cardNumber.slice(-4)}</QueueRowNumber>
        )}
        <QueueRowStateContainer inside={item.state === QueueItemState.Inside}>
          {t(`queue.${item.state}`)}
        </QueueRowStateContainer>
      </QueueRowInfo>
      <QueueRowDoor>
        <div>{item.doorNumber}</div>
      </QueueRowDoor>
    </QueueRowContainer>
  );
};

export default QueueRow;
