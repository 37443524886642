import { AxiosResponse } from "axios";
import { AdminClientsFilter } from "../components/admin/AdminClients/AdminClientsContent/AdminClientsContent";
import { AdminEmployeesFilter } from "../components/admin/AdminEmloyees/AdminEmployeesContent/AdminEmployeesContent";
import { UserRights } from "../models/auth";
import { Paged } from "../models/common";
import { Employee } from "../models/employee";
import { User, UserImage, UserList } from "../models/user";
import api from "../utils/api";

export const getUser = async (
  customerId: string
): Promise<AxiosResponse<User>> => api.get(`/api/Customer/${customerId}`);

export const updateUser = async (data: User): Promise<AxiosResponse<boolean>> =>
  api.put(`/api/Customer/${data.id}`, data);

export const updateIsCustomerActive = async (
  customerId: string,
  isCustomerActive: boolean
): Promise<AxiosResponse<boolean>> =>
  api.put(`/api/Customer/${customerId}/IsCustomerActive/${isCustomerActive}`);

export const getEmployees = async (
  params?: AdminEmployeesFilter
): Promise<AxiosResponse<Paged<UserList>>> =>
  api.get(`/api/Employee`, { params });

export const getEmployee = async (
  employeeId: string
): Promise<AxiosResponse<Employee>> => api.get(`/api/Employee/${employeeId}`);

export const getRoleRights = async (
  roleName: string
): Promise<AxiosResponse<UserRights>> =>
  api.get(`/api/Employee/RoleRights/${roleName}`);

export const updateEmployee = async (
  employeeId: string,
  data: Employee
): Promise<AxiosResponse<boolean>> =>
  api.put(`/api/Employee/${employeeId}`, data);

export const createEmployee = async (
  data: Employee
): Promise<AxiosResponse<boolean>> => api.post(`/api/Employee`, data);

export const getClients = async (
  branchId: number,
  params?: AdminClientsFilter
): Promise<AxiosResponse<Paged<UserList>>> =>
  api.get(`/api/Branch/${branchId}/Customer`, { params });

export const getUserImages = (
  customerId: string
): Promise<AxiosResponse<UserImage[]>> =>
  api.get(`/api/Customer/${customerId}/Image`);

export const getUserImage = (
  customerId: string,
  imageId: string
): Promise<AxiosResponse<Blob>> =>
  api.get(`/api/Customer/${customerId}/Image/${imageId}`, {
    responseType: "blob",
  });

export const uploadUserImage = (
  customerId: string,
  image: Blob
): Promise<AxiosResponse<UserImage>> => {
  const formData = new FormData();
  formData.append("image", image);
  return api.post(`/api/Customer/${customerId}/Image`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const deleteUserImage = (
  customerId: string,
  imageId: string
): Promise<AxiosResponse<void>> =>
  api.delete(`/api/Customer/${customerId}/Image/${imageId}`);

export const deleteUser = (customerId: number): Promise<AxiosResponse<void>> =>
  api.delete(`/api/Customer/${customerId}`);

export const rotateUserImage = (
  customerId: string,
  imageId: string,
  direction: number
): Promise<AxiosResponse<Blob>> =>
  api.put(
    `/api/Customer/${customerId}/Image/${imageId}/Rotate/${direction}`,
    undefined,
    {
      responseType: "blob",
    }
  );

const userApi = {
  getUser,
  updateUser,
  updateIsCustomerActive,
  getEmployees,
  getEmployee,
  getRoleRights,
  updateEmployee,
  createEmployee,
  getClients,
  getUserImages,
  getUserImage,
  uploadUserImage,
  deleteUserImage,
  deleteUser,
  rotateUserImage,
};

export default userApi;
