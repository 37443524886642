import { Formik } from "formik";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import * as Yup from "yup";
import settingsApi from "../../../../api/settings";
import {
  BasicSettings,
  BasicSettingsExtended,
} from "../../../../models/settings";
import { Card } from "../../../../styles/card";
import { FadeInBottom } from "../../../../styles/fadeIn";
import { PageSection } from "../../../../styles/layout";
import { H2 } from "../../../../styles/title";
import { getErrorMessage } from "../../../../utils/errors";
import Loader from "../../../common/Loader/Loader";
import AdminBasicSettingsForm from "../AdminBasicSettingsForm/AdminBasicSettingsForm";

const basicSettingsInitial: BasicSettingsExtended = {
  reservationOfferDays: 0,
  invoicesDueDays: 0,
  boxStopAccessAfterDays: 0,
  notifyUserBeforeDays: 0,
  logoutCustomerAfterMinutes: 20,
  logoutEmployeeAfterMinutes: 60,
  logoutCustomerAfterMinutesCheckbox: false,
  logoutEmployeeAfterMinutesCheckbox: false,
};

const AdminBasicSettingsContent: FC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const [basicSettings, setBasicSettings] =
    useState<BasicSettingsExtended>(basicSettingsInitial);
  const integerValidator = Yup.number()
    .integer(t("errors.positiveInteger"))
    .positive(t("errors.positiveInteger"))
    .required(t("errors.required"));

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const response = await settingsApi.getBasicSettings();
        const basicSettingsData = response.data as BasicSettingsExtended;

        basicSettingsData.logoutCustomerAfterMinutesCheckbox =
          !!basicSettingsData.logoutCustomerAfterMinutes;
        basicSettingsData.logoutEmployeeAfterMinutesCheckbox =
          !!basicSettingsData.logoutEmployeeAfterMinutes;

        basicSettingsData.logoutCustomerAfterMinutes =
          basicSettingsData.logoutCustomerAfterMinutes ??
          basicSettingsInitial.logoutCustomerAfterMinutes;

        basicSettingsData.logoutEmployeeAfterMinutes =
          basicSettingsData.logoutEmployeeAfterMinutes ??
          basicSettingsInitial.logoutEmployeeAfterMinutes;

        setBasicSettings(basicSettingsData);
      } catch (err) {
        toast.error(getErrorMessage(err, t("errors.loadError")));
      }
      setLoading(false);
    })();
  }, [t]);

  const handleSubmit = async (values: BasicSettingsExtended) => {
    const basicSettings: BasicSettings = {
      reservationOfferDays: values.reservationOfferDays,
      invoicesDueDays: values.invoicesDueDays,
      boxStopAccessAfterDays: values.boxStopAccessAfterDays,
      notifyUserBeforeDays: values.notifyUserBeforeDays,
      logoutCustomerAfterMinutes: values.logoutCustomerAfterMinutesCheckbox
        ? values.logoutCustomerAfterMinutes
        : null,
      logoutEmployeeAfterMinutes: values.logoutEmployeeAfterMinutesCheckbox
        ? values.logoutEmployeeAfterMinutes
        : null,
    };

    try {
      await settingsApi.updateBasicSettings(basicSettings);
      toast.success(t("common.saveSuccess"));
    } catch (err) {
      toast.error(getErrorMessage(err, t("errors.saveError")));
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <FadeInBottom>
          <H2>{t("adminBasicSettings.title")}</H2>
          <PageSection>
            <Card overflowX={"auto"}>
              <Formik
                initialValues={basicSettings}
                enableReinitialize
                validateOnMount
                onSubmit={handleSubmit}
                validationSchema={Yup.object({
                  reservationOfferDays: integerValidator,
                  invoicesDueDays: integerValidator,
                  boxStopAccessAfterDays: integerValidator,
                  notifyUserBeforeDays: integerValidator,
                  logoutCustomerAfterMinutesCheckbox: Yup.boolean(),
                  logoutCustomerAfterMinutes: Yup.mixed().when(
                    "logoutCustomerAfterMinutesCheckbox",
                    {
                      is: true,
                      then: integerValidator,
                    }
                  ),
                  logoutEmployeeAfterMinutesCheckbox: Yup.boolean(),
                  logoutEmployeeAfterMinutes: Yup.mixed().when(
                    "logoutEmployeeAfterMinutesCheckbox",
                    {
                      is: true,
                      then: integerValidator,
                    }
                  ),
                })}
              >
                <AdminBasicSettingsForm />
              </Formik>
            </Card>
          </PageSection>
        </FadeInBottom>
      )}
    </>
  );
};

export default AdminBasicSettingsContent;
