import BoxS from "../images/box-S.png";
import BoxM from "../images/box-M.png";
import BoxL from "../images/box-L.png";
import BoxXL from "../images/box-XL.png";
import BoxXXL from "../images/box-XXL.png";

export const getBoxImg = (type: string) => {
  switch (type) {
    case "S":
      return BoxS;
    case "M":
      return BoxM;
    case "L":
      return BoxL;
    case "XL":
      return BoxXL;
    case "XXL":
      return BoxXXL;
  }
};
