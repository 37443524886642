import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { COLORS } from "../utils/constants";
import { fadeIn, size } from "../utils/styles";

export const CalendarContainer = styled.div`
  width: 100%;
`;

export const CalendarHeader = styled.div<{ justifyContent?: "space-between" }>`
  display: grid;
  grid-auto-columns: 1fr;
  grid-gap: 1rem;
  margin: 1rem 0;

  @media screen and (min-width: ${size.tablet}) {
    display: flex;
    align-items: center;
    justify-content: ${({ justifyContent }) => justifyContent || "flex-end"};
  }
`;

export const CalendarContent = styled.div`
  display: flex;
  animation: ${fadeIn} 0.15s ease-in-out;
`;

export const CalendarTimesCol = styled.div`
  padding-top: 5rem;
  padding-right: 0.5rem;
`;

export const CalendarTimeCell = styled.div`
  height: 3rem;
  user-select: none;
`;

export const CalendarTime = styled.div`
  transform: translateY(-50%);
`;

export const CalendarGridWrapper = styled.div`
  width: 100%;
  overflow: hidden;
`;

export const CalendarGrid = styled.div`
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  margin: -0.25rem;
`;

export const CalendarCell = styled.div<{ pointer?: boolean }>`
  position: relative;
  height: 3rem;
  border-top: 1px solid ${COLORS.secondary};
  border-right: 1px solid ${COLORS.secondary};
  border-left: 1px solid ${COLORS.secondary};
  ${({ pointer }) => pointer && "cursor: pointer"};

  &:last-child {
    border-bottom: 1px solid ${COLORS.secondary};
  }
`;

export const CalendarCellReservationButton = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: ${COLORS.primary};
  color: ${COLORS.white};
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  transition: 0.15s all ease-in-out;

  &:hover {
    background-color: ${COLORS.primaryDark};
  }
`;

export const CalendarCellEventWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: -0.25rem;
  padding: 0.5rem;
  user-select: none;
`;

export const CalendarCellWrapperClosed = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  color: ${COLORS.secondaryDark};
  background-color: ${COLORS.secondary};
`;

export const CalendarCellWrapperPast = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${COLORS.secondary};
`;

export const CalendarCellEvent = styled.div`
  width: 100%;
  margin: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CalendarCellEventAvailable = styled(CalendarCellEvent)`
  color: ${COLORS.white};
  background-color: ${COLORS.primary};
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: ${COLORS.primaryDark};
  }
`;

export const CalendarCellEventUnavailable = styled(CalendarCellEvent)`
  color: ${COLORS.white};
  background-color: ${COLORS.red};
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: ${COLORS.primaryDark};
  }
`;

export const CalendarCellEventReserved = styled(CalendarCellEvent)`
  color: ${COLORS.white};
  background-color: ${COLORS.green};
`;

export const CalendarCellEventText = styled.div`
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 0.85rem;
  animation: ${fadeIn} 0.3s ease-in-out;
`;

export const CalendarCellEventIcon = styled(FontAwesomeIcon)`
  margin-right: 0.25rem;
`;

export const CalendarMeetingConfirmationContainer = styled.div`
  position: sticky;
  bottom: 0;
  display: grid;
  grid-template-columns: 1fr;
  background-color: ${COLORS.white};
  margin-top: 2rem;
  animation: ${fadeIn} 0.15s ease-in-out;

  @media screen and (min-width: ${size.desktop}) {
    grid-template-columns: 2fr 1fr;
    grid-gap: 1rem;
  }
`;

export const CalendarMeetingConfirmationInfo = styled.div`
  display: grid;
  grid-gap: 1rem;
`;

export const CalendarMeetingConfirmationRow = styled.div`
  font-size: 0.85rem;

  @media screen and (min-width: ${size.desktop}) {
    display: flex;
    align-items: center;
    font-size: inherit;
  }
`;

export const CalendarMeetingConfirmationLabel = styled.div`
  display: flex;
`;

export const CalendarMeetingConfirmationIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  min-width: 2rem;
`;

export const CalendarMeetingConfirmationAddr = styled.div`
  margin-left: 2rem;

  @media screen and (min-width: ${size.desktop}) {
    margin-left: 1rem;
  }
`;
