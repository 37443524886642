import {
  faCaretDown,
  faCaretUp,
  faCreditCard,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { UserList } from "../../../../models/user";
import { IconButtonDelete } from "../../../../styles/button";
import { OrderCards } from "../../../../styles/orders";
import { NoTableData } from "../../../../styles/table";
import { useUserHasRight } from "../../../../utils/auth";
import { COLORS } from "../../../../utils/constants";
import { formatDate } from "../../../../utils/formats";
import { useStore } from "../../../../utils/store";
import { OverflowX } from "../../../../utils/styles";
import { OnChangeOrder, OrderType } from "../../../../utils/types";
import CircleStateIsActive from "../../../common/CircleStateIsActive/CircleStateIsActive";
import Loader from "../../../common/Loader/Loader";
import { AdminClientsTableContainer } from "./styles";

const cols: Array<[string, string]> = [
  ["adminClients.table.state", "isCustomerActive"],
  ["adminClients.table.created", "created"],
  ["adminClients.table.fullName", "lastname"],
  ["adminClients.table.email", "email"],
  ["adminClients.table.phone", "phone"],
  ["adminClients.table.cards", ""],
];

const AdminClientsTable: FC<{
  isLoading?: boolean;
  data: UserList[];
  onDelete: (id: number) => void;
  order?: OrderType;
  onChangeOrder?: OnChangeOrder;
}> = ({ isLoading, data, order, onDelete, onChangeOrder }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const user = useStore((store) => store.user);
  const isAdmin = user?.role === "Admin";
  const { hasUserAllRights } = useUserHasRight();
  const canDelete = hasUserAllRights(["deleteCustomers"]);

  const handleOrderChange = useCallback(
    (orderName: string) => {
      if (orderName) {
        onChangeOrder?.(orderName, !Boolean(order?.orderDesc));
      }
    },
    [onChangeOrder, order?.orderDesc]
  );

  return (
    <OverflowX>
      {data.length || isLoading ? (
        <>
          {isLoading && <Loader />}
          <AdminClientsTableContainer>
            <thead>
              <tr>
                {cols.map(([col, orderName]) => (
                  <th key={col} onClick={() => handleOrderChange(orderName)}>
                    {t(col)}{" "}
                    {order?.orderBy === orderName && (
                      <FontAwesomeIcon
                        icon={order?.orderDesc ? faCaretDown : faCaretUp}
                      />
                    )}
                  </th>
                ))}
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data.map((client) => (
                <tr
                  key={client.id}
                  onClick={() => history.push(`/admin/clients/${client.id}`)}
                >
                  <td>
                    <CircleStateIsActive
                      isActive={client.isActive && client.isCustomerActive}
                    />
                  </td>
                  <td>
                    <div>{formatDate(client.created)}</div>
                  </td>
                  <td>
                    {client.firstName}&nbsp;{client.lastName}
                  </td>
                  <td>
                    <div>{client.email}</div>
                  </td>
                  <td>
                    <div>{client.phone}</div>
                  </td>
                  <td>
                    <OrderCards>
                      <FontAwesomeIcon
                        icon={faCreditCard}
                        color={COLORS.primaryDark}
                      />
                      {client.cardsNumbers?.length}
                    </OrderCards>
                  </td>
                  <td>
                    {(isAdmin || canDelete) && (
                      <IconButtonDelete
                        icon={faTimes}
                        size={"lg"}
                        onClick={(event) => {
                          event.stopPropagation();
                          onDelete(client.id);
                        }}
                      />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </AdminClientsTableContainer>
        </>
      ) : (
        <NoTableData>{t("adminClients.noData")}</NoTableData>
      )}
    </OverflowX>
  );
};

export default AdminClientsTable;
