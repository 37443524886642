import styled from "styled-components";
import { size } from "../../../../utils/styles";

export const CardBoxOverviewMain = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 1.3rem;
`;

export const CardBoxOverviewContent = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
`;

export const CardBoxOverviewLegend = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  min-width: 9rem;
`;

export const CardBoxOverviewChart = styled.div`
  position: relative;
  width: 8rem;
  height: 8rem;
  margin: auto 0;

  @media screen and (min-width: ${size.mobile}) {
    width: 13rem;
    height: 13rem;
  }
`;

export const CardBoxOverviewLabel = styled.div`
  display: flex;
  align-items: center;

  margin: 0.5rem 0;
`;

export const CardBoxOverviewCircle = styled.div<{
  color: string;
  borderColor?: string;
}>`
  background-color: ${({ color }) => color};
  color: var(--white);
  border: 1px solid ${({ borderColor }) => borderColor || "transparent"};

  min-width: 1.5rem;
  min-height: 1.5rem;
  border-radius: 50%;
  margin-right: 0.5rem;

  display: flex;
  align-items: center;
  justify-content: center;
`;
