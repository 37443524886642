import styled from "styled-components";
import { IconButtonDelete } from "../../../../styles/button";
import { Table } from "../../../../styles/table";

export const CardsTableContainer = styled(Table)`
  th:nth-child(3) {
    width: max-content;
  }
`;

export const CardsTableDelete = styled(IconButtonDelete)`
  margin-left: 0.3rem;
`;
