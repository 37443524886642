import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import userApi from "../../../../api/user";
import { User } from "../../../../models/user";
import { ContactCard, ContactCardHeader } from "../../../../styles/card";
import { H3 } from "../../../../styles/title";
import { useUserHasRight } from "../../../../utils/auth";
import { COLORS } from "../../../../utils/constants";
import UserInfoCardContent from "../../../common/UserInfo/UserInfoCardContent";
import UserInfoModal from "../../../common/UserInfo/UserInfoModal";
import { transformUserData } from "../../../common/UserInfo/utils";

const ClientInfoCard: FC<{
  userInfo: User;
  reload?: () => void;
}> = ({ userInfo, reload }) => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const { hasUserAllRights } = useUserHasRight();
  const canWrite = hasUserAllRights(["writeCustomerData"]);

  const handleModalFormSubmit = async (data: User, isCompany: boolean) => {
    try {
      await userApi.updateUser(transformUserData(data, isCompany));
      toast.success(t("userInfo.editSuccess"));
      if (reload) reload();
      setModalOpen(false);
    } catch (err) {
      toast.error(t("errors.userUpdate"));
    }
  };

  return (
    <ContactCard>
      <UserInfoModal
        open={modalOpen}
        setOpen={setModalOpen}
        handleSubmit={handleModalFormSubmit}
        user={userInfo}
        showNote={true}
      />
      <ContactCardHeader>
        <H3>
          {t("userInfo.info.title")}
          {canWrite && (
            <FontAwesomeIcon
              icon={faPen}
              size="sm"
              color={COLORS.primary}
              onClick={() => setModalOpen(true)}
            />
          )}
        </H3>
      </ContactCardHeader>
      <UserInfoCardContent userInfo={userInfo} showNote={true} />
    </ContactCard>
  );
};

export default ClientInfoCard;
