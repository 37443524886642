import styled from "styled-components";
import { Table } from "../../../../styles/table";

export const AdminOrdersTableContainer = styled(Table)`
  th,
  td {
    &:nth-child(4) {
      text-align: center;
    }
  }

  tr {
    cursor: pointer;
  }
`;
