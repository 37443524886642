import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import {
  OrderSidebarIconWrapper,
  OrderSidebarNoBoxH1,
  OrderSidebarNoBoxSubtitle,
} from "./styles";

const OrderSidebarNoBox: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <OrderSidebarIconWrapper>
        <FontAwesomeIcon icon={faShoppingCart} color="var(--white)" size="lg" />
      </OrderSidebarIconWrapper>
      <OrderSidebarNoBoxH1 bold uppercase>
        {t(`sidebar.order.noBox.title`)}
      </OrderSidebarNoBoxH1>
      <OrderSidebarNoBoxSubtitle>
        {t(`sidebar.order.noBox.subtitle`)}
      </OrderSidebarNoBoxSubtitle>
    </>
  );
};

export default OrderSidebarNoBox;
