import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faPause } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { COLORS } from "../../../../utils/constants";
import { CardStateContainer, CardStateIcon } from "./styles";

const CardState: FC<{ active: boolean }> = ({ active }) => {
  const { t } = useTranslation();

  return (
    <CardStateContainer active={active}>
      <CardStateIcon>
        <FontAwesomeIcon
          icon={active ? faCheck : faPause}
          color={COLORS.white}
        />
      </CardStateIcon>
      {t(`cards.table.state.${active ? "active" : "inactive"}`)}
    </CardStateContainer>
  );
};

export default CardState;
