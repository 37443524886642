import { AxiosResponse } from "axios";
import { AdditionalService } from "../models/additionalServices";
import api from "../utils/api";

export const getAll = (): Promise<AxiosResponse<AdditionalService[]>> =>
  api.get(`/api/AdditionalServices`);

export const add = (data: AdditionalService): Promise<AxiosResponse<void>> =>
  api.post(`/api/AdditionalServices`, data);

export const update = (data: AdditionalService): Promise<AxiosResponse<void>> =>
  api.put(`/api/AdditionalServices/${data.id}`, data);

const additionalServicesApi = {
  getAll,
  add,
  update,
};

export default additionalServicesApi;
