import { getDate, getMonth, getYear } from "date-fns";

export interface DateAndLabel {
  date: Date;
  timeLabel: Date;
}

export const addZero = (n: number) => ("0" + n).slice(-2);

export const getTimeLabel = (date: Date): string => {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  return `${addZero(hours)}:${addZero(minutes)}`;
};

export const getReservationDateString = (dateAndLabel: DateAndLabel) => {
  const year: string = getYear(dateAndLabel.date).toString();
  const month: string = addZero(getMonth(dateAndLabel.date) + 1);
  const date: string = addZero(getDate(dateAndLabel.date));
  const time: string = getTimeLabel(dateAndLabel.timeLabel);

  return new Date(`${year}-${month}-${date}T${time}`).toUTCString();
};
