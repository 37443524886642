import React, { FC, useEffect } from "react";
import { Button } from "../../../../../styles/button";
import { OrderAutoExtendTitle, OrderEditSubscriptionForm } from "./styles";
import { FormRow } from "../../../../../styles/form";
import Radio from "../../../../common/form/Radio/Radio";
import { Field, useFormikContext } from "formik";
import {
  OrderLeaseLengthLabel,
  OrderLeaseLengthPrice,
  OrderRadioLabel,
} from "../../../../order/OrderParameters/styles";
import { formatCurrency } from "../../../../../utils/formats";
import CustomModal from "../../../../common/CustomModal/CustomModal";
import { useTranslation } from "react-i18next";
import { SetState } from "../../../../../utils/types";

const OrderEditSubscriptionModalContent: FC<{
  open: boolean;
  setOpen: SetState<boolean>;
  fields: { lengthOfLeaseType: string; price: number; months: number }[];
  enableAutoExtend: boolean;
}> = ({ open, setOpen, fields, enableAutoExtend }) => {
  const { t } = useTranslation();
  const {
    isSubmitting,
    submitForm,
    isValid,
    values,
    resetForm,
  } = useFormikContext<{
    lengthOfLeaseType: string;
  }>();

  useEffect(() => {
    if (open) resetForm();
  }, [open, resetForm]);

  return (
    <CustomModal
      open={open}
      setOpen={setOpen}
      title={
        enableAutoExtend
          ? t("orders.detail.editSubscriptionModal.enableAutoRenewTitle")
          : t("orders.detail.editSubscriptionModal.title")
      }
      actions={
        <>
          <Button
            type="button"
            ver="secondary"
            disabled={isSubmitting}
            onClick={() => setOpen(false)}
          >
            {t("common.cancel")}
          </Button>
          <Button disabled={!isValid || isSubmitting} onClick={submitForm}>
            {t("common.save")}
          </Button>
        </>
      }
    >
      <OrderEditSubscriptionForm>
        {enableAutoExtend && (
          <OrderAutoExtendTitle>
            {t("orders.detail.editSubscriptionModal.enableAutoRenew")}
          </OrderAutoExtendTitle>
        )}
        {fields.map((f) => (
          <FormRow key={f.lengthOfLeaseType}>
            <Radio>
              <Field
                type="radio"
                name="lengthOfLeaseType"
                value={f.lengthOfLeaseType}
                checked={values.lengthOfLeaseType === f.lengthOfLeaseType}
              />
              <OrderRadioLabel
                checked={values.lengthOfLeaseType === f.lengthOfLeaseType}
              >
                <OrderLeaseLengthLabel>
                  {t(
                    `order.parameters.lengthOfLeaseType.${f.lengthOfLeaseType}`
                  )}
                </OrderLeaseLengthLabel>
                <OrderLeaseLengthPrice>
                  <div>{formatCurrency(f.price)}</div>
                  <div>
                    ({formatCurrency(f.price / f.months)}
                    {t("common.price.monthly")})
                  </div>
                </OrderLeaseLengthPrice>
              </OrderRadioLabel>
            </Radio>
          </FormRow>
        ))}
      </OrderEditSubscriptionForm>
    </CustomModal>
  );
};

export default OrderEditSubscriptionModalContent;
