import styled, { css } from "styled-components";
import { size } from "../../../utils/styles";
import { SidebarContent } from "../../../styles/sidebar";

export const OrderSidebarContent = styled(SidebarContent)<{ noBox: boolean }>`
  line-height: 1.5;
  padding: 1rem;

  @media screen and (min-width: ${size.tablet}) {
    padding: 1.5rem;
  }

  ${({ noBox }) =>
    noBox &&
    css`
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
    `}
`;

export const OrderSidebarDetails = styled.div`
  padding: 0.5rem;

  span,
  b {
    display: inline-block;
    font-size: 0.9rem;
  }
`;

export const OrderSidebarParameter = styled.div`
  display: flex;
  justify-content: space-between;
  flex-flow: column;

  @media screen and (min-width: ${size.mobile}) {
    flex-flow: row;
  }
`;
