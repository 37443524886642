import React, { FC, useState } from "react";
import { InputField, InputIcon, InputMark, InputTextAfter } from "./styles";
import FormError from "../FormError/FormError";
import { InputContainer, InputRow } from "../../../../styles/form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { COLORS } from "../../../../utils/constants";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { useFormikContext } from "formik";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const Input: FC<{
  name: string;
  label?: string;
  error?: boolean;
  type?: string;
  textAfter?: string;
  inputWidth?: string;
  inputHeight?: string;
  disabled?: boolean;
  tooltipLeft?: boolean;
  onInputChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => boolean;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => boolean;
  onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => boolean;
  innerIcon?: IconProp;
  innerIconColor?: string;
  mark?: string;
  markPosition?: "hidden" | "left" | "right";
  hidePasswordIcon?: boolean;
  maxLength?: number;
}> = ({
  name,
  label,
  error,
  type,
  textAfter,
  inputWidth,
  inputHeight,
  disabled,
  tooltipLeft,
  onInputChange,
  onKeyPress,
  onKeyDown,
  onKeyUp,
  innerIcon,
  innerIconColor,
  mark,
  markPosition,
  hidePasswordIcon,
  maxLength,
}) => {
  const [inputType, setInputType] = useState(type);
  const { handleChange } = useFormikContext<any>();

  return (
    <InputRow>
      {label && <label htmlFor={name}>{label}</label>}
      <InputContainer>
        <InputField
          type={inputType || "text"}
          id={name}
          name={name}
          $error={error}
          width={inputWidth}
          height={inputHeight}
          markposition={markPosition || "hidden"}
          disabled={disabled}
          pattern={inputType === "number" ? "[0-9]*" : undefined}
          inputMode={inputType === "number" ? "numeric" : undefined}
          maxLength={maxLength}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            handleChange(event);
            onInputChange && onInputChange(event);
          }}
          onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
            if (onKeyPress && onKeyPress(event)) {
              handleChange(event);
            }
          }}
          onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
            if (onKeyDown && onKeyDown(event)) {
              handleChange(event);
            }
          }}
          onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
            if (onKeyUp && onKeyUp(event)) {
              handleChange(event);
            }
          }}
        />
        {!hidePasswordIcon && type === "password" && (
          <InputIcon
            $error={error}
            hasHover
            onClick={() =>
              inputType === "password"
                ? setInputType("text")
                : setInputType("password")
            }
          >
            <FontAwesomeIcon icon={faEye} color={COLORS.primaryDark} />
          </InputIcon>
        )}
        {innerIcon && (
          <InputIcon $error={error}>
            <FontAwesomeIcon
              icon={innerIcon}
              color={innerIconColor || COLORS.primaryDark}
            />
          </InputIcon>
        )}
        {textAfter && <InputTextAfter>{textAfter}</InputTextAfter>}
        {error && <FormError name={name} tooltipLeft={tooltipLeft} />}
        {mark && (
          <InputMark position={markPosition} $error={error}>
            {mark}
          </InputMark>
        )}
      </InputContainer>
    </InputRow>
  );
};

export default Input;
