import styled from "styled-components";
import { H1 } from "../../../../styles/title";

export const OrderSidebarBoxH1 = styled(H1)`
  margin-bottom: 0.5rem;

  > :first-child {
    margin-right: 0.5rem;
  }

  > :last-child {
    text-transform: uppercase;
  }
`;

export const OrderSidebarBoxImg = styled.img`
  width: 100%;
  max-width: 20rem;
  height: auto;
  display: block;
  margin: 1rem auto;
`;

export const OrderSidebarBoxDetails = styled.div`
  display: flex;
  justify-content: space-between;
`;
