import React, { FC, useEffect } from "react";
import CustomModal from "../../../common/CustomModal/CustomModal";
import { Button } from "../../../../styles/button";
import CardEditOrders from "../CardEditOrders/CardEditOrders";
import { Divider } from "../../../../styles/divider";
import { FormGroup } from "../../../../styles/form";
import Input from "../../../common/form/Input/Input";
import { Form, useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { SetState } from "../../../../utils/types";
import { Card } from "../../../../models/card";
import { OrderSimple } from "../../../../models/order";

interface FormData {
  name: string;
  number: string;
}

const CardEditModalContent: FC<{
  card: Card;
  open: boolean;
  setOpen: SetState<boolean>;
  admin?: boolean;
  userId?: string;
  orders: OrderSimple[];
  setOrders: SetState<OrderSimple[]>;
  setReload: SetState<boolean>;
}> = ({ card, open, setOpen, admin, userId, orders, setOrders, setReload }) => {
  const { t } = useTranslation();
  const {
    isSubmitting,
    setSubmitting,
    isValid,
    submitForm,
    errors,
    touched,
    resetForm,
  } = useFormikContext<FormData>();

  useEffect(() => {
    if (open) resetForm();
  }, [open, resetForm]);

  return (
    <CustomModal
      title={t("cards.modal.title")}
      open={open}
      setOpen={setOpen}
      actions={
        <>
          <Button
            type="button"
            ver="secondary"
            disabled={isSubmitting}
            onClick={() => setOpen(false)}
          >
            {t("common.cancel")}
          </Button>
          {!admin && (
            <Button
              disabled={!isValid || isSubmitting}
              onClick={() => submitForm()}
            >
              {t("common.save")}
            </Button>
          )}
        </>
      }
    >
      <Form>
        <CardEditOrders
          cardId={card.id}
          cardOrders={orders}
          setCardOrders={setOrders}
          isSubmitting={isSubmitting}
          setSubmitting={setSubmitting}
          userId={userId}
          setReload={setReload}
        />
        <Divider my="1.5rem" />
        <FormGroup>
          {!admin && (
            <Input
              key="name"
              name="name"
              label={t(`cards.modal.name`)}
              error={!!errors["name"] && touched["name"]}
            />
          )}
          <Input
            key="number"
            name="number"
            label={t(`cards.modal.number`)}
            error={!!errors["number"] && touched["number"]}
            disabled={true}
          />
        </FormGroup>
      </Form>
    </CustomModal>
  );
};

export default CardEditModalContent;
