import {
  IDLE_MINUTES_KEY,
  TOKEN_KEY,
  TOKEN_REFRESH_TIMEOUT_KEY,
  TOKEN_TIMEOUT_KEY,
  USER_KEY,
} from "../utils/constants";
import { User } from "../models/user";

export const getCurrentUser = (): User | null => {
  const user = localStorage.getItem(USER_KEY);
  return user ? JSON.parse(user) : null;
};

export const setCurrentUser = (user: User) => {
  localStorage.setItem(USER_KEY, JSON.stringify(user));
};

export const getToken = (): string | null => localStorage.getItem(TOKEN_KEY);

export const setToken = (token: string) => {
  localStorage.setItem(TOKEN_KEY, token);
};

export const getIdleMinutes = (): number | null => {
  const timeout = localStorage.getItem(IDLE_MINUTES_KEY);
  return timeout ? parseInt(timeout) : null;
};

export const setIdleMinutes = (timeout: number) => {
  localStorage.setItem(IDLE_MINUTES_KEY, timeout.toString());
};

export const getTokenRefreshTimeout = (): Date | null => {
  const timeout = localStorage.getItem(TOKEN_REFRESH_TIMEOUT_KEY);
  return timeout ? new Date(timeout) : null;
};

export const setTokenRefreshTimeout = (timeout: Date) => {
  localStorage.setItem(TOKEN_REFRESH_TIMEOUT_KEY, timeout.toISOString());
};

export const getTokenTimeout = (): Date | null => {
  const timeout = localStorage.getItem(TOKEN_TIMEOUT_KEY);
  return timeout ? new Date(timeout) : null;
};

export const setTokenTimeout = (timeout: Date) => {
  localStorage.setItem(TOKEN_TIMEOUT_KEY, timeout.toISOString());
};

const storageApi = {
  getCurrentUser,
  setCurrentUser,
  setToken,
  getToken,
  getIdleMinutes,
  setIdleMinutes,
  getTokenRefreshTimeout,
  setTokenRefreshTimeout,
  getTokenTimeout,
  setTokenTimeout,
};

export default storageApi;
