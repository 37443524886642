import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { IconButton } from "../../../../styles/button";
import Dropdown from "rc-dropdown";
import { DropdownMenu, DropdownMenuItem } from "../../../../styles/dropdown";
import { useHistory } from "react-router";

const OrdersTableMenu: FC<{
  openDetailModal: () => void;
  orderId: string;
  admin?: boolean;
}> = ({ openDetailModal, orderId, admin }) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Dropdown
      trigger={["click"]}
      overlay={
        <DropdownMenu>
          <DropdownMenuItem key="boxDetail" onClick={() => openDetailModal()}>
            {t("orders.table.actions.boxDetail")}
          </DropdownMenuItem>
          <DropdownMenuItem
            key="orderDetail"
            onClick={() =>
              history.push(
                admin ? `/admin/orders/${orderId}` : `/orders/${orderId}`
              )
            }
          >
            {t("orders.table.actions.orderDetail")}
          </DropdownMenuItem>
        </DropdownMenu>
      }
      animation="slide-up"
      placement="bottomRight"
    >
      <div>
        <IconButton icon={faEllipsisH} size="lg" />
      </div>
    </Dropdown>
  );
};

export default OrdersTableMenu;
