import React, { FC } from "react";
import {
  HeaderLink,
  HeaderLinkButton,
  HeaderLinks,
  HeaderLoginButton,
} from "./styles";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { SetState } from "../../../../utils/types";
import { size } from "../../../../utils/styles";
import { useStore } from "../../../../utils/store";
import { Link } from "react-router-dom";
import { ReactComponent as UserImg } from "../../../../images/user.svg";

const links = [
  { id: "how-it-works", href: "https://www.safeboxes.cz/cs/jak-to-funguje" },
  { id: "faq", href: "https://www.safeboxes.cz/cs/otazky-a-odpovedi" },
  { id: "contact", href: "https://www.safeboxes.cz/cs/o-nas-kontakt" },
];

const HeaderMenu: FC<{
  open: boolean;
  setOpen: SetState<boolean>;
}> = ({ open, setOpen }) => {
  const { t } = useTranslation();
  const user = useStore((store) => store.user);
  const isLaptop = useMediaQuery({
    query: `(min-width: ${size.laptop})`,
  });
  const showMenu = !user || user.role === "Customer";

  return (
    <HeaderLinks open={open || isLaptop}>
      {showMenu && (
        <>
          {links.map((l) => (
            <li key={l.id} onClick={() => setOpen(false)}>
              <HeaderLink as="a" href={l.href}>
                {t(`header.nav.${l.id}`)}
              </HeaderLink>
            </li>
          ))}
          <li key="order" onClick={() => setOpen(false)}>
            <HeaderLinkButton as={Link} to="/order">
              {t("header.nav.order")}
            </HeaderLinkButton>
          </li>
        </>
      )}
      <li key="login" onClick={() => setOpen(false)}>
        <HeaderLoginButton to="/">
          <UserImg />
        </HeaderLoginButton>
      </li>
    </HeaderLinks>
  );
};

export default HeaderMenu;
