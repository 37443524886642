import React, { FC, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import documentApi from "../../../../api/document";
import { Document } from "../../../../models/document";
import { Button } from "../../../../styles/button";
import { useUserHasRight } from "../../../../utils/auth";
import { DocumentTemplateType } from "../../../../utils/enums";
import { getErrorMessage } from "../../../../utils/errors";
import Tabs, { TabItem } from "../../../common/Tabs/Tabs";
import EditOrderDocumentsModal from "../AddDocumentModal/EditOrderDocumentsModal";
import AddCardModal from "./AddCardModal/AddCardModal";
import AdminClientsDetailCards from "./AdminClientsDetailCards";
import AdminClientsDetailDocuments from "./AdminClientsDetailDocuments";
import AdminClientsDetailHistory from "./AdminClientsDetailHistory";
import AdminClientsDetailImages from "./AdminClientsDetailImages";
import AdminClientsDetailInvoices from "./AdminClientsDetailInvoices";
import AdminClientsDetailMeetings from "./AdminClientsDetailMeetings";
import AdminClientsDetailOrders from "./AdminClientsDetailOrders";

const AdminClientsDetailTabs: FC<{ reloadNonSignedDocuments: () => void }> = ({
  reloadNonSignedDocuments,
}) => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [addCard, setAddCard] = useState(false);
  const [reloadCards, setReloadCards] = useState(false);
  const [addDocument, setAddDocument] = useState(false);
  const [reloadDocuments, setReloadDocuments] = useState(false);
  const [pairedDocuments, setPairedDocuments] = useState<Document[]>([]);

  const handleAddedDocument = async () => {
    setReloadDocuments(true);
    reloadNonSignedDocuments();
  };

  const handleAddDocument = useCallback(async () => {
    try {
      const response = await documentApi.getUserDocuments(id);
      setPairedDocuments(response.data);
      setAddDocument(true);
    } catch (err) {
      toast.error(getErrorMessage(err, t("errors.loadError")));
    }
  }, [id, t]);

  const { hasUserAllRights } = useUserHasRight();

  const tabItems = useMemo(() => {
    const items: TabItem[] = [];

    if (hasUserAllRights(["readOrders"])) {
      items.push({
        tabLabel: t("adminClientsDetail.tabs.orders"),
        tab: <AdminClientsDetailOrders />,
      });
    }
    if (hasUserAllRights(["readCustomerDocuments"])) {
      items.push({
        tabLabel: t("adminClientsDetail.tabs.documents"),
        tab: <AdminClientsDetailDocuments reload={reloadDocuments} />,
        tabAction: hasUserAllRights(["writeCustomerDocuments"]) ? (
          <Button $small onClick={() => handleAddDocument()}>
            {t("adminClientsDetail.tabActions.addDocument")}
          </Button>
        ) : undefined,
      });
    }
    if (hasUserAllRights(["readCustomerImages"])) {
      items.push({
        tabLabel: t("adminClientsDetail.tabs.images"),
        tab: <AdminClientsDetailImages />,
      });
    }
    if (hasUserAllRights(["readCards"])) {
      items.push({
        tabLabel: t("adminClientsDetail.tabs.cards"),
        tab: <AdminClientsDetailCards reload={reloadCards} />,
        tabAction: hasUserAllRights(["createCards"]) ? (
          <Button $small onClick={() => setAddCard(true)}>
            {t("adminClientsDetail.tabActions.addCard")}
          </Button>
        ) : undefined,
      });
    }
    if (hasUserAllRights(["readInvoices"])) {
      items.push({
        tabLabel: t("adminClientsDetail.tabs.invoices"),
        tab: <AdminClientsDetailInvoices />,
      });
    }
    if (hasUserAllRights(["readHistory"])) {
      items.push({
        tabLabel: t("adminClientsDetail.tabs.history"),
        tab: <AdminClientsDetailHistory />,
      });
    }
    if (hasUserAllRights(["readCustomerCalendar"])) {
      items.push({
        tabLabel: t("adminClientsDetail.tabs.meetings"),
        tab: <AdminClientsDetailMeetings />,
        tabAction: hasUserAllRights(["reserveCustomerCalendar"]) ? (
          <Button $small onClick={() => history.push(`/admin/meetings/${id}`)}>
            {t("adminClientsDetail.tabActions.addMeeting")}
          </Button>
        ) : undefined,
      });
    }

    return items.length ? items : undefined;
  }, [
    handleAddDocument,
    hasUserAllRights,
    history,
    id,
    reloadCards,
    reloadDocuments,
    t,
  ]);

  return (
    <>
      <AddCardModal
        open={addCard}
        setOpen={setAddCard}
        reload={() => setReloadCards((prev) => !prev)}
      />
      <EditOrderDocumentsModal
        type={DocumentTemplateType.Customer}
        open={addDocument}
        setOpen={setAddDocument}
        pairedDocs={pairedDocuments}
        onSuccess={handleAddedDocument}
      />
      <Tabs tabItems={tabItems} />
    </>
  );
};

export default AdminClientsDetailTabs;
