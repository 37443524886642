import styled from "styled-components";
import { size, slideInLeft } from "../utils/styles";
import { Button } from "./button";

export const Sidebar = styled.div<{ open: boolean; wideSidebar?: boolean }>`
  width: 75%;
  max-width: var(--sidebar-width-wide);
  height: calc(100vh - var(--header-height));

  background-color: var(--white);
  border-right: 2px solid var(--whitesmoke);

  position: absolute;
  left: 0;
  top: var(--header-height);
  z-index: 100;

  display: ${({ open }) => (open ? "block" : "none")};

  animation: ${slideInLeft} 0.25s ease-in-out;

  @media screen and (min-width: ${size.laptop}) {
    height: calc(100vh - var(--header-height));
    width: 100%;
    min-width: ${({ wideSidebar }) =>
      wideSidebar ? "var(--sidebar-width-wide)" : "var(--sidebar-width)"};
    max-width: var(--sidebar-width-wide);
    position: initial;
    display: block;
    animation: none;
  }
`;

export const SidebarContent = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`;

export const SidebarContentHeader = styled.div`
  height: 100%;
  width: 100%;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  overflow: auto;
`;

export const SidebarHeaderContainer = styled.div<{ color?: string }>`
  padding: 1rem;

  background-color: var(--${({ color }) => color || "primary"});
  color: var(--white);
  font-size: 1.25rem;

  display: flex;
  justify-content: space-between;

  @media screen and (min-width: ${size.laptop}) {
    padding: 1.5rem;
  }
`;

export const SidebarHeaderUsername = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 0.5rem;
  }
`;

export const SidebarLogoutButton = styled(Button)``;

export const SidebarLogout = styled.div`
  display: flex;
  align-items: center;

  ${SidebarLogoutButton} {
    display: none;
  }

  svg:hover {
    cursor: pointer;
  }

  @media screen and (min-width: ${size.mobile}) {
    ${SidebarLogoutButton} {
      display: block;
    }

    svg {
      display: none;
      margin: 0;
    }
  }
`;
