import React, { FC, Fragment, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { BoxType } from "../../../../models/box";
import { COLORS } from "../../../../utils/constants";
import {
  AdminBoxesSettingsTrafficStatsMain,
  AdminBoxesSettingsTrafficStatsGrid,
  AdminBoxesSettingsTrafficStatsLabel,
  AdminBoxesSettingsTrafficStatsNumber,
  AdminBoxesSettingsTrafficStatsGraph,
  AdminBoxesSettingsTrafficStatsBar,
} from "./styles";

interface Stats {
  usedReservedCount: number;
  usedActiveCount: number;
  usedPausedCount: number;
  availableCount: number;
}

const countPercentage = (max: number, current: number) => (current / max) * 100;

const AdminBoxesSettingsTrafficStats: FC<{ data: BoxType }> = ({ data }) => {
  const { t } = useTranslation();
  const stats: Stats = useMemo(
    () => ({
      usedReservedCount: data.usedReservedCount || 0,
      usedActiveCount: data.usedActiveCount || 0,
      usedPausedCount: data.usedPausedCount || 0,
      availableCount: data.availableCount,
    }),
    [data]
  );

  const maxValue: number = useMemo(() => Math.max(...Object.values(stats)), [
    stats,
  ]);

  var statItemsData = [
    {
      label: t("adminBoxesSettingsTraffic.stats.reservedBox"),
      color: COLORS.orange,
      value: stats.usedReservedCount,
      width: countPercentage(maxValue, stats.usedReservedCount),
    },
    {
      label: t("adminBoxesSettingsTraffic.stats.activeBox"),
      color: COLORS.green,
      value: stats.usedActiveCount,
      width: countPercentage(maxValue, stats.usedActiveCount),
    },
    {
      label: t("adminBoxesSettingsTraffic.stats.pausedBox"),
      color: COLORS.red,
      value: stats.usedPausedCount,
      width: countPercentage(maxValue, stats.usedPausedCount),
    },
    {
      label: t("adminBoxesSettingsTraffic.stats.availableBox"),
      color: COLORS.primary,
      value: stats.availableCount,
      width: countPercentage(maxValue, stats.availableCount),
    },
  ];

  const statItems = statItemsData.map((statItem) => (
    <Fragment key={statItem.label}>
      <AdminBoxesSettingsTrafficStatsLabel>
        {statItem.label}
      </AdminBoxesSettingsTrafficStatsLabel>
      <AdminBoxesSettingsTrafficStatsNumber>
        {statItem.value}
      </AdminBoxesSettingsTrafficStatsNumber>
      <AdminBoxesSettingsTrafficStatsGraph>
        <AdminBoxesSettingsTrafficStatsBar
          color={statItem.color}
          percentage={statItem.width}
        />
      </AdminBoxesSettingsTrafficStatsGraph>
    </Fragment>
  ));

  return (
    <AdminBoxesSettingsTrafficStatsMain>
      <AdminBoxesSettingsTrafficStatsGrid>
        {statItems}
      </AdminBoxesSettingsTrafficStatsGrid>
    </AdminBoxesSettingsTrafficStatsMain>
  );
};

export default AdminBoxesSettingsTrafficStats;
