import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { Field, Form, useFormikContext } from "formik";
import React, { FC, Fragment, useMemo, useState } from "react";
import { useCollapse } from "react-collapsed";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import authApi from "../../../../api/auth";
import { UserRight, UserRights } from "../../../../models/auth";
import { Employee, EmployeeRole } from "../../../../models/employee";
import { Button, IconButton } from "../../../../styles/button";
import { FormGroup } from "../../../../styles/form";
import { H3, H4 } from "../../../../styles/title";
import { useUserHasRight } from "../../../../utils/auth";
import Checkbox from "../../../common/form/Checkbox/Checkbox";
import Input from "../../../common/form/Input/Input";
import Select from "../../../common/form/Select/Select";
import {
  AdminEmployeesDetailCheckboxCol,
  AdminEmployeesDetailCheckboxRow,
  AdminEmployeesDetailControls,
  AdminEmployeesDetailFormCol,
  AdminEmployeesDetailFormRow,
  AdminEmployeesDetailFormWrapper,
} from "./styles";

const basicFields: Array<keyof Employee> = ["firstName", "lastName", "email"];

const checkboxFields: Array<keyof Employee> = [
  "dailyReport",
  "ownCalendar",
  "calendarEmail",
  "notificationAdditionalServiceRenewal"
];

const AdminEmployeesDetailForm: FC<{
  isCreate: boolean;
  roleRights: UserRights;
  onRoleChanged: (roleName: string) => void;
}> = ({ isCreate, roleRights, onRoleChanged }) => {
  const { t } = useTranslation();
  const [sending, setSending] = useState(false);
  const { errors, touched, values, isSubmitting, setFieldValue } =
    useFormikContext<Employee>();
  const { hasUserAllRights, isAdmin } = useUserHasRight();

  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({
    defaultExpanded: false,
  });

  const sendResetPasswordLink = async () => {
    setSending(true);
    try {
      await authApi.sendPasswordResetLink({ email: values.email });
      toast.success(t("forgottenPassword.success"));
    } catch (err) {
      toast.error(t("errors.sendResetPasswordLink"));
    }
    setSending(false);
  };

  const basicInputs = basicFields.map((f) => (
    <Fragment key={f}>
      <Input
        type={"text"}
        name={f}
        label={t(`adminEmployeesDetail.form.${f}`)}
        error={touched[f] && !!errors[f]}
      />
    </Fragment>
  ));

  const roleOptions = Object.keys(EmployeeRole).map((k) => ({
    value: k,
    label: t(`adminEmployeesDetail.form.roleOptions.${k}`),
  }));

  const checkboxes = checkboxFields.map((f) => (
    <Checkbox key={f}>
      <Field type="checkbox" name={f} />
      {t(`adminEmployeesDetail.form.${f}`)}
    </Checkbox>
  ));

  const userRightsCheckboxes = useMemo(
    () =>
      Object.keys(values.userRights).map((userRight) => {
        const hasRoleRight = Boolean(roleRights[userRight as UserRight]);
        return (
          <Checkbox key={userRight}>
            {hasRoleRight ? (
              <input type="checkbox" checked={true} disabled={true} />
            ) : (
              <Field type="checkbox" name={`userRights.${userRight}`} />
            )}
            {t(`adminEmployeesDetail.form.userRight.${userRight}`, {
              defaultValue: userRight,
            })}
          </Checkbox>
        );
      }),
    [roleRights, t, values.userRights]
  );
  const firstHalfIndex = Math.ceil(userRightsCheckboxes.length / 2);

  return (
    <Form>
      <H3>{t("adminEmployeesDetail.form.title")}</H3>
      <AdminEmployeesDetailFormWrapper>
        <AdminEmployeesDetailFormRow>
          <AdminEmployeesDetailFormCol>
            <FormGroup>
              {basicInputs}
              <Select
                error={touched.role && !!errors.role}
                name={"role"}
                label={t("adminEmployeesDetail.form.role")}
                value={roleOptions.find((role) => role.value === values.role)}
                options={roleOptions}
                onChange={(option) => {
                  const value = option?.value;
                  setFieldValue("role", value);
                  if (value) {
                    onRoleChanged(value);
                  }
                }}
                isDisabled={!isAdmin}
              />
              <AdminEmployeesDetailCheckboxRow>
                <AdminEmployeesDetailCheckboxCol>
                  {t(`adminEmployeesDetail.form.isActive`)}
                </AdminEmployeesDetailCheckboxCol>
                <AdminEmployeesDetailCheckboxCol>
                  <Checkbox>
                    <Field type="checkbox" name={`isActive`} />
                    {t(`adminEmployeesDetail.form.isActiveLabel`)}
                  </Checkbox>
                </AdminEmployeesDetailCheckboxCol>
              </AdminEmployeesDetailCheckboxRow>
            </FormGroup>
          </AdminEmployeesDetailFormCol>
          <AdminEmployeesDetailFormCol>
            <H4>{t("adminEmployeesDetail.form.settings")}</H4>
            <FormGroup>{checkboxes}</FormGroup>
          </AdminEmployeesDetailFormCol>
        </AdminEmployeesDetailFormRow>
        {isAdmin && !isCreate && (
          <div style={{ marginTop: 20 }}>
            <H4
              {...getToggleProps()}
              style={{ marginBottom: 20, cursor: "pointer" }}
            >
              <IconButton
                {...getToggleProps()}
                icon={isExpanded ? faChevronDown : faChevronRight}
              />{" "}
              {t("adminEmployeesDetail.form.userRights")}
            </H4>
            <AdminEmployeesDetailFormRow {...getCollapseProps()}>
              <AdminEmployeesDetailFormCol>
                <FormGroup>
                  {userRightsCheckboxes.slice(0, firstHalfIndex)}
                </FormGroup>
              </AdminEmployeesDetailFormCol>
              <AdminEmployeesDetailFormCol>
                <FormGroup>
                  {userRightsCheckboxes.slice(firstHalfIndex)}
                </FormGroup>
              </AdminEmployeesDetailFormCol>
            </AdminEmployeesDetailFormRow>
          </div>
        )}
      </AdminEmployeesDetailFormWrapper>
      <AdminEmployeesDetailControls>
        {!isCreate && (
          <Button
            ver="secondary"
            disabled={sending}
            onClick={() => sendResetPasswordLink()}
          >
            {t("adminEmployeesDetail.form.sendPasswordResetLink")}
          </Button>
        )}
        <Button
          type="submit"
          disabled={isSubmitting || !hasUserAllRights(["writeEmployees"])}
        >
          {t("common.save")}
        </Button>
      </AdminEmployeesDetailControls>
    </Form>
  );
};

export default AdminEmployeesDetailForm;
