import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import orderApi from "../../../api/order";
import storageApi from "../../../api/storage";
import { InvoiceState, InvoiceStateResponse } from "../../../models/order";
import { CUSTOMER_ID_KEY } from "../../../utils/constants";
import { getErrorMessage } from "../../../utils/errors";
import { useStore } from "../../../utils/store";
import Page from "../../common/layout/Page/Page";
import Loader from "../../common/Loader/Loader";
import CustomerCalendar from "../../user/Reservation/CustomerCalendar/CustomerCalendar";
import OrderInvoiceSidebar from "../OrderSidebar/OrderInvoiceSidebar/OrderInvoiceSidebar";

const OrderInvoice: FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [invoice, setInvoice] = useState<InvoiceStateResponse>();
  const user = useStore((store) => store.user);

  useEffect(() => {
    const token = new URLSearchParams(location.search).get("token");
    if (token) {
      (async () => {
        setLoading(true);
        try {
          const response = await orderApi.checkInvoice(token);
          setInvoice(response.data);
          if (!user) {
            storageApi.setToken(response.data.token);
            localStorage.setItem(CUSTOMER_ID_KEY, response.data.customerId);
          }
        } catch (err) {
          toast.error(getErrorMessage(err, t("common.loadError")));
        }
        setLoading(false);
      })();
    } else {
      history.push(`/login`);
    }
  }, [location, t, history, user]);

  useEffect(() => {
    if (
      invoice?.state === InvoiceState.NotFound ||
      invoice?.state === InvoiceState.AlreadyProcessed
    )
      history.push(`/login`);
  }, [invoice, history]);

  return (
    <Page sidebar={<OrderInvoiceSidebar invoice={invoice} loading={loading} />}>
      {loading ? (
        <Loader />
      ) : (
        <div>
          {invoice?.state === InvoiceState.Continue && (
            <CustomerCalendar signContract />
          )}
        </div>
      )}
    </Page>
  );
};

export default OrderInvoice;
