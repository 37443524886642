import { Field } from "formik";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import ReactSlider from "react-slider";
import { BoxType } from "../../../models/box";
import { H2 } from "../../../styles/title";
import { formatCurrency } from "../../../utils/formats";
import Checkbox from "../../common/form/Checkbox/Checkbox";
import { getBoxPriceWithVat, getLeaseMonths } from "../priceUtils";
import { OrderGroup, OrderGroupNumber } from "../styles";
import { OrderLeaseLengthText, SliderContainer } from "./styles";

const OrderParameterLength: FC<{
  boxType: BoxType | null;
  value: string;
  setValue: (value: string) => void;
}> = ({ boxType, value, setValue }) => {
  const { t } = useTranslation();

  return (
    <section>
      <OrderGroup>
        <OrderGroupNumber>2</OrderGroupNumber>
        <H2 bold>{t("order.parameters.lengthOfLeaseType.title")}</H2>
      </OrderGroup>
      <OrderLeaseLengthText bold>
        {t("order.parameters.lengthOfLeaseType." + value)}
        {boxType && (
          <>
            {" - "}
            {formatCurrency(
              getBoxPriceWithVat(value, boxType.boxPriceDto) /
                getLeaseMonths(value)
            )}
            {t("common.price.monthlyWithVat")}
          </>
        )}
      </OrderLeaseLengthText>
      <SliderContainer>
        <ReactSlider
          className="slider"
          marks={true}
          markClassName="slider-mark"
          min={0}
          max={9}
          thumbClassName="slider-thumb"
          trackClassName="slider-track"
          renderMark={(props) => (
            <span {...props}>
              {t(
                "order.parameters.lengthOfLeaseType." +
                  ((props.key as number) + 1)
              )}
            </span>
          )}
          value={parseInt(value) - 1}
          onChange={(val) => setValue(((val as number) + 1).toString())}
        />
      </SliderContainer>
      <Checkbox>
        <Field type="checkbox" name="autoExtend" />
        {t("orders.detail.autoRenew")}
      </Checkbox>
    </section>
  );
};

export default OrderParameterLength;
