import {
  faCheck,
  faFileAlt,
  faPen,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import documentApi from "../../../api/document";
import { DocumentType } from "../../../models/document";
import { IconButton, LinkButton } from "../../../styles/button";
import { NoTableData } from "../../../styles/table";
import { useUserHasRight } from "../../../utils/auth";
import { COLORS } from "../../../utils/constants";
import { downloadBlob } from "../../../utils/download";
import { DocumentTemplateType } from "../../../utils/enums";
import { getErrorMessage } from "../../../utils/errors";
import { OverflowX } from "../../../utils/styles";
import DocumentTypeEditModal from "./DocumentTypeEditModal";
import DocumentTypeRemoveModal from "./DocumentTypeRemoveModal";
import { DocumentTypesTableContainer, DocumentTypeTableDelete } from "./styles";

const DocumentTypesTable: FC<{
  data: DocumentType[];
  onSuccess: () => void;
}> = ({ data, onSuccess }) => {
  const { t } = useTranslation();
  const [editDocumentId, setEditDocumentId] = useState("");
  const [modalEditOpen, setModalEditOpen] = useState(false);
  const [modalRemoveOpen, setModalRemoveOpen] = useState(false);
  const { hasUserAllRights } = useUserHasRight();

  const handleDownload = useCallback(async (documentTypeId: string) => {
    const response = await documentApi.downloadDocumentType(documentTypeId);
    await downloadBlob(response);
  }, []);

  const handleDeleteConfirmed = async () => {
    try {
      await documentApi.deleteDocumentType(editDocumentId);
      toast.success(t("common.saveSuccess"));
      onSuccess();
    } catch (err) {
      toast.error(getErrorMessage(err, t("errors.saveError")));
    }
  };

  const tableData = useMemo(
    () =>
      data.map((row) => (
        <tr key={row.id}>
          <td>
            <div>
              <LinkButton onClick={() => handleDownload(row.id)}>
                <FontAwesomeIcon
                  icon={faFileAlt}
                  color={COLORS.primary}
                  size="lg"
                />
                {row.name}
              </LinkButton>
            </div>
          </td>
          <td>
            {row.type === DocumentTemplateType.Order
              ? t("documents.typeOrder")
              : t("documents.typeCustomer")}
          </td>
          <td>
            {row.mustSign && (
              <FontAwesomeIcon icon={faCheck} size="lg" color={COLORS.green} />
            )}
          </td>
          <td>
            <div>
              {hasUserAllRights(["writeDocumentTemplates"]) && (
                <IconButton
                  icon={faPen}
                  onClick={() => {
                    setEditDocumentId(row.id);
                    setModalEditOpen(true);
                  }}
                />
              )}
              {hasUserAllRights(["deleteDocumentTemplates"]) && (
                <DocumentTypeTableDelete
                  icon={faTimes}
                  size="lg"
                  onClick={() => {
                    setEditDocumentId(row.id);
                    setModalRemoveOpen(true);
                  }}
                />
              )}
            </div>
          </td>
        </tr>
      )),
    [data, handleDownload, t]
  );

  const tableCols = (
    <tr>
      {[
        "documents.document",
        "documents.type",
        "documents.signature",
        "common.actions",
      ].map((col) => (
        <th key={col}>{t(col)}</th>
      ))}
    </tr>
  );

  return data.length ? (
    <OverflowX>
      <DocumentTypeEditModal
        documentTypeId={editDocumentId}
        open={modalEditOpen}
        setOpen={setModalEditOpen}
        onSuccess={onSuccess}
      />
      <DocumentTypeRemoveModal
        open={modalRemoveOpen}
        setOpen={setModalRemoveOpen}
        confirm={handleDeleteConfirmed}
      />
      <DocumentTypesTableContainer>
        <thead>{tableCols}</thead>
        <tbody>{tableData}</tbody>
      </DocumentTypesTableContainer>
    </OverflowX>
  ) : (
    <NoTableData>{t("adminDocumentsSettings.noData")}</NoTableData>
  );
};

export default DocumentTypesTable;
