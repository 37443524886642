import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { debounce } from "lodash";
import React, { FC, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Button } from "../../../styles/button";
import { PageHeader } from "../../../styles/layout";
import { H2 } from "../../../styles/title";
import { useUserHasRight } from "../../../utils/auth";
import { COLORS, DEBOUNCE_TIMEOUT_MS } from "../../../utils/constants";
import { useReservationStore, useStore } from "../../../utils/store";
import { size } from "../../../utils/styles";
import Search from "../../common/form/Search/Search";
import Page from "../../common/layout/Page/Page";
import Tabs, { TabItem } from "../../common/Tabs/Tabs";
import AdminSidebar from "../AdminSidebar/AdminSidebar";
import AdminCalendarSceneCalendar from "./AdminCalendar/AdminCalendar";
import AdminCalendarModal from "./AdminCalendarModal/AdminCalendarModal";
import AdminCalendarNoteModal from "./AdminCalendarNoteModal";
import AdminCalendarTable from "./AdminCalendarTable/AdminCalendarTable";
import { AdminCalendarSceneButtons } from "./styles";

const AdminCalendarScene: FC = () => {
  const { t } = useTranslation();
  const [filters, setFilters] = useState<{
    searchText: string;
    page: number;
  }>({ searchText: "", page: 1 });
  const user = useStore((store) => store.user);
  const { setNote } = useReservationStore();
  const history = useHistory();

  const isAdmin = user?.role === "Admin" || user?.role === "Boss";
  const [modalMode, setModalMode] = useState<"Add" | "Delete">("Add");
  const [modalOpen, setModalOpen] = useState(false);
  const [modalNoteOpen, setModalNoteOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(isAdmin ? 1 : 0);
  const [mountTabs, setMountTabs] = useState(true);
  const [selectedEmployee, setSelectedEmployee] = useState({
    value: "all",
    label: "all",
  });
  const { hasUserAllRights } = useUserHasRight();
  const canChangeOtherCalendars = hasUserAllRights([
    "changeOtherEmployeeCalendar",
  ]);

  const tabItems: TabItem[] = useMemo(
    () => [
      {
        tab: <AdminCalendarTable filters={filters} setFilters={setFilters} />,
        tabLabel: t("adminCalendarScene.tabs.reservations"),
        tabAction: (
          <Search
            name={"calendarSearch"}
            onInputChange={debounce(
              (event) =>
                setFilters({
                  searchText: event.target.value,
                  page: 1,
                }),
              DEBOUNCE_TIMEOUT_MS
            )}
          />
        ),
      },
      {
        tab: (
          <AdminCalendarSceneCalendar
            selectedEmployee={selectedEmployee}
            setSelectedEmployee={setSelectedEmployee}
          />
        ),
        tabLabel: t("adminCalendarScene.tabs.calendar"),
      },
    ],
    [filters, selectedEmployee, t]
  );

  const handleConfirmNote = useCallback(
    (note: string) => {
      setNote(note);
      history.push("/admin/meetings");
    },
    [history, setNote]
  );

  return (
    <>
      <AdminCalendarModal
        open={modalOpen}
        modalMode={modalMode}
        setOpen={setModalOpen}
        onSuccess={() => {
          // force reload
          setMountTabs(false);
          setMountTabs(true);
        }}
        selectedEmployee={selectedEmployee}
      />
      <AdminCalendarNoteModal
        open={modalNoteOpen}
        setOpen={setModalNoteOpen}
        confirm={handleConfirmNote}
      />
      <Page sidebar={<AdminSidebar />}>
        <PageHeader breakSize={size.mobile}>
          <div>
            <H2>{t("adminCalendarScene.title")}</H2>
          </div>
          <AdminCalendarSceneButtons>
            <Button onClick={() => setModalNoteOpen(true)}>
              <FontAwesomeIcon icon={faPlus} color={COLORS.white} />
              {t(
                "adminCalendarScene.headerButtons.createNonCustomerReservation"
              )}
            </Button>
          </AdminCalendarSceneButtons>
          {(isAdmin || canChangeOtherCalendars) && (
            <AdminCalendarSceneButtons>
              <Button
                onClick={() => {
                  setModalMode("Add");
                  setModalOpen(true);
                }}
              >
                <FontAwesomeIcon icon={faPlus} color={COLORS.white} />
                {t("adminCalendarScene.headerButtons.add")}
              </Button>
              <Button
                ver="primary-dark"
                onClick={() => {
                  setModalMode("Delete");
                  setModalOpen(true);
                }}
              >
                <FontAwesomeIcon icon={faMinus} color={COLORS.white} />
                {t("adminCalendarScene.headerButtons.delete")}
              </Button>
            </AdminCalendarSceneButtons>
          )}
        </PageHeader>
        <Tabs
          tabItems={mountTabs ? tabItems : []}
          breakSize={size.mobile}
          activeTabIndex={activeTab}
          onTabChange={setActiveTab}
        />
      </Page>
    </>
  );
};

export default AdminCalendarScene;
