import formatISO from "date-fns/formatISO";
import { Field, Form, useFormikContext } from "formik";
import React, { FC, Fragment, useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../styles/button";
import { FormGroup } from "../../../../styles/form";
import { H3 } from "../../../../styles/title";
import Checkbox from "../../../common/form/Checkbox/Checkbox";
import Input from "../../../common/form/Input/Input";
import DatePicker from "../../../common/form/DatePicker/DatePicker";
import { DATE_FORMAT } from "../../../../utils/constants";

import {
  AdminPromoCodesDetailControls,
  AdminPromoCodesDetailFormCol,
  AdminPromoCodesDetailFormRow,
  AdminPromoCodesDetailFormWrapper,
} from "./styles";

import { PromoCodeList } from "../../../../models/promoCode";

const basicFields: Array<keyof PromoCodeList> = [
  "code",
  "discount",
  "description",
];
const commissionFields: Array<keyof PromoCodeList> = [
  "commission",
  "nextCommission",
];

const AdminPromoCodesDetailForm: FC = () => {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState<Date | null>(null);

  const { errors, touched, isSubmitting, values, setFieldValue } =
    useFormikContext<PromoCodeList>();

  const isDiscountRepeated = useMemo(
    () => Boolean(values.isDiscountRepeated),
    [values.isDiscountRepeated]
  );

  useEffect(() => {
    if (values.validUntil) {
      setStartDate(new Date(values.validUntil));
    } else {
      setStartDate(null);
    }
  }, [values.validUntil]);

  const basicInputs = basicFields.map((f) => (
    <Fragment key={f}>
      <Input
        type={"text"}
        name={f}
        label={t(`adminPromoCodesDetail.form.${f}`)}
        error={touched[f] && !!errors[f]}
      />
    </Fragment>
  ));

  const commissionInputs = commissionFields.map((f) => (
    <Fragment key={f}>
      <Input
        type={"text"}
        name={f}
        label={t(`adminPromoCodesDetail.form.${f}`)}
        error={touched[f] && !!errors[f]}
      />
    </Fragment>
  ));

  return (
    <Form>
      <H3>{t("adminPromoCodesDetail.form.title")}</H3>
      <AdminPromoCodesDetailFormWrapper>
        <AdminPromoCodesDetailFormRow>
          <AdminPromoCodesDetailFormCol>
            <FormGroup>
              {basicInputs}
              <DatePicker
                name={"validUntil"}
                label={t("adminPromoCodesDetail.form.validUntil")}
                labelWidth={"8rem"}
                //inputWidth={"9rem"}
                popperProps={{ placement: "top-start", position: "absolute" }}
                isClearable={true}
                selected={startDate}
                dateFormat={DATE_FORMAT.dateTime}
                showTimeSelect
                error={!!errors["validUntil"]}
                onChange={(date) => {
                  setFieldValue(
                    "validUntil",
                    date != null ? formatISO(date as Date) : null
                  );
                  setStartDate(date);
                }}
              />
            </FormGroup>
          </AdminPromoCodesDetailFormCol>
          <AdminPromoCodesDetailFormCol>
            <FormGroup>
              <Checkbox>
                <Field type="checkbox" name={"isDiscountRepeated"} />
                {t("adminPromoCodesDetail.form.isDiscountRepeated")}
              </Checkbox>
              <Input
                type={"text"}
                name={"nextDiscount"}
                label={t(`adminPromoCodesDetail.form.nextDiscount`)}
                error={touched.nextDiscount && !!errors.nextDiscount}
                disabled={!isDiscountRepeated}
              />
              {commissionInputs}
            </FormGroup>
          </AdminPromoCodesDetailFormCol>
        </AdminPromoCodesDetailFormRow>
      </AdminPromoCodesDetailFormWrapper>
      <AdminPromoCodesDetailControls>
        <Button type="submit" disabled={isSubmitting}>
          {t("common.save")}
        </Button>
      </AdminPromoCodesDetailControls>
    </Form>
  );
};

export default AdminPromoCodesDetailForm;
