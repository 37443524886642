import React, { FC } from "react";
import { H1 } from "../../../styles/title";
import { useTranslation } from "react-i18next";
import PageNoSidebar from "../layout/Page/PageNoSidebar";
import { Button } from "../../../styles/button";
import { NotFoundContainer } from "./style";
import { Link } from "react-router-dom";

const NotFound: FC = () => {
  const { t } = useTranslation();

  return (
    <PageNoSidebar>
      <NotFoundContainer>
        <div>404</div>
        <div>
          <H1 bold uppercase>
            {t("notFound.title")}
          </H1>
          <span>{t("notFound.subtitle")}</span>
          <span>{t("notFound.returnHome")}</span>
          <div>
            <Button as={Link} to="/">
              {t("notFound.home")}
            </Button>
          </div>
        </div>
      </NotFoundContainer>
    </PageNoSidebar>
  );
};

export default NotFound;
