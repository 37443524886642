import React, { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import userApi from "../../../../api/user";
import Select from "../../../common/form/Select/Select";

const AdminEmployeeSelect: FC<{
  name?: string;
  label?: string;
  inputWidth?: string;
  allOption?: boolean;
  onChange: (employeeId: string, label?: string) => void;
  defaultValue?: { value: string; label: string };
}> = ({
  name = "employee",
  label,
  inputWidth = "16rem",
  allOption,
  onChange,
  defaultValue,
}) => {
  const { t } = useTranslation();
  const [selectLoading, setSelectLoading] = useState<boolean>(true);

  const employeeDefaultOptions = useMemo(
    () =>
      allOption
        ? [
            {
              value: "all",
              label: t("adminCalendarScene.calendar.allUsers"),
            },
          ]
        : [],
    [t, allOption]
  );

  const [employeeOptions, setEmployeeOptions] = useState<
    { label: string; value: string }[]
  >(employeeDefaultOptions);

  useEffect(() => {
    (async () => {
      setSelectLoading(true);
      try {
        const response = await userApi.getEmployees();
        const employeeOptions = response.data.data
          .filter((e) => e.ownCalendar)
          .map((employee) => {
            return {
              value: employee.id.toString(),
              label: `${employee.firstName} ${employee.lastName}`,
            };
          });
        setEmployeeOptions([...employeeDefaultOptions, ...employeeOptions]);
      } catch (err) {
        toast.error(t("errors.employees"));
      }
      setSelectLoading(false);
    })();
  }, [employeeDefaultOptions, t]);

  return (
    <Select
      name={name}
      label={label}
      placeholder={t("adminCalendarScene.selectPlaceholder")}
      inputWidth={inputWidth}
      defaultValue={allOption ? employeeOptions[0] : defaultValue || undefined}
      options={employeeOptions}
      onChange={(option) => option && onChange(option.value, option.label)}
      isLoading={selectLoading}
      isDisabled={selectLoading}
    />
  );
};

export default AdminEmployeeSelect;
