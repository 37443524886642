import {
  faCreditCard,
  faInfoCircle,
  faMoneyBillWave,
  faMoneyCheck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Flex } from "../../../styles/flex";
import { H2 } from "../../../styles/title";
import { COLORS } from "../../../utils/constants";
import { OrderGroup, OrderGroupNumber } from "../styles";
import { PaymentButton } from "./styles";

const OrderPaymentButtons: FC<{
  value: string;
  setValue: (value: string) => void;
}> = ({ value, setValue }) => {
  const { t } = useTranslation();

  return (
    <section>
      <OrderGroup>
        <OrderGroupNumber>5</OrderGroupNumber>
        <H2 bold>{t("order.parameters.paymentButtons.title")}</H2>
      </OrderGroup>
      <PaymentButton
        checked={value === "Card"}
        onClick={() => setValue("Card")}
        type="button"
      >
        <FontAwesomeIcon
          icon={faCreditCard}
          color={value === "Card" ? COLORS.white : COLORS.primary}
        />
        {t("order.parameters.paymentButtons.Card")}
      </PaymentButton>
      <Flex style={{ alignItems: "center" }}>
        <PaymentButton
          checked={value === "Cash"}
          onClick={() => setValue("Cash")}
          type="button"
        >
          <FontAwesomeIcon
            icon={faMoneyBillWave}
            color={value === "Cash" ? COLORS.white : COLORS.primary}
          />
          {t("order.parameters.paymentButtons.Cash")}
        </PaymentButton>
        <Info>
          <FontAwesomeIcon icon={faInfoCircle} />{" "}
          {t("order.parameters.paymentButtons.CashInfo")}
        </Info>
      </Flex>
      <PaymentButton
        checked={value === "Transfer"}
        onClick={() => setValue("Transfer")}
        type="button"
      >
        <FontAwesomeIcon
          icon={faMoneyCheck}
          color={value === "Transfer" ? COLORS.white : COLORS.primary}
        />
        {t("order.parameters.paymentButtons.Transfer")}
      </PaymentButton>
    </section>
  );
};

const Info = styled.div`
  margin-bottom: 1rem;
  margin-left: 1rem;
  color: ${COLORS.grayLight};
`;

export default OrderPaymentButtons;
