import React, { FC, Fragment } from "react";
import { Link } from "react-router-dom";
import { formatDate } from "../../../../utils/formats";
import { OrderStateOverview } from "../../../../models/order";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../../utils/store";

const CardOrdersOverviewRow: FC<{ row: OrderStateOverview }> = ({ row }) => {
  const { t } = useTranslation();
  const user = useStore((store) => store.user);
  const isAdmin = user?.role === "Admin" || user?.role === "Boss";

  return (
    <Fragment key={row.id}>
      <div>
        <b>
          {t("common.box")} {row.branchBox.boxType.type}
        </b>
      </div>
      <div>
        {isAdmin ? (
          <Link to={`/admin/orders/${row.id}`}>{row.name}</Link>
        ) : (
          <span>{row.name}</span>
        )}
      </div>
      <div>
        {isAdmin ? (
          <Link to={`/admin/clients/${row.user.id}`}>
            {row.user.firstName} {row.user.lastName}
          </Link>
        ) : (
          <span>
            {row.user.firstName} {row.user.lastName}
          </span>
        )}
      </div>
      <div>
        {t("adminDashboard.ordersOverview.expAbbr")}{" "}
        <b>{formatDate(row.dateTo)}</b>
      </div>
    </Fragment>
  );
};

export default CardOrdersOverviewRow;
