import { Field, useFormikContext } from "formik";
import { debounce } from "lodash";
import React, { FC, useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import promoCodeApi from "../../../api/promoCode";
import { OrderFormData } from "../../../models/order";
import { FormGroup } from "../../../styles/form";
import { H2 } from "../../../styles/title";
import { DEBOUNCE_TIMEOUT_MS } from "../../../utils/constants";
import Checkbox from "../../common/form/Checkbox/Checkbox";
import FormError from "../../common/form/FormError/FormError";
import Input from "../../common/form/Input/Input";
import UserCompanyForm from "../../common/UserInfo/UserCompanyForm";
import UserInfoForm from "../../common/UserInfo/UserInfoForm";
import { OrderGroup, OrderGroupNumber } from "../styles";
import { ConsentValidationError, Container, DivLeft } from "./styles";
import { toast } from "react-toastify";

const OrderUser: FC = () => {
  const { t } = useTranslation();
  const { values, errors, touched, setFieldValue } =
    useFormikContext<OrderFormData>();

  const isPromoAvailable = useMemo(() => {
    return Number(values.lengthOfLeaseType) >= 4;
  }, [values.lengthOfLeaseType]);

  const handleChangeDiscount = useCallback(
    (value?: number) => {
      setFieldValue("discount", value ?? 0);
    },
    [setFieldValue]
  );

  const handlePromoCode = useCallback(
    async (value: string) => {
      if (!isPromoAvailable || !value) return;

      const promoCode = await promoCodeApi.getDiscount(value);
      handleChangeDiscount(promoCode?.data.discount);
    },
    [handleChangeDiscount, isPromoAvailable]
  );

  useEffect(() => {
    if (values.promoCode && !isPromoAvailable) {
      handleChangeDiscount(0);
      toast.error(t("order.promoCodeMinLease"));
    }
  }, [handleChangeDiscount, isPromoAvailable, t, values.promoCode]);

  useEffect(() => {
    if (values.promoCode && isPromoAvailable && !values.discount) {
      handlePromoCode(values.promoCode);
    }
  }, [handlePromoCode, isPromoAvailable, values.discount, values.promoCode]);

  useEffect(() => {
    if (values.hasPromoCode && !values.promoCode) {
      handleChangeDiscount(0);
    }
  }, [handleChangeDiscount, values.hasPromoCode, values.promoCode]);

  return (
    <>
      <OrderGroup>
        <OrderGroupNumber>4</OrderGroupNumber>
        <H2 bold>{t("userInfo.info.title")}</H2>
      </OrderGroup>
      <Container>
        <FormGroup>
          <Checkbox>
            <Field type="checkbox" name="isCompany" />
            {t("userInfo.isCompany")}
          </Checkbox>
        </FormGroup>
        {values.isCompany && <UserCompanyForm />}
        <section>
          <UserInfoForm />
        </section>
        <FormGroup>
          <Checkbox>
            <Field type="checkbox" name="hasPromoCode" />
            {t("userInfo.hasPromoCode")}
          </Checkbox>
        </FormGroup>
        {values.hasPromoCode && (
          <FormGroup>
            <Input
              type="text"
              name="promoCode"
              onInputChange={debounce(
                (event) => handlePromoCode(event.target.value),
                DEBOUNCE_TIMEOUT_MS
              )}
            />
            <div>{t("order.promoCodeMinLease")}</div>
          </FormGroup>
        )}
        <DivLeft>
          <Checkbox>
            <Field type="checkbox" name="consent" />
            &nbsp;
          </Checkbox>
        </DivLeft>
        <DivLeft>
          <a
            href="https://www.safeboxes.cz/files/2021/04/ochrana-osobnich-udaju_safeboxes-1.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("order.summary.consent")}
          </a>
        </DivLeft>
        {!!errors["consent"] && touched["consent"] && (
          <ConsentValidationError>
            <FormError name="consent" tooltipLeft={false} />{" "}
          </ConsentValidationError>
        )}
      </Container>
    </>
  );
};

export default OrderUser;
