import { formatISO, startOfWeek } from "date-fns";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import calendarApi from "../../../../api/calendar";
import { CalendarCustomer } from "../../../../models/calendar";
import { Button } from "../../../../styles/button";
import { Card } from "../../../../styles/card";
import { FadeInBottom } from "../../../../styles/fadeIn";
import { PageHeader } from "../../../../styles/layout";
import { RedText } from "../../../../styles/text";
import { H2 } from "../../../../styles/title";
import { CUSTOMER_ID_KEY, TOKEN_KEY } from "../../../../utils/constants";
import { getErrorMessage } from "../../../../utils/errors";
import { useReservationStore, useStore } from "../../../../utils/store";
import Calendar from "../../../common/calendar/Calendar/Calendar";
import CalendarMeetingConfirmation from "../CalendarMeetingConfirmation/CalendarMeetingConfirmation";
import CustomerCalendarDay from "../CustomerCalendarDay/CustomerCalendarDay";
import { DateAndLabel, getReservationDateString } from "../utils";

interface PageState {
  loading: boolean;
  saving: boolean;
  reservationDate?: DateAndLabel;
  mondayDate: Date;
  reserved?: DateAndLabel;
}

const CustomerCalendar: FC<{ signContract?: boolean; notClient?: boolean }> = ({
  signContract,
  notClient,
}) => {
  const { t } = useTranslation();
  const { id } = useParams<{ id?: string }>();
  const user = useStore((store) => store.user);
  const [userHasReservation, setUserHasReservation] = useState(false);
  const { setReserved, setNote, note } = useReservationStore();
  const [calendarData, setCalendarData] = useState<CalendarCustomer>({
    labels: [],
    days: [],
  });
  const [state, setState] = useState<PageState>({
    loading: true,
    saving: false,
    reservationDate: undefined,
    mondayDate: startOfWeek(new Date(), { weekStartsOn: 1 }),
    reserved: undefined,
  });

  const loadData = useCallback(async () => {
    setState((prev) => ({ ...prev, loading: true }));
    try {
      const response = await calendarApi.getCustomerCalendarFromDate(1, {
        fromDate: formatISO(state.mondayDate, { representation: "date" }),
        days: 7,
      });

      const userId = id ? id : user?.id;
      if (!notClient && userId) {
        const responseOverview = await calendarApi.getUserCalendarOverview(
          userId
        );
        setUserHasReservation(responseOverview.data.data.length !== 0);
      }

      setCalendarData(response.data);
    } catch (err) {
      toast.error(getErrorMessage(err, t("errors.loadError")));
    }
    setState((prev) => ({ ...prev, loading: false }));
  }, [state.mondayDate, id, user?.id, notClient, t]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(
    () => () => {
      setNote(undefined);
    },
    [setNote]
  );

  const handleConfirmReservation = async () => {
    const reservedDate = state.reservationDate;
    const reservationDateString = getReservationDateString(reservedDate!);

    setState((prev) => ({ ...prev, saving: true }));

    try {
      if (notClient) {
        await calendarApi.createNonCustomerReservation(1, {
          date: reservationDateString,
          note: note!,
        });
        setNote(undefined);
      } else {
        const userId = id
          ? id
          : user?.id ?? localStorage.getItem(CUSTOMER_ID_KEY);
        await calendarApi.createCustomerReservation(1, userId!, {
          date: reservationDateString,
          idOrder: null,
        });
      }

      setState((prev) => ({ ...prev, reservationDate: undefined }));

      if (!user) {
        localStorage.removeItem(TOKEN_KEY);
        localStorage.removeItem(CUSTOMER_ID_KEY);
      }
    } catch (err) {
      toast.error(t("errors.reservation"));
    }

    setState((prev) => ({
      ...prev,
      saving: false,
      reserved: reservedDate,
    }));
    setReserved();
  };

  const days = calendarData.days.map((day) => (
    <CustomerCalendarDay
      key={day.day}
      day={day}
      labels={calendarData.labels.map((l) => new Date(l))}
      reservationDate={state.reservationDate}
      setReservationDate={(date) =>
        setState((prev) => ({ ...prev, reservationDate: date }))
      }
    />
  ));

  return (
    <FadeInBottom>
      {state.reserved && !user ? (
        <>
          <H2>{t("reservation.reserved.title")}</H2>
          <Card>{t("reservation.reserved.subtitle")}</Card>
        </>
      ) : (
        <PageHeader>
          <H2>
            {state.reserved
              ? t("reservation.calendar.reservedDate")
              : t(`reservation.${signContract ? "signContract" : "title"}`)}
          </H2>
          {state.reserved && (
            <Button as={Link} to={"/"}>
              {t("common.home")}
            </Button>
          )}
        </PageHeader>
      )}
      <div>
        {state.reserved ? (
          <CalendarMeetingConfirmation
            reservationDate={new Date(getReservationDateString(state.reserved))}
            onClickConfirmReservation={handleConfirmReservation}
            saving={state.saving}
            reserved
          />
        ) : (
          <Calendar
            loading={state.loading}
            labels={calendarData.labels.map((l) => new Date(l))}
            mondayDate={state.mondayDate}
            setMondayDate={(date) =>
              setState((prev) => ({ ...prev, mondayDate: date }))
            }
            days={days}
            headerContent={
              user && userHasReservation ? (
                <Card padding="0.75rem">
                  <RedText>{t("reservation.headerInfo")}</RedText>
                </Card>
              ) : undefined
            }
          />
        )}
        {!state.loading && state.reservationDate && (
          <CalendarMeetingConfirmation
            reservationDate={
              new Date(getReservationDateString(state.reservationDate))
            }
            onClickConfirmReservation={handleConfirmReservation}
            saving={state.saving}
          />
        )}
      </div>
    </FadeInBottom>
  );
};

export default CustomerCalendar;
