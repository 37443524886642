import styled from "styled-components";
import { Button } from "../../styles/button";
import { COLORS } from "../../utils/constants";
import { fadeIn } from "../../utils/styles";

export const LogoutButton = styled(Button)`
  position: absolute;
  top: 2rem;
  right: 2rem;
  z-index: 999;

  font-size: 1.5rem;
  border-radius: 0.5rem;

  animation: ${fadeIn} 0.2s ease-in-out;
`;

export const DashboardButton = styled(LogoutButton)`
  left: 2rem;
  right: auto;
`;

export const QueueContainer = styled.main`
  display: flex;
  align-items: center;
  flex-flow: column;

  position: relative;
  height: 100vh;
  width: 100vw;

  overflow-y: auto;

  background-color: ${COLORS.black};
`;

export const QueueItems = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
`;

export const QueueItemsNotWaiting = styled(QueueItems)`
  margin-bottom: 2rem;
`;

export const QueueItemsWaiting = styled(QueueItems)`
  grid-gap: 1rem;

  > *:nth-child(2n) {
    transform: translateY(50%);
  }
`;
