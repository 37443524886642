import React, { FC, useEffect, useState } from "react";
import Page from "../../common/layout/Page/Page";
import UserSidebar from "../UserSidebar/UserSidebar";
import { H2 } from "../../../styles/title";
import { useTranslation } from "react-i18next";
import DocumentsTable from "./DocumentsTable/DocumentsTable";
import documentApi from "../../../api/document";
import { Document } from "../../../models/document";
import Loader from "../../common/Loader/Loader";
import { FadeInBottom } from "../../../styles/fadeIn";
import storageApi from "../../../api/storage";

const Documents: FC = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<Document[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const response = await documentApi.getUserDocuments(
          storageApi.getCurrentUser()!.id
        );
        setData(response.data);
      } catch (err) {}
      setLoading(false);
    })();
  }, []);

  return (
    <Page sidebar={<UserSidebar />}>
      {loading ? (
        <Loader />
      ) : (
        <FadeInBottom>
          <H2>{t("documents.title")}</H2>
          <DocumentsTable data={data} />
        </FadeInBottom>
      )}
    </Page>
  );
};

export default Documents;
