import React, { FC, useEffect } from "react";
import CustomModal from "../../../common/CustomModal/CustomModal";
import { Button } from "../../../../styles/button";
import Loader from "../../../common/Loader/Loader";
import { FormGroup } from "../../../../styles/form";
import { H2 } from "../../../../styles/title";
import Checkbox from "../../../common/form/Checkbox/Checkbox";
import { Field, Form, useFormikContext } from "formik";
import { OrderCheckboxLabel } from "../../../order/OrderParameters/styles";
import { formatCurrency } from "../../../../utils/formats";
import { Divider } from "../../../../styles/divider";
import OrderPayment from "../../../order/OrderParameters/OrderPayment";
import { useTranslation } from "react-i18next";
import { SetState } from "../../../../utils/types";
import { BoxType } from "../../../../models/box";
import { OrderEditParametersData } from "../../../../models/order";
import { AdditionalService } from "../../../../models/additionalServices";

const OrderEditParametersModalContent: FC<{
  open: boolean;
  setOpen: SetState<boolean>;
  loading: boolean;
  boxType?: BoxType;
  additionalServices: AdditionalService[];
}> = ({ open, setOpen, loading, boxType, additionalServices }) => {
  const { t } = useTranslation();
  const {
    isSubmitting,
    submitForm,
    values,
    resetForm,
    setFieldValue,
  } = useFormikContext<OrderEditParametersData>();

  useEffect(() => {
    if (open) resetForm();
  }, [open, resetForm]);

  return (
    <CustomModal
      open={open}
      setOpen={setOpen}
      title={t("orders.table.editModal.title")}
      actions={
        <>
          <Button
            type="button"
            ver="secondary"
            disabled={isSubmitting}
            onClick={() => setOpen(false)}
          >
            {t("common.cancel")}
          </Button>
          <Button disabled={isSubmitting} onClick={submitForm}>
            {t("common.save")}
          </Button>
        </>
      }
    >
      <Form>
        {loading ? (
          <Loader />
        ) : (
          boxType && (
            <FormGroup>
              <H2 bold>{t("order.parameters.services.title")}</H2>
              <Checkbox>
                <Field type="checkbox" name="fingerprint" />
                <OrderCheckboxLabel checked={values.fingerprint}>
                  <span>{t("order.parameters.services.fingerPrint")}</span>
                  <span>
                    {formatCurrency(boxType.boxPriceDto.fingerPrintWithVat)}
                    {t("common.price.monthly")}
                  </span>
                </OrderCheckboxLabel>
              </Checkbox>
              <Checkbox>
                <Field type="checkbox" name="smsCheck" />
                <OrderCheckboxLabel checked={values.smsCheck}>
                  <span>{t("order.parameters.services.smsCheck")}</span>
                  <span>
                    {formatCurrency(boxType.boxPriceDto.smsCheckWithVat)}
                    {t("common.price.monthly")}
                  </span>
                </OrderCheckboxLabel>
              </Checkbox>
              {additionalServices.map((service) => (
                <Checkbox key={"additionalService" + service.id}>
                  <input
                    type="checkbox"
                    name={"additionalService" + service.id}
                    checked={
                      values.additionalServices.find(
                        (x) => x === service.id
                      ) !== undefined
                    }
                    onChange={() =>
                      values.additionalServices.find(
                        (x) => x === service.id
                      ) === undefined
                        ? setFieldValue(
                            "additionalServices",
                            values.additionalServices.concat([service.id])
                          )
                        : setFieldValue(
                            "additionalServices",
                            values.additionalServices.filter(
                              (x) => x !== service.id
                            )
                          )
                    }
                  />
                  <OrderCheckboxLabel
                    checked={
                      values.additionalServices.find(
                        (x) => x === service.id
                      ) !== undefined
                    }
                  >
                    <span>{service.name}</span>
                    <span>
                      {formatCurrency(
                        boxType.boxPriceDto.additionalServices.find(
                          (x) => x.id === service.id
                        )!.priceWithVat
                      )}
                      {t("common.price.monthly")}
                    </span>
                  </OrderCheckboxLabel>
                </Checkbox>
              ))}
              <Divider my="1rem" />
              <OrderPayment values={values} />
            </FormGroup>
          )
        )}
      </Form>
    </CustomModal>
  );
};

export default OrderEditParametersModalContent;
