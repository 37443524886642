import { UserRights } from "./auth";
import { Role } from "./user";

export enum EmployeeRole {
  Employee = "Employee",
  Boss = "Boss",
  Admin = "Admin",
  QueueManager = "QueueManager",
  SignManager = "SignManager",
}

export const AdminRoles: Role[] = ["Employee", "Boss", "Admin"];

export interface Employee {
  firstName: string;
  lastName: string;
  email: string;
  role: keyof typeof EmployeeRole;
  isActive: boolean;
  dailyReport: boolean;
  ownCalendar: boolean;
  calendarEmail: boolean;
  notificationAdditionalServiceRenewal: boolean,
  roleRights: UserRights;
  userRights: UserRights;
}
