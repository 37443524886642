import React, { FC, Fragment, useMemo } from "react";
import { Card, CardHeader, CardList } from "../../../../styles/card";
import { H3 } from "../../../../styles/title";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { formatCurrency, formatDate } from "../../../../utils/formats";
import { PageSection } from "../../../../styles/layout";
import InvoiceState from "../../Invoices/InvoiceState/InvoiceState";
import { Invoice } from "../../../../models/document";
import InvoiceName from "../../Invoices/InvoiceName/InvoiceName";
import { NoTableData } from "../../../../styles/table";

const DashboardInvoices: FC<{ data: Invoice[] }> = ({ data }) => {
  const { t } = useTranslation();

  const tableData = useMemo(
    () =>
      data.map((row) => (
        <Fragment key={row.id}>
          <InvoiceName invoice={row} />
          <div>
            {t("dashboard.invoices.issued")} {formatDate(row.createdDate)}
          </div>
          <div>
            {t(`dashboard.invoices.${row.paid ? "paidShort" : "due"}`)}{" "}
            {formatDate(row.dueDate)}
          </div>
          <InvoiceState paid={row.paid} />
          <div>
            <b>{formatCurrency(row.priceInclVat)}</b>
          </div>
        </Fragment>
      )),
    [data, t]
  );

  return (
    <PageSection>
      <Card overflowX="auto">
        <CardHeader>
          <H3>{t("dashboard.invoices.title")}</H3>
          <Link to="/invoices">
            <b>{t("dashboard.invoices.showInvoices")}</b>
          </Link>
        </CardHeader>
        {data.length ? (
          <CardList cols={5}>{tableData}</CardList>
        ) : (
          <NoTableData>{t("invoices.noData")}</NoTableData>
        )}
      </Card>
    </PageSection>
  );
};

export default DashboardInvoices;
