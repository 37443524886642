import { Form, useFormikContext } from "formik";
import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../../styles/button";
import { FormGroup } from "../../../styles/form";
import { useUserHasRight } from "../../../utils/auth";
import { DocumentTemplateType } from "../../../utils/enums";
import { SetState } from "../../../utils/types";
import CustomModal from "../../common/CustomModal/CustomModal";
import Input from "../../common/form/Input/Input";
import Select from "../../common/form/Select/Select";

interface FormData {
  name: string;
  type: DocumentTemplateType;
  mustSign: boolean;
}

const DocumentTypeEditModalContent: FC<{
  open: boolean;
  setOpen: SetState<boolean>;
}> = ({ open, setOpen }) => {
  const { t } = useTranslation();
  const {
    isSubmitting,
    isValid,
    submitForm,
    errors,
    touched,
    resetForm,
    values,
    setFieldValue,
  } = useFormikContext<FormData>();
  const { hasUserAllRights } = useUserHasRight();

  useEffect(() => {
    if (open) resetForm();
  }, [open, resetForm]);

  let types = [];
  types.push({
    label: t("documents.typeOrder"),
    value: DocumentTemplateType.Order,
  });
  types.push({
    label: t("documents.typeCustomer"),
    value: DocumentTemplateType.Customer,
  });

  return (
    <CustomModal
      title={t("documents.editModal.title")}
      open={open}
      setOpen={setOpen}
      actions={
        <>
          <Button
            type="button"
            ver="secondary"
            disabled={isSubmitting}
            onClick={() => setOpen(false)}
          >
            {t("common.cancel")}
          </Button>
          <Button
            disabled={
              !isValid ||
              isSubmitting ||
              !hasUserAllRights(["writeDocumentTemplates"])
            }
            onClick={() => submitForm()}
          >
            {t("common.save")}
          </Button>
        </>
      }
    >
      <Form>
        <FormGroup>
          <Input
            key="name"
            name="name"
            label={t(`documents.editModal.name`)}
            error={!!errors["name"] && touched["name"]}
          />
          <Select
            key="type"
            name="type"
            label={t(`documents.editModal.type`)}
            value={types.find((x) => x.value === values["type"])}
            options={types}
            onChange={(option) => setFieldValue("type", option?.value)}
          />
          <Input
            key="mustSign"
            name="mustSign"
            type="checkbox"
            label={t(`documents.editModal.mustSign`)}
            inputWidth="1.5rem"
            inputHeight="1.5rem"
          />
        </FormGroup>
      </Form>
    </CustomModal>
  );
};

export default DocumentTypeEditModalContent;
