import { faPen } from "@fortawesome/free-solid-svg-icons";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { IconButton } from "../../../../styles/button";
import { useUserHasRight } from "../../../../utils/auth";
import AdminBoxesSettingsDetailInfoTotalModal from "./AdminBoxesSettingsDetailInfoTotalModal";
import {
  AdminBoxesSettingsDetailInfoFormCol,
  AdminBoxesSettingsDetailInfoFormRow,
  AdminBoxesSettingsDetailInfoTotalNumber,
  AdminBoxesSettingsDetailInfoTotalNumberWrapper,
} from "./styles";

const AdminBoxesSettingsDetailInfoTotal: FC<{
  defaultTotalCount: number;
}> = ({ defaultTotalCount }) => {
  const { t } = useTranslation();
  const [currentTotalCount, setCurrentTotalCount] = useState(defaultTotalCount);
  const [modalOpen, setModalOpen] = useState(false);

  const { hasUserAllRights } = useUserHasRight();
  const canWrite = hasUserAllRights(["writeBoxTotalCount"]);

  return (
    <>
      <AdminBoxesSettingsDetailInfoTotalModal
        open={modalOpen}
        setOpen={setModalOpen}
        defaultTotalCount={currentTotalCount}
        setCurrentTotalCount={setCurrentTotalCount}
      />
      <AdminBoxesSettingsDetailInfoFormRow noMargin>
        <AdminBoxesSettingsDetailInfoFormCol>
          <label htmlFor="isActive">
            {t("adminBoxesSettingsDetail.totalLabel")}
          </label>
        </AdminBoxesSettingsDetailInfoFormCol>
        <AdminBoxesSettingsDetailInfoFormCol>
          <AdminBoxesSettingsDetailInfoTotalNumberWrapper>
            <AdminBoxesSettingsDetailInfoTotalNumber>
              {currentTotalCount}
            </AdminBoxesSettingsDetailInfoTotalNumber>
            {canWrite && (
              <IconButton icon={faPen} onClick={() => setModalOpen(true)} />
            )}
          </AdminBoxesSettingsDetailInfoTotalNumberWrapper>
        </AdminBoxesSettingsDetailInfoFormCol>
      </AdminBoxesSettingsDetailInfoFormRow>
    </>
  );
};
export default AdminBoxesSettingsDetailInfoTotal;
