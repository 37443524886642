import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import cardApi from "../../../../api/card";
import documentApi from "../../../../api/document";
import orderApi from "../../../../api/order";
import userApi from "../../../../api/user";
import { Button } from "../../../../styles/button";
import { Table } from "../../../../styles/table";
import { useUserHasRight } from "../../../../utils/auth";
import { COLORS } from "../../../../utils/constants";
import { OrderState } from "../../../../utils/enums";
import { getErrorMessage } from "../../../../utils/errors";
import { SetState } from "../../../../utils/types";
import CustomModal from "../../../common/CustomModal/CustomModal";
import Loader from "../../../common/Loader/Loader";

const AdminClientsDetailActivateModal: FC<{
  open: boolean;
  setOpen: SetState<boolean>;
  confirm: () => Promise<void>;
  customerId: string;
}> = ({ open, setOpen, confirm, customerId }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [existsOkOrder, setExistsOkOrder] = useState(false);
  const [existsNonSignedDocument, setExistsNonSignedDocument] = useState(true);
  const [existsImage, setExistsImage] = useState(false);
  const [existActiveCard, setExistActiveCard] = useState(false);
  const [existsOrderUnpaidInvoice, setExistsOrderUnpaidInvoice] =
    useState(true);
  const [existsOrderNonSignedDocument, setExistsOrderNonSignedDocument] =
    useState(true);

  const { hasUserAllRights } = useUserHasRight();

  const handleConfirm = useCallback(async () => {
    setSaving(true);
    //Close first.
    setOpen(false);
    await confirm();
    setSaving(false);
  }, [confirm, setOpen]);

  const loadData = useCallback(() => {
    (async () => {
      if (!open) {
        return;
      }

      if (
        !hasUserAllRights([
          "readOrders",
          "readCustomerDocuments",
          "readCustomerImages",
          "readCards",
        ])
      ) {
        return;
      }

      setLoading(true);
      try {
        const responseOrders = await orderApi.getUserOrders(customerId);
        const existsOkOrder =
          responseOrders.data.find((x) => x.state === OrderState.Ok) !==
          undefined;

        const responseDocuments = await documentApi.getUserDocuments(
          customerId
        );
        const existsNonSignedDocument =
          responseDocuments.data.find(
            (x) => !x.orderId && x.mustSign && !x.signed
          ) !== undefined;

        const responseImages = await userApi.getUserImages(customerId);
        const existsImage = responseImages.data.length > 0;

        const responseCards = await cardApi.getUserCards(customerId);
        const existActiveCard =
          responseCards.data.find((x) => x.orders.length !== 0) !== undefined;

        setExistsOkOrder(existsOkOrder);
        setExistsNonSignedDocument(existsNonSignedDocument);
        setExistsImage(existsImage);
        setExistActiveCard(existActiveCard);

        if (
          existsOkOrder &&
          !existsNonSignedDocument &&
          existsImage &&
          existActiveCard
        ) {
          await handleConfirm();
        }

        if (!existsOkOrder) {
          if (hasUserAllRights(["readInvoices"])) {
            const responseInvoices = await documentApi.getUserInvoices(
              customerId
            );

            const existsOrderUnpaidInvoice =
              responseInvoices.data.find((x) => !x.paid) !== undefined;
            const existsOrderNonSignedDocument =
              responseDocuments.data.find(
                (x) => x.orderId && x.mustSign && !x.signed
              ) !== undefined;

            setExistsOrderUnpaidInvoice(existsOrderUnpaidInvoice);
            setExistsOrderNonSignedDocument(existsOrderNonSignedDocument);
          }
        }
      } catch (err) {
        toast.error(getErrorMessage(err, t("errors.loadError")));
      }
      setLoading(false);
    })();
  }, [customerId, handleConfirm, hasUserAllRights, open, t]);

  useEffect(() => {
    loadData();
  }, [loadData, open, customerId]);

  if (loading) {
    return <Loader />;
  }

  return (
    <CustomModal
      title={t("adminClientsDetail.activateModal.title")}
      open={open}
      setOpen={setOpen}
      actions={
        <>
          <Button
            ver="secondary"
            disabled={saving}
            onClick={() => setOpen(false)}
          >
            {t("common.close")}
          </Button>
        </>
      }
    >
      <Table>
        <tr>
          {existsOkOrder ? (
            <>
              <td>
                <FontAwesomeIcon
                  icon={faCheck}
                  size="lg"
                  color={COLORS.green}
                />
              </td>
              <td>{t("adminClientsDetail.activateModal.ordersOk")}</td>
            </>
          ) : (
            <>
              <td>
                <FontAwesomeIcon icon={faTimes} size="lg" color={COLORS.red} />
              </td>
              <td>{t("adminClientsDetail.activateModal.ordersNotOk")}</td>
            </>
          )}
        </tr>
        <tr>
          {existsNonSignedDocument ? (
            <>
              <td>
                <FontAwesomeIcon icon={faTimes} size="lg" color={COLORS.red} />
              </td>
              <td>{t("adminClientsDetail.activateModal.documentsNotOk")}</td>
            </>
          ) : (
            <>
              <td>
                <FontAwesomeIcon
                  icon={faCheck}
                  size="lg"
                  color={COLORS.green}
                />
              </td>
              <td>{t("adminClientsDetail.activateModal.documentsOk")}</td>
            </>
          )}
        </tr>
        <tr>
          {existsImage ? (
            <>
              <td>
                <FontAwesomeIcon
                  icon={faCheck}
                  size="lg"
                  color={COLORS.green}
                />
              </td>
              <td>{t("adminClientsDetail.activateModal.imagesOk")}</td>
            </>
          ) : (
            <>
              <td>
                <FontAwesomeIcon icon={faTimes} size="lg" color={COLORS.red} />
              </td>
              <td>{t("adminClientsDetail.activateModal.imagesNotOk")}</td>
            </>
          )}
        </tr>
        <tr>
          {existActiveCard ? (
            <>
              <td>
                <FontAwesomeIcon
                  icon={faCheck}
                  size="lg"
                  color={COLORS.green}
                />
              </td>
              <td>{t("adminClientsDetail.activateModal.cardsOk")}</td>
            </>
          ) : (
            <>
              <td>
                <FontAwesomeIcon icon={faTimes} size="lg" color={COLORS.red} />
              </td>
              <td>{t("adminClientsDetail.activateModal.cardsNotOk")}</td>
            </>
          )}
        </tr>
        {!existsOkOrder && (
          <>
            <tr>
              {existsOrderUnpaidInvoice ? (
                <>
                  <td>
                    <FontAwesomeIcon
                      icon={faTimes}
                      size="lg"
                      color={COLORS.red}
                    />
                  </td>
                  <td>
                    {t("adminClientsDetail.activateModal.orderInvoicesNotOk")}
                  </td>
                </>
              ) : (
                <>
                  <td>
                    <FontAwesomeIcon
                      icon={faCheck}
                      size="lg"
                      color={COLORS.green}
                    />
                  </td>
                  <td>
                    {t("adminClientsDetail.activateModal.orderInvoicesOk")}
                  </td>
                </>
              )}
            </tr>
            <tr>
              {existsOrderNonSignedDocument ? (
                <>
                  <td>
                    <FontAwesomeIcon
                      icon={faTimes}
                      size="lg"
                      color={COLORS.red}
                    />
                  </td>
                  <td>
                    {t("adminClientsDetail.activateModal.orderDocumentsNotOk")}
                  </td>
                </>
              ) : (
                <>
                  <td>
                    <FontAwesomeIcon
                      icon={faCheck}
                      size="lg"
                      color={COLORS.green}
                    />
                  </td>
                  <td>
                    {t("adminClientsDetail.activateModal.orderDocumentsOk")}
                  </td>
                </>
              )}
            </tr>
          </>
        )}
      </Table>
    </CustomModal>
  );
};

export default AdminClientsDetailActivateModal;
