import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import { DEFAULT_PAGE_SIZE } from "../../../utils/constants";
import {
  ReactPaginateContainer,
  ReactPaginateCurrentPage,
  ReactPaginateWrapper,
} from "./styles";

const Pagination: FC<{
  initialPage: number;
  totalCount: number;
  pageSize?: number;
  onPageChange(selectedPage: number): void;
}> = ({
  initialPage,
  totalCount,
  pageSize = DEFAULT_PAGE_SIZE,
  onPageChange,
}) => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState<number>(initialPage);
  const pageCount = Math.ceil(totalCount / pageSize);

  useEffect(() => {
    setCurrentPage(initialPage);
  }, [initialPage]);

  return totalCount > pageSize ? (
    <ReactPaginateContainer>
      <ReactPaginateCurrentPage>
        {t("common.page")}&nbsp;{currentPage}/{pageCount}
      </ReactPaginateCurrentPage>
      <ReactPaginateWrapper>
        <ReactPaginate
          pageCount={pageCount}
          pageRangeDisplayed={3}
          marginPagesDisplayed={1}
          initialPage={initialPage - 1}
          forcePage={currentPage - 1}
          previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
          nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
          onPageChange={(selectedItem) => {
            const selectedPage = selectedItem.selected + 1;
            onPageChange(selectedPage);
            setCurrentPage(selectedPage);
          }}
        />
      </ReactPaginateWrapper>
    </ReactPaginateContainer>
  ) : null;
};
export default Pagination;
