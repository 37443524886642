import { AxiosResponse } from "axios";
import { Card, CardCreateData, CardEditNameData } from "../models/card";
import api from "../utils/api";

export const getUserCards = (
  customerId: string
): Promise<AxiosResponse<Card[]>> =>
  api.get(`/api/Customer/${customerId}/Card`);

export const updateUserCardName = (
  cardId: string,
  data: CardEditNameData
): Promise<AxiosResponse<boolean>> => api.put(`/api/Card/${cardId}/Name`, data);

export const pairUserCardWithOrder = (
  cardId: string,
  orderId: string
): Promise<AxiosResponse<boolean>> =>
  api.post(`/api/Card/${cardId}/PairWithOrder/${orderId}`);

export const unpairUserCardWithOrder = (
  cardId: string,
  orderId: string
): Promise<AxiosResponse<boolean>> =>
  api.post(`/api/Card/${cardId}/UnPairWithOrder/${orderId}`);

export const createCard = (
  customerId: string,
  data: CardCreateData
): Promise<AxiosResponse<boolean>> =>
  api.post(`/api/Customer/${customerId}/Card`, data);

export const deleteCard = (cardId: string): Promise<AxiosResponse<boolean>> =>
  api.delete(`/api/Card/${cardId}`);

const cardApi = {
  getUserCards,
  updateUserCardName,
  pairUserCardWithOrder,
  unpairUserCardWithOrder,
  createCard,
  deleteCard,
};

export default cardApi;
