import styled, { css } from "styled-components";
import { fadeIn, size } from "../../../utils/styles";
import { COLORS } from "../../../utils/constants";

export const TooltipContent = styled.div<{ left?: boolean }>`
  display: none;

  position: absolute;
  top: 0;
  right: 100%;

  min-height: 2rem;
  max-width: 18rem;
  padding: 0.5rem 0.8rem;

  background: ${COLORS.whitesmoke};
  color: var(--font-color);
  font-weight: normal;
  font-size: 0.8rem;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
  pointer-events: none;

  animation: ${fadeIn} 0.15s ease-in-out;

  ${({ left }) =>
    !left &&
    css`
      @media screen and (min-width: ${size.desktop}) {
        left: 2rem;
        right: auto;
      }
    `}
`;

export const TooltipContainer = styled.div<{ contentAlign?: "topLeft" }>`
  width: 2rem !important;
  height: 2rem !important;

  position: absolute;
  ${({ contentAlign }) =>
    contentAlign === "topLeft"
      ? css`
          top: 0;
        `
      : css`
          top: 50%;
          transform: translateY(-50%);
        `};
  right: -4rem;
  z-index: 300;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${COLORS.secondary};
  color: ${COLORS.primary};
  border-radius: 0.25rem 0 0 0.25rem;

  &:hover {
    ${TooltipContent} {
      display: block;
    }
  }

  &::before {
    content: "";
    position: absolute;
    left: -0.4rem;
    transform: rotate(45deg);

    width: 0.8rem;
    height: 0.8rem;

    background-color: ${COLORS.secondary};
  }
`;
