import { AxiosResponse } from "axios";
import { AdminOrdersFilter } from "../components/admin/AdminOrders/AdminOrdersContent/AdminOrdersContent";
import { Paged } from "../models/common";
import {
  InvoiceStateResponse,
  Order,
  OrderCreateData,
  OrderCreateResponse,
  OrderEditParametersData,
  OrderStateOverviews,
  OrderSubscriptionData,
} from "../models/order";
import api from "../utils/api";

export const getAllOrders = async (
  params?: AdminOrdersFilter
): Promise<AxiosResponse<Paged<Order>>> => api.get(`/api/Order`, { params });

export const getUserOrders = async (
  customerId: string
): Promise<AxiosResponse<Order[]>> =>
  api.get(`/api/Customer/${customerId}/Order`);

export const getOrder = async (
  orderId: string
): Promise<AxiosResponse<Order>> => api.get(`/api/Order/${orderId}`);

export const getOrderStateOverview = async (): Promise<
  AxiosResponse<OrderStateOverviews>
> => api.get(`/api/Order/Overview`);

export const finishOrder = async (
  orderId: string
): Promise<AxiosResponse<boolean>> => api.put(`/api/Order/${orderId}/Finish`);

export const setOrderExpired = (
  orderId: string
): Promise<AxiosResponse<boolean>> => api.put(`/api/Order/${orderId}/Expire`);

export const updateOrderName = (
  orderId: string,
  name: string
): Promise<AxiosResponse> => api.put(`/api/Order/${orderId}/Name/${name}`);

export const updateOrderParams = (
  data: OrderEditParametersData,
  orderId: string
): Promise<AxiosResponse<OrderCreateResponse>> =>
  api.put(`/api/Order/${orderId}`, data);

export const updateOrderSubscription = (
  orderId: string,
  data: OrderSubscriptionData
): Promise<AxiosResponse<boolean>> =>
  api.put(`/api/Order/${orderId}/Subscription`, data);

export const extendOrder = (
  orderId: string,
  data: OrderSubscriptionData
): Promise<AxiosResponse<boolean>> =>
  api.put(`/api/Order/${orderId}/Extend`, data);

export const createOrder = (
  data: OrderCreateData,
  customerId: string
): Promise<AxiosResponse<OrderCreateResponse>> =>
  api.post(`/api/Customer/${customerId}/Order`, data);

export const checkInvoice = (
  token: string
): Promise<AxiosResponse<InvoiceStateResponse>> =>
  api.get(`/api/Invoice/Check/${token}`);

export const cancelOrder = (orderId: string): Promise<AxiosResponse<boolean>> =>
  api.put(`/api/Order/${orderId}/Cancel`);

const orderApi = {
  getAllOrders,
  getUserOrders,
  getOrder,
  getOrderStateOverview,
  finishOrder,
  setOrderExpired,
  createOrder,
  updateOrderParams,
  updateOrderSubscription,
  extendOrder,
  updateOrderName,
  checkInvoice,
  cancelOrder,
};

export default orderApi;
