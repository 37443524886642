import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import calendarApi from "../../../../api/calendar";
import { LinkButton } from "../../../../styles/button";
import MeetingCancelModal from "./MeetingCancelModal";

const MeetingCancelButton: React.FC<{
  id: number;
  notClient?: boolean;
  reload?: () => Promise<void>;
}> = ({ id, notClient, reload }) => {
  const { t } = useTranslation();
  const [pending, setPending] = useState(false);
  const [cancelModal, setCancelModal] = useState<boolean>(false);

  const handleClick = async (id: number) => {
    setPending(true);
    try {
      if (notClient) {
        await calendarApi.cancelNonCustomerMeeting(id);
      } else {
        await calendarApi.cancelMeeting(id);
      }
      toast.success(t("dashboard.meetings.cancelSuccess"));
      reload?.();
    } catch (err) {
      toast.error(err.response?.data?.ErrorMessage ?? t("errors.saveError"));
    }
    setPending(false);
  };

  return (
    <>
      <LinkButton disabled={pending} onClick={() => setCancelModal(true)}>
        {t("common.cancel")}
      </LinkButton>
      <MeetingCancelModal
        open={cancelModal}
        setOpen={setCancelModal}
        confirm={() => handleClick(id)}
      />
    </>
  );
};

export default MeetingCancelButton;
