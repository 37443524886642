import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import documentApi from "../../../../api/document";
import { Document, DocumentType } from "../../../../models/document";
import { Button } from "../../../../styles/button";
import { DocumentTemplateType } from "../../../../utils/enums";
import { getErrorMessage } from "../../../../utils/errors";
import { SetState } from "../../../../utils/types";
import CustomModal from "../../../common/CustomModal/CustomModal";
import Checkbox from "../../../common/form/Checkbox/Checkbox";
import Loader from "../../../common/Loader/Loader";
import { DocumentTypeListItem } from "./styles";

const EditOrderDocumentsModal: FC<{
  type: DocumentTemplateType;
  open: boolean;
  setOpen: SetState<boolean>;
  pairedDocs: Document[];
  onSuccess: () => void;
}> = ({ type, open, setOpen, pairedDocs, onSuccess }) => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [docTypes, setDocTypes] = useState<DocumentType[]>([]);
  const [selectedDocs, setSelectedDocs] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [reload, setReload] = useState(false);

  const isChecked = (id: string) => selectedDocs.includes(id);

  const handleChecked = async (checked: boolean, doc: DocumentType) => {
    setSaving(true);
    try {
      if (type === DocumentTemplateType.Customer) {
        await documentApi.pairDocumentWithCustomer(doc.id, id);
      } else {
        await documentApi.pairDocumentWithOrder(doc.id, id);
      }
      toast.success(t("common.saveSuccess"));
      setSelectedDocs((prev) => [...prev, doc.id]);
      setReload(true);
    } catch (err) {
      toast.error(getErrorMessage(err, t("errors.saveError")));
    }
    setSaving(false);
  };

  useEffect(() => {
    if (open) {
      setReload(false);
      (async () => {
        setLoading(true);
        try {
          const response = await documentApi.getAllDocumentTypes();
          setSelectedDocs(pairedDocs.map((d) => d.documentTemplateId));
          setDocTypes(response.data.filter((x) => x.type === type));
        } catch (err) {
          toast.error(getErrorMessage(err, t("errors.loadError")));
        }
        setLoading(false);
      })();
    } else {
      if (reload) onSuccess();
    }
    // don't fire on `reload` change
    // eslint-disable-next-line
  }, [open, t, onSuccess, pairedDocs]);

  return (
    <CustomModal
      open={open}
      setOpen={setOpen}
      title={t("documents.addModal.title")}
      actions={
        <Button ver="secondary" onClick={() => setOpen(false)}>
          {t("common.cancel")}
        </Button>
      }
    >
      {loading ? (
        <Loader />
      ) : (
        <div>
          {docTypes.map((dt, i) => (
            <DocumentTypeListItem key={i}>
              {dt.name}
              <Checkbox>
                <input
                  type="checkbox"
                  checked={isChecked(dt.id)}
                  disabled={saving || isChecked(dt.id)}
                  onChange={({ target }) => handleChecked(target.checked, dt)}
                />
              </Checkbox>
            </DocumentTypeListItem>
          ))}
        </div>
      )}
    </CustomModal>
  );
};

export default EditOrderDocumentsModal;
