import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { COLORS } from "../../../../utils/constants";

export const SettingsLinksWrapper = styled.nav`
  background-color: var(--transparent);
`;

export const SettingsLinksHeading = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  text-transform: uppercase;
  color: ${COLORS.white};

  padding: 0.5rem 1.25rem 0 1.25rem;
`;

export const SidebarLinks = styled.nav`
  display: flex;
  flex-flow: column;

  padding: 0.5rem 0.5rem 0.5rem 0.5rem;

  background-color: var(--transparent);

  .active {
    background-color: var(--gray-light);
  }
`;

export const SidebarLink = styled(NavLink)`
  width: 100%;

  padding: 0.5rem 0.75rem;
  border-radius: 0.3rem;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  color: var(--secondary) !important;
  text-decoration: none;

  &:hover {
    &,
    svg {
      color: var(--white) !important;
    }
  }
`;

export const SidebarLinkIcon = styled.span`
  display: inline-block;
  width: 2.5rem;
  text-align: center;
  margin-left: 1rem;
  font-size: 1.75rem;

  svg,
  span {
    transition: color 0.2s ease-in-out;
  }
`;
