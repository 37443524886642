import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LinkButton } from "../../../styles/button";

export const BreadcrumbsContainer = styled.nav`
  font-size: 1.2rem;
  margin-bottom: 0.8rem;
  line-height: 1.7;

  display: flex;

  ${LinkButton} {
    font-size: 1.2rem;
  }
`;

export const BreadcrumbsList = styled.ol`
  display: flex;
  flex-wrap: wrap;
`;

export const BreadcrumbsCrumb = styled.li`
  display: flex;
  align-items: center;
`;

export const BreadcrumbsIcon = styled(FontAwesomeIcon)`
  margin: 0 0.5rem;
`;
