import React, { FC } from "react";
import {
  BoxTypeCardContainer,
  BoxTypeCardContent,
  BoxTypeCardIcon,
  BoxTypeCardImg,
  BoxTypePrice,
  BoxTypeVolumeIcon,
} from "./styles";
import { useTranslation } from "react-i18next";
import { ImgResponsive } from "../../../styles/img";
import {
  faArrowsAltH,
  faWeightHanging,
} from "@fortawesome/free-solid-svg-icons";
import { BoxType } from "../../../models/box";
import { BoxTypeLabel } from "../../common/BoxTypeLabel/styles";
import { getBoxImg } from "../../../utils/images";
import { formatCurrency } from "../../../utils/formats";
import { getLeaseMonths } from "../priceUtils";

const OrderBoxType: FC<{
  boxType: BoxType;
  active: boolean;
  onClick: () => any;
}> = ({ boxType, active, onClick }) => {
  const { t } = useTranslation();

  return (
    <BoxTypeCardContainer
      isBoxActive={boxType.isActive}
      active={active}
      onClick={boxType.isActive ? onClick : undefined}
    >
      <BoxTypeCardImg>
        <ImgResponsive src={getBoxImg(boxType.type)} alt={boxType.type} />
      </BoxTypeCardImg>
      <BoxTypeCardContent>
        <BoxTypeLabel>{boxType.type}</BoxTypeLabel>
        <span>{t("common.box")}</span>
        <BoxTypeCardIcon icon={faArrowsAltH} color="var(--primary)" />
        <div>{boxType.dimensions}</div>
        <BoxTypeVolumeIcon />
        <div>{boxType.volume}</div>
        <BoxTypeCardIcon icon={faWeightHanging} color="var(--primary)" />
        <div>{boxType.loadCapacity}</div>
        <BoxTypePrice>
          {t("order.boxTypes.priceFrom")}{" "}
          {formatCurrency(
            boxType.boxPriceDto.price4WithVat / getLeaseMonths("4")
          )}
        </BoxTypePrice>
      </BoxTypeCardContent>
    </BoxTypeCardContainer>
  );
};

export default OrderBoxType;
