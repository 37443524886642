import styled from "styled-components";
import { IconButtonDelete } from "../../../styles/button";
import { Table } from "../../../styles/table";

export const DocumentTypesTableContainer = styled(Table)`
  td:first-child {
    svg {
      margin-right: 0.5rem;
    }
  }

  th:nth-child(3) {
    width: max-content;
  }
`;

export const DocumentTypeTableDelete = styled(IconButtonDelete)`
  margin-left: 0.3rem;
`;
