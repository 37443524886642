import { faCalendarAlt, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import calendarApi from "../../../../api/calendar";
import storageApi from "../../../../api/storage";
import { EmployeeCalendarOverview } from "../../../../models/calendar";
import { Paged } from "../../../../models/common";
import { FadeInBottom } from "../../../../styles/fadeIn";
import { NoTableData } from "../../../../styles/table";
import { H4 } from "../../../../styles/title";
import { getErrorMessage } from "../../../../utils/errors";
import { formatDate, formatTime, spaceToNbsp } from "../../../../utils/formats";
import { OverflowX } from "../../../../utils/styles";
import Loader from "../../../common/Loader/Loader";
import {
  CardEventsCell,
  CardEventsContent,
  CardEventsHeader,
  CardEventsIcon,
  CardEventsNameTruncate,
  CartEventsTable,
} from "./styles";

const CardEvents: FC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [reservations, setReservations] = useState<
    Paged<EmployeeCalendarOverview>
  >({
    count: 0,
    data: [],
  });
  const ownCalendar = storageApi.getCurrentUser()?.ownCalendar;
  const isAdmin =
    storageApi.getCurrentUser()?.role === "Admin" ||
    storageApi.getCurrentUser()?.role === "Boss";

  useEffect(() => {
    if (!ownCalendar) return;

    (async () => {
      setLoading(true);
      try {
        const response = await calendarApi.getEmployeeCalendarReservation(
          1,
          storageApi.getCurrentUser()!.id,
          {
            pageSize: 7,
            orderBy: "DateTime",
            cardMode: true,
          }
        );
        setReservations(response.data);
      } catch (err) {
        toast.error(getErrorMessage(err, t("errors.loadError")));
      }
      setLoading(false);
    })();
  }, [ownCalendar, t]);

  const tableRows = useMemo(
    () =>
      reservations.data.slice(0, 7).map((record) => (
        <tr key={record.id}>
          <td>
            <CardEventsCell>
              <CardEventsIcon>
                <FontAwesomeIcon icon={faCalendarAlt} size={"sm"} />
              </CardEventsIcon>
              <div>
                <b>{formatDate(record.date)}</b>&nbsp;
                {spaceToNbsp(formatTime(record.date))}
              </div>
            </CardEventsCell>
          </td>
          <td>
            <CardEventsNameTruncate>
              {record.customer && (
                <Link to={`/admin/clients/${record.customer.id}`}>
                  {record.customer.firstName}&nbsp;{record.customer.lastName}
                </Link>
              )}
              {record.note}
            </CardEventsNameTruncate>
          </td>
          <td>
            <CardEventsCell>
              <CardEventsIcon>
                <FontAwesomeIcon icon={faUserCircle} size={"sm"} />
              </CardEventsIcon>
              <CardEventsNameTruncate>
                {record.employee && (
                  <Link to={`/admin/settings/employees/${record.employee.id}`}>
                    {record.employee.firstName}&nbsp;{record.employee.lastName}
                  </Link>
                )}
              </CardEventsNameTruncate>
            </CardEventsCell>
          </td>
        </tr>
      )),
    [reservations]
  );

  return (
    <>
      <CardEventsHeader>
        <H4>{t("adminDashboard.events.title")}</H4>
        <Link to="/admin/calendar">
          <b>
            {t(`adminDashboard.events.calendarLink${isAdmin ? "Admin" : ""}`)}
          </b>
        </Link>
      </CardEventsHeader>
      <CardEventsContent>
        {loading ? (
          <Loader />
        ) : (
          <FadeInBottom>
            {reservations.data.length ? (
              <OverflowX>
                <CartEventsTable size={"small"}>
                  <tbody>{tableRows}</tbody>
                </CartEventsTable>
              </OverflowX>
            ) : (
              <NoTableData>
                {t(
                  `adminDashboard.events.${
                    ownCalendar ? "noData" : "noCalendar"
                  }`
                )}
              </NoTableData>
            )}
          </FadeInBottom>
        )}
      </CardEventsContent>
    </>
  );
};

export default CardEvents;
