import React, { FC } from "react";
import { InvoiceStateContainer } from "./styles";
import { useTranslation } from "react-i18next";

const InvoiceState: FC<{ paid: string | null }> = ({ paid }) => {
  const { t } = useTranslation();
  const isPaid = !!paid && new Date(paid) < new Date();

  return (
    <InvoiceStateContainer paid={isPaid}>
      {t(`invoices.paid.${isPaid.toString()}`)}
    </InvoiceStateContainer>
  );
};

export default InvoiceState;
