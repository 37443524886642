import styled from "styled-components";
import { Link } from "react-router-dom";
import { COLORS } from "../utils/constants";

export const PrimaryText = styled.span`
  color: ${COLORS.primary};
`;

export const GreenText = styled.span`
  color: ${COLORS.green};
`;

export const OrangeText = styled.span`
  color: ${COLORS.orange};
`;

export const RedText = styled.span`
  color: ${COLORS.red};
  font-weight: bold;
`;

export const TextTruncate = styled.div<{ width: number }>`
  width: auto;
  min-width: 0;
  max-width: ${({ width }) => width}rem !important;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const LinkNoStyle = styled(Link)`
  text-decoration: none;
  color: inherit;

  &:hover {
    text-decoration: underline;
  }
`;
