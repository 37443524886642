import styled from "styled-components";
import { size } from "../../../../../utils/styles";

export const OrderDetailCardParameter = styled.div`
  text-align: start;

  display: flex;
  flex-wrap: wrap;

  @media screen and (min-width: ${size.mobile}) {
    justify-content: flex-end;
  }
`;
