import React, { FC, useCallback, useMemo } from "react";
import { OverflowX } from "../../../../utils/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faFileAlt, faTimes } from "@fortawesome/free-solid-svg-icons";
import { COLORS } from "../../../../utils/constants";
import { formatDate } from "../../../../utils/formats";
import { useTranslation } from "react-i18next";
import { CenterColumn, DocumentsTableContainer } from "./styles";
import { Document } from "../../../../models/document";
import documentApi from "../../../../api/document";
import { downloadBlob } from "../../../../utils/download";
import { LinkButton } from "../../../../styles/button";
import { LinkNoStyle } from "../../../../styles/text";
import { NoTableData, TableLabels } from "../../../../styles/table";
import { useStore } from "../../../../utils/store";

const DocumentsTable: FC<{
  data: Document[];
  hideOrderColumn?: boolean;
}> = ({ data, hideOrderColumn }) => {
  const { t } = useTranslation();
  const user = useStore((store) => store.user);

  const handleDownload = useCallback(async (documentId: string) => {
    const response = await documentApi.downloadDocument(documentId);
    await downloadBlob(response);
  }, []);

  const tableData = useMemo(
    () =>
      data.map((row) => (
        <tr key={row.id}>
          <td>
            <div>
              <LinkButton onClick={() => handleDownload(row.id)}>
                <FontAwesomeIcon
                  icon={faFileAlt}
                  color={COLORS.primary}
                  size="lg"
                />
                {row.documentName}
              </LinkButton>
            </div>
          </td>
          <td>{formatDate(row.created)}</td>
          <td>
            {!hideOrderColumn && row.orderId && (
              <TableLabels>
                <LinkNoStyle
                  to={
                    user?.role === "Customer"
                      ? `/orders/${row.orderId}`
                      : `/admin/orders/${row.orderId}`
                  }
                >
                  {row.orderName}
                </LinkNoStyle>
              </TableLabels>
            )}
          </td>
          <td>
            <CenterColumn>
              {row.mustSign &&
                (row.signed ? (
                  <FontAwesomeIcon
                    icon={faCheck}
                    size="lg"
                    color={COLORS.green}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faTimes}
                    size="lg"
                    color={COLORS.red}
                  />
                ))}
            </CenterColumn>
          </td>
        </tr>
      )),
    [data, user?.role, hideOrderColumn, handleDownload]
  );

  const tableCols = (
    <tr>
      <th key={1}>{t("documents.document")}</th>
      <th key={2}>{t("documents.created")}</th>
      <th key={3}>{!hideOrderColumn && t("documents.order")}</th>
      <th key={4}>{t("documents.signed")}</th>
    </tr>
  );

  return data.length ? (
    <OverflowX>
      <DocumentsTableContainer>
        <thead>{tableCols}</thead>
        <tbody>{tableData}</tbody>
      </DocumentsTableContainer>
    </OverflowX>
  ) : (
    <NoTableData>{t("documents.noData")}</NoTableData>
  );
};

export default DocumentsTable;
