import {
  faBox,
  faCalendarAlt,
  faDesktop,
  faHistory,
  faKey,
  faThLarge,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { uniq } from "lodash";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { UserRight } from "../../../models/auth";
import { SidebarContentHeader } from "../../../styles/sidebar";
import { useUserHasRight } from "../../../utils/auth";
import { COLORS } from "../../../utils/constants";
import { useStore } from "../../../utils/store";
import SidebarBasicLinks from "../../common/layout/sidebar/SidebarBasicLinks/SidebarBasicLinks";
import SidebarHeader from "../../common/layout/sidebar/SidebarHeader/SidebarHeader";
import AdminSidebarSettingsLinks, {
  AdminSidebarSettingsLinksProps,
} from "./AdminSidebarSettingsLinks/AdminSidebarSettingsLinks";
import { AdminFooterText, AdminSidebarFooter, LinksWrapper } from "./styles";

const basicLinks = [
  { id: "dashboard", link: "/admin", icon: faThLarge },
  {
    id: "boxes",
    link: "/admin/orders",
    icon: faBox,
    admin: true,
    rights: ["readAllOrders"],
  },
  {
    id: "clients",
    link: "/admin/clients",
    icon: faUsers,
    admin: true,
    rights: ["readCustomers"],
  },
  { id: "calendar", link: "/admin/calendar", icon: faCalendarAlt },
  { id: "history", link: "/admin/history", icon: faHistory, admin: true },
  { id: "changePassword", link: "/admin/change-password", icon: faKey },
];

const settingsLinks: AdminSidebarSettingsLinksProps["links"] = [
  {
    id: "basicSettings",
    link: "/admin/settings",
    rights: ["readBasicSettings"],
  },
  {
    id: "boxesSettings",
    link: "/admin/settings/boxes",
    rights: ["readBoxSettings"],
  },
  {
    id: "employeesSettings",
    link: "/admin/settings/employees",
    rights: ["readEmployees"],
  },
  {
    id: "additionalServices",
    link: "/admin/settings/additional-services",
    rights: ["readAdditionalServices"],
  },
  {
    id: "scheduledTasksSettings",
    link: "/admin/settings/scheduled-tasks",
    rights: ["readScheduledTasks"],
  },
  {
    id: "documentsSettings",
    link: "/admin/settings/documents",
    rights: ["readDocumentTemplates"],
  },
  {
    id: "promoCodes",
    link: "/admin/settings/promo-codes",
    rights: ["readPromoCodes"],
  },
];

const AdminSidebar: FC = () => {
  const { t } = useTranslation();
  const user = useStore((store) => store.user);
  const isAdmin = user?.role === "Admin";
  const isAdminOrBoss = user?.role === "Admin" || user?.role === "Boss";
  const { hasUserAnyRight, hasUserAllRights } = useUserHasRight();
  const linksRights = useMemo(
    () => uniq(settingsLinks.flatMap(({ rights }) => (rights ? rights : []))),
    []
  );
  const hasAnyRight = hasUserAnyRight(linksRights);
  const basicLinksFiltered = useMemo(
    () =>
      isAdminOrBoss
        ? basicLinks
        : basicLinks.filter(
            ({ admin, rights }) =>
              !admin || (rights && hasUserAllRights(rights as UserRight[]))
          ),
    [hasUserAllRights, isAdminOrBoss]
  );

  return (
    <SidebarContentHeader>
      <SidebarHeader
        name={`${user?.firstName} ${user?.lastName}`}
        color={"green"}
      />
      <LinksWrapper>
        <SidebarBasicLinks links={basicLinksFiltered} />
        {(isAdmin || hasAnyRight) && (
          <AdminSidebarSettingsLinks links={settingsLinks} />
        )}
      </LinksWrapper>
      <AdminSidebarFooter>
        <AdminFooterText to="/queue">
          <FontAwesomeIcon
            icon={faDesktop}
            size={"1x"}
            color={COLORS.secondary}
          />
          {t("sidebar.user.terminal")}
        </AdminFooterText>
      </AdminSidebarFooter>
    </SidebarContentHeader>
  );
};

export default AdminSidebar;
