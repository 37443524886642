import { Link } from "react-router-dom";
import styled from "styled-components";
import { LinkButton } from "../../../../styles/button";
import { CardList } from "../../../../styles/card";

export const DashboardMeetingsList = styled(CardList)`
  grid-template-columns: min-content min-content min-content 1fr;
  grid-column-gap: 0.5rem;

  > div {
    display: flex;
    align-items: center;
    white-space: pre;

    &:nth-child(4n) {
      display: flex;
      justify-content: flex-end;

      ${LinkButton} {
        text-transform: lowercase;
      }
    }
  }

  svg {
    font-size: 1.25rem;
    margin-right: 0.5rem;
  }
`;

export const RescheduleButton = styled(Link)`
  margin-left: 0.3rem;
  text-transform: lowercase;
`;
