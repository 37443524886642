import { Formik } from "formik";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import * as Yup from "yup";
import additionalServicesApi from "../../../api/additionalServices";
import { AdditionalService } from "../../../models/additionalServices";
import { getErrorMessage } from "../../../utils/errors";
import { SetState } from "../../../utils/types";
import AdminAdditionalServicesModalContent from "./AdminAdditionalServicesModalContent";

const AdminAdditionalServicesModal: FC<{
  initData: AdditionalService;
  open: boolean;
  setOpen: SetState<boolean>;
  onSuccess: () => void;
}> = ({ initData, open, setOpen, onSuccess }) => {
  const { t } = useTranslation();

  const handleSubmit = async (data: AdditionalService) => {
    try {
      if (data.id) {
        await additionalServicesApi.update(data);
      } else {
        await additionalServicesApi.add(data);
      }

      toast.success(t("common.saveSuccess"));
      onSuccess();
      setOpen(false);
    } catch (err) {
      toast.error(getErrorMessage(err, t("errors.saveError")));
    }
  };

  return (
    <Formik
      initialValues={initData}
      enableReinitialize
      onSubmit={handleSubmit}
      validationSchema={Yup.object({
        name: Yup.string().required(t("errors.required")),
        description: Yup.string().required(t("errors.required")),
        icon: Yup.string().required(t("errors.required")),
      })}
    >
      <AdminAdditionalServicesModalContent open={open} setOpen={setOpen} />
    </Formik>
  );
};

export default AdminAdditionalServicesModal;
