import React, { FC, Suspense } from "react";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import AdminAdditionalServices from "./components/admin/AdminAdditionalServices/AdminAdditionalServices";
import AdminBasicSettings from "./components/admin/AdminBasicSettings/AdminBasicSettings";
import AdminBoxesSettings from "./components/admin/AdminBoxesSettings/AdminBoxesSettings";
import AdminBoxesSettingsDetail from "./components/admin/AdminBoxesSettingsDetail/AdminBoxesSettingsDetail";
import AdminCalendar from "./components/admin/AdminCalendarScene/AdminCalendarScene";
import AdminChangePassword from "./components/admin/AdminChangePassword/AdminChangePassword";
import AdminClients from "./components/admin/AdminClients/AdminClients";
import AdminClientsDetail from "./components/admin/AdminClientsDetail/AdminClientsDetail";
import AdminDashboard from "./components/admin/AdminDashboard/AdminDashboard";
import AdminDocumentsSettings from "./components/admin/AdminDocumentsSettings/AdminDocumentsSettings";
import AdminEmployees from "./components/admin/AdminEmloyees/AdminEmloyees";
import AdminEmployeesDetail from "./components/admin/AdminEmployeesDetail/AdminEmployeesDetail";
import AdminHistory from "./components/admin/AdminHistory/AdminHistory";
import AdminOrders from "./components/admin/AdminOrders/AdminOrders";
import AdminPromoCodes from "./components/admin/AdminPromoCodes/AdminPromoCodes";
import AdminPromoCodesDetail from "./components/admin/AdminPromoCodesDetail/AdminPromoCodesDetail";
import AdminScheduledTasks from "./components/admin/AdminScheduledTasks/AdminScheduledTasks";
import AuthRoute from "./components/auth/AuthRoute";
import ForgottenPassword from "./components/auth/forgotten-password/ForgottenPassword";
import Login from "./components/auth/login/Login";
import ResetPassword from "./components/auth/reset-password/ResetPassword";
import NotFound from "./components/common/NotFound/NotFound";
import Providers from "./components/common/Providers/Providers";
import Order from "./components/order/Order";
import OrderInvoice from "./components/order/OrderInvoice/OrderInvoice";
import Queue from "./components/queue/Queue";
import Tablet from "./components/tablet/Tablet";
import Cards from "./components/user/Cards/Cards";
import Dashboard from "./components/user/Dashboard/Dashboard";
import Documents from "./components/user/Documents/Documents";
import Invoices from "./components/user/Invoices/Invoices";
import Meetings from "./components/user/Meetings/Meetings";
import OrderDetail from "./components/user/Orders/OrderDetail/OrderDetail";
import Orders from "./components/user/Orders/Orders";
import Profile from "./components/user/Profile/Profile";
import CustomerCalendar from "./components/user/Reservation/CustomerCalendar/CustomerCalendar";
import Reservation from "./components/user/Reservation/Reservation";
import Settings from "./components/user/Settings/Settings";
import { AdminRoles } from "./models/employee";
import history from "./utils/history";

export const App: FC = () => {
  return (
    <Suspense fallback="loading">
      <Router history={history}>
        <Providers>
          <Switch>
            <AuthRoute exact path="/" requiredRoles={["Customer"]}>
              <Dashboard />
            </AuthRoute>
            <AuthRoute exact path="/orders" requiredRoles={["Customer"]}>
              <Orders />
            </AuthRoute>
            <AuthRoute path="/orders/:id" requiredRoles={["Customer"]}>
              <OrderDetail />
            </AuthRoute>
            <AuthRoute exact path="/cards" requiredRoles={["Customer"]}>
              <Cards />
            </AuthRoute>
            <AuthRoute exact path="/profile" requiredRoles={["Customer"]}>
              <Profile />
            </AuthRoute>
            <AuthRoute path="/documents" requiredRoles={["Customer"]}>
              <Documents />
            </AuthRoute>
            <AuthRoute path="/invoices" requiredRoles={["Customer"]}>
              <Invoices />
            </AuthRoute>
            <AuthRoute exact path="/settings" requiredRoles={["Customer"]}>
              <Settings />
            </AuthRoute>
            <AuthRoute exact path="/meetings" requiredRoles={["Customer"]}>
              <Meetings />
            </AuthRoute>
            <AuthRoute exact path="/admin" requiredRoles={AdminRoles}>
              <AdminDashboard />
            </AuthRoute>
            <AuthRoute
              exact
              path="/admin/orders"
              requiredRoles={["Admin", "Boss"]}
            >
              <AdminOrders />
            </AuthRoute>
            <AuthRoute
              exact
              path="/admin/orders/:id"
              requiredRoles={AdminRoles}
            >
              <OrderDetail admin />
            </AuthRoute>
            <AuthRoute
              exact
              path="/admin/clients"
              requiredRoles={["Admin", "Boss"]}
              requiredRights={["readCustomers"]}
            >
              <AdminClients />
            </AuthRoute>
            <AuthRoute
              exact
              path="/admin/clients/:id"
              requiredRoles={AdminRoles}
            >
              <AdminClientsDetail />
            </AuthRoute>
            <AuthRoute exact path="/admin/calendar" requiredRoles={AdminRoles}>
              <AdminCalendar />
            </AuthRoute>
            <AuthRoute
              exact
              path="/admin/history"
              requiredRoles={["Admin", "Boss"]}
            >
              <AdminHistory />
            </AuthRoute>
            <AuthRoute exact path="/admin/settings" requiredRoles={AdminRoles}>
              <AdminBasicSettings />
            </AuthRoute>
            <AuthRoute
              exact
              path="/admin/settings/boxes"
              requiredRoles={AdminRoles}
            >
              <AdminBoxesSettings />
            </AuthRoute>
            <AuthRoute
              exact
              path="/admin/settings/boxes/:id"
              requiredRoles={AdminRoles}
            >
              <AdminBoxesSettingsDetail />
            </AuthRoute>
            <AuthRoute
              exact
              path="/admin/settings/employees"
              requiredRoles={AdminRoles}
            >
              <AdminEmployees />
            </AuthRoute>
            <AuthRoute
              exact
              path="/admin/settings/employees/:id"
              requiredRoles={AdminRoles}
            >
              <AdminEmployeesDetail />
            </AuthRoute>
            <AuthRoute
              exact
              path="/admin/settings/additional-services"
              requiredRoles={AdminRoles}
            >
              <AdminAdditionalServices />
            </AuthRoute>
            <AuthRoute
              exact
              path="/admin/settings/scheduled-tasks"
              requiredRoles={AdminRoles}
            >
              <AdminScheduledTasks />
            </AuthRoute>
            <AuthRoute
              exact
              path="/admin/settings/documents"
              requiredRoles={AdminRoles}
            >
              <AdminDocumentsSettings />
            </AuthRoute>
            <AuthRoute
              exact
              path="/admin/settings/promo-codes"
              requiredRoles={AdminRoles}
            >
              <AdminPromoCodes />
            </AuthRoute>
            <AuthRoute
              exact
              path="/admin/settings/promo-code/:id"
              requiredRoles={AdminRoles}
            >
              <AdminPromoCodesDetail />
            </AuthRoute>
            <AuthRoute
              exact
              path="/admin/change-password"
              requiredRoles={AdminRoles}
            >
              <AdminChangePassword />
            </AuthRoute>
            <AuthRoute
              exact
              path="/admin/meetings/:id"
              requiredRoles={AdminRoles}
            >
              <CustomerCalendar />
            </AuthRoute>
            <AuthRoute exact path="/admin/meetings" requiredRoles={AdminRoles}>
              <CustomerCalendar notClient={true} />
            </AuthRoute>
            <AuthRoute exact path="/order/:box?">
              <Order />
            </AuthRoute>
            <AuthRoute exact path="/invoice">
              <OrderInvoice />
            </AuthRoute>
            <AuthRoute exact path="/reservation">
              <Reservation />
            </AuthRoute>
            <AuthRoute exact path="/login">
              <Login />
            </AuthRoute>
            <AuthRoute exact path="/forgotten-password">
              <ForgottenPassword />
            </AuthRoute>
            <AuthRoute exact path="/forgotten-password/:token">
              <ResetPassword />
            </AuthRoute>
            <AuthRoute
              exact
              path="/queue"
              requiredRoles={[...AdminRoles, "QueueManager"]}
            >
              <Queue />
            </AuthRoute>
            <AuthRoute
              exact
              path="/tablet"
              requiredRoles={[...AdminRoles, "SignManager"]}
            >
              <Tablet />
            </AuthRoute>
            <Route exact path="/not-found">
              <NotFound />
            </Route>
            <Route path="*">
              <Redirect to="/not-found" />
            </Route>
          </Switch>
        </Providers>
      </Router>
    </Suspense>
  );
};
