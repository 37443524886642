import { AxiosResponse } from "axios";
import { BoxPrice, BoxType, BoxUpdate } from "../models/box";
import api from "../utils/api";

export const getBox = (
  boxId: string | number,
  orderId?: string,
  branchId: number = 1
): Promise<AxiosResponse<BoxType>> =>
  api.get(
    `/api/Branch/${branchId}/Box/${boxId}${
      orderId ? `?orderId=${orderId}` : ""
    }`
  );

export const updateBox = (
  boxId: number,
  data: BoxUpdate
): Promise<AxiosResponse<boolean>> => api.put(`/api/Box/${boxId}`, data);

export const setBoxIsActive = (
  boxId: number,
  branchId: number,
  isActive: boolean
): Promise<AxiosResponse<boolean>> =>
  api.put(`/api/Branch/${branchId}/Box/${boxId}/IsActive`, { isActive });

export const setBoxTotalCount = (
  boxId: number,
  branchId: number,
  totalCount: number
): Promise<AxiosResponse<boolean>> =>
  api.put(`/api/Branch/${branchId}/Box/${boxId}/TotalCount`, {
    totalCount,
  });

export const getAllBoxes = (
  branchId: number = 1
): Promise<AxiosResponse<BoxType[]>> => api.get(`/api/Branch/${branchId}/Box`);

export const getAllBoxPrices = (
  boxId: number,
  branchId: number = 1
): Promise<AxiosResponse<BoxPrice[]>> =>
  api.get(`/api/Branch/${branchId}/Box/${boxId}/Price`);

export const addBoxPrices = async (
  boxId: number,
  branchId: number,
  data: BoxPrice
): Promise<AxiosResponse<boolean>> =>
  api.post(`/api/Branch/${branchId}/Box/${boxId}/Price`, data);

export const updateBoxPrices = (
  boxId: number,
  branchId: number,
  priceId: number,
  data: BoxPrice
): Promise<AxiosResponse<boolean>> =>
  api.put(`/api/Branch/${branchId}/Box/${boxId}/Price/${priceId}`, data);

const boxApi = {
  getBox,
  updateBox,
  setBoxIsActive,
  setBoxTotalCount,
  getAllBoxes,
  getAllBoxPrices,
  addBoxPrices,
  updateBoxPrices,
};

export default boxApi;
