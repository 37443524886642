import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { COLORS } from "../../../../utils/constants";
import { WeekSelectorContainer, WeekSelectorIconWrapper } from "./styles";

const WeekSelector: FC<{
  week: number;
  onClickPrevWeek: (event: React.MouseEvent<HTMLDivElement>) => void;
  onClickNextWeek: (event: React.MouseEvent<HTMLDivElement>) => void;
}> = ({ week, onClickPrevWeek, onClickNextWeek }) => {
  const { t } = useTranslation();

  return (
    <WeekSelectorContainer>
      <WeekSelectorIconWrapper onClick={onClickPrevWeek}>
        <FontAwesomeIcon icon={faAngleLeft} color={COLORS.white} />
      </WeekSelectorIconWrapper>
      {week}
      {"."}&nbsp;{t("common.week")}
      <WeekSelectorIconWrapper onClick={onClickNextWeek}>
        <FontAwesomeIcon icon={faAngleRight} color={COLORS.white} />
      </WeekSelectorIconWrapper>
    </WeekSelectorContainer>
  );
};

export default WeekSelector;
