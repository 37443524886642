import styled from "styled-components";
import { TooltipContainer, TooltipContent } from "../../Tooltip/styles";

export const FormErrorContainer = styled(TooltipContainer)`
  margin-left: 1rem;

  position: relative;
  top: initial;
  right: initial;
  transform: none;

  background-color: var(--red);
  color: var(--red);
  font-size: 0.8rem;
  flex-shrink: 0;

  ${TooltipContent} {
    display: none;
    width: initial;
    color: var(--red);
  }

  &::before {
    background-color: var(--red);
  }
`;
