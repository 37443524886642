import { faCog } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { UserRight } from "../../../../models/auth";
import { useUserHasRight } from "../../../../utils/auth";
import { useStore } from "../../../../utils/store";
import {
  SettingsLinksHeading,
  SettingsLinksWrapper,
  SidebarLink,
  SidebarLinkIcon,
  SidebarLinks,
} from "./styles";

export interface AdminSidebarSettingsLinksProps {
  links: {
    id: string;
    link: string;
    rights?: UserRight[];
  }[];
}

const AdminSidebarSettingsLinks: FC<AdminSidebarSettingsLinksProps> = ({
  links,
}) => {
  const { t } = useTranslation();
  const user = useStore((store) => store.user);
  const isAdmin = user?.role === "Admin";
  const { hasUserAnyRight } = useUserHasRight();

  const allowedLinks = useMemo(
    () =>
      links.filter(
        ({ rights }) => isAdmin || (rights && hasUserAnyRight(rights))
      ),
    [hasUserAnyRight, isAdmin, links]
  );

  return (
    <SettingsLinksWrapper>
      <SettingsLinksHeading>
        <SidebarLinkIcon>
          <FontAwesomeIcon icon={faCog} size="1x" />
        </SidebarLinkIcon>{" "}
        {t(`sidebar.user.settings`)}
      </SettingsLinksHeading>
      <SidebarLinks>
        {allowedLinks.map((l) => (
          <SidebarLink key={l.id} to={l.link} exact>
            {t(`sidebar.user.${l.id}`)}
          </SidebarLink>
        ))}
      </SidebarLinks>
    </SettingsLinksWrapper>
  );
};

export default AdminSidebarSettingsLinks;
