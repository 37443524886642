import { AxiosResponse } from "axios";
import {
  CalendarAdmin,
  CalendarAvailabilityData,
  CalendarCustomer,
  CalendarOverview,
  CalendarReservationDateTime,
  CalendarReservationNonCustomerDateTime,
  EmployeeCalendarOverview,
} from "../models/calendar";
import { FiltersSearchParams, Paged } from "../models/common";
import api from "../utils/api";

interface ReservationParams extends FiltersSearchParams {
  date?: string;
  cardMode?: boolean;
}

interface DaysParams {
  fromDate: string;
  days: number;
}

export const getEmployeeCalendarReservation = (
  branchId: number,
  employeeId: string,
  params?: ReservationParams
): Promise<AxiosResponse<Paged<EmployeeCalendarOverview>>> =>
  api.get(`/api/Branch/${branchId}/Employee/${employeeId}/Calendar/Overview`, {
    params,
  });

export const getEmployeeCalendarFromDate = (
  branchId: number,
  employeeId: string,
  params: DaysParams
): Promise<AxiosResponse<CalendarAdmin>> =>
  api.get(`/api/Branch/${branchId}/Employee/${employeeId}/Calendar/Details`, {
    params,
  });

export const addAvailableTimes = (
  branchId: number,
  employeeId: string,
  data: CalendarAvailabilityData
): Promise<AxiosResponse<boolean>> =>
  api.post(
    `/api/Branch/${branchId}/Employee/${employeeId}/Calendar/AvailableFromTo`,
    data
  );

export const deleteAvailableTimes = (
  employeeId: string,
  newEmployeeId: string,
  data: CalendarAvailabilityData,
  branchId: number = 1
): Promise<AxiosResponse<boolean>> =>
  api.delete(
    `/api/Branch/${branchId}/Employee/${employeeId}/Calendar/AvailableFromTo`,
    {
      data: { ...data, newEmployeeId },
    }
  );

export const getUserCalendarOverview = (
  customerId: string
): Promise<AxiosResponse<Paged<CalendarOverview>>> =>
  api.get(`/api/Customer/${customerId}/Calendar/Overview`);

export const getCustomerCalendarFromDate = (
  branchId: number,
  params: DaysParams
): Promise<AxiosResponse<CalendarCustomer>> =>
  api.get(`/api/Branch/${branchId}/Calendar`, { params });

export const createCustomerReservation = (
  branchId: number,
  customerId: string,
  data: CalendarReservationDateTime
): Promise<AxiosResponse<boolean>> =>
  api.post(
    `/api/Branch/${branchId}/Customer/${customerId}/Calendar/Reservation`,
    data
  );

export const createNonCustomerReservation = (
  branchId: number,
  data: CalendarReservationNonCustomerDateTime
): Promise<AxiosResponse<boolean>> =>
  api.post(`/api/Branch/${branchId}/NonCustomer/Calendar/Reservation`, data);

export const cancelMeeting = (id: number): Promise<AxiosResponse<boolean>> =>
  api.delete(`/api/Reservation/${id}`);

export const cancelNonCustomerMeeting = (
  id: number
): Promise<AxiosResponse<boolean>> =>
  api.delete(`/api/Reservation/NonCustomer/${id}`);

const calendarApi = {
  getEmployeeCalendarReservation,
  getEmployeeCalendarFromDate,
  addAvailableTimes,
  deleteAvailableTimes,
  getUserCalendarOverview,
  getCustomerCalendarFromDate,
  createCustomerReservation,
  createNonCustomerReservation,
  cancelMeeting,
  cancelNonCustomerMeeting,
};

export default calendarApi;
