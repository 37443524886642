import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { History } from "../../../models/history";
import { Link } from "react-router-dom";
import { formatDateTime } from "../../../utils/formats";
import { NoTableData, Table } from "../../../styles/table";
import { OverflowX } from "../../../utils/styles";
import { useStore } from "../../../utils/store";

const cols = [
  "adminHistory.table.datetime",
  "adminHistory.table.user",
  "adminHistory.table.action",
  "adminHistory.table.relatedAsset",
];

const AdminHistoryTable: FC<{ data: History[] }> = ({ data }) => {
  const { t } = useTranslation();
  const user = useStore((store) => store.user);
  const isAdmin = user?.role === "Admin";
  const isAdminOrBoss = user?.role === "Admin" || user?.role === "Boss";

  const tableCols = cols.map((col) => <th key={col}>{t(col)}</th>);

  const tableRows = useMemo(
    () =>
      data.map((action) => (
        <tr key={action.id}>
          <td>
            <div>
              <b>{formatDateTime(action.dateTime)}</b>
            </div>
          </td>
          <td>
            <div>
              {isAdminOrBoss && (isAdmin || action.isCustomer) ? (
                <Link
                  to={`/admin/${
                    action.isCustomer ? "clients" : "settings/employees"
                  }/${action.user.id}`}
                >
                  {action.user.firstName} {action.user.lastName}
                </Link>
              ) : (
                <span>
                  {action.user.firstName} {action.user.lastName}
                </span>
              )}
            </div>
          </td>
          <td>
            <div>{action.actionName}</div>
          </td>
          <td>
            <div>{action.detail}</div>
          </td>
        </tr>
      )),
    [data, isAdmin, isAdminOrBoss]
  );

  return (
    <OverflowX>
      {data.length ? (
        <Table>
          <thead>
            <tr>{tableCols}</tr>
          </thead>
          <tbody>{tableRows}</tbody>
        </Table>
      ) : (
        <NoTableData>{t("adminHistory.noData")}</NoTableData>
      )}
    </OverflowX>
  );
};

export default AdminHistoryTable;
