import React, { FC } from "react";
import { Button } from "../../../../../styles/button";
import CustomModal from "../../../../common/CustomModal/CustomModal";
import { SetState } from "../../../../../utils/types";
import { useTranslation } from "react-i18next";
import { OrderBox } from "../../../../../models/box";
import { OrdersTableDetailContainer } from "./styles";
import BoxTypeNameLabel from "../../../../common/BoxTypeLabel/BoxTypeLabel";
import { CardList } from "../../../../../styles/card";
import { getBoxImg } from "../../../../../utils/images";

const OrdersTableDetailModal: FC<{
  open: boolean;
  setOpen: SetState<boolean>;
  box: OrderBox;
}> = ({ open, setOpen, box }) => {
  const { t } = useTranslation();

  return (
    <CustomModal
      title={t("orders.table.detailModal.title")}
      open={open}
      setOpen={setOpen}
      actions={
        <Button ver="secondary" onClick={() => setOpen(false)}>
          {t("common.close")}
        </Button>
      }
    >
      <OrdersTableDetailContainer>
        <img src={getBoxImg(box.type)} alt={box.type} />
        <div>
          <BoxTypeNameLabel type={box.type} />
          <CardList cols={2} rowGap="0.5rem">
            <div>{t("sidebar.order.selected.dimensions")}</div>
            <b>{box.dimensions}</b>
            <div>{t("sidebar.order.selected.volume")}</div>
            <b>{box.volume}</b>
            <div>{t("sidebar.order.selected.loadCapacity")}</div>
            <b>{box.loadCapacity}</b>
          </CardList>
        </div>
      </OrdersTableDetailContainer>
    </CustomModal>
  );
};

export default OrdersTableDetailModal;
