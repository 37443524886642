import { faRedo, faTimes, faUndo } from "@fortawesome/free-solid-svg-icons";
import { canvasToBlob } from "blob-util";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Webcam from "react-webcam";
import userApi from "../../../../api/user";
import { FadeInBottom } from "../../../../styles/fadeIn";
import { useUserHasRight } from "../../../../utils/auth";
import { getErrorMessage } from "../../../../utils/errors";
import Loader from "../../../common/Loader/Loader";
import AdminClientsDetailImagesModal from "./AdminClientsDetailImagesModal";
import {
  AdminClientsDetailTabsLoader,
  WebcamButton,
  WebcamContainer,
  WebcamDeleteIcon,
  WebcamError,
  WebcamRotateIcon,
  WebcamRotateIcons,
  WebcamScreenshot,
  WebcamScreenshotContainer,
  WebcamScreenshots,
} from "./styles";

const AdminClientsDetailImages: FC = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const ref = useRef<Webcam>(null);
  const [apiImgs, setApiImgs] = useState<
    { id: string; hasRights: boolean; blob: Blob }[]
  >([]);
  const [webcamState, setWebcamState] = useState<
    "loading" | "loaded" | "error"
  >("loading");
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(true);
  const [deleteImageId, setDeleteImageId] = useState<string>("");
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const { hasUserAllRights } = useUserHasRight();

  const loadImages = useCallback(async () => {
    setLoading(true);
    try {
      const imagesResponse = await userApi.getUserImages(id);
      const response = await Promise.all(
        imagesResponse.data.map((x) => userApi.getUserImage(id, x.id))
      );
      setApiImgs(
        response.map((res, i) => ({
          id: imagesResponse.data[i].id,
          hasRights: imagesResponse.data[i].hasRights,
          blob: res.data,
        }))
      );
    } catch (err) {
      toast.error(getErrorMessage(err, t("errors.loadError")));
    }
    setLoading(false);
  }, [id, t]);

  const capture = useCallback(async () => {
    setSaving(true);
    try {
      const canvas = ref.current?.getCanvas();
      if (canvas) {
        const image = await canvasToBlob(canvas, "image/jpeg");
        if (image) {
          var response = await userApi.uploadUserImage(id, image);
          const newImg = {
            id: response.data.id,
            hasRights: response.data.hasRights,
            blob: image,
          };
          setApiImgs((prev) => [newImg, ...prev]);
          toast.success(t("common.saveSuccess"));
        } else {
          toast.error(t("errors.imageCapture"));
        }
      }
    } catch (err) {
      toast.error(getErrorMessage(err, t("errors.saveError")));
    }
    setSaving(false);
  }, [ref, t, id]);

  const handleRotate = async (imageId: string, direction: number) => {
    setSaving(true);
    try {
      var response = await userApi.rotateUserImage(id, imageId, direction);
      setApiImgs(
        apiImgs.map((x) => {
          if (x.id === imageId) {
            x.blob = response.data;
          }
          return x;
        })
      );
      toast.success(t("common.saveSuccess"));
    } catch (err) {
      toast.error(getErrorMessage(err, t("errors.saveError")));
    }
    setSaving(false);
  };

  const handleDelete = async (imageId: string) => {
    setDeleteImageId(imageId);
    setDeleteModal(true);
  };

  const handleDeleteConfirmed = async () => {
    setSaving(true);
    try {
      await userApi.deleteUserImage(id, deleteImageId);
      setApiImgs(apiImgs.filter((x) => x.id !== deleteImageId));
      toast.success(t("common.saveSuccess"));
    } catch (err) {
      toast.error(getErrorMessage(err, t("errors.saveError")));
    }
    setSaving(false);
  };

  useEffect(() => {
    loadImages();
  }, [loadImages]);

  return loading ? (
    <AdminClientsDetailTabsLoader>
      <Loader />
    </AdminClientsDetailTabsLoader>
  ) : (
    <>
      <FadeInBottom>
        <WebcamContainer>
          {hasUserAllRights(["writeCustomerImages"]) && (
            <WebcamButton
              ver="secondary"
              onClick={capture}
              disabled={saving || webcamState !== "loaded"}
            >
              {t("adminClientsDetail.addIdPicture.capture")}
            </WebcamButton>
          )}
          {webcamState === "loading" && <Loader />}
          {webcamState === "error" && (
            <WebcamError>{t("errors.webcam")}</WebcamError>
          )}
          <Webcam
            ref={ref}
            screenshotFormat="image/jpeg"
            width={640}
            height={480}
            onUserMedia={() => setWebcamState("loaded")}
            onUserMediaError={() => setWebcamState("error")}
          />
        </WebcamContainer>
        <WebcamScreenshots>
          {apiImgs.map((src) => (
            <WebcamScreenshotContainer key={src.id}>
              {src.hasRights && (
                <>
                  {hasUserAllRights(["writeCustomerImages"]) && (
                    <WebcamRotateIcons>
                      <WebcamRotateIcon
                        icon={faUndo}
                        size="lg"
                        $secondary
                        disabled={saving}
                        onClick={() => !saving && handleRotate(src.id, 270)}
                      />
                      <WebcamRotateIcon
                        icon={faRedo}
                        size="lg"
                        $secondary
                        disabled={saving}
                        onClick={() => !saving && handleRotate(src.id, 90)}
                      />
                    </WebcamRotateIcons>
                  )}
                  {hasUserAllRights(["deleteCustomerImages"]) && (
                    <WebcamDeleteIcon
                      icon={faTimes}
                      size="2x"
                      $secondary
                      disabled={saving}
                      onClick={() => !saving && handleDelete(src.id)}
                    />
                  )}
                </>
              )}
              <WebcamScreenshot
                src={window.URL.createObjectURL(src.blob)}
                alt="screenshot"
              />
            </WebcamScreenshotContainer>
          ))}
        </WebcamScreenshots>
      </FadeInBottom>
      <AdminClientsDetailImagesModal
        open={deleteModal}
        setOpen={setDeleteModal}
        confirm={handleDeleteConfirmed}
      />
    </>
  );
};

export default AdminClientsDetailImages;
