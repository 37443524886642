import styled from "styled-components";
import { COLORS } from "../../../../utils/constants";
import { size } from "../../../../utils/styles";

export const WeekSelectorContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 2.5rem;
  padding: 0.5rem;
  border-radius: 3px;
  border: 1px solid #b0b0b0;
  font-weight: bold;
  user-select: none;

  @media screen and (min-width: ${size.tablet}) {
    width: 14rem;
  }
`;

export const WeekSelectorIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: ${COLORS.primary};
  height: 100%;
  padding: 0 0.5rem;
`;
