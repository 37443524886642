import { Field, Form, useFormikContext } from "formik";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { BasicSettingsExtended } from "../../../../models/settings";
import { Button } from "../../../../styles/button";
import { H3 } from "../../../../styles/title";
import { useUserHasRight } from "../../../../utils/auth";
import Checkbox from "../../../common/form/Checkbox/Checkbox";
import FormError from "../../../common/form/FormError/FormError";
import Input from "../../../common/form/Input/Input";
import { InputField, InputTextAfter } from "../../../common/form/Input/styles";
import {
  AdminBasicSettingsFormGroup,
  AdminBasicSettingsInputCol,
  AdminBasicSettingsInputRow,
} from "./styles";

const deadlineFields: Array<keyof BasicSettingsExtended> = [
  "reservationOfferDays",
  "invoicesDueDays",
  "boxStopAccessAfterDays",
  "notifyUserBeforeDays",
];

const logoutFields: Array<keyof BasicSettingsExtended> = [
  "logoutCustomerAfterMinutes",
  "logoutEmployeeAfterMinutes",
];

const AdminBasicSettingsForm: FC = () => {
  const { t } = useTranslation();
  const { hasUserAllRights } = useUserHasRight();
  const canWrite = hasUserAllRights(["writeBasicSettings"]);
  const { errors, touched, values, isSubmitting } =
    useFormikContext<BasicSettingsExtended>();

  const deadlineInputs = deadlineFields.map((f) => (
    <Input
      key={f}
      type="number"
      name={f}
      label={t(`adminBasicSettings.${f}FirstPart`)}
      textAfter={t(`adminBasicSettings.${f}SecondPart`)}
      error={touched[f] && !!errors[f]}
      inputWidth={"4rem"}
    />
  ));

  const logoutInputs = logoutFields.map((f) => {
    const hidden =
      f === "logoutCustomerAfterMinutes"
        ? !values.logoutCustomerAfterMinutesCheckbox
        : !values.logoutEmployeeAfterMinutesCheckbox;

    const $error = !hidden && touched[f] && !!errors[f];

    return (
      <AdminBasicSettingsInputRow key={f}>
        <AdminBasicSettingsInputCol>
          <Checkbox>
            <Field type="checkbox" name={`${f}Checkbox`} />
            {t(`adminBasicSettings.${f}Label`)}
          </Checkbox>
        </AdminBasicSettingsInputCol>
        <AdminBasicSettingsInputCol>
          <InputField
            type="number"
            name={f}
            $error={$error}
            width={"4rem"}
            disabled={hidden}
          />
          <InputTextAfter>{t(`adminBasicSettings.${f}`)}</InputTextAfter>
          {$error && <FormError name={f} />}
        </AdminBasicSettingsInputCol>
      </AdminBasicSettingsInputRow>
    );
  });

  return (
    <Form>
      <H3>{t("adminBasicSettings.deadlinesAndNotifications")}</H3>
      <AdminBasicSettingsFormGroup labelWidth={"32rem"}>
        {deadlineInputs}
      </AdminBasicSettingsFormGroup>
      <H3>{t("adminBasicSettings.automaticLogout")}</H3>
      <AdminBasicSettingsFormGroup>
        {logoutInputs}
        <div>
          <Button type="submit" disabled={!canWrite || isSubmitting}>
            {t("common.save")}
          </Button>
        </div>
      </AdminBasicSettingsFormGroup>
    </Form>
  );
};

export default AdminBasicSettingsForm;
