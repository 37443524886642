import styled, { css } from "styled-components";
import { Field } from "formik";
import { COLORS } from "../../../../utils/constants";
import { textInput } from "../../../../styles/form";

export const InputField = styled(Field)<{
  $error?: boolean;
  width?: string;
  height?: string;
  markposition?: "hidden" | "left" | "right";
}>`
  ${(props) => textInput(props)}
`;

export const InputIcon = styled.div<{ $error?: boolean; hasHover?: boolean }>`
  width: min-content !important;
  position: absolute;
  right: ${({ $error }) => ($error ? "3.5rem" : "0.75rem")};
  top: 50%;
  transform: translateY(-50%);

  ${({ hasHover }) =>
    hasHover &&
    css`
      &:hover {
        cursor: pointer;

        svg {
          color: ${COLORS.primary};
        }
      }
    `}
`;

export const InputTextAfter = styled.div`
  margin-left: 1rem;
  flex-shrink: 0;
`;

export const InputMark = styled.div<{ position?: string; $error?: boolean }>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 100%;
  background-color: ${COLORS.primaryDark};
  color: ${COLORS.white};
  top: 0;
  border: 1px solid ${COLORS.primaryDark};

  ${({ position }) =>
    position === "left" &&
    css`
      left: 0;
      border-radius: 3px 0 0 3px;
    `}

  ${({ position, $error }) =>
    position === "right" &&
    css`
      border-radius: 0 3px 3px 0;
      right: ${$error ? "3rem" : 0};
    `}
`;
