import styled from "styled-components";
import { Button } from "../../../styles/button";
import { size } from "../../../utils/styles";

export const LoginContainer = styled.section`
  width: 100%;
  max-width: 30rem;
`;

export const LoginCard = styled.div`
  margin-top: 1rem;
  padding: 1.5rem;

  background-color: var(--whitesmoke);
  border-radius: 8px;
`;

export const LoginButton = styled(Button)`
  width: 100%;
  margin-top: 1.5rem;
`;

export const LoginCardActions = styled.div`
  margin-top: 1rem;

  display: flex;
  justify-content: space-between;
  flex-flow: column;

  @media screen and (min-width: ${size.mobile}) {
    flex-flow: row;
  }
`;
