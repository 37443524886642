import { formatISO, startOfWeek } from "date-fns";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import calendarApi from "../../../../api/calendar";
import storageApi from "../../../../api/storage";
import { CalendarAdmin, CalendarAdminTime } from "../../../../models/calendar";
import { FadeInBottom } from "../../../../styles/fadeIn";
import { useUserHasRight } from "../../../../utils/auth";
import { CalendarAdminTimeState } from "../../../../utils/enums";
import { SetState } from "../../../../utils/types";
import Calendar from "../../../common/calendar/Calendar/Calendar";
import CalendarDay from "../../../common/calendar/CalendarDay/CalendarDay";
import AdminCalendarCell from "../AdminCalendarCell/AdminCalendarCell";
import AdminEmployeeSelect from "../AdminEmployeeSelect/AdminEmployeeSelect";
import AdminEventsModal from "../AdminEventsModal/AdminEventsModal";

const AdminCalendar: FC<{
  selectedEmployee: { value: string; label: string };
  setSelectedEmployee: SetState<{ value: string; label: string }>;
}> = ({ selectedEmployee, setSelectedEmployee }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const [calendarData, setCalendarData] = useState<CalendarAdmin>({
    labels: [],
    days: [],
  });
  const [mondayDate, setMondayDate] = useState<Date>(
    startOfWeek(new Date(), { weekStartsOn: 1 }) // possible wrong week (week by client)
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState<CalendarAdminTimeState>(
    CalendarAdminTimeState.Available
  );
  const [modalData, setModalData] = useState<CalendarAdminTime>({
    users: [],
    dateTime: "",
  });
  const isAdmin =
    storageApi.getCurrentUser()?.role === "Admin" ||
    storageApi.getCurrentUser()?.role === "Boss";
  const { hasUserAllRights } = useUserHasRight();
  const canShowAllCalendars = hasUserAllRights(["showAllCalendars"]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const response = await calendarApi.getEmployeeCalendarFromDate(
          1,
          selectedEmployee.value === "all"
            ? isAdmin || canShowAllCalendars
              ? "0"
              : storageApi.getCurrentUser()!.id
            : selectedEmployee.value,
          {
            fromDate: formatISO(mondayDate, { representation: "date" }),
            days: 7,
          }
        );
        setCalendarData(response.data);
      } catch (err) {
        toast.error(t("errors.calendar"));
      }
      setLoading(false);
    })();
  }, [t, mondayDate, selectedEmployee, isAdmin, canShowAllCalendars]);

  const days = useMemo(
    () =>
      calendarData?.days.map((record) => (
        <CalendarDay
          key={record.day}
          date={new Date(record.day)}
          cells={record.times.map((time, i) => (
            <AdminCalendarCell
              key={i}
              time={time}
              onCellEventClick={(state) => {
                setModalMode(state);
                setModalData(time);
                setModalOpen(true);
              }}
            />
          ))}
        />
      )),
    [calendarData?.days]
  );

  const headerContent = useMemo(
    () => (
      <AdminEmployeeSelect
        label={t("adminCalendarScene.calendar.employee")}
        allOption
        onChange={(value, label) =>
          setSelectedEmployee({ value, label: label! })
        }
      />
    ),
    [t, setSelectedEmployee]
  );

  return (
    <>
      <AdminEventsModal
        open={modalOpen}
        setOpen={setModalOpen}
        modalMode={modalMode}
        modalData={modalData}
      />
      <FadeInBottom>
        <Calendar
          loading={loading}
          labels={calendarData.labels.map((l) => new Date(l))}
          mondayDate={mondayDate}
          setMondayDate={setMondayDate}
          headerContent={
            isAdmin || canShowAllCalendars ? headerContent : undefined
          }
          days={days}
        />
      </FadeInBottom>
    </>
  );
};

export default AdminCalendar;
