import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import boxApi from "../../../../api/box";
import { useUserHasRight } from "../../../../utils/auth";
import { getErrorMessage } from "../../../../utils/errors";
import Checkbox from "../../../common/form/Checkbox/Checkbox";
import {
  AdminBoxesSettingsDetailInfoFormCol,
  AdminBoxesSettingsDetailInfoFormRow,
} from "./styles";

const AdminBoxesSettingsDetailInfoState: FC<{
  boxId: number;
  defaultState: boolean;
}> = ({ boxId, defaultState }) => {
  const { t } = useTranslation();
  const [currentState, setCurrentState] = useState(defaultState);
  const [disabled, setDisabled] = useState(false);

  const { hasUserAllRights } = useUserHasRight();
  const canWrite = hasUserAllRights(["writeBoxIsActive"]);

  const handleSubmit = async (isActive: boolean) => {
    setDisabled(true);
    try {
      await boxApi.setBoxIsActive(boxId, 1, isActive);
      toast.success(t("common.saveSuccess"));
      setCurrentState(isActive);
    } catch (err) {
      toast.error(getErrorMessage(err, t("errors.saveError")));
      setCurrentState(defaultState);
    }
    setDisabled(false);
  };

  return (
    <AdminBoxesSettingsDetailInfoFormRow noMargin>
      <AdminBoxesSettingsDetailInfoFormCol>
        <label htmlFor="isActive">
          {t("adminBoxesSettingsDetail.stateCheckbox.label")}
        </label>
      </AdminBoxesSettingsDetailInfoFormCol>
      <AdminBoxesSettingsDetailInfoFormCol>
        <Checkbox>
          <input
            type="checkbox"
            name="isActive"
            checked={currentState}
            onChange={(e) => handleSubmit(e.target.checked)}
            disabled={disabled || !canWrite}
          />
          {t("adminBoxesSettingsDetail.stateCheckbox.text")}
        </Checkbox>
      </AdminBoxesSettingsDetailInfoFormCol>
    </AdminBoxesSettingsDetailInfoFormRow>
  );
};
export default AdminBoxesSettingsDetailInfoState;
