import {
  faCaretDown,
  faCaretUp,
  faCreditCard,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { Order } from "../../../../models/order";
import { OrderCards } from "../../../../styles/orders";
import { NoTableData } from "../../../../styles/table";
import { COLORS } from "../../../../utils/constants";
import { formatDate } from "../../../../utils/formats";
import { OverflowX } from "../../../../utils/styles";
import { OnChangeOrder, OrderType } from "../../../../utils/types";
import Loader from "../../../common/Loader/Loader";
import OrderStateLabel from "../../../common/orders/OrderState/OrderStateLabel";
import { AdminOrdersTableContainer } from "./styles";

const cols = [
  ["adminOrders.table.order", "name"],
  ["adminOrders.table.expiration", "dateto"],
  ["adminOrders.table.user", "lastname"],
  ["adminOrders.table.cards", ""],
  ["adminOrders.table.state.title", "state"],
];

const AdminOrdersTable: FC<{
  isLoading?: boolean;
  data: Order[];
  order?: OrderType;
  onChangeOrder?: OnChangeOrder;
}> = ({ data, order, onChangeOrder, isLoading }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleOrderChange = useCallback(
    (orderName: string) => {
      if (orderName) {
        onChangeOrder?.(orderName, !Boolean(order?.orderDesc));
      }
    },
    [onChangeOrder, order?.orderDesc]
  );

  return (
    <OverflowX>
      {data.length || isLoading ? (
        <>
          {isLoading && <Loader />}
          <AdminOrdersTableContainer>
            <thead>
              <tr>
                {cols.map(([col, orderName]) => (
                  <th key={col} onClick={() => handleOrderChange(orderName)}>
                    {t(col)}{" "}
                    {order?.orderBy === orderName && (
                      <FontAwesomeIcon
                        icon={order?.orderDesc ? faCaretDown : faCaretUp}
                      />
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((order) => (
                <tr
                  key={order.id}
                  onClick={() => history.push(`/admin/orders/${order.id}`)}
                >
                  <td>
                    <Link
                      to={`/admin/orders/${order.id}`}
                      onClick={(e) => e.stopPropagation()}
                    >
                      {order.name}
                    </Link>
                  </td>
                  <td>
                    <div>{formatDate(order.dateTo)}</div>
                  </td>
                  <td>
                    <div>
                      <Link
                        to={`/admin/clients/${order.user.id}`}
                        onClick={(e) => e.stopPropagation()}
                      >
                        {order.user.firstName}&nbsp;{order.user.lastName}
                      </Link>
                    </div>
                  </td>
                  <td>
                    <OrderCards>
                      <FontAwesomeIcon
                        icon={faCreditCard}
                        color={COLORS.primaryDark}
                      />
                      {order.cards.length}
                    </OrderCards>
                  </td>
                  <td>
                    <OrderStateLabel state={order.state} />
                  </td>
                </tr>
              ))}
            </tbody>
          </AdminOrdersTableContainer>
        </>
      ) : (
        <NoTableData>{t("adminOrders.noData")}</NoTableData>
      )}
    </OverflowX>
  );
};

export default AdminOrdersTable;
