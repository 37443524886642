import styled, { keyframes } from "styled-components";

export const size = {
  mobile: "481px",
  tablet: "769px",
  laptop: "1025px",
  desktop: "1201px",
  desktopL: "1441px",
};

export const fadeIn = keyframes`
  from {
    opacity: 0;  
  }
  to {
    opacity: 1;
  }
`;

export const fadeOut = keyframes`
  from {
    opacity: 1;  
  }
  to {
    opacity: 0;
  }
`;

export const slideInLeft = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
`;

export const OverflowX = styled.div`
  overflow-x: auto;
`;
