import { FormikErrors } from "formik";
import libphonenumber from "google-libphonenumber";
import { TFunction } from "i18next";
import * as Yup from "yup";
import { OrderCreateData, OrderFormData } from "../../models/order";
import { User } from "../../models/user";
import { checkVAT } from "../../utils/vat/checkVat";
import { czechRepublic } from "../../utils/vat/czechRepublic";

const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();

export const isOrderValid = (errors: FormikErrors<OrderFormData>) =>
  !errors.boxId && !errors.consent && isContactValid(errors);

export const isContactValid = (errors: FormikErrors<OrderFormData>) =>
  !errors.firstName &&
  !errors.lastName &&
  !errors.email &&
  !errors.phone &&
  !errors.street &&
  !errors.city &&
  !errors.zipCode &&
  !errors.country &&
  !errors.companyName &&
  !errors.companyNo &&
  !errors.companyVatNo;

export const orderValidation = (t: TFunction) => () =>
  Yup.object({
    branchId: Yup.string().required(t("errors.required")),
    boxId: Yup.string().required(t("errors.required")),

    firstName: Yup.string().required(t("errors.required")),
    lastName: Yup.string().required(t("errors.required")),
    street: Yup.string().required(t("errors.required")),
    city: Yup.string().required(t("errors.required")),
    zipCode: Yup.string()
      .required(t("errors.required"))
      .min(3, t("errors.required")),
    country: Yup.string().required(t("errors.required")),
    email: Yup.string().email(t("errors.email")).required(t("errors.required")),
    phone: Yup.string()
      .test("phone", t("errors.phone"), (value) => {
        if (!value) return true;
        try {
          const number = phoneUtil.parse(value);
          if (!phoneUtil.isValidNumber(number)) return false;
        } catch (err) {
          return false;
        }
        return true;
      })
      .required(t("errors.required")),

    companyName: Yup.string()
      .nullable()
      .when("isCompany", {
        is: true,
        then: Yup.string().required(t("errors.required")),
      }),
    companyNo: Yup.string()
      .nullable()
      .when("isCompany", {
        is: true,
        then: Yup.string().required(t("errors.required")),
      }),
    companyVatNo: Yup.string().nullable(),
    consent: Yup.boolean().equals([true], t("errors.required")),
  });

export const isCompanyNoValid = (value: string | null | undefined): boolean => {
  if (!value) {
    return false;
  }

  if (!/^[0-9]{8}$/.test(value)) {
    return false;
  }

  return validateRegNumber(value);
};

export const isCompanyVatNoValid = (
  value: string | null | undefined
): boolean => {
  if (!value) {
    return true;
  }

  return checkVAT(value, [czechRepublic]).isValid;
};

const validateRegNumber = (value: string): boolean => {
  try {
    const divisor = 11;

    var sum = 0;
    var number = value.trim();
    var factor = number.length;
    var numberBase = number.slice(0, -1);
    var lastDigit = parseInt(number[number.length - 1]);

    for (var i = 0; i < numberBase.length; i++) {
      var parsedDigit = parseInt(numberBase[i]);
      sum += parsedDigit * factor;
      factor--;
    }

    var residue = sum % divisor;

    if (residue === 0 && lastDigit === 1) {
      return true;
    }

    if (residue === 1 && lastDigit === 0) {
      return true;
    }

    if (lastDigit === divisor - residue) {
      return true;
    }
  } catch (err) {}

  return false;
};

export const orderUserInitValues: User = {
  id: "",
  role: "Customer",
  isCustomer: true,
  email: "",
  firstName: "",
  lastName: "",
  street: "",
  city: "",
  zipCode: "",
  country: "",
  companyName: "",
  companyNo: "",
  companyVatNo: "",
  note: "",
  phone: "+420",
  ownCalendar: true,
  isActive: true,
  isCustomerActive: false,
  rights: {},
};

export const orderInitValues: OrderFormData = {
  branchId: "1",
  boxId: null,
  promoCode: null,
  hasPromoCode: false,
  lengthOfLeaseType: "4",
  autoExtend: false,
  fingerPrint: false,
  smsCheck: false,
  paymentType: "Card",
  isCompany: false,
  consent: false,
  additionalServices: [],
  ...orderUserInitValues,
};

export const getTransformedOrderData = (
  data: OrderFormData,
  user: User | null
): OrderCreateData => {
  return {
    branchId: +data.branchId,
    boxId: data.boxId !== null ? +data.boxId : null,
    lengthOfLeaseType: +data.lengthOfLeaseType,
    autoExtend: data.autoExtend,
    promoCode: data.promoCode,
    fingerPrint: data.fingerPrint,
    smsCheck: data.smsCheck,
    paymentType: data.paymentType,
    userData: {
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      street: data.street,
      zipCode: data.zipCode,
      city: data.city,
      country: data.country,
      phone: data.phone,
      companyName: data.isCompany ? data.companyName : "",
      companyNo: data.isCompany ? data.companyNo : "",
      companyVatNo: data.isCompany ? data.companyVatNo : "",
      note: data.note ? data.note : "",
      ...(user?.id
        ? { id: +user.id, isCustomer: user.isCustomer, role: user.role }
        : {}),
    },
    additionalServices: data.additionalServices,
  };
};
