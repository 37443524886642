import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import React, { FC, ReactElement } from "react";
import { LinkButton } from "../../../styles/button";
import {
  BreadcrumbsContainer,
  BreadcrumbsCrumb,
  BreadcrumbsIcon,
  BreadcrumbsList,
} from "./styles";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { DividerVert } from "../../../styles/divider";

const Breadcrumbs: FC<{ children: ReactElement[]; backBtn?: boolean }> = ({
  backBtn,
  children,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const items = children.map((el, i) => {
    const isLastEl = i === children.length - 1;
    return (
      <BreadcrumbsCrumb key={i} aria-current={isLastEl ? "page" : false}>
        {el}
        {!isLastEl && <BreadcrumbsIcon icon={faAngleRight} />}
      </BreadcrumbsCrumb>
    );
  });

  return (
    <BreadcrumbsContainer aria-label="breadcrumb">
      {backBtn && (
        <>
          <LinkButton onClick={() => history.goBack()}>
            {t("common.back")}
          </LinkButton>
          <DividerVert mx />
        </>
      )}
      <BreadcrumbsList>{items}</BreadcrumbsList>
    </BreadcrumbsContainer>
  );
};

export default Breadcrumbs;
