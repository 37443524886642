import React, { FC, useEffect, useState } from "react";
import CustomModal from "../../../../common/CustomModal/CustomModal";
import { SetState } from "../../../../../utils/types";
import { useTranslation } from "react-i18next";
import { Form, useFormikContext } from "formik";
import { CardCreateData } from "../../../../../models/card";
import { FormGroup } from "../../../../../styles/form";
import Input from "../../../../common/form/Input/Input";
import { Button } from "../../../../../styles/button";

const fields: Array<keyof CardCreateData> = ["cardNumber", "rfid"];

const AddCardModalContent: FC<{
  open: boolean;
  setOpen: SetState<boolean>;
}> = ({ open, setOpen }) => {
  const { t } = useTranslation();
  const {
    isSubmitting,
    isValid,
    submitForm,
    touched,
    errors,
    resetForm,
  } = useFormikContext<CardCreateData>();
  const [keyPressCalled, setkeyPressCalled] = useState<boolean>(false);

  const handleCardNumberKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>
  ): boolean => {
    setkeyPressCalled(true);
    event.preventDefault();

    if (!/^\d*$/.test(event.key)) {
      return false;
    }

    const input = event.target as HTMLInputElement;
    const value = "000000" + input.value + event.key;
    input.value = value.substr(value.length - 6);

    return true;
  };

  const handleCardNumberKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>
  ): boolean => {
    setkeyPressCalled(false);

    if (
      event.code !== "Backspace" &&
      event.code !== "Delete" &&
      event.key !== "Backspace" &&
      event.code !== "Delete"
    ) {
      return false;
    }

    event.preventDefault();
    const input = event.target as HTMLInputElement;
    const value = "0000000" + input.value;
    input.value = value.substr(value.length - 7, 6);

    return true;
  };

  const handleCardNumberKeyUp = (
    event: React.KeyboardEvent<HTMLInputElement>
  ): boolean => {
    if (keyPressCalled) {
      return false;
    }

    event.preventDefault();
    const input = event.target as HTMLInputElement;

    if (!/^\d*$/.test(event.key)) {
      let value = input.value;
      value = value.replace(/[^\d]/g, "");
      value = "000000" + value;
      input.value = value.substr(value.length - 6);

      return true;
    }

    const value = "000000" + input.value + event.key;
    input.value = value.substr(value.length - 6);

    return true;
  };

  useEffect(() => {
    if (open) resetForm();
  }, [open, resetForm]);

  return (
    <CustomModal
      open={open}
      setOpen={setOpen}
      title={t("adminClientsDetail.addCardModal.title")}
      actions={
        <>
          <Button
            type="button"
            ver="secondary"
            disabled={isSubmitting}
            onClick={() => setOpen(false)}
          >
            {t("common.cancel")}
          </Button>
          <Button
            disabled={!isValid || isSubmitting}
            onClick={() => submitForm()}
          >
            {t("common.save")}
          </Button>
        </>
      }
    >
      <Form>
        <FormGroup>
          {fields.map((f) => (
            <Input
              key={f}
              name={f}
              type={f === "rfid" ? "password" : undefined}
              hidePasswordIcon={true}
              label={t(`adminClientsDetail.addCardModal.${f}`)}
              error={touched[f] && !!errors[f]}
              onKeyPress={(e) =>
                f === "cardNumber" && handleCardNumberKeyPress(e)
              }
              onKeyDown={(e) =>
                f === "cardNumber" && handleCardNumberKeyDown(e)
              }
              onKeyUp={(e) => f === "cardNumber" && handleCardNumberKeyUp(e)}
              tooltipLeft
            />
          ))}
        </FormGroup>
      </Form>
    </CustomModal>
  );
};

export default AddCardModalContent;
