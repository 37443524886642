import React, { FC, useState } from "react";
import { Tab, TabActions, TabList, TabsContainer, TabsHeader } from "./styles";

export interface TabItem {
  tab: JSX.Element;
  tabLabel: string;
  tabAction?: JSX.Element;
}

const Tabs: FC<{
  tabItems?: TabItem[];
  breakSize?: string;
  activeTabIndex?: number;
  onTabChange?: (tabIndex: number) => void;
}> = ({ tabItems, breakSize, activeTabIndex, onTabChange }) => {
  const [activeTab, setActiveTab] = useState(activeTabIndex || 0);

  if (!tabItems) {
    return null;
  }

  return (
    <TabsContainer>
      <TabsHeader breakSize={breakSize}>
        <TabList breakSize={breakSize}>
          {tabItems.map(({ tab }, i) => (
            <Tab
              key={i}
              active={activeTab === i}
              breakSize={breakSize}
              onClick={() => {
                setActiveTab(i);
                onTabChange && onTabChange(i);
              }}
            >
              {tabItems[i]?.tabLabel}
            </Tab>
          ))}
        </TabList>
        <TabActions breakSize={breakSize}>
          {tabItems[activeTab]?.tabAction}
        </TabActions>
      </TabsHeader>
      {tabItems[activeTab]?.tab}
    </TabsContainer>
  );
};

export default Tabs;
