import styled from "styled-components";

export const RadioButton = styled.span`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;

  height: 1.5rem;
  width: 1.5rem;

  background-color: var(--white);
  border: 1px solid var(--secondary-dark);
  border-radius: 50%;
  transition: background-color 0.2s ease-in-out;

  &::after {
    content: "";
    position: absolute;
    display: none;

    left: calc(0.25rem - 1px);
    top: calc(0.25rem - 1px);
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background-color: var(--primary);
  }
`;

export const RadioContainer = styled.label`
  display: block;
  position: relative;
  padding-left: 2rem;
  cursor: pointer;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ ${RadioButton} {
      background-color: var(--white);

      &::after {
        display: block;
      }
    }

    & ~ ${RadioButton}:hover {
      background-color: var(--secondary);
    }

    &:disabled ~ ${RadioButton} {
      background-color: var(--secondary) !important;
    }
  }
`;
