import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import {
  AdminBoxesSettingsTrafficDescDetailLink,
  AdminBoxesSettingsTrafficDescGrid,
  AdminBoxesSettingsTrafficDescLabel,
  AdminBoxesSettingsTrafficDescPrice,
  AdminBoxesSettingsTrafficDescPriceBox,
  AdminBoxesSettingsTrafficDescTitle,
  AdminBoxesSettingsTrafficDescTitleWrapper,
} from "./styles";
import { BoxPrice, BoxType } from "../../../../models/box";
import { formatCurrency } from "../../../../utils/formats";
import { PrimaryText } from "../../../../styles/text";
import { AdminBoxesSettingsBoxInfo } from "../AdminBoxesSettingsTraffic/styles";

export const priceDefs: Array<keyof BoxPrice> = [
  "price1WithVat",
  "price2WithVat",
  "price3WithVat",
  "price4WithVat",
];

const AdminBoxesSettingsTrafficDesc: FC<{
  boxType: BoxType;
}> = ({ boxType }) => {
  const { t } = useTranslation();

  const priceBoxes = priceDefs.map((item, i) => (
    <AdminBoxesSettingsTrafficDescPriceBox key={i}>
      <span>{t(`adminBoxesSettingsTraffic.info.period.${item}`)}</span>
      <strong>{formatCurrency(boxType.boxPriceDto[item] as number)}</strong>
    </AdminBoxesSettingsTrafficDescPriceBox>
  ));

  return (
    <div>
      <AdminBoxesSettingsTrafficDescTitleWrapper>
        <AdminBoxesSettingsTrafficDescTitle>
          <h3>
            {t("common.box")}&nbsp;{boxType.type}
          </h3>
          <span>
            {boxType.totalCount}&nbsp;
            {t("adminBoxesSettingsTraffic.info.quantity")}
          </span>
        </AdminBoxesSettingsTrafficDescTitle>
        <AdminBoxesSettingsTrafficDescDetailLink
          to={`/admin/settings/boxes/${boxType.id}`}
        >
          {t("adminBoxesSettingsTraffic.info.detailLink")}
        </AdminBoxesSettingsTrafficDescDetailLink>
      </AdminBoxesSettingsTrafficDescTitleWrapper>
      <AdminBoxesSettingsTrafficDescGrid>
        <AdminBoxesSettingsTrafficDescLabel>
          {t("adminBoxesSettingsTraffic.info.priceLabel")}
        </AdminBoxesSettingsTrafficDescLabel>
        <AdminBoxesSettingsTrafficDescPrice>
          {priceBoxes}
        </AdminBoxesSettingsTrafficDescPrice>
      </AdminBoxesSettingsTrafficDescGrid>
      <AdminBoxesSettingsBoxInfo cols={2}>
        <PrimaryText>{t("adminBoxesSettings.table.dimensions")}</PrimaryText>
        <span>{boxType.dimensions}</span>
        <PrimaryText>{t("adminBoxesSettings.table.volume")}</PrimaryText>
        <span>{boxType.volume}</span>
        <PrimaryText>{t("adminBoxesSettings.table.loadCapacity")}</PrimaryText>
        <span>{boxType.loadCapacity}</span>
      </AdminBoxesSettingsBoxInfo>
    </div>
  );
};

export default AdminBoxesSettingsTrafficDesc;
