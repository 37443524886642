import {
  faFingerprint,
  faMobileAlt,
  IconName,
  IconPrefix,
} from "@fortawesome/free-solid-svg-icons";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { AdditionalService } from "../../../models/additionalServices";
import { BoxType } from "../../../models/box";
import { H2 } from "../../../styles/title";
import { COLORS } from "../../../utils/constants";
import { formatCurrency } from "../../../utils/formats";
import { OrderGroup, OrderGroupNumber } from "../styles";
import {
  ServiceColumns,
  ServiceContainer,
  ServiceDetail,
  ServiceIcon,
  ServiceIconContainer,
  ServicePrice,
  ServicesContainer,
  ServiceTitle,
} from "./styles";

const OrderParameterServices: FC<{
  boxType: BoxType | null;
  additionalServices: AdditionalService[];
  fingerPrint: boolean;
  setFingerPrint: (value: boolean) => void;
  smsCheck: boolean;
  setSmsCheck: (value: boolean) => void;
  additionalServicesValue: number[];
  setAdditionalServicesValue: (value: number[]) => void;
}> = ({
  boxType,
  additionalServices,
  fingerPrint,
  setFingerPrint,
  smsCheck,
  setSmsCheck,
  additionalServicesValue,
  setAdditionalServicesValue,
}) => {
  const { t } = useTranslation();

  return (
    <section>
      <OrderGroup>
        <OrderGroupNumber>3</OrderGroupNumber>
        <H2 bold>{t("order.parameters.services.title")}</H2>
      </OrderGroup>
      <ServicesContainer>
        <ServiceContainer
          active={fingerPrint}
          onClick={() => setFingerPrint(!fingerPrint)}
        >
          <ServiceTitle bold>
            {t("order.parameters.services.fingerPrint")}
          </ServiceTitle>
          <ServiceColumns>
            <ServiceIconContainer>
              <ServiceIcon
                icon={faFingerprint}
                size="4x"
                color={COLORS.primary}
              />
            </ServiceIconContainer>
            <div>
              <ServiceDetail>
                {t("order.parameters.services.fingerPrintDetail")}
              </ServiceDetail>
              <ServicePrice>
                {boxType
                  ? formatCurrency(boxType.boxPriceDto.fingerPrintWithVat)
                  : "?"}{" "}
                {t("order.parameters.services.perMonth")}
              </ServicePrice>
            </div>
          </ServiceColumns>
        </ServiceContainer>
        <ServiceContainer
          active={smsCheck}
          onClick={() => setSmsCheck(!smsCheck)}
        >
          <ServiceTitle bold>
            {t("order.parameters.services.smsCheck")}
          </ServiceTitle>
          <ServiceColumns>
            <ServiceIconContainer>
              <ServiceIcon
                icon={faMobileAlt}
                size="4x"
                color={COLORS.primary}
              />
            </ServiceIconContainer>
            <div>
              <ServiceDetail>
                {t("order.parameters.services.smsCheckDetail")}
              </ServiceDetail>
              <ServicePrice>
                {boxType
                  ? formatCurrency(boxType.boxPriceDto.smsCheckWithVat)
                  : "?"}{" "}
                {t("order.parameters.services.perMonth")}
              </ServicePrice>
            </div>
          </ServiceColumns>
        </ServiceContainer>
        {additionalServices.map((service) => (
          <ServiceContainer
            key={"additionalService" + service.id}
            active={
              additionalServicesValue.find((x) => x === service.id) !==
              undefined
            }
            onClick={() =>
              additionalServicesValue.find((x) => x === service.id) ===
              undefined
                ? setAdditionalServicesValue(
                    additionalServicesValue.concat([service.id])
                  )
                : setAdditionalServicesValue(
                    additionalServicesValue.filter((x) => x !== service.id)
                  )
            }
          >
            <ServiceTitle bold>{service.name}</ServiceTitle>
            <ServiceColumns>
              <ServiceIconContainer>
                <ServiceIcon
                  icon={{
                    prefix: service.icon.substring(0, 3) as IconPrefix,
                    iconName: service.icon.substring(3) as IconName,
                  }}
                  size="4x"
                  color={COLORS.primary}
                />
              </ServiceIconContainer>
              <div>
                <ServiceDetail>{service.description}</ServiceDetail>
                <ServicePrice>
                  {boxType
                    ? formatCurrency(
                        boxType.boxPriceDto.additionalServices.find(
                          (x) => x.id === service.id
                        )!.priceWithVat
                      )
                    : "?"}{" "}
                  {t("order.parameters.services.perMonth")}
                </ServicePrice>
              </div>
            </ServiceColumns>
          </ServiceContainer>
        ))}
      </ServicesContainer>
    </section>
  );
};

export default OrderParameterServices;
