import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import orderApi from "../../../../api/order";
import { Button } from "../../../../styles/button";
import { getErrorMessage } from "../../../../utils/errors";
import { SetState } from "../../../../utils/types";
import CustomModal from "../../../common/CustomModal/CustomModal";

const OrderDetailExpireModal: FC<{
  open: boolean;
  setOpen: SetState<boolean>;
  id: string;
  reload: () => void;
}> = ({ open, setOpen, id, reload }) => {
  const { t } = useTranslation();
  const [saving, setSaving] = useState(false);

  const handleExpire = async () => {
    setSaving(true);
    try {
      await orderApi.setOrderExpired(id);
      toast.success(t("common.saveSuccess"));
      reload();
    } catch (err) {
      toast.error(getErrorMessage(err, t("errors.saveError")));
    }
    setSaving(false);
  };

  return (
    <CustomModal
      title={t("orders.detail.expireOrder")}
      open={open}
      setOpen={setOpen}
      actions={
        <>
          <Button
            ver="secondary"
            disabled={saving}
            onClick={() => setOpen(false)}
          >
            {t("common.no")}
          </Button>
          <Button disabled={saving} onClick={handleExpire}>
            {t("common.yes")}
          </Button>
        </>
      }
    >
      {t("orders.detail.expireOrderMessage")}
    </CustomModal>
  );
};

export default OrderDetailExpireModal;
