import React, { FC } from "react";
import { ErrorMessage } from "formik";
import { FormErrorContainer } from "./styles";
import { TooltipContent } from "../../Tooltip/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { COLORS } from "../../../../utils/constants";

const FormError: FC<{ name: string; tooltipLeft?: boolean }> = ({
  name,
  tooltipLeft,
}) => {
  return (
    <FormErrorContainer>
      <FontAwesomeIcon icon={faTimes} color={COLORS.white} />
      <TooltipContent left={tooltipLeft}>
        <ErrorMessage name={name} />
      </TooltipContent>
    </FormErrorContainer>
  );
};

export default FormError;
