import React, { FC } from "react";
import Page from "../../common/layout/Page/Page";
import AdminSidebar from "../AdminSidebar/AdminSidebar";
import AdminEmployeesContent from "./AdminEmployeesDetailContent/AdminEmployeesDetailContent";

const AdminEmployeesDetail: FC = () => (
  <Page sidebar={<AdminSidebar />}>
    <AdminEmployeesContent />
  </Page>
);

export default AdminEmployeesDetail;
