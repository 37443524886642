import React, { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import documentApi from "../../../api/document";
import userApi from "../../../api/user";
import { User } from "../../../models/user";
import { Button } from "../../../styles/button";
import { FadeInBottom } from "../../../styles/fadeIn";
import { PageHeader } from "../../../styles/layout";
import { useUserHasRight } from "../../../utils/auth";
import { getErrorMessage } from "../../../utils/errors";
import { useStore } from "../../../utils/store";
import Breadcrumbs from "../../common/Breadcrumbs/Breadcrumbs";
import Page from "../../common/layout/Page/Page";
import Loader from "../../common/Loader/Loader";
import UserCompanyCard from "../../common/UserInfo/UserCompanyCard";
import { AdminCalendarSceneButtons } from "../AdminCalendarScene/styles";
import AdminSidebar from "../AdminSidebar/AdminSidebar";
import AdminClientsDetailActivateModal from "./ActivateModal/AdminClientsDetailActivateModal";
import AdminClientsDetailDeactivateModal from "./ActivateModal/AdminClientsDetailDeactivateModal";
import AdminClientsDetailTabs from "./AdminClientsDetailTabs/AdminClientsDetailTabs";
import ClientInfoCard from "./ClientInfoCard/ClientInfoCard";
import AdminClientsDetailSignModal from "./SignModal/AdminClientsDetailSignModal";

const AdminClientsDetail: FC = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const user = useStore((store) => store.user);
  const [loading, setLoading] = useState<boolean>(true);
  const [userInfo, setUserInfo] = useState<User>();
  const [reload, setReload] = useState<boolean>(false);
  const [activateOpen, setActivateOpen] = useState(false);
  const [deactivateOpen, setDeactivateOpen] = useState(false);
  const [areDocumentsUnSigned, setAreDocumentsUnSigned] = useState(false);
  const [signOpen, setSignOpen] = useState(false);

  const { hasUserAllRights } = useUserHasRight();

  const handleSignDocuments = () => {
    setSignOpen(true);
  };

  const handleActivate = () => {
    if (userInfo?.isCustomerActive) {
      setDeactivateOpen(true);
    } else {
      setActivateOpen(true);
    }
  };

  const handleActivateConfirmed = async () => {
    await setActivate(true);
  };

  const handleDeactivateConfirmed = async () => {
    await setActivate(false);
  };

  const setActivate = async (active: boolean) => {
    setLoading(true);
    try {
      await userApi.updateIsCustomerActive(id, active);
      userInfo!.isCustomerActive = active;
      setUserInfo(userInfo);
      toast.success(t("common.saveSuccess"));
    } catch (err) {
      toast.error(getErrorMessage(err, t("errors.saveError")));
    }
    setLoading(false);
  };

  const loadNonSignedDocuments = useCallback(async () => {
    if (!hasUserAllRights(["readCustomerDocuments"])) {
      return;
    }

    setLoading(true);
    try {
      const response = await documentApi.getUserDocuments(id);
      setAreDocumentsUnSigned(
        response.data.some((x) => x.mustSign && !x.signed)
      );
    } catch (err) {
      toast.error(getErrorMessage(err, t("errors.loadError")));
    }
    setLoading(false);
  }, [hasUserAllRights, id, t]);

  const loadData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await userApi.getUser(id);
      setUserInfo(response.data);
    } catch (err) {
      toast.error(getErrorMessage(err, t("errors.loadError")));
    }
    setLoading(false);
    setReload(false);
  }, [t, id]);

  useEffect(() => {
    if (signOpen === false) {
      loadNonSignedDocuments();
    }
  }, [signOpen, loadNonSignedDocuments]);

  useEffect(() => {
    loadNonSignedDocuments();
  }, [userInfo, loadNonSignedDocuments]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    // does not run twice after reload is set to false in loadData()
    if (reload) loadData();
  }, [loadData, reload]);

  return (
    <Page sidebar={<AdminSidebar />}>
      {loading ? (
        <Loader />
      ) : (
        <FadeInBottom>
          <PageHeader>
            <Breadcrumbs>
              {user?.role !== "Employee" ? (
                <Link to={"/admin/clients"}>
                  {t("adminClientsDetail.breadcrumbs.clients")}
                </Link>
              ) : (
                <span>{t("adminClientsDetail.breadcrumbs.clients")}</span>
              )}
              <span>
                {userInfo?.firstName}&nbsp;{userInfo?.lastName}
              </span>
            </Breadcrumbs>
            <AdminCalendarSceneButtons>
              {areDocumentsUnSigned &&
                hasUserAllRights(["signCustomerDocuments"]) && (
                  <Button ver="primary" onClick={handleSignDocuments}>
                    {t("adminClientsDetail.signDocuments")}
                  </Button>
                )}
              {hasUserAllRights(
                userInfo?.isCustomerActive
                  ? ["customerDeactivate"]
                  : ["customerActivate"]
              ) && (
                <Button
                  ver={
                    userInfo?.isCustomerActive || areDocumentsUnSigned
                      ? "secondary"
                      : "primary"
                  }
                  onClick={handleActivate}
                >
                  {t(
                    "adminClientsDetail." +
                      (userInfo?.isCustomerActive ? "deactivate" : "activate")
                  )}
                </Button>
              )}
            </AdminCalendarSceneButtons>
          </PageHeader>
          <AdminClientsDetailActivateModal
            open={activateOpen}
            setOpen={setActivateOpen}
            confirm={handleActivateConfirmed}
            customerId={id}
          />
          <AdminClientsDetailDeactivateModal
            open={deactivateOpen}
            setOpen={setDeactivateOpen}
            confirm={handleDeactivateConfirmed}
          />
          <AdminClientsDetailSignModal
            open={signOpen}
            setOpen={setSignOpen}
            customerId={id}
          />
          {userInfo && (
            <ClientInfoCard
              userInfo={userInfo}
              reload={() => setReload(true)}
            />
          )}
          {!!userInfo?.companyName && <UserCompanyCard userInfo={userInfo} />}
          <AdminClientsDetailTabs
            reloadNonSignedDocuments={loadNonSignedDocuments}
          />
        </FadeInBottom>
      )}
    </Page>
  );
};

export default AdminClientsDetail;
