import { Formik } from "formik";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import promoCodeApi from "../../../../api/promoCode";
import { PromoCodeList } from "../../../../models/promoCode";
import { Card } from "../../../../styles/card";
import { FadeInBottom } from "../../../../styles/fadeIn";
import { PageSection } from "../../../../styles/layout";
import { getErrorMessage } from "../../../../utils/errors";
import Breadcrumbs from "../../../common/Breadcrumbs/Breadcrumbs";
import Loader from "../../../common/Loader/Loader";
import AdminPromoCodesDetailForm from "../AdminPromoCodesDetailForm/AdminPromoCodesDetailForm";

const promoCodeInitial: PromoCodeList = {
  id: 0,
  code: "",
  discount: 0,
  description: "",
  validUntil: "1970-01-01",
  nextDiscount: null,
  commission: null,
  nextCommission: null,
  isDiscountRepeated: null,
};

const AdminPromoCodesDetailContent: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const isCreate = id === "new";
  const [loading, setLoading] = useState<boolean>(false);

  const [promoCodeData, setpromoCodeData] = useState<PromoCodeList>({
    ...promoCodeInitial,
    validUntil: null,
    nextDiscount: null,
    commission: null,
    nextCommission: null,
    isDiscountRepeated: null,
  });

  const validationSchema = Yup.object({
    code: Yup.string().required(t("errors.required")),
    discount: Yup.number(),
    description: Yup.string(),
    validUntil: Yup.string().nullable(),
    nextDiscount: Yup.number().nullable(),
    commission: Yup.number().nullable(),
    nextCommission: Yup.number().nullable(),
    isDiscountRepeated: Yup.boolean().nullable(),
  });

  useEffect(() => {
    if (id !== "new")
      (async () => {
        setLoading(true);
        try {
          const response = await promoCodeApi.getPromoCode(id);
          const dataWithoutNulls = JSON.parse(
            JSON.stringify(response.data, (k, v) => (v === null ? "" : v))
          );
          setpromoCodeData(dataWithoutNulls);
        } catch (err) {
          toast.error(getErrorMessage(err, t("errors.loadError")));
        }
        setLoading(false);
      })();
  }, [id, t, history]);

  const handleSubmit = async (data: PromoCodeList) => {
    try {
      if (isCreate) {
        await promoCodeApi.createPromoCode(data);
        toast.success(t("adminPromoCodesDetail.form.createSuccess"));
      } else {
        await promoCodeApi.updatePromoCode(data);
        toast.success(t("adminPromoCodesDetail.form.updateSuccess"));
      }
      history.push("/admin/settings/promo-codes");
    } catch (err) {
      toast.error(getErrorMessage(err, t("errors.saveError")));
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <FadeInBottom>
          <PageSection>
            <Breadcrumbs backBtn>
              <Link to={"/admin/settings/promo-codes"}>
                {t("adminPromoCodesDetail.breadcrumbs.promoCodes")}
              </Link>
              <span>
                {t(
                  `adminPromoCodesDetail.breadcrumbs.${
                    isCreate ? "new" : "edit"
                  }`
                )}
              </span>
            </Breadcrumbs>
            <Card>
              <Formik
                initialValues={promoCodeData}
                enableReinitialize
                validateOnMount
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
              >
                <AdminPromoCodesDetailForm />
              </Formik>
            </Card>
          </PageSection>
        </FadeInBottom>
      )}
    </>
  );
};

export default AdminPromoCodesDetailContent;
