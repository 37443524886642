import React, { FC } from "react";
import Page from "../../common/layout/Page/Page";
import AdminSidebar from "../AdminSidebar/AdminSidebar";
import AdminBoxesSettingsDetailContent from "./AdminBoxesSettingsDetailContent/AdminBoxesSettingsDetailContent";

const AdminBoxesSettingsDetail: FC = () => (
  <Page sidebar={<AdminSidebar />}>
    <AdminBoxesSettingsDetailContent />
  </Page>
);

export default AdminBoxesSettingsDetail;
