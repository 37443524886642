import React, { FC, useState } from "react";
import {
  OrderBoxCard,
  OrderBoxDetails,
  OrderBoxImg,
  OrderBoxImgContainer,
} from "./styles";
import OrderDetailCardBox from "../OrderDetailCardBox/OrderDetailCardBox";
import { Order } from "../../../../../models/order";
import { BoxPrice } from "../../../../../models/box";
import OrderEditParametersModal from "../../OrderEditParametersModal/OrderEditParametersModal";
import { getBoxImg } from "../../../../../utils/images";

const OrderDetailCard: FC<{
  order: Order;
  boxPrice: BoxPrice;
  reload: () => Promise<void>;
}> = ({ order, boxPrice, reload }) => {
  const [editParams, setEditParams] = useState(false);

  return (
    <OrderBoxCard>
      <OrderEditParametersModal
        open={editParams}
        setOpen={setEditParams}
        order={order}
        onSuccess={reload}
      />
      <OrderBoxDetails>
        <OrderBoxImgContainer>
          <OrderBoxImg src={getBoxImg(order.box.type)} />
        </OrderBoxImgContainer>
        <OrderDetailCardBox
          order={order}
          boxPrice={boxPrice}
          setEditParams={setEditParams}
          reload={reload}
        />
      </OrderBoxDetails>
    </OrderBoxCard>
  );
};

export default OrderDetailCard;
