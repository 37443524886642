import styled from "styled-components";
import { COLORS } from "../../../utils/constants";
import { size } from "../../../utils/styles";

export const AdminDashboardCards = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: -0.625rem;
`;

export const AdminDashboardCard = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.whitesmoke};
  border-radius: 3px;
  width: calc(100% - 1.25rem);
  margin: 0.625rem;
  padding: 1rem;

  @media screen and (min-width: ${size.desktop}) {
    width: calc(100% / 2 - 1.25rem);
    height: 18.125rem;
  }
`;
