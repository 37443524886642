import React, { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../../styles/button";
import { SetState } from "../../../utils/types";
import CustomModal from "../../common/CustomModal/CustomModal";
import FilterTextInput from "../../common/form/Filters/FilterTextInput";

const AdminCalendarNoteModal: FC<{
  open: boolean;
  setOpen: SetState<boolean>;
  confirm: (note: string) => void;
}> = ({ open, setOpen, confirm }) => {
  const { t } = useTranslation();
  const [note, setNote] = useState<string>();

  const handleConfirm = useCallback(() => {
    confirm(note!);
    setOpen(false);
  }, [confirm, note, setOpen]);

  return (
    <CustomModal
      title={t("adminCalendarScene.noteModal.title")}
      open={open}
      setOpen={setOpen}
      actions={
        <>
          <Button
            ver="secondary"
            onClick={() => {
              setOpen(false);
              setNote(undefined);
            }}
          >
            {t("common.cancel")}
          </Button>
          <Button onClick={handleConfirm} disabled={!note}>
            {t("common.continue")}
          </Button>
        </>
      }
    >
      <FilterTextInput
        value={note}
        onInputChange={(note) => setNote(note)}
        label={t("adminCalendarScene.noteModal.note")}
        width={400}
      />
    </CustomModal>
  );
};

export default AdminCalendarNoteModal;
