import styled from "styled-components";
import { COLORS } from "../../../../utils/constants";
import { size } from "../../../../utils/styles";
import { CardList } from "../../../../styles/card";

export const AdminBoxesSettingsTrafficItem = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  border: 1px solid ${COLORS.whitesmoke};

  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }

  @media screen and (min-width: ${size.tablet}) {
    grid-template-columns: auto 1fr;
  }
`;

export const AdminBoxesSettingsTrafficThumbnail = styled.div`
  max-width: 15rem;
  min-width: 5rem;
  margin: 0 auto;

  @media screen and (min-width: ${size.tablet}) {
    display: flex;
    align-items: center;
    margin: 1rem 1.5rem 1rem 0;
  }
`;

export const AdminBoxesSettingsTrafficInfoGrid = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  margin-top: 1rem;

  @media screen and (min-width: ${size.tablet}) {
    margin-top: 0;
  }

  @media screen and (min-width: ${size.desktopL}) {
    grid-template-columns: auto 4fr;
  }
`;

export const AdminBoxesSettingsBoxInfo = styled(CardList)`
  width: min-content;
  margin-bottom: 1rem;

  > * {
    white-space: pre;
  }

  @media screen and (min-width: ${size.desktopL}) {
    margin-bottom: 0;
  }
`;
