import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, Fragment, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { CalendarOverview } from "../../../../models/calendar";
import { AdminRoles } from "../../../../models/employee";
import { Card, CardHeader } from "../../../../styles/card";
import { PageSection } from "../../../../styles/layout";
import { NoTableData } from "../../../../styles/table";
import { H3 } from "../../../../styles/title";
import { useUserHasRight } from "../../../../utils/auth";
import { COLORS } from "../../../../utils/constants";
import { formatDate, formatTime } from "../../../../utils/formats";
import { useStore } from "../../../../utils/store";
import { OverflowX } from "../../../../utils/styles";
import MeetingCancelButton from "./MeetingCancelButton";
import { DashboardMeetingsList, RescheduleButton } from "./styles";

const MeetingsTable: FC<{
  data: CalendarOverview[];
  reload?: () => Promise<void>;
  canCancel?: boolean;
  table?: boolean;
  userId?: number;
}> = ({ data, reload, canCancel, table, userId }) => {
  const { t } = useTranslation();
  const user = useStore((store) => store.user);
  const isAdmin = useMemo(
    () => Boolean(AdminRoles.find((r) => r === user?.role)),
    [user?.role]
  );
  const { hasUserAllRights } = useUserHasRight();
  const canReserve = hasUserAllRights(["reserveCustomerCalendar"]);
  const canDelete = hasUserAllRights(["deleteCustomerCalendar"]);

  return (
    <PageSection>
      <OverflowX>
        <Card background={table ? COLORS.white : undefined}>
          {!table && (
            <CardHeader>
              <H3>{t("dashboard.meetings.title")}</H3>
              <Link to="/meetings">
                <b>+ {t("dashboard.meetings.planMeeting")}</b>
              </Link>
            </CardHeader>
          )}
          {data.length ? (
            <DashboardMeetingsList cols={4}>
              {data.map((row) => (
                <Fragment key={row.id}>
                  <div>
                    <FontAwesomeIcon icon={faCalendarAlt} color={COLORS.gray} />
                    <b>{formatDate(row.date)}</b>
                  </div>
                  <div>{formatTime(row.date)}</div>
                  <div>
                    {isAdmin && (
                      <Link to={`/admin/orders/${row.orderId}`}>
                        {row.orderName}
                      </Link>
                    )}
                  </div>
                  <div>
                    {canCancel && canDelete && (
                      <MeetingCancelButton id={row.id} reload={reload} />
                    )}
                    {(!isAdmin || (table && canReserve)) && (
                      <RescheduleButton
                        to={table ? `/admin/meetings/${userId}` : "/meetings"}
                      >
                        {t("dashboard.meetings.reschedule")}
                      </RescheduleButton>
                    )}
                  </div>
                </Fragment>
              ))}
            </DashboardMeetingsList>
          ) : (
            <NoTableData>{t("dashboard.meetings.noMeetings")}</NoTableData>
          )}
        </Card>
      </OverflowX>
    </PageSection>
  );
};

export default MeetingsTable;
