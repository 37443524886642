import api from "../utils/api";
import { AxiosResponse } from "axios";
import { History } from "../models/history";
import { FiltersSearchBranchParams, Paged } from "../models/common";

export const getHistory = (
  params?: FiltersSearchBranchParams
): Promise<AxiosResponse<Paged<History>>> =>
  api.get(`/api/History`, { params });

const historyApi = {
  getHistory,
};

export default historyApi;
