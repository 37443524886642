import { addMinutes, setHours, setMinutes } from "date-fns";
import { Field, Form, Formik } from "formik";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import calendarApi from "../../../../api/calendar";
import storageApi from "../../../../api/storage";
import { Button } from "../../../../styles/button";
import { useUserHasRight } from "../../../../utils/auth";
import { DATE_FORMAT, TIME_SLOT_MINUTES } from "../../../../utils/constants";
import { getErrorMessage } from "../../../../utils/errors";
import { SetState } from "../../../../utils/types";
import CustomModal from "../../../common/CustomModal/CustomModal";
import Checkbox from "../../../common/form/Checkbox/Checkbox";
import DatePicker from "../../../common/form/DatePicker/DatePicker";
import Select from "../../../common/form/Select/Select";
import AdminEmployeeSelect from "../AdminEmployeeSelect/AdminEmployeeSelect";
import {
  AdminCalendarModalDays,
  AdminCalendarModalFormGroup,
  AdminCalendarModalInfoText,
  CalendarModalTimes,
  CalendarModalTimesContainer,
  CalendarModalTimesLabel,
} from "./styles";

interface FormModel {
  employeeId?: number;
  startDate: Date;
  endDate: Date;
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
}

const dayFields = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

const datePickerDefs: Array<keyof FormModel> = ["startDate", "endDate"];

const floorToSlotTime = (date: Date) => {
  const flooredMinutesToSlot =
    Math.floor(date.getMinutes() / TIME_SLOT_MINUTES) * TIME_SLOT_MINUTES;
  const flooredTimeToSlot = new Date(date);
  flooredTimeToSlot.setMinutes(flooredMinutesToSlot);
  flooredTimeToSlot.setSeconds(0);
  flooredTimeToSlot.setMilliseconds(0);

  return flooredTimeToSlot;
};

const getTimeSlots = () => {
  let res = [];
  let date = new Date(0, 0, 0, 0, 0);
  for (let i = 0; i < 96; i++) {
    let time = `${("0" + date.getHours()).slice(-2)}:${(
      "0" + date.getMinutes()
    ).slice(-2)}`;
    res.push({ label: time, value: time });
    date = addMinutes(date, TIME_SLOT_MINUTES);
  }
  return res;
};

const getDateWithTimeslot = (date: Date, time: string) => {
  const [hrs, mnts] = time.split(":");
  const hours = parseInt(hrs, 10);
  const minutes = parseInt(mnts, 10);

  let newDate: Date;
  newDate = setHours(date, hours);
  newDate = setMinutes(newDate, minutes);
  return newDate.toUTCString();
};

const AdminCalendarModal: FC<{
  open: boolean;
  modalMode: "Add" | "Delete";
  setOpen: SetState<boolean>;
  onSuccess?: () => void;
  selectedEmployee: { value: string; label: string };
}> = ({ open, modalMode, setOpen, onSuccess, selectedEmployee }) => {
  const { t } = useTranslation();
  const [employee, setEmployee] = useState<
    | {
        value: string;
        label: string;
      }
    | undefined
  >();
  const [newEmployee, setNewEmployee] = useState<
    | {
        value: string;
        label: string;
      }
    | undefined
  >();
  const [timeFrom, setTimeFrom] = useState<{ label: string; value: string }>({
    label: "08:00",
    value: "08:00",
  });
  const [timeTo, setTimeTo] = useState<{ label: string; value: string }>({
    label: "16:00",
    value: "16:00",
  });
  const user = storageApi.getCurrentUser();
  const isAdmin = user?.role === "Admin" || user?.role === "Boss";
  const { hasUserAllRights } = useUserHasRight();
  const canChangeOtherCalendars = hasUserAllRights([
    "changeOtherEmployeeCalendar",
  ]);
  const currentDate = new Date();

  const initialValues: FormModel = {
    startDate: floorToSlotTime(currentDate),
    endDate: floorToSlotTime(addMinutes(currentDate, TIME_SLOT_MINUTES)),
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
    saturday: false,
    sunday: false,
  };

  const handleSubmit = async (values: FormModel) => {
    try {
      switch (modalMode) {
        case "Add":
          await calendarApi.addAvailableTimes(1, employee!.value, {
            from: getDateWithTimeslot(values.startDate, timeFrom.value),
            to: getDateWithTimeslot(values.endDate, timeTo.value),
            monday: values.monday,
            tuesday: values.tuesday,
            wednesday: values.wednesday,
            thursday: values.thursday,
            friday: values.friday,
            saturday: values.saturday,
            sunday: values.sunday,
          });
          break;
        case "Delete":
          await calendarApi.deleteAvailableTimes(
            isAdmin ? employee!.value : user?.id!,
            newEmployee!.value,
            {
              from: getDateWithTimeslot(values.startDate, timeFrom.value),
              to: getDateWithTimeslot(values.endDate, timeTo.value),
            }
          );
      }
      toast.success(t("common.saveSuccess"));
      onSuccess && onSuccess();
      setOpen(false);
    } catch (err) {
      toast.error(getErrorMessage(err, t("errors.saveError")));
    }
  };

  const getSelect = () => {
    switch (modalMode) {
      case "Add":
        return (
          (isAdmin || canChangeOtherCalendars) && (
            <AdminEmployeeSelect
              inputWidth="100%"
              label={t("adminCalendarScene.modal.setAvailableTimeFor")}
              defaultValue={employee}
              onChange={(value, label) => setEmployee({ value, label: label! })}
            />
          )
        );
      case "Delete":
        return (
          <>
            {(isAdmin || canChangeOtherCalendars) && (
              <AdminEmployeeSelect
                label={t("adminCalendarScene.modal.assignReservationsFrom")}
                defaultValue={employee}
                onChange={(value, label) =>
                  setEmployee({ value, label: label! })
                }
              />
            )}
            <AdminEmployeeSelect
              label={t("adminCalendarScene.modal.assignReservationsTo")}
              defaultValue={newEmployee}
              onChange={(value, label) =>
                setNewEmployee({ value, label: label! })
              }
            />
            <AdminCalendarModalInfoText>
              {t("adminCalendarScene.modal.infoText")}
            </AdminCalendarModalInfoText>
          </>
        );
    }
  };

  useEffect(() => {
    setEmployee(
      selectedEmployee.value === "all" ? undefined : selectedEmployee
    );
  }, [selectedEmployee]);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {({
        isSubmitting,
        isValid,
        errors,
        submitForm,
        resetForm,
        values,
        setFieldValue,
      }) => (
        <CustomModal
          title={t(`adminCalendarScene.modal.title${modalMode}`)}
          open={open}
          setOpen={(state) => {
            resetForm();
            setOpen(state);
          }}
          actions={
            <>
              <Button
                type="button"
                ver="secondary"
                disabled={isSubmitting}
                onClick={() => {
                  resetForm();
                  setOpen(false);
                }}
              >
                {t("common.cancel")}
              </Button>
              <Button
                type={"submit"}
                disabled={
                  !isValid ||
                  isSubmitting ||
                  !employee ||
                  (modalMode === "Delete" && !newEmployee)
                }
                onClick={submitForm}
              >
                {t("common.save")}
              </Button>
            </>
          }
        >
          <Form>
            <AdminCalendarModalFormGroup labelWidth="11.25rem">
              {getSelect()}
              <CalendarModalTimesContainer>
                <label>{t("adminCalendarScene.modal.date")}</label>
                <CalendarModalTimes>
                  {datePickerDefs.map((f) => (
                    <div key={f}>
                      <CalendarModalTimesLabel>
                        {t("common." + (f === "startDate" ? "from" : "to"))}
                      </CalendarModalTimesLabel>
                      <DatePicker
                        key={f}
                        name={f}
                        popperProps={{
                          placement: "top-start",
                          position: "absolute",
                        }}
                        preventOpenOnFocus
                        inputWidth="100%"
                        selected={values[f] as Date}
                        dateFormat={DATE_FORMAT.date}
                        minDate={currentDate}
                        error={!!errors[f]}
                        onChange={(date) => {
                          setFieldValue(
                            f,
                            floorToSlotTime(
                              (date as Date) ||
                                (f === "startDate"
                                  ? currentDate
                                  : addMinutes(currentDate, TIME_SLOT_MINUTES))
                            )
                          );
                        }}
                      />
                    </div>
                  ))}
                </CalendarModalTimes>
              </CalendarModalTimesContainer>
              {modalMode === "Add" && (
                <AdminCalendarModalDays>
                  {dayFields.map((d) => (
                    <Checkbox key={d}>
                      <Field type="checkbox" name={d} />
                      {t(`common.days.${d}`)}
                    </Checkbox>
                  ))}
                </AdminCalendarModalDays>
              )}
              <CalendarModalTimesContainer>
                <label>{t("adminCalendarScene.modal.timeCaption")}</label>
                <CalendarModalTimes>
                  <div>
                    <CalendarModalTimesLabel>
                      {t("common.from")}
                    </CalendarModalTimesLabel>
                    <Select
                      value={timeFrom}
                      onChange={(val) =>
                        setTimeFrom(
                          val || {
                            label: "08:00",
                            value: "08:00",
                          }
                        )
                      }
                      options={getTimeSlots()}
                    />
                  </div>
                  <div>
                    <CalendarModalTimesLabel>
                      {t("common.to")}
                    </CalendarModalTimesLabel>
                    <Select
                      value={timeTo}
                      onChange={(val) =>
                        setTimeTo(
                          val || {
                            label: "16:00",
                            value: "16:00",
                          }
                        )
                      }
                      options={getTimeSlots()}
                    />
                  </div>
                </CalendarModalTimes>
              </CalendarModalTimesContainer>
            </AdminCalendarModalFormGroup>
          </Form>
        </CustomModal>
      )}
    </Formik>
  );
};

export default AdminCalendarModal;
