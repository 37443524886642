import React, { FC } from "react";
import Page from "../../common/layout/Page/Page";
import AdminSidebar from "../AdminSidebar/AdminSidebar";
import AdminOrdersContent from "./AdminOrdersContent/AdminOrdersContent";

const AdminOrders: FC = () => (
  <Page sidebar={<AdminSidebar />}>
    <AdminOrdersContent />
  </Page>
);

export default AdminOrders;
