import styled from "styled-components";
import { Table } from "../../../../styles/table";
import { size } from "../../../../utils/styles";

export const CardEventsHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CardEventsContent = styled.div`
  position: relative;
  height: 100%;
  min-height: 3rem;
  padding-top: 0.7rem;

  @media screen and (min-width: ${size.desktopL}) {
    padding-top: 0.3rem;
  }
`;

export const CardEventsCell = styled.div`
  display: flex;
  align-items: center;
`;

export const CardEventsIcon = styled.div`
  color: var(--primary);
  margin-right: 0.3rem;
`;

export const CartEventsTable = styled(Table)`
  font-size: 1rem;

  @media screen and (min-width: ${size.desktop}) {
    font-size: 0.8rem;
  }

  tbody {
    tr {
      background-color: var(--transparent);
    }
  }
`;

export const CardEventsNameTruncate = styled.div`
  width: auto;
  min-width: 0;
  max-width: 12rem;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media screen and (min-width: ${size.desktop}) {
    max-width: 9rem;
  }
`;
