import React, { FC, useEffect, useState } from "react";
import Page from "../../common/layout/Page/Page";
import UserSidebar from "../UserSidebar/UserSidebar";
import { H2 } from "../../../styles/title";
import { useTranslation } from "react-i18next";
import UserInfoCard from "../../common/UserInfo/UserInfoCard";
import userApi from "../../../api/user";
import { User } from "../../../models/user";
import { toast } from "react-toastify";
import Loader from "../../common/Loader/Loader";
import { FadeInBottom } from "../../../styles/fadeIn";
import { useStore } from "../../../utils/store";
import storageApi from "../../../api/storage";
import UserCompanyCard from "../../common/UserInfo/UserCompanyCard";

const Profile: FC = () => {
  const { t } = useTranslation();
  const [state, setState] = useState({ loading: true, reload: false });
  const [userInfo, setUserInfo] = useState<User | null>(null);
  const setUser = useStore((store) => store.setUser);

  const reload = () => setState((prev) => ({ ...prev, reload: !prev.reload }));

  useEffect(() => {
    (async () => {
      setState((prev) => ({ ...prev, loading: true }));
      try {
        const userData = await userApi.getUser(storageApi.getCurrentUser()!.id);
        setUserInfo(userData.data);

        storageApi.setCurrentUser(userData.data);
        setUser(userData.data);
      } catch (err) {
        toast.error(t("errors.userInfo"));
      }
      setState((prev) => ({ ...prev, loading: false }));
    })();
  }, [t, state.reload, setUser]);

  return (
    <Page sidebar={<UserSidebar />}>
      {state.loading ? (
        <Loader />
      ) : (
        <FadeInBottom>
          <H2>{t("profile.title")}</H2>
          {userInfo && (
            <UserInfoCard userInfo={userInfo} editable reload={reload} />
          )}
          {!!userInfo?.companyName && <UserCompanyCard userInfo={userInfo} />}
        </FadeInBottom>
      )}
    </Page>
  );
};

export default Profile;
