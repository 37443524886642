import React, { FC, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import scheduledTasksApi from "../../../../api/scheduledTasks";
import { ScheduledTask } from "../../../../models/scheduledTask";
import { TableLabels } from "../../../../styles/table";
import { useUserHasRight } from "../../../../utils/auth";
import { getErrorMessage } from "../../../../utils/errors";
import { OverflowX } from "../../../../utils/styles";
import { ScheduledTasksTable, ScheduledTaskState } from "./styles";

const cols = [
  "adminScheduledTasks.isActive",
  "adminScheduledTasks.name",
  "adminScheduledTasks.description",
  "adminScheduledTasks.readableCron",
];

const AdminScheduledTasksTable: FC<{
  data: ScheduledTask[];
  reload: () => Promise<void>;
}> = ({ data, reload }) => {
  const { t } = useTranslation();
  const [saving, setSaving] = useState(false);
  const { hasUserAllRights } = useUserHasRight();

  const handleClick = useCallback(
    async (id: string, isActive: boolean) => {
      setSaving(true);
      try {
        await scheduledTasksApi.setScheduledTask(id, isActive);
        toast.success(t("common.saveSuccess"));
        reload();
      } catch (err) {
        toast.error(getErrorMessage(err, t("errors.saveError")));
      }
      setSaving(false);
    },
    [t, setSaving, reload]
  );

  const tableCols = cols.map((col) => <th key={col}>{t(col)}</th>);

  const tableRows = useMemo(
    () =>
      data.map((row) => (
        <tr key={row.id}>
          <td>
            <ScheduledTaskState
              isActive={row.isActive}
              disabled={saving || !hasUserAllRights(["writeScheduledTasks"])}
              onClick={() => handleClick(row.id, !row.isActive)}
            >
              {t(`adminScheduledTasks.${row.isActive ? "active" : "inactive"}`)}
            </ScheduledTaskState>
          </td>
          <td>
            <b>{row.name}</b>
          </td>
          <td>{row.description}</td>
          <td>
            <TableLabels>
              <span>{t(`adminScheduledTasks.${row.readableCron}`)}</span>
            </TableLabels>
          </td>
        </tr>
      )),
    [data, t, handleClick, saving]
  );

  return (
    <OverflowX>
      <ScheduledTasksTable>
        <thead>
          <tr>{tableCols}</tr>
        </thead>
        <tbody>{tableRows}</tbody>
      </ScheduledTasksTable>
    </OverflowX>
  );
};

export default AdminScheduledTasksTable;
