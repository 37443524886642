import styled from "styled-components";
import { H1 } from "../../../../styles/title";
import { size } from "../../../../utils/styles";
import { COLORS } from "../../../../utils/constants";

export const OrderSidebarNoBoxH1 = styled(H1)`
  margin-bottom: 0.25rem;
  text-align: center;
  font-size: 1.4rem;

  @media screen and (min-width: ${size.mobile}) {
    font-size: revert;
  }
`;

export const OrderSidebarNoBoxSubtitle = styled.span`
  text-align: center;
`;

export const OrderSidebarIconWrapper = styled.div`
  padding: 0.75rem;
  margin-bottom: 0.75rem;

  background-color: ${COLORS.primary};
  border-radius: 4px;

  display: flex;
  justify-content: center;
  align-items: center;
`;
