import styled from "styled-components";
import { Link } from "react-router-dom";

export const UserSidebarLinkIcon = styled.span`
  display: inline-block;
  width: 2.5rem;
  text-align: center;
  margin-left: 1rem;
  font-size: 1.75rem;

  svg,
  span {
    transition: color 0.2s ease-in-out;
  }
`;

export const UserSidebarFooter = styled.div`
  padding: 1rem;

  background-color: var(--gray);
  color: var(--secondary);
`;

export const UserSidebarFooterLink = styled(Link)`
  display: flex;
  align-items: center;

  text-decoration: none;
  color: var(--secondary) !important;
  margin-bottom: 0.5rem;

  &:hover {
    &,
    svg {
      color: var(--secondary);
    }
  }
`;

export const UserSidebarFooterA = styled.a`
  display: flex;
  align-items: center;

  text-decoration: none;
  color: var(--secondary) !important;
  margin-bottom: 0.5rem;

  &:hover {
    &,
    svg {
      color: var(--secondary);
    }
  }
`;

export const UserSidebarFooterIcon = styled(UserSidebarLinkIcon)`
  margin: 0 0.25rem 0 0;
  font-size: 1.5rem;
`;

export const UserSidebarFooterText = styled.div`
  &:first-of-type {
    margin-bottom: 0.75rem;
    margin-top: 1.25rem;
  }
`;
