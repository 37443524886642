import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import additionalServicesApi from "../../../api/additionalServices";
import { AdditionalService } from "../../../models/additionalServices";
import { Button } from "../../../styles/button";
import { FadeInBottom } from "../../../styles/fadeIn";
import { PageSection } from "../../../styles/layout";
import { TableHeaderActions } from "../../../styles/table";
import { H2 } from "../../../styles/title";
import { useUserHasRight } from "../../../utils/auth";
import { COLORS } from "../../../utils/constants";
import { getErrorMessage } from "../../../utils/errors";
import Page from "../../common/layout/Page/Page";
import Loader from "../../common/Loader/Loader";
import AdminSidebar from "../AdminSidebar/AdminSidebar";
import AdminAdditionalServicesModal from "./AdminAdditionalServicesModal";
import AdminAdditionalServicesTable from "./AdminAdditionalServicesTable";

const AdminAdditionalServices: FC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<AdditionalService[]>([]);
  const [editItem, setEditItem] = useState<AdditionalService>({
    id: 0,
    isActive: false,
    name: "",
    description: "",
    icon: "",
    priceWithoutVat: 0,
    priceWithVat: 0,
    shouldSendNotificationOnRenewal: false
  });
  const [open, setOpen] = useState(false);
  const { hasUserAllRights } = useUserHasRight();

  const handleAdd = () => {
    setEditItem({
      id: 0,
      isActive: false,
      name: "",
      description: "",
      icon: "",
      priceWithoutVat: 0,
      priceWithVat: 0,
      shouldSendNotificationOnRenewal: false
    });
    setOpen(true);
  };

  const handleEdit = (id: number) => {
    setEditItem(data.find((x) => x.id === id)!);
    setOpen(true);
  };

  const loadData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await additionalServicesApi.getAll();
      setData(response.data);
    } catch (err) {
      toast.error(getErrorMessage(err, t("errors.loadError")));
    }
    setLoading(false);
  }, [t]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <Page sidebar={<AdminSidebar />}>
      {loading ? (
        <Loader />
      ) : (
        <FadeInBottom>
          <PageSection>
            <AdminAdditionalServicesModal
              initData={editItem}
              open={open}
              setOpen={setOpen}
              onSuccess={() => loadData()}
            />
            <TableHeaderActions>
              <div>
                <H2>{t("adminAdditionalServices.title")}</H2>
              </div>
              <div>
                {hasUserAllRights(["writeAdditionalServices"]) && (
                  <Button onClick={handleAdd}>
                    <FontAwesomeIcon icon={faPlus} color={COLORS.white} />
                    {t("adminAdditionalServices.add")}
                  </Button>
                )}
              </div>
            </TableHeaderActions>
            <AdminAdditionalServicesTable data={data} onEdit={handleEdit} />
          </PageSection>
        </FadeInBottom>
      )}
    </Page>
  );
};

export default AdminAdditionalServices;
