import React, { FC, useMemo } from "react";
import { Invoice } from "../../../../models/document";
import { useTranslation } from "react-i18next";
import { OverflowX } from "../../../../utils/styles";
import { formatCurrency, formatDate } from "../../../../utils/formats";
import { InvoicesTableContainer, PayText } from "./styles";
import InvoiceState from "../InvoiceState/InvoiceState";
import InvoiceName from "../InvoiceName/InvoiceName";
import { NoTableData } from "../../../../styles/table";

const InvoicesTable: FC<{
  data: Invoice[];
  onPay?: (invoiceId: string) => void;
}> = ({ data, onPay }) => {
  const { t } = useTranslation();

  const tableData = useMemo(
    () =>
      data.map((row) => (
        <tr key={row.id}>
          <td>
            <InvoiceName invoice={row} />
          </td>
          <td>
            <div>{formatDate(row.createdDate)}</div>
          </td>
          <td>
            <div>{formatDate(row.dueDate)}</div>
          </td>
          <td>
            <InvoiceState paid={row.paid} />
          </td>
          <td>
            <div>
              <b>{formatCurrency(row.priceInclVat)}</b>
            </div>
          </td>
          {onPay && (
            <td>
              {row.canPay && (
                <PayText onClick={() => onPay && onPay(row.id)}>
                  {t("invoices.pay")}
                </PayText>
              )}
            </td>
          )}
        </tr>
      )),
    [data, onPay, t]
  );

  const tableCols = (
    <tr>
      {[
        t("invoices.name"),
        t("invoices.issued"),
        t("invoices.due"),
        t("invoices.paid.title"),
        t("invoices.amount"),
      ].map((col) => (
        <th key={col}>{col}</th>
      ))}
      {onPay && <th key="pay" />}
    </tr>
  );

  return data.length ? (
    <OverflowX>
      <InvoicesTableContainer>
        <thead>{tableCols}</thead>
        <tbody>{tableData}</tbody>
      </InvoicesTableContainer>
    </OverflowX>
  ) : (
    <NoTableData>{t("invoices.noData")}</NoTableData>
  );
};

export default InvoicesTable;
