import { addDays, getWeek, subDays } from "date-fns";
import React, { FC, ReactElement, useState } from "react";
import {
  CalendarContainer,
  CalendarContent,
  CalendarGrid,
  CalendarGridWrapper,
  CalendarHeader,
  CalendarTime,
  CalendarTimeCell,
  CalendarTimesCol,
} from "../../../../styles/calendar";
import Loader from "../../../common/Loader/Loader";
import WeekSelector from "../WeekSelector/WeekSelector";
import { getTimeLabel } from "../../../user/Reservation/utils";

const Calendar: FC<{
  loading: boolean;
  labels: Date[];
  mondayDate: Date;
  setMondayDate: (date: Date) => void;
  headerContent?: ReactElement;
  days: ReactElement[];
}> = ({ loading, labels, mondayDate, setMondayDate, headerContent, days }) => {
  const [week, setWeek] = useState(getWeek(mondayDate));

  const handlePrevWeek = () => {
    const newMonday = subDays(mondayDate, 7);
    setMondayDate(newMonday);
    setWeek(getWeek(newMonday));
  };

  const handleNextWeek = () => {
    const newMonday = addDays(mondayDate, 7);
    setMondayDate(newMonday);
    setWeek(getWeek(newMonday));
  };

  const timeCells = labels.map((label, i) => (
    <CalendarTimeCell key={i}>
      <CalendarTime>{getTimeLabel(label)}</CalendarTime>
    </CalendarTimeCell>
  ));

  return (
    <CalendarContainer>
      <CalendarHeader justifyContent={headerContent && "space-between"}>
        {headerContent}
        <WeekSelector
          week={week}
          onClickPrevWeek={handlePrevWeek}
          onClickNextWeek={handleNextWeek}
        />
      </CalendarHeader>
      {loading ? (
        <Loader />
      ) : (
        <CalendarContent>
          <CalendarTimesCol>{timeCells}</CalendarTimesCol>
          <CalendarGridWrapper>
            <CalendarGrid>{days}</CalendarGrid>
          </CalendarGridWrapper>
        </CalendarContent>
      )}
    </CalendarContainer>
  );
};

export default Calendar;
