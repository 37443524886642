import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import orderApi from "../../../../api/order";
import { Order } from "../../../../models/order";
import { FadeInBottom } from "../../../../styles/fadeIn";
import { getErrorMessage } from "../../../../utils/errors";
import Loader from "../../../common/Loader/Loader";
import OrdersTable from "../../../user/Orders/OrdersTable/OrdersTable";
import { AdminClientsDetailTabsLoader } from "./styles";

const AdminClientsDetailOrders: FC = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [data, setData] = useState<Order[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const response = await orderApi.getUserOrders(id);
        setData(response.data);
      } catch (err) {
        toast.error(getErrorMessage(err, t("errors.loadError")));
      }
      setLoading(false);
    })();
  }, [id, t]);

  return loading ? (
    <AdminClientsDetailTabsLoader>
      <Loader />
    </AdminClientsDetailTabsLoader>
  ) : (
    <FadeInBottom>
      <OrdersTable data={data} admin />
    </FadeInBottom>
  );
};

export default AdminClientsDetailOrders;
