import styled, { css } from "styled-components";
import { fadeIn, size } from "../../../utils/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FadeIn from "react-fade-in";

export const BoxTypeCards = styled(FadeIn)`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;

  @media screen and (min-width: ${size.mobile}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (min-width: ${size.tablet}) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (min-width: ${size.desktop}) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media screen and (min-width: ${size.desktopL}) {
    grid-template-columns: repeat(5, 1fr);
  }
`;

export const BoxTypeCardContainer = styled.div<{
  active: boolean;
  isBoxActive: boolean;
}>`
  transition: all 0.15s ease-in-out;
  border: ${({ active }) =>
    active ? "5px solid var(--primary)" : "2px solid var(--secondary)"};
  border-radius: 15px;
  animation: ${fadeIn} 0.3s ease-in-out;
  margin-bottom: ${({ active }) => (active ? "0px" : "20px")};
  overflow: hidden;

  ${({ isBoxActive }) =>
    isBoxActive
      ? css`
          &:hover {
            cursor: pointer;
          }
        `
      : css`
          filter: grayscale(100%);
          opacity: 0.7;
        `}
`;

export const BoxTypeCardImg = styled.div`
  padding: 0.5rem;
  margin: auto;
  width: 10rem;
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BoxTypeCardContent = styled.div`
  width: 100%;
  padding: 0.5rem;
  background-color: var(--whitesmoke);
  line-height: 1.5;

  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 0.5rem;
  justify-content: center;
  align-items: center;

  span {
    font-weight: bold;
    text-transform: uppercase;
  }

  div {
    font-size: 0.8rem;
    line-height: 1.25rem;
  }
`;

export const BoxTypeCardIcon = styled(FontAwesomeIcon)`
  margin: 0 auto;
`;

export const BoxTypeVolumeIcon = styled.div`
  width: 14px;
  height: 14px;
  border: 1px dashed var(--primary);
  border-radius: 2px;
  margin: 0 auto;
`;

export const BoxTypeLoader = styled.div`
  height: 16rem;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BoxTypePrice = styled.div`
  grid-column: 1 / 3;
  text-align: center;
  position: relative;
  top: 0.25rem;
  font-weight: 700;
`;
