import { rgba } from "polished";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import orderApi from "../../../../api/order";
import { OrderStateOverviews } from "../../../../models/order";
import { Card } from "../../../../styles/card";
import { H4 } from "../../../../styles/title";
import { COLORS } from "../../../../utils/constants";
import { getErrorMessage } from "../../../../utils/errors";
import Loader from "../../../common/Loader/Loader";
import CardOrdersOverviewRow from "./CardOrdersOverviewRow";
import {
  CardOrdersContainer,
  CardOrdersOverviewContent,
  CardOrdersOverviewHeader,
  CardOrdersOverviews,
  CardOrdersOverviewsHeader,
} from "./styles";

const CardOrdersOverview: FC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<OrderStateOverviews>();

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const response = await orderApi.getOrderStateOverview();
        setData(response.data);
      } catch (err) {
        toast.error(getErrorMessage(err, t("errors.loadError")));
      }
      setLoading(false);
    })();
  }, [t]);

  return (
    <CardOrdersContainer>
      {loading ? (
        <Loader />
      ) : (
        <CardOrdersOverviews>
          <CardOrdersOverviewsHeader>
            <H4>{t("adminDashboard.ordersOverview.title")}</H4>
            <Link to="/admin/orders">
              <b>{t("adminDashboard.ordersOverview.ordersLink")}</b>
            </Link>
          </CardOrdersOverviewsHeader>
          <Card
            background={rgba(COLORS.orange, 0.15)}
            padding="0.5rem"
            overflowX="auto"
          >
            <CardOrdersOverviewHeader color={COLORS.orange}>
              {t("adminDashboard.ordersOverview.soonExpired")}
            </CardOrdersOverviewHeader>
            <CardOrdersOverviewContent>
              {data?.soonExpired.length ? (
                data?.soonExpired.map((row) => (
                  <CardOrdersOverviewRow key={row.id} row={row} />
                ))
              ) : (
                <div>-</div>
              )}
            </CardOrdersOverviewContent>
          </Card>
          <Card
            background={rgba(COLORS.red, 0.15)}
            padding="0.5rem"
            overflowX="auto"
          >
            <CardOrdersOverviewHeader color={COLORS.red}>
              {t("adminDashboard.ordersOverview.expired")}
            </CardOrdersOverviewHeader>
            <CardOrdersOverviewContent>
              {data?.expired.length ? (
                data?.expired.map((row) => (
                  <CardOrdersOverviewRow key={row.id} row={row} />
                ))
              ) : (
                <div>-</div>
              )}
            </CardOrdersOverviewContent>
          </Card>
          <Card
            background={rgba(COLORS.gray, 0.15)}
            padding="0.5rem"
            overflowX="auto"
          >
            <CardOrdersOverviewHeader color={COLORS.gray}>
              {t("adminDashboard.ordersOverview.paused")}
            </CardOrdersOverviewHeader>
            <CardOrdersOverviewContent>
              {data?.paused.length ? (
                data?.paused.map((row) => (
                  <CardOrdersOverviewRow key={row.id} row={row} />
                ))
              ) : (
                <div>-</div>
              )}
            </CardOrdersOverviewContent>
          </Card>
        </CardOrdersOverviews>
      )}
    </CardOrdersContainer>
  );
};

export default CardOrdersOverview;
