import React, { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import queueApi from "../../../../api/queue";
import { FiltersParams, Paged } from "../../../../models/common";
import { QueueHistory } from "../../../../models/queue";
import { FadeInBottom } from "../../../../styles/fadeIn";
import { getErrorMessage } from "../../../../utils/errors";
import Pagination from "../../../common/Pagination/Pagination";
import CardsAccesses from "../../../user/Cards/CardsAccesses/CardsAccesses";

const AdminClientsDetailHistory: FC = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [historyPaging, setHistoryPaging] = useState<FiltersParams>({
    page: 1,
    pageSize: 5,
  });
  const [historyData, setHistoryData] = useState<Paged<QueueHistory>>();
  const [loading, setLoading] = useState(true);

  const handleLoadHistory = useCallback(async () => {
    setLoading(true);
    try {
      const response = await queueApi.getUserHistory(id, historyPaging);
      setHistoryData(response.data);
    } catch (err) {
      toast.error(getErrorMessage(err, t("errors.loadError")));
    }
    setLoading(false);
  }, [historyPaging, id, t]);

  useEffect(() => {
    handleLoadHistory();
  }, [handleLoadHistory]);

  return (
    <FadeInBottom>
      <CardsAccesses data={historyData?.data} isLoading={loading} />
      <Pagination
        initialPage={historyPaging.page!}
        totalCount={historyData?.count ?? 0}
        pageSize={5}
        onPageChange={(n: number) =>
          setHistoryPaging({ ...historyPaging, page: n })
        }
      />
    </FadeInBottom>
  );
};

export default AdminClientsDetailHistory;
