import { User } from "../../../models/user";

export const transformUserData = (data: User, isCompany: boolean) => {
  return {
    ...data,
    companyName: isCompany ? data.companyName : "",
    companyNo: isCompany ? data.companyNo : "",
    companyVatNo: isCompany ? data.companyVatNo : "",
  };
};
