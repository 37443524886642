import React, { FC, Fragment } from "react";
import { H3 } from "../../../styles/title";
import {
  ContactCard,
  ContactCardContent,
  ContactCardHeader,
} from "../../../styles/card";
import { UserData } from "../../../models/user";
import { useTranslation } from "react-i18next";

const companyFields: Array<keyof UserData> = [
  "companyName",
  "companyNo",
  "companyVatNo",
];

const UserCompanyCard: FC<{ userInfo: UserData }> = ({ userInfo }) => {
  const { t } = useTranslation();

  return (
    <ContactCard>
      <ContactCardHeader>
        <H3>{t("userInfo.companyInfo")}</H3>
      </ContactCardHeader>
      <ContactCardContent>
        {companyFields.map((f) => (
          <Fragment key={f}>
            <span>{t(`userInfo.info.${f}`)}</span>
            <b>{userInfo[f]}</b>
          </Fragment>
        ))}
      </ContactCardContent>
    </ContactCard>
  );
};

export default UserCompanyCard;
