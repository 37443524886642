import { Formik } from "formik";
import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import * as Yup from "yup";
import orderApi from "../../../../../api/order";
import { BoxPrice } from "../../../../../models/box";
import { Order, OrderSubscriptionData } from "../../../../../models/order";
import { getErrorMessage } from "../../../../../utils/errors";
import { SetState } from "../../../../../utils/types";
import OrderEditSubscriptionModalContent from "./OrderEditSubscriptionModalContent";

const OrderEditSubscriptionModal: FC<{
  open: boolean;
  setOpen: SetState<boolean>;
  order: Order;
  boxPrice: BoxPrice;
  reload: () => Promise<void>;
  enableAutoExtend: boolean;
  setEnableAutoExtend: SetState<boolean>;
}> = ({
  open,
  setOpen,
  order,
  boxPrice,
  reload,
  enableAutoExtend,
  setEnableAutoExtend,
}) => {
  const { t } = useTranslation();

  const fields = [
    { lengthOfLeaseType: "1", price: boxPrice.price1WithVat, months: 1 },
    { lengthOfLeaseType: "2", price: boxPrice.price2WithVat, months: 3 },
    { lengthOfLeaseType: "3", price: boxPrice.price3WithVat, months: 6 },
    { lengthOfLeaseType: "4", price: boxPrice.price4WithVat, months: 12 },
    { lengthOfLeaseType: "5", price: boxPrice.price4WithVat * 2, months: 24 },
    { lengthOfLeaseType: "6", price: boxPrice.price4WithVat * 3, months: 36 },
    { lengthOfLeaseType: "7", price: boxPrice.price4WithVat * 4, months: 48 },
    { lengthOfLeaseType: "8", price: boxPrice.price4WithVat * 5, months: 60 },
    { lengthOfLeaseType: "9", price: boxPrice.price4WithVat * 6, months: 72 },
    { lengthOfLeaseType: "10", price: boxPrice.price4WithVat * 7, months: 84 },
  ];

  const handleSubmit = async (data: OrderSubscriptionData) => {
    try {
      order.autoExtend
        ? await orderApi.updateOrderSubscription(order.id, data)
        : enableAutoExtend
        ? await orderApi.updateOrderSubscription(order.id, {
            ...data,
            autoExtend: true,
          })
        : await orderApi.extendOrder(order.id, data);
      toast.success(t("common.saveSuccess"));
      setOpen(false);
      await reload();
    } catch (err) {
      toast.error(getErrorMessage(err, t("errors.saveError")));
    }
  };

  useEffect(() => {
    if (!open) {
      setEnableAutoExtend(false);
    }
  }, [open, setEnableAutoExtend]);

  return (
    <Formik
      initialValues={{
        lengthOfLeaseType: `${order.lengthOfLeaseType || 4}`,
        autoExtend: order.autoExtend,
      }}
      onSubmit={handleSubmit}
      validationSchema={Yup.object({
        lengthOfLeaseType: Yup.number().required(t("errors.required")),
      })}
    >
      <OrderEditSubscriptionModalContent
        open={open}
        setOpen={setOpen}
        fields={fields}
        enableAutoExtend={enableAutoExtend}
      />
    </Formik>
  );
};

export default OrderEditSubscriptionModal;
