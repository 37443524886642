import React, { FC } from "react";
import Page from "../../common/layout/Page/Page";
import AdminSidebar from "../AdminSidebar/AdminSidebar";
import { AdminDashboardCards, AdminDashboardCard } from "./styles";
import CardBoxOverview from "./CardBoxOverview/CardBoxOverview";
import CardEvents from "./CardEvents/CardEvents";
import CardBoxesOccupancy from "./CardBoxesOccupancy/CardBoxesOccupancy";
import CardOrdersOverview from "./CardOrdersOverview/CardOrdersOverview";
import { FadeInBottom } from "../../../styles/fadeIn";
import { useStore } from "../../../utils/store";

const AdminDashboard: FC = () => {
  const user = useStore((store) => store.user);

  return (
    <Page sidebar={<AdminSidebar />}>
      <FadeInBottom>
        <AdminDashboardCards>
          <AdminDashboardCard>
            <CardBoxOverview />
          </AdminDashboardCard>
          <AdminDashboardCard>
            <CardEvents />
          </AdminDashboardCard>
          <AdminDashboardCard>
            <CardBoxesOccupancy />
          </AdminDashboardCard>
          {(user?.role === "Admin" || user?.role === "Boss") && (
            <AdminDashboardCard>
              <CardOrdersOverview />
            </AdminDashboardCard>
          )}
        </AdminDashboardCards>
      </FadeInBottom>
    </Page>
  );
};

export default AdminDashboard;
