import React, { FC } from "react";
import { OrderSidebarDetails } from "../styles";
import { useTranslation } from "react-i18next";
import { H3 } from "../../../../styles/title";
import { BoxType } from "../../../../models/box";
import { PrimaryText } from "../../../../styles/text";
import { BoxTypeLabel } from "../../../common/BoxTypeLabel/styles";
import {
  OrderSidebarBoxDetails,
  OrderSidebarBoxH1,
  OrderSidebarBoxImg,
} from "./styles";
import { getBoxImg } from "../../../../utils/images";

const OrderSidebarBoxInfo: FC<{ boxType: BoxType }> = ({ boxType }) => {
  const { t } = useTranslation();

  return (
    <>
      <H3 color="var(--primary)">{t("sidebar.order.selected.title")}</H3>
      <OrderSidebarBoxH1 bold>
        <BoxTypeLabel>{boxType.type}</BoxTypeLabel>
        <span>{t("common.box")}</span>
      </OrderSidebarBoxH1>
      <OrderSidebarBoxImg src={getBoxImg(boxType.type)} />
      <OrderSidebarDetails>
        <OrderSidebarBoxDetails>
          <PrimaryText>{t("sidebar.order.selected.dimensions")}:</PrimaryText>
          <b>{boxType.dimensions}</b>
        </OrderSidebarBoxDetails>
        <OrderSidebarBoxDetails>
          <PrimaryText>{t("sidebar.order.selected.volume")}:</PrimaryText>
          <b>{boxType.volume}</b>
        </OrderSidebarBoxDetails>
        <OrderSidebarBoxDetails>
          <PrimaryText>{t("sidebar.order.selected.loadCapacity")}:</PrimaryText>
          <b>{boxType.loadCapacity}</b>
        </OrderSidebarBoxDetails>
      </OrderSidebarDetails>
    </>
  );
};

export default OrderSidebarBoxInfo;
