import styled from "styled-components";
import { COLORS } from "../../../../utils/constants";

export const CardAccessRowName = styled.div<{ isActive?: boolean }>`
  color: ${({ isActive }) => (isActive ? COLORS.primary : COLORS.secondary)};
`;

export const CardAccessRow = styled.div`
  display: flex;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 0.75rem;
  }

  ${CardAccessRowName} {
    svg {
      margin-right: 0.75rem;
    }
  }
`;
