import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { AdditionalService } from "../../../models/additionalServices";
import { BoxType } from "../../../models/box";
import { OrderFormData } from "../../../models/order";
import { GreenText, PrimaryText } from "../../../styles/text";
import { formatCurrency } from "../../../utils/formats";
import {
  getBaseTotalPriceWithVat,
  getBoxPriceWithVat,
  getLeaseMonths,
  getPromoCodeDiscountWithVat,
} from "../priceUtils";
import OrderSidebarBoxInfo from "./OrderSidebarBoxInfo/OrderSidebarBoxInfo";
import OrderSidebarNoBox from "./OrderSidebarNoBox/OrderSidebarNoBox";
import OrderSidebarTotalPrice from "./OrderSidebarTotalPrice/OrderSidebarTotalPrice";
import {
  OrderSidebarContent,
  OrderSidebarDetails,
  OrderSidebarParameter,
} from "./styles";

const OrderSidebar: FC<{
  values: OrderFormData;
  boxType: BoxType | null;
  additionalServices: AdditionalService[];
}> = ({ values, boxType, additionalServices }) => {
  const { t } = useTranslation();

  return (
    <OrderSidebarContent noBox={!boxType}>
      {!boxType ? (
        <OrderSidebarNoBox />
      ) : (
        <>
          <OrderSidebarBoxInfo boxType={boxType} />
          {values.lengthOfLeaseType && (
            <OrderSidebarDetails>
              <OrderSidebarParameter>
                <PrimaryText>
                  {t("sidebar.order.selected.lengthOfLeaseType")}
                </PrimaryText>
              </OrderSidebarParameter>
              <OrderSidebarParameter>
                <b>
                  {t(
                    `order.parameters.lengthOfLeaseType.${values.lengthOfLeaseType}`
                  )}
                </b>
                <GreenText>
                  {formatCurrency(
                    getBoxPriceWithVat(
                      values.lengthOfLeaseType,
                      boxType.boxPriceDto
                    )
                  )}
                </GreenText>
              </OrderSidebarParameter>
            </OrderSidebarDetails>
          )}
          <OrderSidebarDetails>
            <OrderSidebarParameter>
              <PrimaryText>
                {t("sidebar.order.selected.parameters")}
              </PrimaryText>
            </OrderSidebarParameter>
            {values.fingerPrint && (
              <OrderSidebarParameter>
                <b>{t(`order.parameters.services.fingerPrint`)}</b>
                <GreenText>
                  +{" "}
                  {formatCurrency(
                    boxType.boxPriceDto.fingerPrintWithVat *
                      getLeaseMonths(values.lengthOfLeaseType)
                  )}
                </GreenText>
              </OrderSidebarParameter>
            )}
            {values.smsCheck && (
              <OrderSidebarParameter>
                <b>{t(`order.parameters.services.smsCheck`)}</b>
                <GreenText>
                  +{" "}
                  {formatCurrency(
                    boxType.boxPriceDto.smsCheckWithVat *
                      getLeaseMonths(values.lengthOfLeaseType)
                  )}
                </GreenText>
              </OrderSidebarParameter>
            )}
            {values.additionalServices.map((id) => (
              <OrderSidebarParameter key={"additionalService" + id}>
                <b>{additionalServices.find((x) => x.id === id)!.name}</b>
                <GreenText>
                  +{" "}
                  {formatCurrency(
                    boxType.boxPriceDto.additionalServices.find(
                      (x) => x.id === id
                    )!.priceWithVat * getLeaseMonths(values.lengthOfLeaseType)
                  )}
                </GreenText>
              </OrderSidebarParameter>
            ))}

            <OrderSidebarParameter>
              <b>{t(`order.parameters.services.guarantee`)}</b>
              <GreenText>
                + {formatCurrency(boxType.boxPriceDto.guaranteeWithVat)}
                {t("common.price.oneTimeWithVat")}
              </GreenText>
            </OrderSidebarParameter>
          </OrderSidebarDetails>

          <OrderPromoCodeDiscount
            discount={values.discount ?? 0}
            totalPrice={getBaseTotalPriceWithVat(values, boxType.boxPriceDto)}
          />

          <OrderSidebarTotalPrice
            values={values}
            boxType={boxType}
            additionalServices={values.additionalServices}
          />
        </>
      )}
    </OrderSidebarContent>
  );
};

const OrderPromoCodeDiscount: FC<{
  discount: number;
  totalPrice: number;
}> = ({ discount, totalPrice }) => {
  const { t } = useTranslation();

  const discountWithVat: number =
    totalPrice - getPromoCodeDiscountWithVat(totalPrice, discount);

  return (
    <OrderSidebarDetails>
      {discount > 0 && (
        <>
          <OrderSidebarParameter>
            <PrimaryText>{t("sidebar.order.selected.promoCode")}</PrimaryText>
          </OrderSidebarParameter>

          <OrderSidebarParameter>
            <b>{discount}%</b>
            <GreenText>
              - {formatCurrency(discountWithVat.toFixed(2))}
              {t("common.price.oneTimeWithVat")}
            </GreenText>
          </OrderSidebarParameter>
        </>
      )}
    </OrderSidebarDetails>
  );
};

export default OrderSidebar;
