import styled from "styled-components";
import { Table } from "../../../../styles/table";

export const DocumentsTableContainer = styled(Table)`
  td:first-child {
    svg {
      margin-right: 0.5rem;
    }
  }

  th:nth-child(3) {
    width: max-content;
  }
`;

export const CenterColumn = styled.div`
  margin: 0 auto;
`;
