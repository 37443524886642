import { Formik } from "formik";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import * as Yup from "yup";
import orderApi from "../../../../../api/order";
import { getErrorMessage } from "../../../../../utils/errors";
import { SetState } from "../../../../../utils/types";
import OrderEditNameModalContent from "./OrderEditNameModalContent";

const OrderEditNameModal: FC<{
  open: boolean;
  setOpen: SetState<boolean>;
  orderId: string;
  orderName: string;
  reload: () => Promise<void>;
}> = ({ open, setOpen, orderId, orderName, reload }) => {
  const { t } = useTranslation();

  const handleSubmit = async ({ name }: { name: string }) => {
    try {
      await orderApi.updateOrderName(orderId, name);
      toast.success(t("common.saveSuccess"));
      setOpen(false);
      await reload();
    } catch (err) {
      toast.error(getErrorMessage(err, t("errors.saveError")));
    }
  };

  return (
    <Formik
      initialValues={{ name: orderName }}
      validationSchema={Yup.object({
        name: Yup.string().required(t("errors.required")),
      })}
      onSubmit={handleSubmit}
    >
      <OrderEditNameModalContent open={open} setOpen={setOpen} />
    </Formik>
  );
};

export default OrderEditNameModal;
