import { Link } from "react-router-dom";
import styled from "styled-components";
import { COLORS } from "../../../../utils/constants";
import { size } from "../../../../utils/styles";

export const AdminBoxesSettingsTrafficDescTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  @media screen and (min-width: ${size.tablet}) {
    flex-direction: row;
  }
`;

export const AdminBoxesSettingsTrafficDescTitle = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0.75rem 0;

  @media screen and (min-width: ${size.tablet}) {
    margin: 0;
    flex-direction: row;
  }

  h3 {
    display: inline-block;
    font-size: 1.5rem;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 0.3rem;

    @media screen and (min-width: ${size.mobile}) {
      margin-right: 1rem;
      margin-bottom: 0;
    }
  }

  span {
    display: inline-block;
    font-weight: 700;
    padding: 0.5rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.5rem;
    color: ${COLORS.white};
    background-color: ${COLORS.primary};
  }
`;

export const AdminBoxesSettingsTrafficDescDetailLink = styled(Link)`
  @media screen and (min-width: ${size.tablet}) {
    margin-left: 1rem;
  }
`;

export const AdminBoxesSettingsTrafficDescGrid = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  align-items: center;
  row-gap: 1rem;
  column-gap: 1.5rem;
  margin: 1rem 0;

  @media screen and (min-width: ${size.desktopL}) {
    grid-template-columns: auto auto;
  }
`;

export const AdminBoxesSettingsTrafficDescLabel = styled.div`
  color: ${COLORS.primary};
`;

export const AdminBoxesSettingsTrafficDescPrice = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  > div {
    font-size: 0.8rem;
  }

  @media screen and (min-width: ${size.tablet}) {
    font-size: 1rem;
    flex-direction: row;
    flex-grow: initial;
  }
`;

export const AdminBoxesSettingsTrafficDescPriceBox = styled.div`
  padding: 0.5rem 0.75rem;
  text-align: center;
  background-color: ${COLORS.white};
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${COLORS.whitesmoke};

  @media screen and (min-width: ${size.tablet}) {
    display: block;
    min-width: 6rem;
    width: 100%;
    border-bottom: none;

    &:not(:last-child) {
      border-right: 1px solid ${COLORS.whitesmoke};
    }
  }

  span {
    display: block;
    min-width: initial;
  }

  strong {
    display: block;
    color: ${COLORS.green};
  }
`;

export const AdminBoxesSettingsTrafficDescForm = styled.div`
  display: flex;
  align-items: center;

  input {
    width: 5rem;
    text-align: center;
  }

  input:disabled {
  }
`;
