import React, { FC } from "react";
import { CalendarCustomerTimeState } from "../../../../utils/enums";
import CustomerCellEvent from "../CustomerCellEvent/CustomerCellEvent";
import {
  CalendarCell,
  CalendarCellReservationButton,
} from "../../../../styles/calendar";
import { useTranslation } from "react-i18next";

const CustomerCalendarCell: FC<{
  timeState: keyof typeof CalendarCustomerTimeState;
  isReserved: boolean;
  onClickReservation: (event: React.MouseEvent<HTMLDivElement>) => void;
}> = ({ timeState, isReserved, onClickReservation }) => {
  const { t } = useTranslation();
  const isAvailable = timeState === "Available";

  return (
    <CalendarCell
      onClick={isAvailable && !isReserved ? onClickReservation : undefined}
      pointer={isAvailable && !isReserved}
    >
      <CustomerCellEvent timeState={isReserved ? "Reserved" : timeState} />
      {isAvailable && !isReserved && (
        <CalendarCellReservationButton role="button">
          <div>{t("reservation.calendar.free")}</div>
        </CalendarCellReservationButton>
      )}
    </CalendarCell>
  );
};

export default CustomerCalendarCell;
