import { Formik } from "formik";
import libphonenumber from "google-libphonenumber";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { User } from "../../../models/user";
import { SetState } from "../../../utils/types";
import { isCompanyNoValid, isCompanyVatNoValid } from "../../order/formUtils";
import UserInfoModalContent from "./UserInfoModalContent";

const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();

const UserInfoModal: FC<{
  open: boolean;
  setOpen: SetState<boolean>;
  handleSubmit: any;
  loading?: boolean;
  user: User;
  showNote?: boolean;
}> = ({ open, setOpen, handleSubmit, loading, user, showNote }) => {
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={{ ...user, isCompany: !!user.companyName }}
      enableReinitialize
      onSubmit={(data) => handleSubmit(data, data.isCompany)}
      validationSchema={Yup.object({
        firstName: Yup.string().required(t("errors.required")),
        lastName: Yup.string().required(t("errors.required")),
        street: Yup.string().required(t("errors.required")),
        city: Yup.string().required(t("errors.required")),
        zipCode: Yup.string().required(t("errors.required")),
        country: Yup.string().required(t("errors.required")),
        email: Yup.string()
          .email(t("errors.email"))
          .required(t("errors.required")),
        phone: Yup.string()
          .test("phone", t("errors.phone"), (value) => {
            if (!value) return true;
            try {
              const number = phoneUtil.parse(value);
              if (!phoneUtil.isValidNumber(number)) return false;
            } catch (err) {
              return false;
            }
            return true;
          })
          .required(t("errors.required")),

        companyName: Yup.string()
          .nullable()
          .when("isCompany", {
            is: true,
            then: Yup.string().required(t("errors.required")),
          }),
        companyNo: Yup.string()
          .nullable()
          .when("isCompany", {
            is: true,
            then: Yup.string()
              .required(t("errors.required"))
              .test("isCompanyNoValid", t("errors.required"), isCompanyNoValid),
          }),
        companyVatNo: Yup.string()
          .nullable()
          .when("isCompany", {
            is: true,
            then: Yup.string().test(
              "isCompanyVatNoValid",
              t("errors.required"),
              isCompanyVatNoValid
            ),
          }),
      })}
    >
      <UserInfoModalContent
        open={open}
        setOpen={setOpen}
        loading={loading}
        showNote={showNote}
      />
    </Formik>
  );
};

export default UserInfoModal;
