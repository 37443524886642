import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { Table } from "../../../styles/table";
import { COLORS } from "../../../utils/constants";

export const AdditionalServicesTable = styled(Table)`
  th:nth-child(4) {
    width: max-content;
  }

  td: nth-child(2) {
    text-align: center;
  }

  td: nth-child(3) {
    font-weight: bold;
    white-space: nowrap;
  }
`;

export const AdditionalServiceState = styled.span<{ isActive: boolean }>`
  color: ${({ isActive }) => (isActive ? COLORS.green : COLORS.red)};
  font-weight: bold;
`;

export const IconSelector = styled.div`
  height: 12rem;
  overflow-y: scroll;
  overflow-x: hidden;
`;

export const IconPreview = styled.div`
  width: 64px;
  height: 64px;
`;

export const SearchContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 15rem;
`;

export const IconItem = styled(FontAwesomeIcon)`
  margin: 0.25rem;
  cursor: pointer;
`;
