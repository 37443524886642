import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useDebounce } from "usehooks-ts";
import userApi from "../../../../api/user";
import { FiltersSearchParams, Paged } from "../../../../models/common";
import { UserList } from "../../../../models/user";
import { Button } from "../../../../styles/button";
import { FadeInBottom } from "../../../../styles/fadeIn";
import { PageSection } from "../../../../styles/layout";
import { TableHeaderActions } from "../../../../styles/table";
import { H2 } from "../../../../styles/title";
import { useUserHasRight } from "../../../../utils/auth";
import {
  COLORS,
  DEBOUNCE_TIMEOUT_MS,
  DEFAULT_PAGE_SIZE,
} from "../../../../utils/constants";
import { getErrorMessage } from "../../../../utils/errors";
import { useAdminEmployeesFilterStore } from "../../../../utils/store";
import {
  OnChangeOrder,
  OrderType,
  WithRequired,
} from "../../../../utils/types";
import FilterTextInput from "../../../common/form/Filters/FilterTextInput";
import { FilterContainer } from "../../../common/form/Filters/styles";
import Pagination from "../../../common/Pagination/Pagination";
import AdminEmployeesTable from "../AdminEmloyeesTable/AdminEmloyeesTable";

export interface AdminEmployeesFilter
  extends WithRequired<FiltersSearchParams, "page"> {
  name?: string;
  email?: string;
}

export const DEFAULT_ADMIN_EMPLOYEES_FILTER: AdminEmployeesFilter = { page: 1 };

const AdminEmployeesContent: FC = () => {
  const { t } = useTranslation();
  const { hasUserAllRights } = useUserHasRight();
  const [loading, setLoading] = useState<boolean>(true);
  const [employeesData, setEmployeesData] = useState<Paged<UserList>>({
    count: 0,
    data: [],
  });

  const { filters, setFilters } = useAdminEmployeesFilterStore();
  const debouncedFilters = useDebounce(filters, DEBOUNCE_TIMEOUT_MS);

  const order: OrderType = useMemo(
    () => ({ orderBy: filters.orderBy, orderDesc: filters.orderDesc }),
    [filters.orderBy, filters.orderDesc]
  );
  const handleChangeOrder = useCallback<OnChangeOrder>(
    (orderBy, orderDesc) => {
      setFilters({ ...filters, orderBy, orderDesc });
    },
    [filters, setFilters]
  );

  const handleResetFilter = useCallback(() => {
    setFilters(DEFAULT_ADMIN_EMPLOYEES_FILTER);
  }, [setFilters]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const response = await userApi.getEmployees({
          ...(debouncedFilters.name && { name: debouncedFilters.name }),
          ...(debouncedFilters.email && { email: debouncedFilters.email }),
          ...(debouncedFilters.orderBy && {
            orderBy: debouncedFilters.orderBy,
          }),
          ...(debouncedFilters.orderDesc && {
            orderDesc: debouncedFilters.orderDesc,
          }),
          pageSize: DEFAULT_PAGE_SIZE,
          page: debouncedFilters.page,
        });
        setEmployeesData(response.data);
      } catch (err) {
        toast.error(getErrorMessage(err, t("errors.loadError")));
      }
      setLoading(false);
    })();
  }, [t, debouncedFilters]);

  return (
    <FadeInBottom>
      <PageSection>
        <H2>{t("adminEmployees.title")}</H2>
        <TableHeaderActions>
          <div>
            {hasUserAllRights(["createEmployee"]) && (
              <Button as={Link} to={"/admin/settings/employees/new"}>
                <FontAwesomeIcon icon={faPlus} color={COLORS.white} />
                {t("adminEmployees.newEmployee")}
              </Button>
            )}
          </div>
        </TableHeaderActions>
        <TableHeaderActions>
          <FilterContainer>
            <FilterTextInput
              value={filters.name}
              label={t("adminClients.filter.name")}
              onInputChange={(value) => {
                setFilters({
                  ...filters,
                  name: value,
                  page: 1,
                });
              }}
            />
            <FilterTextInput
              value={filters.email}
              label={t("adminClients.filter.email")}
              onInputChange={(value) => {
                setFilters({
                  ...filters,
                  email: value,
                  page: 1,
                });
              }}
            />
            <Button
              onClick={handleResetFilter}
              style={{ alignSelf: "flex-end" }}
            >
              {t("common.resetFilter")}
            </Button>
          </FilterContainer>
        </TableHeaderActions>
        <TableHeaderActions>
          <b>
            {(!loading || employeesData.count > 0) &&
              `${employeesData.count} ${t("adminEmployees.countTextPlural")}`}
          </b>
        </TableHeaderActions>
        <FadeInBottom>
          <AdminEmployeesTable
            isLoading={loading}
            data={employeesData.data}
            order={order}
            onChangeOrder={handleChangeOrder}
          />
          <Pagination
            initialPage={filters.page}
            totalCount={employeesData.count}
            onPageChange={(n: number) => setFilters({ ...filters, page: n })}
          />
        </FadeInBottom>
      </PageSection>
    </FadeInBottom>
  );
};

export default AdminEmployeesContent;
