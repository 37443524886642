import styled, { css } from "styled-components";
import { COLORS } from "../utils/constants";
import { size } from "../utils/styles";

export const InputRow = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;

  & > div {
    width: 100%;
    display: flex;
  }

  label {
    margin-bottom: 0.5rem;
  }

  @media screen and (min-width: ${size.mobile}) {
    align-items: center;
    flex-flow: row;

    label {
      margin-bottom: 0;
      margin-right: 1rem;
    }
  }
`;

export const InputContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;

  /* Hide arrows on number type. */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
  }

  /* Hide arrows on number type. */
  input[type="number"] {
    -moz-appearance: textfield;
  }
`;

export const FormGroup = styled.div<{ labelWidth?: string }>`
  width: 100%;
  max-width: 30rem;

  > :not(:last-child) {
    margin-bottom: 0.75rem;
  }

  @media screen and (min-width: ${size.mobile}) {
    ${InputRow} label {
      width: ${({ labelWidth }) => labelWidth || "7rem"};
    }
  }
`;

export const FormGroupTooltips = styled(FormGroup)`
  width: calc(100% - 4rem);
`;

export const FormRow = styled.div<{ mb?: string }>`
  position: relative;
  width: 100%;
  margin-bottom: ${({ mb }) => `${mb} !important` || "inherit"};
`;

export const FormActions = styled.div`
  width: 100%;
  text-align: end;
`;

export const textInput = (props: {
  $error?: boolean;
  width?: string;
  height?: string;
  markposition?: "hidden" | "left" | "right";
}) => css`
  height: ${props.height || "2.5rem"};
  width: ${props.width || "100%"};

  ${() => {
    if (!props.markposition || props.markposition === "hidden")
      return css`
        padding: 0.5rem 0.75rem;
      `;
    return props.markposition === "left"
      ? css`
          padding: 0.5rem 0.75rem 0.5rem 2.75rem;
        `
      : css`
          padding: 0.5rem 2.75rem 0.5rem 0.75rem;
        `;
  }};

  border: 1px solid ${props.$error ? COLORS.red : COLORS.secondaryDark};
  border-radius: 3px;
  outline: none;
  color: ${COLORS.fontColor};
  font-size: 1rem;

  transition: all 0.15s ease-in-out;

  &:focus {
    border-color: ${COLORS.primaryDark};
    box-shadow: 0 0 3px 0 ${COLORS.primary};
  }

  &:disabled {
    background-color: ${COLORS.secondary} !important;
    color: ${COLORS.grayLight} !important;
  }
`;
