import React, { FC, useCallback, useEffect, useState } from "react";
import { FiltersParams, Paged } from "../../../models/common";
import Pagination from "../../common/Pagination/Pagination";
import UserSidebar from "../UserSidebar/UserSidebar";
import DashboardInvoices from "./DashboardInvoices/DashboardInvoices";
import MeetingsTable from "./DashboardMeetings/MeetingsTable";
import CardsAccesses from "../Cards/CardsAccesses/CardsAccesses";
import Page from "../../common/layout/Page/Page";
import { DashboardListCard, ExpiringOrderWarning } from "./styles";
import OrdersTable from "../Orders/OrdersTable/OrdersTable";
import { H2 } from "../../../styles/title";
import { useTranslation } from "react-i18next";
import { PageSection } from "../../../styles/layout";
import orderApi from "../../../api/order";
import { toast } from "react-toastify";
import { Order } from "../../../models/order";
import Loader from "../../common/Loader/Loader";
import { FadeInBottom } from "../../../styles/fadeIn";
import OrdersTableHeader from "../Orders/OrdersTableHeader/OrdersTableHeader";
import { Invoice } from "../../../models/document";
import documentApi from "../../../api/document";
import queueApi from "../../../api/queue";
import { QueueHistory } from "../../../models/queue";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { COLORS } from "../../../utils/constants";
import { formatDate } from "../../../utils/formats";
import calendarApi from "../../../api/calendar";
import { CalendarOverview } from "../../../models/calendar";
import storageApi from "../../../api/storage";

const Dashboard: FC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [loadingHistory, setLoadingHistory] = useState(true);
  const [historyPaging, setHistoryPaging] = useState<FiltersParams>({
    page: 1,
    pageSize: 5,
  });
  const [data, setData] = useState<{
    orders: Order[];
    invoices: Invoice[];
    history?: Paged<QueueHistory>;
    meetings: CalendarOverview[];
  }>({
    orders: [],
    invoices: [],
    meetings: [],
  });
  const [expiringOrder, setExpiringOrder] = useState<Order>();

  const loadData = useCallback(async () => {
    try {
      const [orders, invoices, meetings] = await Promise.all([
        orderApi.getUserOrders(storageApi.getCurrentUser()!.id),
        documentApi.getUserInvoices(storageApi.getCurrentUser()!.id),
        calendarApi.getUserCalendarOverview(storageApi.getCurrentUser()!.id),
      ]);
      setData((prevState) => ({
        ...prevState,
        orders: orders.data.slice(0, 5),
        invoices: invoices.data.slice(0, 5),
        meetings: meetings.data.data,
      }));
      const expOrder = orders.data.find((o) => o.soonExpires);
      setExpiringOrder(expOrder);
    } catch (err) {
      toast.error(t("errors.orders"));
    }
    setLoading(false);
  }, [t]);

  const loadHistoryData = useCallback(async () => {
    setLoadingHistory(true);
    try {
      const historyData = await queueApi.getUserHistory(
        storageApi.getCurrentUser()!.id,
        historyPaging
      );
      setData((prevState) => ({ ...prevState, history: historyData.data }));
    } catch (err) {
      toast.error(t("errors.orders"));
    }
    setLoadingHistory(false);
  }, [historyPaging, t]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    loadHistoryData();
  }, [loadData, loadHistoryData]);

  return (
    <Page sidebar={<UserSidebar />}>
      <FadeInBottom mb="2rem">
        {loading ? (
          <Loader />
        ) : (
          <>
            <PageSection>
              <OrdersTableHeader dashboard />
              {expiringOrder && (
                <ExpiringOrderWarning>
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    color={COLORS.orange}
                  />
                  {t("dashboard.orders.expiringWarning", {
                    orderName: expiringOrder.name,
                    dateTo: formatDate(expiringOrder.dateTo),
                  })}
                </ExpiringOrderWarning>
              )}
              <OrdersTable data={data.orders} />
            </PageSection>
            <DashboardListCard>
              <DashboardInvoices data={data.invoices.slice(0, 3)} />
            </DashboardListCard>
            <DashboardListCard>
              <MeetingsTable
                data={data.meetings}
                canCancel={data.orders.some((o) => !!o.cards.length)}
                reload={loadData}
              />
            </DashboardListCard>
          </>
        )}
        <PageSection>
          <H2>{t("dashboard.accesses.title")}</H2>
          <CardsAccesses
            flat
            data={data.history?.data}
            isLoading={loadingHistory}
          />
          <Pagination
            initialPage={historyPaging.page!}
            totalCount={data.history?.count ?? 0}
            pageSize={5}
            onPageChange={(n: number) =>
              setHistoryPaging({ ...historyPaging, page: n })
            }
          />
        </PageSection>
      </FadeInBottom>
    </Page>
  );
};

export default Dashboard;
