import styled from "styled-components";
import { size } from "../../../../utils/styles";

export const PageContainer = styled.main`
  height: calc(100vh - var(--header-height));
  display: grid;
  grid-template-columns: 1fr;

  @media screen and (min-width: ${size.laptop}) {
    grid-template-columns: auto 1fr;
  }
`;

export const PageContent = styled.div`
  width: 100%;
  overflow-y: auto;
`;

export const PageContentWrapper = styled.div`
  position: relative;

  height: auto;
  min-height: calc(100vh - var(--header-height));
  width: 100%;
  max-width: ${size.desktop};

  margin: 0 auto;
  padding: 1.5rem;

  @media screen and (min-width: ${size.tablet}) {
    padding: 2rem;
  }
`;

export const PageContainerNoSidebar = styled.main`
  height: calc(100vh - var(--header-height));

  padding: 1rem;

  display: flex;
  justify-content: center;
  align-items: center;
`;
