import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { CalendarAdminTime } from "../../../../models/calendar";
import { Table } from "../../../../styles/table";
import { H4 } from "../../../../styles/title";
import { CalendarAdminTimeState } from "../../../../utils/enums";
import { formatDateTime } from "../../../../utils/formats";
import { SetState } from "../../../../utils/types";
import CustomModal from "../../../common/CustomModal/CustomModal";
import { AdminEventsModalUser } from "./styles";

const AdminEventsModal: FC<{
  open: boolean;
  setOpen: SetState<boolean>;
  modalMode: CalendarAdminTimeState;
  modalData: CalendarAdminTime;
}> = ({ open, setOpen, modalMode, modalData }) => {
  const { t } = useTranslation();
  const isAvailableModalMode = modalMode === CalendarAdminTimeState.Available;

  const users = useMemo(
    () =>
      modalData.users.map(
        (user) =>
          isAvailableModalMode === user.isAvailable &&
          (isAvailableModalMode ? (
            <AdminEventsModalUser key={user.employee?.id}>
              <Link to={`/admin/settings/employees/${user.employee?.id}`}>
                {user.employee?.firstName} {user.employee?.lastName}
              </Link>
            </AdminEventsModalUser>
          ) : (
            <tr>
              <td>
                <Link to={`/admin/settings/employees/${user.employee?.id}`}>
                  {user.employee?.firstName} {user.employee?.lastName}
                </Link>
              </td>
              <td>
                <Link to={`/admin/clients/${user.customer?.id}`}>
                  {user.customer?.firstName} {user.customer?.lastName}
                </Link>
              </td>
              <td>
                <Link to={`/admin/orders/${user.orderId}`}>
                  {user.orderName}
                </Link>
              </td>
              <td>{user.note}</td>
            </tr>
          ))
      ),
    [modalData, isAvailableModalMode]
  );

  return (
    <CustomModal
      title={t(`adminCalendarScene.eventsModal.title${modalMode}`)}
      open={open}
      setOpen={setOpen}
    >
      <H4 bold>{formatDateTime(modalData.dateTime)}</H4>
      {isAvailableModalMode ? (
        users
      ) : (
        <Table>
          <thead>
            <tr>
              <td>{t("adminCalendarScene.eventsModal.employee")}</td>
              <td>{t("adminCalendarScene.eventsModal.client")}</td>
              <td>{t("adminCalendarScene.eventsModal.order")}</td>
              <td>{t("adminCalendarScene.eventsModal.note")}</td>
            </tr>
          </thead>
          {users}
        </Table>
      )}
    </CustomModal>
  );
};

export default AdminEventsModal;
