import React, { FC } from "react";
import Page from "../../common/layout/Page/Page";
import AdminSidebar from "../AdminSidebar/AdminSidebar";
import AdminClientsContent from "./AdminClientsContent/AdminClientsContent";

const AdminClients: FC = () => (
  <Page sidebar={<AdminSidebar />}>
    <AdminClientsContent />
  </Page>
);

export default AdminClients;
