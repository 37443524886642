import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import PageNoSidebar from "../../common/layout/Page/PageNoSidebar";
import { LoginButton, LoginCard, LoginContainer } from "../login/styles";
import { H1 } from "../../../styles/title";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { FormGroup } from "../../../styles/form";
import Input from "../../common/form/Input/Input";
import { ResetPasswordData } from "../../../models/auth";
import { toast } from "react-toastify";
import authApi from "../../../api/auth";
import { useHistory } from "react-router";
import storageApi from "../../../api/storage";
import { TOKEN_KEY } from "../../../utils/constants";
import { useStore } from "../../../utils/store";

const initialValues: ResetPasswordData = {
  newPassword: "",
  newPasswordRepeat: "",
};

const fields: Array<keyof ResetPasswordData> = [
  "newPassword",
  "newPasswordRepeat",
];

const ResetPassword: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { token } = useParams<{ token: string }>();
  const [setUser] = useStore((store) => [store.setUser]);

  const handleSubmit = async (data: ResetPasswordData) => {
    try {
      storageApi.setToken(token);
      await authApi.resetPassword(data);
      toast.success(t("forgottenPassword.resetSuccess"));
      localStorage.removeItem(TOKEN_KEY);
      setTimeout(() => history.push("/login"), 4000);
    } catch (err) {
      toast.error(t("errors.resetPassword"));
    }
  };

  useEffect(() => {
    setUser(null);
    localStorage.clear();
    // don't fire on `setUser` change
    // eslint-disable-next-line
  }, []);

  return (
    <PageNoSidebar>
      <LoginContainer>
        <H1 bold uppercase>
          {t("forgottenPassword.title")}
        </H1>
        <span>{t("forgottenPassword.subtitle")}</span>
        <LoginCard>
          <Formik
            initialValues={initialValues}
            validationSchema={Yup.object({
              newPassword: Yup.string()
                .matches(
                  /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
                  t("errors.passwordStrength")
                )
                .required(t("errors.required")),
              newPasswordRepeat: Yup.string()
                .required(t("errors.required"))
                .oneOf([Yup.ref("newPassword")], t("errors.passwordMismatch")),
            })}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, isValid, isSubmitting }) => (
              <Form>
                <FormGroup labelWidth="13rem">
                  {fields.map((f) => (
                    <Input
                      key={f}
                      name={f}
                      type="password"
                      label={t(`forgottenPassword.${f}`)}
                      error={touched[f] && !!errors[f]}
                    />
                  ))}
                </FormGroup>
                <LoginButton type="submit" disabled={!isValid || isSubmitting}>
                  {t("common.save")}
                </LoginButton>
              </Form>
            )}
          </Formik>
        </LoginCard>
      </LoginContainer>
    </PageNoSidebar>
  );
};

export default ResetPassword;
