import styled from "styled-components";
import { COLORS } from "../../../utils/constants";
import { Link } from "react-router-dom";

export const LinksWrapper = styled.div`
  background-color: var(--black);
`;

export const AdminSidebarFooter = styled.div`
  display: flex;
  align-items: center;

  background-color: var(--green);
  color: var(--white);

  padding: 1.2rem 1rem;
  text-transform: uppercase;
`;

export const AdminFooterText = styled(Link)`
  color: ${COLORS.secondary};
  text-decoration: none;

  svg {
    margin-right: 0.5rem;
  }

  &:hover {
    color: ${COLORS.white};

    svg {
      color: ${COLORS.white};
    }
  }
`;
