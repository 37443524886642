import styled from "styled-components";
import { TableFlat } from "../../../../styles/table";

export const OrdersHistoryTable = styled(TableFlat)`
  th,
  td {
    padding-right: 3rem;

    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5) {
      padding-right: 1rem;
    }
  }
`;
