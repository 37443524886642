import styled from "styled-components";
import { size } from "../../../../utils/styles";
import { Button } from "../../../../styles/button";

export const AdminEmployeesDetailFormWrapper = styled.div`
  margin-bottom: 0.75rem;
`;

export const AdminEmployeesDetailFormRow = styled.div`
  @media screen and (min-width: ${size.tablet}) {
    display: flex;
    margin: -0.75rem;
  }
`;

export const AdminEmployeesDetailFormCol = styled.div`
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 0.75rem;
  }

  @media screen and (min-width: ${size.tablet}) {
    margin: 0.75rem;
  }
`;

export const AdminEmployeesDetailCheckboxRow = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;

  @media screen and (min-width: ${size.mobile}) {
    align-items: center;
    flex-flow: row;
    padding: 0.75rem 0;
  }
`;

export const AdminEmployeesDetailCheckboxCol = styled.div<{ hidden?: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 0.5rem;

  &:first-of-type {
    width: 7rem;
  }

  @media screen and (min-width: ${size.mobile}) {
    margin-bottom: 0;
    margin-right: 1.3rem;
  }
`;

export const AdminEmployeesDetailControls = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  padding-top: 0.5rem;

  ${Button}:not(:last-child) {
    margin-bottom: 0.5rem;
  }

  @media screen and (min-width: ${size.mobile}) {
    flex-flow: row;

    ${Button}:not(:last-child) {
      margin-right: 0.5rem;
      margin-bottom: 0;
    }
  }
`;
