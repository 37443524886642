import { faCalendarAlt, faCheck } from "@fortawesome/free-solid-svg-icons";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import {
  CalendarCellEventIcon,
  CalendarCellEventText,
  CalendarCellEventUnavailable,
  CalendarCellEventReserved,
  CalendarCellWrapperClosed,
  CalendarCellEventWrapper,
  CalendarCellWrapperPast,
} from "../../../../styles/calendar";
import { CalendarCustomerTimeState } from "../../../../utils/enums";

const CustomerCellEvent: FC<{
  timeState: keyof typeof CalendarCustomerTimeState | "Reserved";
}> = ({ timeState }) => {
  const { t } = useTranslation();

  switch (timeState) {
    case "Available":
      return null;
    case "Unavailable":
      return (
        <CalendarCellEventWrapper>
          <CalendarCellEventUnavailable>
            <CalendarCellEventText>
              <CalendarCellEventIcon icon={faCalendarAlt} size={"xs"} />
              {t("reservation.calendar.unavailable")}
            </CalendarCellEventText>
          </CalendarCellEventUnavailable>
        </CalendarCellEventWrapper>
      );
    case "Closed":
      return (
        <CalendarCellWrapperClosed>
          <CalendarCellEventText>
            {t("adminCalendarScene.calendar.closed")}
          </CalendarCellEventText>
        </CalendarCellWrapperClosed>
      );
    case "Past":
      return <CalendarCellWrapperPast />;
    case "Reserved":
      return (
        <CalendarCellEventWrapper>
          <CalendarCellEventReserved>
            <CalendarCellEventText>
              <CalendarCellEventIcon icon={faCheck} size={"xs"} />
              {t("reservation.calendar.reserved")}
            </CalendarCellEventText>
          </CalendarCellEventReserved>
        </CalendarCellEventWrapper>
      );
  }
};

export default CustomerCellEvent;
