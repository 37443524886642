import React, { FC, useRef, useState } from "react";
import LogoImg from "../../../../images/logo-light.svg";
import {
  HeaderContainer,
  HeaderIconButton,
  HeaderLogo,
  HeaderNav,
  HeaderSidebarButton,
} from "./styles";
import HeaderMenu from "./HeaderMenu";
import { useMediaQuery } from "react-responsive";
import { useOnClickOutside } from "../../../../utils/hooks";
import { SetState } from "../../../../utils/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faCaretSquareDown } from "@fortawesome/free-solid-svg-icons";
import { size } from "../../../../utils/styles";
import { COLORS } from "../../../../utils/constants";
import { useStore } from "../../../../utils/store";

const Header: FC<{
  sidebar?: boolean;
  sidebarOpen?: boolean;
  setSidebarOpen?: SetState<boolean>;
  sidebarInfo?: any;
}> = ({ sidebar, sidebarOpen, setSidebarOpen, sidebarInfo }) => {
  const isLaptop = useMediaQuery({
    query: `(min-width: ${size.laptop})`,
  });
  const [open, setOpen] = useState(false);
  const user = useStore((store) => store.user);

  const node = useRef(null);
  useOnClickOutside(node, () => setOpen(false));

  return (
    <HeaderContainer>
      {!isLaptop &&
        sidebar &&
        sidebarOpen !== undefined &&
        setSidebarOpen !== undefined && (
          <HeaderSidebarButton onClick={() => setSidebarOpen((prev) => !prev)}>
            {sidebarInfo && sidebarInfo}
            <FontAwesomeIcon
              icon={faCaretSquareDown}
              color={sidebarOpen ? COLORS.primary : COLORS.white}
              size="3x"
            />
          </HeaderSidebarButton>
        )}
      <a href="https://safeboxes.cz">
        <HeaderLogo src={LogoImg} alt="Safeboxes" />
      </a>
      <HeaderNav ref={node}>
        <HeaderMenu open={open} setOpen={setOpen} />
        {(!user || user?.role === "Customer") && (
          <HeaderIconButton onClick={() => setOpen((prev) => !prev)}>
            <FontAwesomeIcon
              icon={faBars}
              color={open ? COLORS.primary : COLORS.white}
              size="3x"
            />
          </HeaderIconButton>
        )}
      </HeaderNav>
    </HeaderContainer>
  );
};

export default Header;
