import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import documentApi from "../../../../api/document";
import { Invoice } from "../../../../models/document";
import { FadeInBottom } from "../../../../styles/fadeIn";
import { getErrorMessage } from "../../../../utils/errors";
import Loader from "../../../common/Loader/Loader";
import InvoicesTable from "../../../user/Invoices/InvoicesTable/InvoicesTable";
import AdminInvoicePayModal from "../../AdminInvoices/AdminInvoicePayModal";
import { AdminClientsDetailTabsLoader } from "./styles";

const AdminClientsDetailInvoices: FC = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [data, setData] = useState<Invoice[]>([]);
  const [loading, setLoading] = useState(true);
  const [payOpen, setPayOpen] = useState(false);
  const [payInvoiceId, setPayInvoiceId] = useState("");

  const handlePay = (invoiceId: string) => {
    setPayInvoiceId(invoiceId);
    setPayOpen(true);
  };

  const handlePayConfirm = async () => {
    try {
      await documentApi.payUserInvoice(payInvoiceId);
      setData(
        data.map((x) => {
          if (x.id === payInvoiceId) {
            x.paid = new Date().toString();
            x.canPay = false;
          }
          return x;
        })
      );
      toast.success(t("common.saveSuccess"));
    } catch (err) {
      toast.error(getErrorMessage(err, t("errors.saveError")));
    }
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const response = await documentApi.getUserInvoices(id);
        setData(response.data);
      } catch (err) {
        toast.error(getErrorMessage(err, t("errors.loadError")));
      }
      setLoading(false);
    })();
  }, [id, t]);

  return loading ? (
    <AdminClientsDetailTabsLoader>
      <Loader />
    </AdminClientsDetailTabsLoader>
  ) : (
    <FadeInBottom>
      <AdminInvoicePayModal
        open={payOpen}
        setOpen={setPayOpen}
        confirm={handlePayConfirm}
      />
      <InvoicesTable data={data} onPay={handlePay} />
    </FadeInBottom>
  );
};

export default AdminClientsDetailInvoices;
